/* eslint-disable no-unused-vars */
import * as React from "react";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import parseISO from "date-fns/parseISO";
import * as CommonValues from "./common-values"
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography"

const styles = (theme) => ({
    list: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
    },

    listItemLevel1: {
        paddingBottom: "2px",
        paddingTop: "2px",
    },

    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    listItemText: {
        color: theme.menuListItem.colour
    },

    topLevelText: {
        color: theme.menuListItem.colour
    },

    listIconNested: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    nestedLevel1: {
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(0),
        color: theme.menuListItem.colour
    }

});

/* eslint-enable no-unused-vars */

/* eslint-disable no-useless-escape */
const isoDateRegex = /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)?$/;
/* eslint-enable no-useless-escape */

export default class CustomCell extends React.Component {
    getRenderValue() {
        const dateLocale =
            this.props.columnDef.dateSetting &&
            this.props.columnDef.dateSetting.locale
                ? this.props.columnDef.dateSetting.locale
                : undefined;
        if (
            this.props.columnDef.emptyValue !== undefined &&
            (this.props.value === undefined || this.props.value === null)
        ) {
            return this.getEmptyValue(this.props.columnDef.emptyValue);
        }
        if (this.props.columnDef.render) {
            if (this.props.rowData) {
                return this.props.columnDef.render(this.props.rowData, "row");
            } else if (this.props.value) {
                return this.props.columnDef.render(this.props.value, "group");
            }
        } else if (this.props.columnDef.type === "boolean") {
            const style = { textAlign: "left", verticalAlign: "middle", width: 48 };
            if (this.props.value) {
                return <this.props.icons.Check style={style} />;
            } else {
                return <this.props.icons.ThirdStateCheck style={style} />;
            }
        } else if (this.props.columnDef.type === "date") {
            if (this.props.value instanceof Date) {
                return this.props.value.toLocaleDateString(dateLocale);
            } else if (isoDateRegex.exec(this.props.value)) {
                return parseISO(this.props.value).toLocaleDateString(dateLocale);
            } else {
                return this.props.value;
            }
        } else if (this.props.columnDef.type === "time") {
            if (this.props.value instanceof Date) {
                return this.props.value.toLocaleTimeString();
            } else if (isoDateRegex.exec(this.props.value)) {
                return parseISO(this.props.value).toLocaleTimeString(dateLocale);
            } else {
                return this.props.value;
            }
        } else if (this.props.columnDef.type === "datetime") {
            if (this.props.value instanceof Date) {
                return this.props.value.toLocaleString();
            } else if (isoDateRegex.exec(this.props.value)) {
                return parseISO(this.props.value).toLocaleString(dateLocale);
            } else {
                return this.props.value;
            }
        } else if (this.props.columnDef.type === "currency") {
            return this.getCurrencyValue(
                this.props.columnDef.currencySetting,
                this.props.value
            );
        } else if (typeof this.props.value === "boolean") {
            // To avoid forwardref boolean children.
            return this.props.value.toString();
        }

        return this.props.value;
    }

    getEmptyValue(emptyValue) {
        if (typeof emptyValue === "function") {
            return this.props.columnDef.emptyValue(this.props.rowData);
        } else {
            return emptyValue;
        }
    }

    getCurrencyValue(currencySetting, value) {
        if (currencySetting !== undefined) {
            return new Intl.NumberFormat(
                currencySetting.locale !== undefined ? currencySetting.locale : "en-US",
                {
                    style: "currency",
                    currency:
                        currencySetting.currencyCode !== undefined
                            ? currencySetting.currencyCode
                            : "USD",
                    minimumFractionDigits:
                        currencySetting.minimumFractionDigits !== undefined
                            ? currencySetting.minimumFractionDigits
                            : 2,
                    maximumFractionDigits:
                        currencySetting.maximumFractionDigits !== undefined
                            ? currencySetting.maximumFractionDigits
                            : 2,
                }
            ).format(value !== undefined ? value : 0);
        } else {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(value !== undefined ? value : 0);
        }
    }

    handleClickCell = (e) => {
       this.props.onGroupExpandChanged(this.props.path)
        // if (this.props.columnDef.disableClick) {
        //     e.stopPropagation();
        // }
    };

    getStyle = () => {
        const width = CommonValues.reducePercentsInCalc(
            this.props.columnDef.tableData.width,
            this.props.scrollWidth
        );

        let cellStyle = {
            color: "inherit",
            width,
            maxWidth: this.props.columnDef.maxWidth,
            minWidth: this.props.columnDef.minWidth,
            boxSizing: "border-box",
            fontSize: "inherit",
            fontFamily: "inherit",
            // fontWeight: "bold",
            // backgroundColor: 'grey'
        };

        if (typeof this.props.columnDef.cellStyle === "function") {
            cellStyle = {
                ...cellStyle,
                ...this.props.columnDef.cellStyle(this.props.value, this.props.rowData),
            };
        } else {
            cellStyle = { ...cellStyle, ...this.props.columnDef.cellStyle };
        }

        if (this.props.columnDef.disableClick) {
            cellStyle.cursor = "default";
        }

        return { ...this.props.style, ...cellStyle };
    };

    render() {

        const {
            icons,
            columnDef,
            rowData,
            errorState,
            cellEditable,
            onCellEditStarted,
            scrollWidth,
            ...cellProps
        } = this.props;
        const cellAlignment =
            columnDef.align !== undefined
                ? columnDef.align
                : ["numeric", "currency"].indexOf(this.props.columnDef.type) !== -1
                    ? "right"
                    : "left";

        let renderValue = this.getRenderValue();

        if (!renderValue) {
            renderValue = "Not categorised"
        }
        if (cellEditable) {
            renderValue = (
                <div
                    style={{
                        borderBottom: "1px dashed grey",
                        cursor: "pointer",
                        width: "max-content",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        onCellEditStarted(this.props.rowData, this.props.columnDef);
                    }}
                >
                    {renderValue}
                </div>
            );
        }

        const groupData = this.props.groupData;

        const iterate = (obj) => {
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    if (key === 'data') {
                        if (Array.isArray(obj[key])) {
                            docCount = docCount + obj[key].length
                        }
                    }
                    iterate(obj[key])
                }
            })
        }
        let docCount = 0;
        iterate(groupData)

        let groupingIcon =
            {
                expanded: this.props.groupingIcon && this.props.groupingIcon.expanded ? this.props.groupingIcon.expanded :  "folder_open",
                collapsed: this.props.groupingIcon && this.props.groupingIcon.collapsed ? this.props.groupingIcon.collapsed : "folder"
            };


        return (
            // <TableCell
            //     size={this.props.size}
            //     {...cellProps}
            //     style={this.getStyle()}
            //     align={cellAlignment}
            //     onClick={this.handleClickCell}
            // >
            //     {this.props.children}
            //     {renderValue}
            // </TableCell>

            <TableCell
                    size={this.props.size}
                    {...cellProps}
                    style={this.getStyle()}
                    align={cellAlignment}
                    onClick={this.handleClickCell}
            >
                    <ListItem button
                              selected={groupData.isExpanded}
                              key={renderValue} id={renderValue}
                    >
                        <ListItemIcon style={{color: this.props.iconColour}}><i className='material-icons' color={'primary'}>{groupData.isExpanded ? groupingIcon.expanded : groupingIcon.collapsed}</i></ListItemIcon>
                        <ListItemText
                              primary={
                                  <Typography type="body2" color={'secondary'} style={{fontWeight: 'bold'}}>{renderValue}</Typography>}
                              secondary = {(!groupData.isExpanded && docCount > 0 ) && docCount  + " document" +
                                  (docCount === 1 ? "" : "s")}
                        />
                    </ListItem>
            </TableCell>

        );
    }
}

CustomCell.defaultProps = {
    columnDef: {},
    value: undefined,
};

CustomCell.propTypes = {
    columnDef: PropTypes.object.isRequired,
    value: PropTypes.any,
    rowData: PropTypes.object,
    groupData: PropTypes.object,
    errorState: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onGroupExpandChanged: PropTypes.func,
    path: PropTypes.string,
    iconColour: PropTypes.string,
    groupingIcon: PropTypes.object
};