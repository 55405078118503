import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {numberWithCommas} from "../../common/helper";

const styles = theme => ({});

class InfiniteScrollLoader extends React.Component {

    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('InfiniteScrollLoader props=', this.props);

        let fetchingText = "Retrieving next " + this.props.searchLimit + " search results...";
        if ( this.props.searchReturnedCount + this.props.searchLimit >= this.props.searchTotalCount) {
            const remaining = this.props.searchTotalCount - this.props.searchReturnedCount;
            fetchingText = "Retrieving remaining " + numberWithCommas(remaining) ;
            if (remaining === 1) {
                fetchingText = fetchingText + " search result..."
            } else {
                fetchingText = fetchingText + " search results..."
            }
        }

        let actionComponents = [];
        actionComponents.push(
            <Tooltip title={"Show more"} key={"footerTT"}>
                <IconButton onClick={this.props.getNextResults} size={'small'} key={"ibNext"}>
                    <i className='material-icons' key={"iNext"}>play_arrow</i>
                </IconButton>
            </Tooltip>
        );

        // actionComponents.push(
        //     <Tooltip title={"Show all"}>
        //         <IconButton onClick={this.props.getAllResults} size={'small'}>
        //             <i className='material-icons'>skip_next</i>
        //         </IconButton>
        //     </Tooltip>
        // );

        return (
            <React.Fragment>
                {
                    this.props.isFetching &&
                    <React.Fragment>
                        <LinearProgress style={{marginTop: '0px'}} color="primary"/>
                    </React.Fragment>
                }

                {
                    this.props.nextMarker || this.props.searchTotalCount === 0 ?

                        //Results from Metadata API where more docs (nextMarker) are available but we don't know total count

                        <BottomNavigation style={{justifyContent: 'left', paddingTop: '7px', height: '100%'}} showlabel="">
                            {/* 'default', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary'*/}
                            <Typography variant={"subtitle2"} color={'secondary'} style={{fontWeight: "normal"}}>
                                {
                                    this.props.isFetching ?
                                        "Retrieving more results..." :
                                        this.props.nextMarker ?
                                            "Showing first " + this.props.searchReturnedCount + " search results" :
                                            this.props.searchReturnedCount === 1 ?
                                                "Showing all search results" :
                                                "Showing all " + this.props.searchReturnedCount + " search results"
                                }
                                {
                                    (this.props.nextMarker && !this.props.isFetching)  ?
                                        actionComponents :
                                        ""
                                }
                            </Typography>
                        </BottomNavigation> :

                        //Case Search Results, Simple Search Results, Advanced Search Results

                        <BottomNavigation style={{justifyContent: 'left', paddingTop: '7px', height: '100%'}} showlabel="">
                            {/* 'default', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary'*/}
                            {/*React does not recognize the `showLabel` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `showlabel` instead*/}
                            <Typography variant={"subtitle2"} color={'secondary'} style={{fontWeight: "normal"}}>
                                {
                                    this.props.isFetching ?
                                        fetchingText :
                                        "Showing " + this.props.searchReturnedCount + " of " + this.props.searchTotalCount + " search results"
                                }
                                {
                                    (this.props.searchTotalCount > this.props.searchReturnedCount && !this.props.isFetching) ?
                                        actionComponents : ""
                                }
                            </Typography>
                        </BottomNavigation>
                }

            </React.Fragment>
        );
    };

}

InfiniteScrollLoader.propTypes = {
    classes: PropTypes.object.isRequired,
    searchReturnedCount: PropTypes.number.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    nextMarker: PropTypes.string
};

export default withSnackbar(withStyles(styles, { withTheme: true })(InfiniteScrollLoader));

