import React from 'react';
import {addLocaleData} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import 'box-ui-elements/dist/preview.css';
import 'box-ui-elements/dist/explorer.css';
import messages from 'box-ui-elements/i18n/en-US';
import PropTypes from 'prop-types';
import {ContentPreview, ContentOpenWith} from 'box-ui-elements';
import {hasAccess} from "../../common/helper";
import {withSnackbar} from "notistack";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Grid} from "@material-ui/core";

addLocaleData(enLocaleData);

//const LANGUAGE = 'en_UK';
const LANGUAGE = "en-US";

/*

fileOptions info:
    https://github.com/box/box-ui-elements/blob/master/src/elements/content-preview/README.md

    Note: Any other option listed here https://github.com/box/box-content-preview#parameters--options,
    which is also not listed or overridden above as a prop, will be passed on as-is to the Preview Library.

    https://github.com/box/box-content-preview#parameters--options

 */
class BoxViewer extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            pageNumber: this.props.pageNumber
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log('preview props = ', props);

    }

    componentDidMount() {
       // this.getServiceToken();
    }

    componentDidUpdate(prevProps) {

        //Remount Preview if page changes and using box viewer - required for ContentPreview element to pick up change in page number
        if (this.state.pageNumber!== this.props.pageNumber){
            if (this.props.unmountComponent) {
                this.props.unmountComponent();
            }
        }
    }

    componentWillUnmount() {
        if (this.props.remountComponent) {
            this.props.remountComponent();
        }
    }

    // getServiceToken = () => {
    //     const queryStr = "?userId=" + this.props.userDetails.boxId;
    //     console.log(' - GET SERVICE TOKEN - ')
    //     let boxServiceToken = null;
    //     fetch(window.REACT_APP_CONTENT_API_BASE_URL + "/api/box/security/serviceToken" + queryStr, {
    //         headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
    //     })
    //         .then(response => response.json())
    //         .then(resultA => {
    //             window.location.pathname.toLowerCase().includes("debug") && console.log('resultA=', resultA)
    //             boxServiceToken = resultA.access_token;
    //             this.setState({boxServiceToken: boxServiceToken});
    //             window.location.pathname.toLowerCase().includes("debug") && console.log('box response service token = ', boxServiceToken)
    //         })
    //         .catch(e => {
    //             window.location.pathname.toLowerCase().includes("debug") && console.log(e);
    //            // setErrorDetails("Box Auth Error " + e);
    //            // setUserDetails(null);
    //         })
    // }

    render() {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        let fileOptions = {};
        fileOptions[this.props.boxDocID] = {
            startAt: {
                unit: 'pages',
                value: this.props.pageNumber
            }
        };
        debug &&  console.log ('fileOptions=', fileOptions)
        
        const previewAction = this.props.actionsConfig.previewDocument;

        let enableOpenWith = false;
        if (!this.props.preventOpen) { //e.g. getTemplates dialog
            if (previewAction) {
                if (previewAction.openWith) {
                    if (previewAction.openWith.enabled) {
                        if (hasAccess(previewAction.openWith, this.props.userDetails.userRoles)) {
                            enableOpenWith = true
                        }
                    }
                }
            }
        }

        debug && console.log ('enableOpenWith = ', enableOpenWith)

        let showAnnotations = false;
        if (previewAction) {
            if (previewAction.showAnnotations) {
                if (previewAction.showAnnotations.enabled) {
                    if (hasAccess(previewAction.showAnnotations, this.props.userDetails.userRoles)) {
                        showAnnotations = true
                    }
                }
            }
        }

        /*
             TODO showDownload
             showDownload documentation:
             Whether download button is shown in header.
             Will also control print button visibility in viewers that support print.
             Note that this option will not override download permissions on the access token.
        */

        let contentSidebarProps = {};

        if (this.props.showSidebar) {

            let enableSidebarDetails = false;
            let detailsSidebarProps = {};
            if (previewAction.sidebarDetails) {
                if (previewAction.sidebarDetails.enabled) {
                    if (hasAccess(previewAction.sidebarDetails, this.props.userDetails.userRoles)) {
                        enableSidebarDetails = true
                        detailsSidebarProps = JSON.parse(JSON.stringify(previewAction.sidebarDetails.options));

                        //Access Stats now requires editor access and above so control access via user roles
                        let accessStats = previewAction.sidebarDetails.options.hasAccessStats;
                        if (accessStats) {
                            detailsSidebarProps.hasAccessStats = accessStats.enabled && hasAccess(accessStats, this.props.userDetails.userRoles);
                        }
                        console.log ('detailsSidebarProps =', detailsSidebarProps);
                    }
                }
            }

            let enableSidebarMetadata = false;
            if (previewAction.sidebarMetadata) {
                if (previewAction.sidebarMetadata.enabled) {
                    if (hasAccess(previewAction.sidebarMetadata, this.props.userDetails.userRoles)) {
                        enableSidebarMetadata = true
                    }
                }
            }

            //Note Activity feed requires token scopes adjusted to work
            let enableSidebarActivityFeed = false;
            if (previewAction.sidebarActivityFeed) {
                if (previewAction.sidebarActivityFeed.enabled) {
                    if (hasAccess(previewAction.sidebarActivityFeed, this.props.userDetails.userRoles)) {
                        enableSidebarActivityFeed = true
                    }
                }
            }

            //Note sidebar details needs to be enabled for versions to appear (with at least one option enabled)
            let enableSidebarVersions = false;
            if (previewAction.sidebarVersions) {
                if (previewAction.sidebarVersions.enabled) {
                    if (hasAccess(previewAction.sidebarVersions, this.props.userDetails.userRoles)) {
                        enableSidebarVersions = true
                    }
                }
            }

            debug && console.log (
                'enableSidebarDetails = ', enableSidebarDetails,
                'enableSidebarMetadata = ' , enableSidebarMetadata,
                'enableSidebarActivityFeed = ' , enableSidebarActivityFeed,
                'enableSidebarVersions = ' , enableSidebarVersions
            );

            contentSidebarProps = {
                detailsSidebarProps: detailsSidebarProps,
                hasActivityFeed: enableSidebarActivityFeed,
                hasMetadata: enableSidebarMetadata,
                hasSkills: false,
                hasVersions: enableSidebarVersions,
            }
        }

        return (
            <React.Fragment>
                {
                    enableOpenWith &&
                    <div style={{height: "40px", marginTop: "-40px", paddingRight: "150px"}}>
                        <div style={{"height": "40px"}}/>  {/*used in conjunction with negative margin to force overlap of preview element*/}
                        <Grid container>
                            <Grid item xs={11} style={{textAlign: "right"}}>
                                <ContentOpenWith
                                    fileId={this.props.boxDocID}
                                    token={this.props.userDetails.boxAccessToken}
                                />
                            </Grid>
                            <Grid item xs={1} style={{textAlign: "left"}}>
                                <Tooltip title={"Refresh"}>
                                    <IconButton onClick={this.props.unmountComponent}><i className='material-icons'>refresh</i></IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </div>
                }

                <ContentPreview
                    hasHeader={this.props.showHeader} //hide header to hide print/download options
                    fileId={this.props.boxDocID}
                    token={this.props.userDetails.boxAccessToken}
                    language={LANGUAGE}
                    messages={messages}
                    showAnnotations={showAnnotations}
                    showDownload={true}
                    fileOptions={fileOptions}
                    contentSidebarProps={contentSidebarProps}
                />

            </React.Fragment>

        )
    }
}

BoxViewer.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    pageNumber: PropTypes.number,
    showHeader: PropTypes.bool.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    showSidebar: PropTypes.bool,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    preventOpen: PropTypes.bool
};

export default  withSnackbar(BoxViewer);