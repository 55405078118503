import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import {withSnackbar} from "notistack";

const styles = theme => ({})

function RefreshDashboard(props) {
    return (
        <Tooltip title={"Reload Dashboard"}>
            <IconButton
                color={"inherit"}
                aria-label={"Reload Dashboard"}
                aria-haspopup="true"
                onClick={props.unmountDashboard}>
                {
                    <i className='material-icons'>refresh</i>
                }
            </IconButton>
        </Tooltip>
    );
}

RefreshDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    unmountDashboard: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(RefreshDashboard));