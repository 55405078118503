import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton/IconButton";
import PropTypes from "prop-types";

const styles = theme => ({});

function FolderIcon(props){

    return (

        <div>
            <IconButton color="inherit" style={{ backgroundColor: 'transparent' }}>
                <i className='material-icons'> {props.icon ? props.icon : "folder_open"}</i>
            </IconButton>

        </div>

    );

}

FolderIcon.propTypes = {
    icon: PropTypes.string
}

export default withStyles(styles)(FolderIcon);