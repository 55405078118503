import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import AddFolderTaskDialog from "./AddFolderTaskDialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});

const INITIAL_STATE = {
    //dialog
    open: false,
};

class AddFolderTaskButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        window.location.pathname.toLowerCase().includes("debug") && console.log('Add Folder Button, props = ', props);

    }

    showDialog = () => {
        this.setState({
            //open: this.props.isOpen
            showDialog: true
        });
    };

    closeDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showDialog: false,
        });
        if (this.props.getFolderTasks) {
            this.props.getFolderTasks();
        }
    };


    render() {


        return (
            <React.Fragment>
                <Tooltip title={'Add task'}>
                    <IconButton color = "secondary" aria-label={'Add task'}
                                onClick={this.showDialog}>
                        <i className='material-icons'>add</i>
                    </IconButton>
                </Tooltip>

                {this.state.showDialog &&
                    <AddFolderTaskDialog
                        parentClasses={this.props.classes}
                        boxDocID={this.props.parentFolderId}
                        userDetails={this.props.userDetails}
                        calledFromDocumentMenu={false}
                        handleCloseDialog = {this.closeDialog}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        getFolderTasks={this.props.getFolderTasks}
                        folderTasksConfig={this.props.folderTasksConfig}
                        defaultTaskDesc={this.props.defaultTaskDesc}
                        folderDetails={this.props.folderDetails}
                        snippet={this.props.snippet}
                    />}
            </React.Fragment>
        );
    }
}

AddFolderTaskButton.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    parentFolderId: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    getFolderTasks: PropTypes.func.isRequired,
    folderTasksConfig: PropTypes.object.isRequired,
    defaultTaskDesc: PropTypes.string,
    folderDetails: PropTypes.object,
    snippet: PropTypes.object
};

export default withSnackbar(withStyles(styles, { withTheme: true })(AddFolderTaskButton));
