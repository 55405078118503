import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core";
import {withSnackbar} from "notistack";
import MLTypes from "./MLTypes";
import {asyncForEach} from "../../../common/helper"

const styles = theme => ({});

class MLResults extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            selectedPage: "",
            results: this.props.result.categories
        };
        window.location.pathname.toLowerCase().includes("debug") && console.log('MLResults props =', props)

        this.actionSnippet = this.actionSnippet.bind(this);
        this.actionPage = this.actionPage.bind(this);

    }

    selectPage = (pageNumber, snippet) => {
        if (pageNumber !== this.state.selectedPage || snippet) {
            this.setState({selectedPage: pageNumber});
            this.props.changePageNumber(parseInt(pageNumber), snippet);
        }
    };

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    actionSnippet = async(typeIndex, entityIndex, pageIndex, snippetIndex, snippet, action) => {

        //action =  approve / reject

        snippet.review_status = action === "approve" ? "VALID MATCH" : "FALSE POSITIVE"
        snippet.modified_by = "Dee Marsh"

        const success = true;
        console.log ('>>> >>> >>> update snippet results...success=', success , 'updated snippet=', snippet)
        let updatedResults = this.state.results;
        updatedResults[typeIndex].results[entityIndex].pages[pageIndex].snippets[snippetIndex] = snippet

        console.log ('>>> >>> >>> entityIndex', entityIndex , 'pageIndex', pageIndex, 'snippetIndex', snippetIndex, '=============snippet done=============');
        this.setState({
            results: updatedResults,
            isFetching: false
        });
        this.props.updateAllSnippetsActioned (updatedResults);

        this.setState({isFetching: false})

        return success;
    };

    actionEntity = async (typeIndex, entityIndex, action) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('>>> actionEntity typeIndex = ', typeIndex, " entityIndex=", entityIndex, 'action=', action)

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            let entity = this.state.results[typeIndex].results[entityIndex];

            let pageIndex = -1
            await asyncForEach(entity.pages, async (page) => {
                pageIndex++;

                await this.actionPage(typeIndex, entityIndex, pageIndex, page, action)
            });

            return true;
        };

        let response = await start();
        // All pages in entity Done -  show snackbar
        console.log ('>>>entityIndex', entityIndex + '=============all pages in entity done=============');
        return response;
    };

    actionPage = async(typeIndex, entityIndex, pageIndex, page, action) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('>>> >>> actionPage typeIndex = ', typeIndex, " entityIndex=", entityIndex, 'pageIndex=', pageIndex)


        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            let snippetIndex = -1;
            await asyncForEach(page.snippets, async (snippet) => {
                snippetIndex++
                await this.actionSnippet(typeIndex, entityIndex, pageIndex, snippetIndex, snippet, action)
                    .then(result => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('>>> >>> result from actionSnippet = ', result);
                    })
            });

            // All snippets in page Done
            console.log ('>>> >>> pageIndex', pageIndex + '=============all snippets in page done=============');
        };

        await start();
    };

    render() {

        //TODO Add grid to scroll the list separate to the header

        return (

            <MLTypes
                selectPage = {this.selectPage}
                results = {this.state.results}
                changePageNumber = {this.props.changePageNumber}
                actionSnippet={this.actionSnippet}
                actionEntity={this.actionEntity}
                userDetails={this.props.userDetails}
                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                enableApproveRejectSnippet={this.props.enableApproveRejectSnippet}
                resultId={this.props.result.id}
                disableApproveRejectSnippet={this.props.disableApproveRejectSnippet}
                folderDetails={this.props.folderDetails}
            />

        );
    }
}
MLResults.propTypes = {
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    changePageNumber: PropTypes.func.isRequired,
    result: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    updateAllSnippetsActioned: PropTypes.func.isRequired,
    enableApproveRejectSnippet: PropTypes.bool.isRequired,
    folderDetails: PropTypes.object
};

export default withSnackbar(withStyles(styles, { withTheme: true })(MLResults));

