import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import Input from "@material-ui/core/Input/Input";
import {withSnackbar} from "notistack";
import DialogTitle from "@material-ui/core/DialogTitle";

function ViewSharedLinkFolderDialog(props){
    
    const [open, setOpen] = useState(true)
    const [textArea, setTextArea] = useState()
    
    const handleCloseDialog = () => {
        setOpen(false)

        if (props.handleCloseMenu) {
            props.handleCloseMenu()
        }
        //Added this to address issue of dialog not re-opening after initial open
        if (props.handleCloseDialog) {
            props.handleCloseDialog()
        }
    };

    const copyToClipboard = (e) => {
        textArea.select();
        document.execCommand('copy');
        e.target.focus();
        props.enqueueSnackbar("URL copied to clipboard", {variant: 'success'});
        handleCloseDialog();
    };

    const actionConfig = props.actionConfig;

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>
                    {
                        actionConfig && actionConfig.dialogTitle ?
                            actionConfig.dialogTitle :
                            'Shared Link'
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            actionConfig && actionConfig.dialogText ?
                                actionConfig.dialogText :
                                'Use the URL below to access the shared link for this folder'
                        }
                    </DialogContentText>
                    <br/>

                    <Input
                        id = "fileLink"
                        name = "fileLink"
                        value = {props.sharedLink}
                        inputRef = {(textarea) => setTextArea(textarea)}
                        type = "text"
                        margin="none"
                        variant="outlined"
                        readOnly={true}
                        style={{width: "100%"}}
                        autoFocus={true}
                        onFocus={event => {event.target.select()}}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={copyToClipboard}
                            variant="contained"
                            color="secondary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseDialog}
                            variant="contained"
                            color="secondary">
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

ViewSharedLinkFolderDialog.propTypes = {
    classes: PropTypes.object,
    sharedLink: PropTypes.string.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    handleCloseMenu: PropTypes.func,
    actionConfig: PropTypes.object.isRequired
};


export default withSnackbar(ViewSharedLinkFolderDialog);