import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Typography from '@material-ui/core/Typography';

const styles = theme => ({});

class InfiniteScrollEndMessage extends React.Component {

    render() {

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('InfiniteScrollEndMessage props=', this.props);

        return (
            <React.Fragment>

                {
                    this.props.nextMarker || this.props.searchTotalCount === 0 ?

                        //Results from Metadata API where more docs (nextMarker) are available but we don't know total count

                        <BottomNavigation style={{justifyContent: 'left', paddingTop: '7px', height: '100%'}} showlabel="">
                            {/* 'default', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary'*/}
                            <Typography variant={"subtitle2"} color={'secondary'} style={{fontWeight: "normal"}}>
                                {
                                    this.props.searchReturnedCount === 1 ?
                                        "Showing all search results" :
                                        "Showing all " + this.props.searchReturnedCount + " search results"
                                }

                            </Typography>
                        </BottomNavigation> :

                        //Case Search Results, Simple Search Results, Advanced Search Results

                        <BottomNavigation style={{justifyContent: 'left', paddingTop: '7px', height: '100%'}} showlabel="">
                            {/* 'default', 'error', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary'*/}
                            {/*React does not recognize the `showLabel` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `showlabel` instead*/}
                            <Typography variant={"subtitle2"} color={'secondary'} style={{fontWeight: "normal"}}>
                                {"Showing " + this.props.searchReturnedCount + " of " + this.props.searchTotalCount + " search results"}
                            </Typography>
                        </BottomNavigation>
                }

            </React.Fragment>
        );
    };
}

InfiniteScrollEndMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    searchReturnedCount: PropTypes.number.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    nextMarker: PropTypes.string
};

export default withSnackbar(withStyles(styles, { withTheme: true })(InfiniteScrollEndMessage));

