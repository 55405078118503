import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import BulkUploadDialog from "./BulkUploadDialog";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem/ListItem";

const styles = theme => ({
    menuListItem: {
        color: theme.menuListItem.colour
    },
    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },
});

const INITIAL_STATE = {
    //dialog
    open: false,
};

class BulkUploadButton extends React.Component {

    constructor(props) {

        console.log ('BulkUploadButton props = ', props);

        super(props);
        this.state = INITIAL_STATE;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('BulkUploadButton props: ', props);
    }

    addClick = () => {
        this.showBulkUploadDialog()
    };

    showBulkUploadDialog = () => {
        this.setState({
            //open: this.props.isOpen
            showBulkUploadDialog: true
        });
    };

    closeBulkUploadDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showBulkUploadDialog: false,
        });
        //force unmount to refresh folder documents
        if (this.props.unmountFolderDocumentsGet) {
            this.props.unmountFolderDocumentsGet();
        }
    };


    cancelBulkUploadDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showBulkUploadDialog: false,
        });
    };

    render() {
        const { classes, uploadConfig } = this.props;

        const folderSearchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch;
        const indexes = folderSearchConfig && folderSearchConfig.elastic && folderSearchConfig.elastic.indexes;

        return (
            <React.Fragment>
                <ListItem
                    button={true}
                    key={'mydocuments'}
                    id={'mydocuments'}
                    onClick={this.showBulkUploadDialog}
                >
                    <ListItemIcon  className={classes.listIcon}><i className='material-icons'>library_add</i></ListItemIcon>
                    <ListItemText className={classes.menuListItem} primary={"Bulk Upload"}/>
                </ListItem>

                {this.state.showBulkUploadDialog &&
                    <BulkUploadDialog
                        parentClasses={this.props.classes}
                        userDetails={this.props.userDetails}
                        uploadConfig={uploadConfig}
                        metadataConfig={this.props.metadataConfig}
                        optionsConfig={this.props.optionsConfig}
                        closeDialog={this.closeBulkUploadDialog}
                        cancelDialog={this.cancelBulkUploadDialog}
                        folderDetails = {this.props.folderDetails}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        actionConfig={this.props.actionConfig}
                        indexes={indexes}
                    />}

            </React.Fragment>
        );
    }
}

BulkUploadButton.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object,
    unmountFolderDocumentsGet: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    indexes: PropTypes.array //required when folder source is elastic
};


export default withSnackbar(withStyles(styles, { withTheme: true })(BulkUploadButton));
