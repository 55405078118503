import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import GetTemplates from "./GetTemplates";
import SelectTemplateTable from "./SelectTemplateTable";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {green, grey, red} from "@material-ui/core/colors";
import {Grid, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {getErrorMessageFromResponse, getFieldConfig} from "../../../common/helper";
import RenderMetadataField from "../../../common/RenderMetadataField";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    greenAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: green[500],
    },
    greyAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: grey[500],
    },
});

function getColour(statusIconClass) {

    switch(statusIconClass) {
        case "redAvatar":
            return(red[500]);
        case "greenAvatar":
            return(green[500]);
        default:
            return(grey[500])
    }
}

const INITIAL_STATE = {
    open: false,
    mountGetTemplates: true,
    mountSelectTemplateTable: false,
    searchLimit: parseInt(window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT),
    searchOffset: 0,
    searchTotalCount: 0,
    newSearch: true,
    isFetching: true,
    copyInProgress: false,
    searchResults: [],
    enableContinue: false,
    filesAdded: true,
    filesToCopy: []
};

class SelectTemplateDialog extends React.Component {

    constructor(props) {
        super(props);

        //INITIAL_STATE.destinationFolderId = props.folderDetails ? props.folderDetails.id : props.folderDetails.id
        INITIAL_STATE.destinationFolderId = props.destinationFolderId;

        let fileNamesValid = true;
        if (props.templateConfig.promptForFilename && !props.templateConfig.defaultToParentFilename) {
            fileNamesValid = false
        }
        INITIAL_STATE.fileNamesValid = fileNamesValid;

        //check if any mandatory user input metadata
        let metadataValid = true;
        if (props.templateConfig.setMetadata) {
            const userInput = props.templateConfig.setMetadata.userInput;
            if (userInput) {
                for (let i = 0; i < userInput.length; i++) {
                    if (userInput[i].mandatory && (!userInput[i].value || userInput[i].value === "")) {
                        //initialise metadataValid to false if any mandatory user input metadata that doesn't have a default value
                        metadataValid = false;
                        break;
                    }
                }
            }
        }


        INITIAL_STATE.metadataValid = metadataValid;

        this.state = INITIAL_STATE;

        this.remountGetTemplates = this.remountGetTemplates.bind(this);
        this.updateSearchResults = this.updateSearchResults.bind(this);
        this.updateIsFetching = this.updateIsFetching.bind(this);
        this.unmountSelectTemplateTable = this.unmountSelectTemplateTable.bind(this);
        this.remountSelectTemplateTable = this.remountSelectTemplateTable.bind(this);
        this.handleCopyFiles = this.handleCopyFiles.bind(this)

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log('SelectTemplateDialog props=', props)
    }

    UNSAFE_componentWillMount() {
        //this.getWorkspaceConfig()
    }

    componentDidMount() {

        this.setState({
            open: true
        })
    }


    updateIsFetching = (isFetching) => {
        this.setState({isFetching: isFetching})
    }

    handleClose = () => {
        this.setState({open: false});
        this.props.handleCloseDialog(this.state.filesAdded);
    }

    getFolderMetadata = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log('SelectTemplateDialog getFolderMetadata for parent folder');

        const pathVar = "/" + this.props.parentFolderId + "/metadata";
        //const params = "?userId=" + this.props.userDetails.boxId;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar;

        const request = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
        };

        debug && console.log ('getFolderMetadata url:' , url, 'request:' , request);

        await this.props.triggerRefreshAuthToken();
        let response = await (fetch(url,request));

        debug && console.log ('getFolderMetadata response = ', response);

        let responseCheck = await response.ok;

        if (responseCheck) {
            let data = await response.json();
            debug &&  console.log('response.json =', data);
            return data;
        } else {
            Promise.resolve(getErrorMessageFromResponse(response, 'retrieving metadata'))
                .then(message => {
                    this.props.enqueueSnackbar(message , {variant: 'error'});
                })
            debug && console.log("getFolderMetadata error. response.statusText:", response.statusText, {variant: 'error'});
            return null
        }
    };

    handleConfirmFileSelection = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        //Get metadata config to determine what metadata to set
        let metadataConfig = this.props.templateConfig.setMetadata;
        let fixedMetadata = [];
        if (metadataConfig.fixedMetadata) {
            fixedMetadata = metadataConfig.fixedMetadata
        }

        let userInput = [];
        if (metadataConfig.userInput) {
            userInput = metadataConfig.userInput
        }

        //Get parent folder metadata if required
        let parentFolderMetadataArray = [];

        // To set metadata from folder that the document is being added to, configure this in uploadConfig in workspaceConfig
        if (metadataConfig.fromParentFolder) {
            //add metadata specified to be applied from parent
            const setMetadataFromParentFolder = metadataConfig.fromParentFolder;
            if (setMetadataFromParentFolder && setMetadataFromParentFolder.length > 0){

                //get parent folder metadata
                let getMetadataResponse = await this.getFolderMetadata();
                let response = await getMetadataResponse;

                debug && console.log ('getFolderMetadata allMetadata = ' + JSON.stringify(response));

                //extract the metadata we need from the response
                //for each metadata template specified in config add item to array with templateKey, metadataKey and value
                if (response) {
                    for (let i = 0; i < setMetadataFromParentFolder.length; i++) {
                        for (let r = 0; r < response.length; r++) {
                            if (response[r]["$template"] === setMetadataFromParentFolder[i].templateKey) {
                                //get metadata for each metadataKey listed
                                setMetadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {
                                    if (response[r][metadataKey]) {
                                        parentFolderMetadataArray.push({
                                            templateKey: setMetadataFromParentFolder[i].templateKey,
                                            metadataKey: metadataKey,
                                            value: response[r][metadataKey]
                                        })
                                    }
                                })
                            }
                        }
                    }
                }
            }
        }

        //console.log ('parentFolderMetadataArray = ', parentFolderMetadataArray);

        //Set metadata on each file based on what is specified in config
        let files = this.state.filesToCopy;
        for (let f = 0; f < files.length; f++) {

            let fileMetadata = [];

            //add parent folder metadata
            debug && console.log ('f = ' , f , 'parentFolderMetadataArray = ', parentFolderMetadataArray);
            if (parentFolderMetadataArray.length > 0) {
                for (let i = 0; i < parentFolderMetadataArray.length; i++) {
                    fileMetadata.push(parentFolderMetadataArray[i])
                }
            }

            //add fixed metadata
            for (let i = 0; i < fixedMetadata.length; i++) {
                fileMetadata.push(fixedMetadata[i])
            }

            //add placeholder for metadata requiring user input
            for (let i = 0; i < userInput.length; i++) {
                fileMetadata.push(
                    {
                        "metadataKey": userInput[i].metadataKey,
                        "templateKey": userInput[i].templateKey,
                        "value": userInput[i].value
                    }
                )
            }

            //add metadata from parent file (i.e. file being copied)

            if (metadataConfig.fromParentFile) {

                if (metadataConfig.fromParentFile.length > 0) {
                    metadataConfig.fromParentFile.forEach(m => {
                        if (m.parentProperty) {
                            if (m.parentProperty.templateKey && m.parentProperty.metadataKey) {
                                if (m.parentProperty.templateKey === "n/a") { //used to read properties such as name, id
                                    if (files[f][m.parentProperty.metadataKey]) {
                                        fileMetadata.push({
                                            "templateKey": m.templateKey,
                                            "metadataKey": m.metadataKey,
                                            "value": files[f][m.parentProperty.metadataKey]

                                        })
                                    } else {
                                        debug && console.log('Unable to find value on parent file for ' + m.parentProperty.metadataKey)
                                    }
                                } else if (files[f][m.parentProperty.templateKey + "~" + m.parentProperty.metadataKey]) {
                                    fileMetadata.push(
                                        {
                                            "templateKey": m.templateKey,
                                            "metadataKey": m.metadataKey,
                                            "value": files[f][m.parentProperty.templateKey + "~" + m.parentProperty.metadataKey]
                                        })
                                } else {
                                    debug && console.log('Unable to find value on parent file for ' + m.parentProperty.templateKey + "~" + m.parentProperty.metadataKey + ' Ensure it is included in search query metadataKeys')
                                }

                            }
                        }
                    })
                }
            }

            //Update file with metadata
            files[f].metadata = fileMetadata;
        }

        this.setState({
            filesToCopy: files,
            fileSelectionConfirmed: true,
            mountSelectTemplateTable: false,
            filenameInputRequired: true
        })
    }

    handleChangeFilename = (index) => (event) => {

        const filesToCopyUpdated = this.state.filesToCopy;

        if (event.target.value.trim() === "") {
            filesToCopyUpdated[index].nameNew = "";
        } else {
            const originalFilename = this.state.filesToCopy[index].name;
            const fileExt = originalFilename.substring(originalFilename.lastIndexOf('.'));
            filesToCopyUpdated[index].nameNew = event.target.value + fileExt;
        }

        const fileNamesValid = this.validateFilenames(filesToCopyUpdated);

        this.setState({
            filesToCopy: filesToCopyUpdated,
            fileNamesValid: fileNamesValid
        });

    }

    handleChangeMetadata = (index, entry) => (id, newValue) => {

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0, 0, 0, 0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        const filesToCopyUpdated = this.state.filesToCopy;
        let file = filesToCopyUpdated[index];

        //let fileMetadata = file.metadata;
        if (file.metadata) {
            file.metadata.forEach(m => {
                if (m.templateKey === entry.templateKey && m.metadataKey === entry.metadataKey) {
                    m.value = val;
                }
            })
        }

        const metadataValid = this.validateRequiredMetadata(filesToCopyUpdated);

        this.setState({
            filesToCopy: filesToCopyUpdated,
            metadataValid: metadataValid
        });

    }

    validateFilenames = (files) => {

        //check if a filename is entered for each file being copied
        let isValid = true;

        for (let i = 0; i < files.length; i++) {
            let nameNew = files[i].nameNew && files[i].nameNew.substring(0, files[i].nameNew.lastIndexOf('.'));
            if (!nameNew || nameNew === "") {
                isValid = false;
                break;
            }
        }

        return isValid;
    }

    validateRequiredMetadata = (files) => {

        //check if a filename is entered for each file being copied
        let isValid = true;

        let templateConfig = this.props.templateConfig;
        let userInput = [];
        if (templateConfig.setMetadata) {
            if (templateConfig.setMetadata.userInput) {
                userInput = templateConfig.setMetadata.userInput
                for (let i = 0; i < files.length; i++) {
                    //check that there is a value for each metadata specified in userInput
                    if (userInput.length > 0) {
                        //ignore compile warning - Function declared in a loop contains unsafe references to variable(s) 'isValid'
                        //changed forEach arrow function to for loop to address warning
                        //userInput.forEach((entry) => {
                        for (let i = 0; i < userInput.length; i++) {
                            const entry = userInput[i];
                            if (entry.mandatory) {
                                //check file metadata for value
                                let fieldValue;
                                if (files[i].metadata) {
                                    files[i].metadata.forEach(m => {
                                        if (m.templateKey === entry.templateKey && m.metadataKey === entry.metadataKey) {
                                            fieldValue = m.value;
                                        }
                                    })
                                }

                                if (!fieldValue || fieldValue === "") {
                                    isValid = false;
                                }
                            }
                        }
                    }

                    if (!isValid) {
                        break;
                    }
                }
            }
        }

        return isValid;
    }

    handleCopyFiles = () => {
        this.setState({
            filenameInputComplete: true,
            fileSelectionConfirmed: true,
            mountSelectTemplateTable: false
        });


        //set any conditional metadata based on metadata input
        //Get metadata config to determine what metadata to set
        let metadataConfig = this.props.templateConfig.setMetadata;
        let conditionalMetadata;
        if (metadataConfig.conditionalMetadata) {
            conditionalMetadata = metadataConfig.conditionalMetadata
        }

        let filesToCopy = this.state.filesToCopy;

        if (conditionalMetadata && conditionalMetadata.length > 0) {
            filesToCopy.forEach(file =>{
                let updatedFileMetadata = file.metadata;

                conditionalMetadata.forEach(entry => {
                    let useDefaultValue = true;
                    if (entry.conditionalValue && entry.conditionalValue.templateKey && entry.conditionalValue.metadataKey) {
                        //get value from file
                        for (let i =0; i<file.metadata.length; i++){
                            let m = file.metadata[i]
                            if (m.templateKey === entry.conditionalValue.templateKey && m.metadataKey === entry.conditionalValue.metadataKey) {
                                let val = m.value;
                                if (entry.conditionalValue.value[val]) {
                                    updatedFileMetadata.push({
                                        templateKey: entry.templateKey,
                                        metadataKey: entry.metadataKey,
                                        value: entry.conditionalValue.value[val]
                                    })
                                    useDefaultValue = false;
                                }
                                //file.metadata = updatedFileMetadata
                                break;
                            }
                        }
                        if (useDefaultValue) {
                            updatedFileMetadata.push({
                                templateKey: entry.templateKey,
                                metadataKey: entry.metadataKey,
                                value: entry.defaultValue
                            })
                        }
                    }
                })
                file.metadata = updatedFileMetadata
            })
        }

        this.setState({filesToCopy: filesToCopy});

        this.copyFilesAsync(filesToCopy, this.state.destinationFolderId)
    }


    unmountGetTemplates = () => {
        this.setState({
            mountGetTemplates: false
        });
    };

    remountGetTemplates = () => {
        this.setState({
            mountGetTemplates: true
        });
    };

    unmountSelectTemplateTable = () => {
        this.setState({
            mountSelectTemplateTable: false
        });
    };

    remountSelectTemplateTable = () => {
        this.setState({
            mountSelectTemplateTable: true
        });
    };

    updateSelectedRows = (rows) => {
        this.setState({
            filesToCopy: rows,
            enableContinue: rows.length > 0,
            isFetching: false
        });
    };

    async copyFile(file, destinationFolderId) {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        debug && console.log("copyFile file:", file, 'destinationFolderId=', destinationFolderId);

        this.setState({copyInProgress: true});
        let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + "/" + file.id + "/copy?audit=false";

        /*POST /api/box/document/{fileId}/copy, same details as box reference docs apart from the old "type"s needed in jsons*/

        let body = {
            type: "file",
            name: file.nameNew,
            parent: {
                type: "folder",
                id: destinationFolderId
            }
        };

        let request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        debug && console.log("copyFile url:", url, 'BODY:', body, 'request: ', request);

        await this.props.triggerRefreshAuthToken();
        let response = await (fetch(url, request));
        debug && console.log('copyFile response = ', response)
        let data = await response.json();

        debug && console.log('copyFile response.json = ', data);

        //Set metadata on newly created file
        if (data) {
            if (data.id) {
                if (file.metadata) {
                    if (file.metadata.length > 0) {
                        //Update metadata
                        let setMetadataResponse = await this.setMetadata(data.id, file.metadata);
                        let response1 = await setMetadataResponse; //unused variable but required
                        console.log ('response1=', response1)
                    }
                }
            }
        }

        return data;
    }

    setMetadata = async (fileId, metadataArray) => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        try {


            debug && console.log('setMetadata metadataArray = ', metadataArray);

            let body = {
                //fileName: this.props.documentDetails.name,
                metadata: metadataArray,
            };

            debug && console.log("body: ", body);

            const pathVar = "/" + fileId;
            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar;
            const request = {
                method: 'PATCH',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };

            debug && console.log('setMetadata url:', url, 'BODY:', body, 'request:', request);

            //fetch
            await this.props.triggerRefreshAuthToken();
            let response = await (fetch(url, request));

            debug && console.log('setMetadata response = ', response);

            let response1 = await response.ok;

            if (!response1) {
                Promise.resolve(getErrorMessageFromResponse(response, 'setting metadata'))
                    .then(message => {
                        this.props.enqueueSnackbar(message , {variant: 'error'});
                    })
            } else {
                this.props.enqueueSnackbar("Metadata updated successfully", {variant: 'success'})
            }

            return response1;

        } catch (err) {
            debug && console.log("setMetadata error: " + err);
            return false;
        }

    };

    copyFilesAsync = (filesToCopy, folderId) => {

        //async/await with forEach() - see https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
        //generic asyncForEach function - pass array and function to execute as parameters
        async function asyncForEach(
            array,
            callback
        ) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        }

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            const debug = window.location.pathname.toLowerCase().includes("debug");
            let progressCounter = 0;
            let updatedFiles = this.state.filesToCopy;

            await this.props.triggerRefreshAuthToken();
            await asyncForEach(
                updatedFiles,
                async (file) => {

                    progressCounter = progressCounter + 1;
                    this.setState({copyProgress: progressCounter});
                    file.statusMessage = "Copying";
                    file.statusIconClass = "greenAvatar";
                    this.setState({files: updatedFiles});

                    let fileAdded = false;

                    await this.copyFile(file, folderId)
                        .then(result => {

                            debug && console.log(file.id, 'copyFile result = ', result);
                            if (result !== null) {
                                if (result.id) {
                                    file.statusIcon = "done";
                                    file.statusIconClass = "greenAvatar";
                                    file.statusMessage = "New Filename: " + result.name;
                                    fileAdded = true;
                                    return result.id
                                } else {
                                    file.statusIcon = "error";
                                    file.statusIconClass = "redAvatar";
                                    file.statusMessage = "Error on copy: " + result.message;
                                    return "0";
                                }
                            } else {
                                debug && console.log("response is null");
                                file.statusIcon = "error";
                                file.statusIconClass = "redAvatar";
                                file.statusMessage = "Error on copy";
                                return "0";
                            }
                        })
                        .then(id => {
                            file.id = id;
                        });

                    this.setState({filesToCopy: updatedFiles});
                    fileAdded && this.setState({filesAdded: true});

                });

            // All Done
            this.setState({isCopyComplete: true});
            this.setState({response: {success: true}});

        };

        //start copy
        start();

    };


    updateSearchResults(searchResults, totalCount, newSearch, nextMarker) {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        debug && console.log('updateSearchResults', " searchResults= ", searchResults, " totalCount= ", totalCount, " newSearch=", newSearch, "nextMarker=", nextMarker);

        //case searching uses page, content searching uses offset
        let newSearchResults = this.state.searchOffset === 0 ? searchResults : this.state.searchResults.concat(searchResults);

        this.setState({
            searchResults: newSearchResults,
            nextMarker: nextMarker,
            isFetching: false,
            mountSelectTemplateTable: newSearch,   //trigger remount of results table
            searchTotalCount: totalCount ? totalCount : 0,
            searchLimit: parseInt(window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT),
            newSearch: false,
        });
    }

    render() {

        const {classes} = this.props;
        let templateConfig = this.props.templateConfig
        const userInputMetadataCount = templateConfig.setMetadata.userInput ? templateConfig.setMetadata.userInput.length : 0;

        let gridSizeFilename = 6;
        let gridSizeMetadata = 0;
        let gridSizeMetadataNested = 0;

        if (userInputMetadataCount > 0) {
            if (userInputMetadataCount === 1) {
                gridSizeFilename = 4
                gridSizeMetadata = 4;
                gridSizeMetadataNested = 12;
            } else {
                if (userInputMetadataCount === 2) {
                    gridSizeFilename = 3
                    gridSizeMetadata = 6;
                    gridSizeMetadataNested = 6;
                } else if (userInputMetadataCount === 3) {
                    gridSizeFilename = 2
                    gridSizeMetadata = 8;
                    gridSizeMetadataNested = 4;
                } else {
                    gridSizeFilename = 2
                    gridSizeMetadata = 8;
                    gridSizeMetadataNested = 3;
                }
            }
        }

        let maxWidth = "lg";
        if ((this.state.fileSelectionConfirmed && this.state.filenameInputRequired && !this.state.filenameInputComplete) && userInputMetadataCount > 2) {
            if (userInputMetadataCount <= 3) {
                maxWidth = "lg"
            } else {
                maxWidth = "xl"
            }
        }

        let dialogTitle =  "Copy file" + (this.state.filesToCopy.length >1 ? "s": "");
        if (this.state.mountSelectTemplateTable|| this.state.filesToCopy.length === 0) {
            dialogTitle = "Select template to use"
        } else if (this.state.fileSelectionConfirmed && this.state.filenameInputRequired && !this.state.filenameInputComplete) {
            dialogTitle = "Confirm new filename"
        }

        return (
            <React.Fragment>

                {
                    (this.state.mountGetTemplates && templateConfig) &&

                    <GetTemplates
                        parentClasses={this.props.classes}
                        userDetails={this.props.userDetails}
                        metadataConfig={this.props.metadataConfig}
                        unmountComponent={this.unmountGetTemplates}
                        remountComponent={this.remountGetTemplates}
                        searchLimit={this.state.searchLimit}
                        searchOffset={this.state.searchOffset}
                        newSearch={this.state.newSearch}
                        updateSearchResults={this.updateSearchResults}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        nextMarker={this.state.nextMarker}
                        templateSearchConfig={templateConfig.searchConfig}
                        //parentFolderId={this.props.folderDetails.parentFolderId}
                        parentFolderId={this.props.parentFolderId}
                        updateIsFetching={this.updateIsFetching}
                    />
                }

                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={maxWidth}
                >
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>

                    <DialogContent>
                        {
                            this.state.isFetching && <LinearProgress variant={"indeterminate"}color={"secondary"}/>
                        }

                        {/*Select template to copy********************************************************************/}
                        {
                            this.state.mountSelectTemplateTable &&
                                <React.Fragment>
                                    <DialogContentText>{this.props.templateConfig.dialogTitle}</DialogContentText>
                                    <SelectTemplateTable
                                        searchConfig={templateConfig.searchConfig}
                                        searchResults={this.state.searchResults}
                                        userDetails={this.props.userDetails}
                                        nextMarker={this.state.nextMarker}
                                        searchTotalCount={this.state.searchTotalCount}
                                        getNextResults={this.getNextResults}
                                        isFetching={this.state.isFetching}
                                        searchLimit={this.state.searchLimit}
                                        unmountComponent={this.unmountSelectTempateTable}
                                        remountComponent={this.remountSelectTemplateTable}
                                        actionsConfig={this.props.actionsConfig}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        updateSelectedRows={this.updateSelectedRows}
                                        metadataConfig={this.props.metadataConfig}
                                    />
                                </React.Fragment>
                        }

                        {/*Prompt to confirm new filenames************************************************************/}
                        {

                            (this.state.fileSelectionConfirmed && this.state.filenameInputRequired && !this.state.filenameInputComplete) &&
                            <React.Fragment>
                                <Grid container spacing={"1"}>
                                    <Grid item xs={gridSizeFilename}>
                                        <Typography variant={"subtitle2"}>File to be copied</Typography>
                                    </Grid>
                                    <Grid item xs={gridSizeFilename}>
                                        <Typography variant={"subtitle2"}>New filename</Typography>
                                    </Grid>
                                    {
                                        userInputMetadataCount > 0 &&
                                        <Grid item xs={gridSizeMetadata}>
                                            <Typography variant={"subtitle2"}>Metadata</Typography>
                                        </Grid>
                                    }
                                    {
                                        this.state.filesToCopy.map((file, index )=> {
                                            //const newFileNameMandatory = this.props.folderDetails.id === this.state.destinationFolderId;
                                            const newFileNameMandatory = true
                                            const helperText = (newFileNameMandatory && !file.nameNew) && "New filename required";
                                            const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
                                            const fileNameNew = file.nameNew && file.nameNew.substring(0, file.nameNew.lastIndexOf('.'));

                                            return(
                                                <React.Fragment>
                                                    {/*Filename of file being copied*/}
                                                    <Grid item xs={gridSizeFilename} style={ index % 2 ? {backgroundColor: '#FFF'} : {backgroundColor: '#F0F0F0'}}>
                                                        <Typography variant={"body1"}>{fileName}</Typography>
                                                        {
                                                            templateConfig.displayMetadata &&
                                                            templateConfig.displayMetadata.map( (d) => {
                                                                //get value from file
                                                                if (file[d.templateKey + "~" + d.metadataKey]) {
                                                                    //get label from metadata config
                                                                    const fieldConfig = getFieldConfig(this.props.metadataConfig, d.templateKey, d.metadataKey)
                                                                    let val = file[d.templateKey + "~" + d.metadataKey];
                                                                    if (fieldConfig.options){
                                                                        //transform value to be displayed if val is in list of options
                                                                        for (let i=0; i<fieldConfig.options.length; i++){
                                                                            if (fieldConfig.options[i].value === val) {
                                                                                val = fieldConfig.options[i].label;
                                                                                break;
                                                                            }
                                                                        }
                                                                    }
                                                                    return(
                                                                        <Typography component = "span" variant={"caption"}>{fieldConfig.label + ": " + val + " "}</Typography>)
                                                                }else {
                                                                    return (<span/>)
                                                                }
                                                            })
                                                        }
                                                    </Grid>
                                                    {/*New Filename*/}
                                                    <Grid item xs={gridSizeFilename} style={ index % 2 ? {backgroundColor: '#FFF'} : {backgroundColor: '#F0F0F0'}}>
                                                        <FormControl style={{width:"100%"}}>
                                                            <InputLabel shrink htmlFor={file.id+"_name"} className={classes.customLabel}>{"Filename"}</InputLabel>
                                                            <TextField
                                                                id={file.id+"_name"}
                                                                name={file.id+"_name"}
                                                                defaultValue={templateConfig.defaultToParentFilename ? file.name.substring(0,file.name.lastIndexOf('.')) : ""}
                                                                value={fileNameNew}
                                                                type={"text"}
                                                                margin="none"
                                                                style={{width: "100%"}}
                                                                onChange={this.handleChangeFilename(index)}
                                                                helperText={helperText}
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    {/*Metadata requiring user input*/}

                                                    {userInputMetadataCount > 0 &&

                                                    <Grid item xs={gridSizeMetadata} style={ index % 2 ? {backgroundColor: '#FFF'} : {backgroundColor: '#F0F0F0'}}>

                                                        {/*Nested grid for user input metadata*/}

                                                        <Grid container spacing={"1"}>

                                                            {
                                                                templateConfig.setMetadata.userInput.map (entry=>{

                                                                    //read value from state
                                                                    let fieldValue;
                                                                    if (file.metadata) {
                                                                        file.metadata.forEach (m => {
                                                                            if (m.templateKey === entry.templateKey && m.metadataKey === entry.metadataKey) {
                                                                                fieldValue = m.value;
                                                                            }
                                                                        })
                                                                    }


                                                                    let amendedMetadataConfig = JSON.parse(JSON.stringify(this.props.metadataConfig)); //deep clone
                                                                    //exclude any values flagged for exclusion (English)
                                                                    if (entry.hideOptions) {
                                                                        let newOptions = [];
                                                                        let fieldConfig = getFieldConfig(this.props.metadataConfig, entry.templateKey, entry.metadataKey);
                                                                        if (fieldConfig && fieldConfig.options) {
                                                                        //remove from list of options in metadataConfig
                                                                        //generate new list of options, without options flagged to be excluded
                                                                        for (let i = 0; i < fieldConfig.options.length; i++) {
                                                                            if (entry.hideOptions.indexOf(fieldConfig.options[i].value) === -1 ) {
                                                                                newOptions.push(fieldConfig.options[i])
                                                                            }
                                                                        }

                                                                        }
                                                                        amendedMetadataConfig[entry.templateKey].metadata[entry.metadataKey].options = newOptions;
                                                                    }

                                                                    return (
                                                                        <Grid item xs={gridSizeMetadataNested}>
                                                                            <FormControl fullWidth  style={{paddingBottom: '10px', margin: '0px'}} key={"fc" + entry.templateKey + "~" + entry.metadataKey}>
                                                                                <RenderMetadataField
                                                                                    fieldValue={fieldValue}
                                                                                    metadataConfig={ amendedMetadataConfig }
                                                                                    optionsConfig={this.props.optionsConfig}
                                                                                    metadataKey={entry.metadataKey}
                                                                                    templateKey={entry.templateKey}
                                                                                    forceDisable={false}
                                                                                    usage={"edit"}
                                                                                    handleOnChange={this.handleChangeMetadata(index, entry)}
                                                                                    helperText={(entry.mandatory && (!fieldValue || fieldValue === "")) ? "Value required" : ""}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    }
                                                </React.Fragment>
                                        )})}
                                </Grid>
                            </React.Fragment>
                        }

                        {/*Show copy progress*/}
                        {
                            (this.state.fileSelectionConfirmed && this.state.filenameInputRequired && this.state.filenameInputComplete) &&
                                <React.Fragment>
                                    {
                                        (this.state.copyProgress > 0 && !this.state.isCopyComplete) &&
                                        <React.Fragment>
                                            <LinearProgress color = "secondary" variant={"indeterminate"} />
                                            <ListSubheader disableSticky>Copying {this.state.copyProgress} of {this.state.filesToCopy.length}</ListSubheader>
                                        </React.Fragment>
                                    }

                                    <List>
                                        {this.state.filesToCopy.map(
                                            file => (
                                                <React.Fragment key={"frag" + file.name}>
                                                    <ListItem key={"li" + file.name}>
                                                        <ListItemAvatar>
                                                            <Avatar style={{backgroundColor: getColour(file.statusIconClass)}}>
                                                                <i className='material-icons'>{file.statusIcon ? file.statusIcon : "file_upload"}</i>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText key={"liText" + file.name} primary={file.name} secondary={file.statusMessage}/>
                                                    </ListItem>
                                                </React.Fragment>
                                            ))}
                                    </List>
                                </React.Fragment>
                        }

                    </DialogContent>

                    <DialogActions>

                        <React.Fragment>

                        {
                            //Cancel / Continue on Select Template
                            this.state.mountSelectTemplateTable ?
                            <React.Fragment>
                                <Button onClick={this.handleClose} variant="contained">Cancel</Button>
                                <Button onClick={this.props.templateConfig.promptForNewFilename ? this.handleConfirmFileSelection : this.handleCopyFiles} variant="contained" color="secondary"
                                        disabled={!this.state.enableContinue}>Continue</Button>
                            </React.Fragment> :

                                //Cancel / Continue on Filename confirmation
                                (this.state.fileSelectionConfirmed && this.state.filenameInputRequired && !this.state.filenameInputComplete) ?
                                <React.Fragment>
                                    <Button onClick={this.handleClose} variant="contained">Cancel</Button>
                                    <Button onClick={this.handleCopyFiles} variant="contained" color="secondary"
                                            disabled={!this.state.fileNamesValid || !this.state.metadataValid}>Continue</Button>
                                </React.Fragment> :

                                    //Close when copy completed
                                    <Button onClick={this.handleClose} disabled={!this.state.isCopyComplete} variant="contained">Close</Button>

                        }

                        </React.Fragment>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        );
    }
}

SelectTemplateDialog.propTypes = {
    classes: PropTypes.object,
    handleCloseDialog: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    //folderDetails: PropTypes.object,
    destinationFolderId: PropTypes.string.isRequired, //new
    parentFolderId: PropTypes.string.isRequired, //new
    workspaceConfig: PropTypes.object.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    templateConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired //required for inline preview
};

export default withSnackbar(withStyles(styles, { withTheme: true })(SelectTemplateDialog));