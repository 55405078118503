import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AppBar from "@material-ui/core/AppBar/AppBar";
import {withSnackbar} from "notistack";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Badge, Grid} from "@material-ui/core";
import FolderComments from "../FolderComments";
import FolderTasks from "../tasks/FolderTasks";
import FolderDocuments from "../documents/FolderDocuments";
import SubFolderDetails from "./SubfolderDetails";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";
import AddFolderDialog from "../add/AddFolderDialog";
import {getErrorMessageFromResponse, getFieldConfig, hasAccess} from "../../common/helper";
import {getDataForSearchResultsTableMetadataSearch} from "../../search/helper";

//TODO Switch to TabPanel from TabContainer

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const INITIAL_STATE = {
    subfolders: [],
    tabValue: 0,
    mountFolderTasks: false,
    mountFolderComments: false,
    mountFolderDocuments: false,
    mountSubfolderDetails: true,
    tasks: [],
    tasksOverdue: []
};

const styles = theme => ({

    addTab: {
        minWidth: 80,
        alignItems: "right",
        color: 'red'
    }
})

class FolderSubfolders extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;
        this.remountFolderTasks = this.remountFolderTasks.bind(this);
        this.remountFolderComments = this.remountFolderComments.bind(this);
        this.updateSubfolderMetadataAfterChanges = this.updateSubfolderMetadataAfterChanges.bind(this);

        const debug = window.location.pathname.toLowerCase().includes("debug")
        debug && console.log ('*** FolderSubfolders props = ' , props);
    }

    addSubfolder() {
        this.setState({showAddFolderDialog : true});
    }

    closeAddFolderDialog = () => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showAddFolderDialog: false,
        });

        this.getSubfolders();

    };

    handleTabChange = (event, tabValue) => {
        //console.log ('*** handleTabChange tabValue=', typeof (tabValue), tabValue)

        if (tabValue === "addSubfolder") {
            this.addSubfolder()
        } else if (tabValue === "tasks") {
            this.setState({ tabValue: tabValue });
            // this.setState({mountFolderTasks : false});
            this.setState({mountFolderDocuments : false});
            this.setState({mountFolderTasks : false});
            this.setState({mountFolderComments : false});
            this.setState({mountSubfolderDetails : false});
        } else {
            if (this.state.tabValue === "tasks") {
                this.setState({ tabValue: tabValue });
                //changing subfolder from tasks so force remount
                this.setState({mountFolderDocuments : true});
                this.setState({mountFolderTasks : true});
                this.setState({mountFolderComments : true});
                this.setState({mountSubfolderDetails : true});
            } else {
                this.setState({ tabValue: tabValue });
                this.setState({mountFolderDocuments : false});
                this.setState({mountFolderTasks : false});
                this.setState({mountFolderComments : false});
                this.setState({mountSubfolderDetails : false});
            }

        }

    };

    updateSubfolderMetadataAfterChanges = (folderDetails) => {
        console.log ('updateSubfolderMetadataAfterChanges - folderDetails = ', folderDetails, 'this.state.subfolders=', this.state.subfolders);

        //if folder has metadata that impacts grouping of  metadata specified then remount folderDocuments
        //check resultsColumns for groupOrderConditional
        const resultsColumns = this.props.selectedWorkspaceConfig.searchConfig.folderDocuments.resultsColumns;
        let groupingFieldUpdated = false;
        for (let i = 0; i < resultsColumns.length; i++) {
            const col = resultsColumns[i];
            if (col.groupOrderConditional && col.groupOrderConditional.templateKey && col.groupOrderConditional.metadataKey){
                const field = col.groupOrderConditional.templateKey + "~" +  col.groupOrderConditional.metadataKey
                if (this.state.tabValue && this.state.tabValue.hasOwnProperty(field)) {
                    if (this.state.tabValue[field] !== folderDetails[field]) {
                        //grouping field has changed so need to remount folder docs
                        groupingFieldUpdated = true;
                        break;
                    }
                } else {
                    //field not available on this.state.tabValue
                    if (folderDetails && folderDetails.hasOwnProperty(field)) {
                        //a value has been set
                        //grouping field has changed so need to remount folder docs
                        groupingFieldUpdated = true;
                        break;
                    }
                }
            }
        }

        this.setState({tabValue: folderDetails});

        //Update the folder details on state.subfolders so that values maintained after switching tab - find subfolder in question and update
        if (this.state.subfolders) {
            for (let i = 0; i < this.state.subfolders.length; i++) {
                if (this.state.subfolders[i].id === folderDetails.id ) {
                    //console.log('* matching subfolder ', i, this.state.subfolders[i], 'folderDetails=', folderDetails);
                    let updatedSubfolders = this.state.subfolders;
                    updatedSubfolders[i] = folderDetails;
                    this.setState({subfolders: updatedSubfolders})
                    break;
                }
            }
        }

        if (groupingFieldUpdated) {
            //grouping field(s) changed so remount folder documents to reflect changes
            this.unmountFolderDocuments()
        }
    }

    UNSAFE_componentWillMount() {
        //this.getAllSubFolders();
        this.getSubfolders()

        const tasksAction = this.props.actionsConfig.folderTasks
        if (tasksAction && tasksAction.enabled && hasAccess(tasksAction, this.props.userDetails.userRoles) ) {
            this.getFolderTasks()
        }
    }

    componentDidMount(){
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    unmountSubfolderDetails = () => {
        this.setState({mountSubfolderDetails : false});
    };

    remountSubfolderDetails = () => {
        this.setState({mountSubfolderDetails : true});
    };

    remountFolderTasks = () => {
        //called by componentWillUnmount on FolderTasks component
        this.setState({mountFolderTasks : true});
    };

    updateTasksBadge = (tasks) => {
        // if (this.props.actionsConfig.folderTasks && props.actionsConfig.folderTasks.enabled) {
            //let tasks = await getFolderTasks();

           //this.setState({tasks: tasks})
        // }
        let filteredOverdue = tasks.filter(task => task.overdue);
        console.log('filteredOverdue = ', filteredOverdue)

        this.setState({
            tasks: tasks,
            tasksOverdue: filteredOverdue
        })



        console.log ('update tasks badge')
    }

    remountFolderComments = () => {
        //called by componentWillUnmount on FolderComments component
        this.setState({mountFolderComments : true});
    };

    remountFolderDocuments = () => {
        //called by componentWillUnmount on FolderDocuments component
        this.setState({mountFolderDocuments : true});
    };

    unmountFolderDocuments = () => {
        this.setState({mountFolderDocuments : false});
    };

    getFolderTasks = async () => {
        const debug = window.location.pathname.toLowerCase().includes("debug");

        await this.props.triggerRefreshAuthToken();

        const pathVar = "/" + this.props.folderDetails.id ;
        const url = window.REACT_APP_TASK_API_BASE_URL + window.REACT_APP_TASK_API_FOLDER + pathVar;
        const request = {
            headers: {
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
        };

        debug && console.log ("*** *** getFolderTasks url = ", url, "request = ", request);

        let tasks=[]

        await fetch(url , request )
            .then(response => {
                debug && console.log('*** *** getFolderTasks RESPONSE: ', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving folder tasks'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("getFolderTasks error.  url:", url, "request: ", request);
                    return ([])
                }
            })
            .then(result => {
                debug && console.log ('*** *** getFolderTasks response.json = ' , result);

                const todayDate = new Date();
                result.forEach(task => {
                    task.statusDisp = task.completed ? "Complete" : "Not started"
                    task.assignee = task.assign_to && task.assign_to.login
                    if (!task.completed && task.due_at) {
                        const dueDate = new Date(task.due_at)
                        task.overdue = dueDate <= todayDate;
                    }
                })

                const filteredOverdue = result.filter(task => task.overdue);
                this.setState({
                    tasks: result,
                    tasksOverdue: filteredOverdue

                })


            })
            .catch(e => {
                this.props.enqueueSnackbar("Error retrieving folder tasks: " + e.toString(), {variant: 'error'});
                debug && console.log("getFolderTasks Exception:", e, " url:", url, "request: ", request);
            })

        return tasks
    }

    getSubfolders = async() => {

        const searchConfig = this.props.selectedSearchConfig.folderSearch;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('getSubfolders searchConfig=', searchConfig)

        let searchSubfolders = searchConfig.subfolderSearch && searchConfig.subfolderSearch.box &&
            searchConfig.subfolderSearch.box.queryParams && searchConfig.subfolderSearch.box.queryParams.length > 0

        if (searchSubfolders) {
            //metadata search
            window.location.pathname.toLowerCase().includes("debug") && console.log('metadata search for folder subfolders');
            this.searchSubfoldersBox()
        } else {
            //no filters in config so get all subfolders in the folder
            window.location.pathname.toLowerCase().includes("debug") && console.log('get all subfolders in folder');
            this.getAllSubFolders()
        }
    }

    getAllSubFolders = async () => {

        const debug=window.location.pathname.toLowerCase().includes("debug");

        let request = {};
        let url = "";
        let pathVar = "/" + this.props.boxFolderID + "/folders";

        //no need to pass a limit at the moment for Wallbrook but may be required for other implementations
        const queryString =  "?userId=" + this.props.userDetails.boxId + "&recursive=false&watson=false&limit=&offset=0";

        url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + queryString;

        request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        debug && console.log('getSubfolders REQUEST.  url:', url, 'request: ', request);


        let subfolders = [];
        let totalCount = 0;

        //update parent state with search status
        //const folderDetails = this.props.folderIdSearch !== "" ? this.props.folderDetails : {};
        await this.props.triggerRefreshAuthToken();
        fetch(url, request)
            .then(response => {

                if (response.ok) {
                    return (response.json())
                } else {
                    debug && console.log('response not ok - getAllSubFolders RESPONSE: ', response);

                    Promise.resolve(getErrorMessageFromResponse(response, 'getting subfolders'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })

                    debug && console.log("getSubfolders error. url:", url, "request: ", request, "response:", response);
                    return (null)
                }
            })
            .then(resultA => {
                debug && console.log ('getSubfolders response.json=', resultA);
                totalCount = resultA && resultA.total_count;
                if (!resultA || totalCount === 0) {
                    subfolders=[];
                } else {
                    resultA.entries.forEach((item, i) => {
                        //extract system metadata (e.g. templateConfig)
                        item.parentFolderId = this.props.boxFolderID;

                        // if (item.metadata) {
                        //     if (window.REACT_APP_SYSTEM_METADATA_TEMPLATE !== "") {
                        //         if (item.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
                        //             if (item.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][window.REACT_APP_SYSTEM_METADATA_TEMPLATE]) {
                        //                 let systemMetadata = item.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][window.REACT_APP_SYSTEM_METADATA_TEMPLATE]
                        //                 if (systemMetadata) {
                        //                     Object.entries(systemMetadata).forEach((s) => {
                        //                         if (!s[0].startsWith("$")) {
                        //                             item[s[0]] = s[1]
                        //                         }
                        //                     })
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }

                        //New - to prevent folderDetails from remounting unnecessarily - loop through metadata returned and add all values, even if not specified in resultsColumns
                        const enterpriseId = window.REACT_APP_ENTERPRISE_ID;
                        if (item.metadata) {
                            if (item.metadata["enterprise_"+enterpriseId]) {
                                const templates = Object.entries(item.metadata["enterprise_"+enterpriseId])
                                if (Array.isArray(templates)) {
                                    templates.forEach(metadataTemplate => {
                                        let templateName = metadataTemplate[0];
                                        Object.entries(metadataTemplate[1]).forEach(property => {
                                            if (property[0].substring(0, 1) !== "$") {
                                                item[property[0]] = property[1];
                                                item[templateName + "~" + property[0]] = property[1];
                                            }
                                        })
                                    })
                                }
                            }
                        }

                        subfolders.push(item);
                    })
                }

            })
            .then(result => {

                this.setState({
                    subfolders: subfolders,
                    tabValue: subfolders.length === 0 ? {} : subfolders[0],
                    mountFolderDocuments: true,
                    mountFolderTasks: true,
                    mountFolderComments: true,
                    mountFolderDetails: true
                });
            })
            .catch(e => {
                debug && console.log("getSubfolders Exception:", e, "url:", url, "request: ", request);

            })
    };

    searchSubfoldersBox = async () => {

        try {

            const debug = window.location.pathname.toLowerCase().includes("debug");

            debug && console.log('searchSubfoldersBox.  props=', this.props);

            let request = {};
            let url = "";
            let nextMarker = this.props.newSearch ? null : this.props.nextMarker;
            url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;

            const searchQuery = this.props.selectedSearchConfig.folderSearch.subfolderSearch.box;

            debug && console.log ('searchSubfoldersBox searchQuery=', searchQuery)

            let fields = [];
            //add standard fields
            fields.push("name", "created_by","created_at","modified_at","version","shared_link","sequence_id","size")
            //get metadata list to query from config
            if (searchQuery.metadataKeys) {
                for (let i = 0; i < searchQuery.metadataKeys.length; i++) {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey + "." + searchQuery.metadataKeys[i])
                }
            }

            //Add a field for each result column to allow us to get metadata from other metadata templates
            this.props.selectedSearchConfig.folderDocuments.resultsColumns.forEach(col => {
                if (col.templateKey !== "n/a"){
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + col.templateKey + "." + col.metadataKey)
                }
            });

            let query = [];
            let query_params = {};
            let queryValid = true;
            let order_by= searchQuery.order_by ? searchQuery.order_by : [];

            let queryParams = searchQuery.queryParams;

            let n=0;
            for (let p = 0; p < queryParams.length; p++)  {
                //if values provided in config (e.g. for metadata filter) , use them, otherwise get values from folder
                if (queryParams[p].values) {
                    let vals = [];
                    for (let v = 0; v < queryParams[p].values.length; v++) {
                        vals.push(":val" + n);
                        query_params["val" + n] = queryParams[p].values[v];
                        n++
                    }
                    if (vals.length > 1 ){
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " (" + vals.join() + ")")
                    } else {
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + vals[0]);
                    }
                } else {
                    //get values from folder
                    let val = this.props.folderDetails[queryParams[p].templateKey + "~" + queryParams[p].metadataKey];
                    let fieldConfig = getFieldConfig(this.props.metadataConfig,queryParams[p].templateKey , queryParams[p].metadataKey )

                    if (!val) {
                        if (queryParams[p].mandatory) {
                            //mandatory value not provided so return an error
                            queryValid = false
                            debug && console.log("Unable to find " + queryParams[p].templateKey + "~" + queryParams[p].metadataKey + " value required for document search")
                            this.props.enqueueSnackbar(fieldConfig.label + " value required for document search", {variant: 'error'});
                        }
                    } else {
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " :val" + n);
                        if (fieldConfig.type === "date") {
                            let dateVal = new Date(val);
                            dateVal.setUTCHours(0,0,0,0);
                            query_params["val" + n] = dateVal ;
                        } else {
                            if (queryParams[p].operation === "ILIKE" || queryParams[p].operation === "LIKE") {
                                query_params["val" + n] = '%' + val + '%';
                            } else {
                                query_params["val" + n] = val;
                            }
                        }
                        n++;
                    }
                }
            }


            debug && console.log ('searchSubfoldersBox query = ', query, 'query_params = ', query_params);

            const folderId = this.props.boxFolderID

            if (!folderId  ){
                this.props.enqueueSnackbar("Folder ID required for document search", {variant: 'error'});
            }

            //Proceed if all required values are available
            if (queryValid && folderId) {
                let body = {
                    "ancestor_folder_id": folderId,
                    "fields": fields,
                    "limit": window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT,
                    "from": "enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey,
                    "query": query.join(" AND "),
                    "query_params": query_params,
                    "order_by": order_by,
                    "marker": nextMarker
                }

                request = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };

                debug && console.log('searchSubfoldersBox REQUEST.  url:', url, 'BODY: ', body, 'request:', request);

                let rows = [];
                let totalCount = 0;
                this.setState({isFetching: true});

                await this.props.triggerRefreshAuthToken();
                fetch(url, request)
                    .then(response => {
                        if (response.ok) {
                            return (response.json())
                        } else {
                            debug && console.log('response not ok - searchSubfoldersBox RESPONSE: ', response);

                            Promise.resolve(getErrorMessageFromResponse(response, 'getting subfolders'))
                                .then(message => {
                                    this.props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                                })

                            debug && console.log("searchSubfoldersBox error. url:", url, "request: ", request, "response:", response);
                            this.setState({isFetching: false});
                            return null
                        }
                    })
                    .then(resultA => {
                        debug && console.log('searchSubfoldersBox response.json: ', resultA);
                        const resultsColumns = this.props.selectedSearchConfig.folderSearch.resultsColumnsSubFolders;

                        if (Array.isArray(resultA)) {
                            //without paging
                            debug && console.log('searchSubfoldersBox - without paging');
                            resultA.forEach((item, i) => {
                                rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                            })
                        } else {
                            debug && console.log('searchSubfoldersBox - with paging resultA = ', resultA);
                            //with paging
                            if (resultA !== null) {
                                //totalCount = resultA.total_count;
                                totalCount = null; //need to set to null as it is sometimes returning and if set it breaks the footer
                                nextMarker = resultA.next_marker;
                                debug && console.log('nextMarker = ', nextMarker);
                                if (totalCount === 0 || !resultA.entries) {
                                    rows = [];
                                } else {
                                    resultA.entries.forEach((item, i) => {
                                        debug && console.log(i, item);
                                        rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                                    })
                                }
                            }
                        }
                    })
                    .then(result => {
                       // this.props.updateSearchResults(rows, totalCount, this.props.newSearch, nextMarker);
                        this.setState({
                            subfolders: rows,
                            tabValue: rows.length === 0 ? {} : rows[0],
                            mountFolderDocuments: true,
                            mountFolderTasks: true,
                            mountFolderComments: true,
                            mountFolderDetails: true
                        });
                    })
                    .catch(e => {
                        this.setState({isFetching: false});
                        debug && console.log("searchSubfoldersBox Exception:", e, "url:", url, "request: ", request);
                        this.props.enqueueSnackbar("Error retrieving search results " + e, {variant: 'error'});
                        this.props.updateSearchResults(rows, totalCount, this.props.newSearch, nextMarker );
                    })
            } else {
                debug && console.log ('query params incomplete')
                this.setState({isFetching: false});
                this.props.updateSearchResults([], 0, this.props.newSearch );
            }
        } catch (e) {
            console.log("searchSubfoldersBox Exception:", e);
        }
    };

    render() {

        const { tabValue } = this.state;
        const classes = styles();
        const searchConfig = this.props.selectedSearchConfig.folderSearch;
        const showSubfolderDetails = this.props.actionsConfig.subFolderDetails.enabled &&
            searchConfig && searchConfig.subfolderDetails && !searchConfig.subfolderDetails.hide


        let showTasks = false;
        const tasksAction = this.props.actionsConfig.folderTasks
        if (tasksAction && tasksAction.enabled && hasAccess(tasksAction, this.props.userDetails.userRoles) ) {
            //if displayWhen criteria provided in config then only show tasks if criteria met
            if (tasksAction.displayWhen && tasksAction.displayWhen.templateKey && tasksAction.displayWhen.metadataKey) {
                let metadataValue;
                if (this.props.folderDetails[tasksAction.displayWhen.templateKey + "~" + tasksAction.displayWhen.metadataKey]) {
                    metadataValue = this.props.folderDetails[tasksAction.displayWhen.templateKey + "~" + tasksAction.displayWhen.metadataKey]
                    showTasks = tasksAction.displayWhen.value === metadataValue
                }
            } else {
                showTasks = true
            }
        }



        // const debug = window.location.pathname.toLowerCase().includes("debug");
        // debug && console.log ("FolderSubfolders render, props = ", this.props);
        // debug && console.log ("FolderSubfolders render, tabValue = ", tabValue);

        const scrollableTabs = this.props.selectedSearchConfig.folderSearch.subfolderSearch &&
            this.props.selectedSearchConfig.folderSearch.subfolderSearch.scrollableTabs;

        return (


                <div style={{width: "100%"}}>
                    <Box border={'1px solid'} borderColor={'#E0E0E0'} boxShadow={0} borderRadius={0}>
                        <AppBar
                            position={"inherit"}
                            color="secondary"
                        >
                            <Tabs
                                value={tabValue}
                                onChange={this.handleTabChange}
                                variant={scrollableTabs ? "scrollable" : "fullWidth"}
                                // TabIndicatorProps={{style: {height: "6px"}}}
                            >
                                {
                                    this.state.subfolders &&
                                    this.state.subfolders.map(subfolder => {
                                        //ocado-group country subfolders prefixed with _ for sorting
                                        const label = subfolder.name.substring(0,1) === "_" ? subfolder.name.substring(1) : subfolder.name;
                                        return (
                                            <Tab label={label} value={subfolder}/>
                                        )
                                    })
                                }


                                {
                                    showTasks &&
                                    <Tab label={
                                        <Badge badgeContent={this.state.tasksOverdue.length} color="error" >
                                            <Box component="span" sx={{paddingRight: '5px'}}>Tasks</Box>
                                        </Badge>
                                    }value={"tasks"} >
                                    </Tab>
                                }

                                {
                                    this.props.selectedWorkspaceConfig.addSubfolderConfig &&
                                    this.props.selectedWorkspaceConfig.addSubfolderConfig.enabled &&

                                    <Tab label = ""
                                         classes={{ root: classes.addTab}}
                                         value={"addSubfolder"}
                                         icon={
                                             <Tooltip title={"Add " + this.props.selectedWorkspaceConfig.addSubfolderConfig.label}>
                                                 <i className="material-icons md-light">add</i>
                                             </Tooltip>
                                         }

                                    />

                                }


                            </Tabs>
                        </AppBar>

                        {
                            tabValue === "tasks" &&
                                <TabContainer>
                                        <Grid item
                                            xs={12}
                                            style={{
                                            width: "100%",
                                            paddingLeft: "24px",
                                            paddingTop: "12px",
                                            paddingRight: "2px",
                                            paddingBottom: "10px"}}>
                                        {
                                            //this.state.mountFolderTasks &&
                                            <FolderTasks
                                                parentClasses={classes}
                                                userDetails={this.props.userDetails}
                                                boxFolderID={this.props.folderDetails.id}
                                                folderTasksConfig={this.props.actionsConfig.folderTasks}
                                                remountComponent={this.remountFolderTasks}
                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                displayInOwnTab={true}
                                                updateTasksBadge={this.updateTasksBadge}
                                                folderDetails={this.props.folderDetails}
                                            />
                                        }

                                    </Grid>
                                </TabContainer>
                        }

                        {
                            typeof tabValue === "object" &&

                                // <TabContainer>
                                //     subfolder... {tabValue.name}
                                // </TabContainer>

                            <TabContainer>
                                {
                                    this.props.boxFolderID !== 0 &&
                                    <Grid container
                                          spacing={0}
                                          style={{overflowY: 'auto'}}
                                    >
                                        {
                                            (showSubfolderDetails && this.state.mountSubfolderDetails) &&
                                            <Grid item xs={12}
                                                  style={{
                                                      paddingTop: "0px",
                                                      // paddingLeft: "5px"
                                                  }}
                                            >
                                                <SubFolderDetails
                                                    parentClasses={this.props.classes}
                                                    userDetails={this.props.userDetails}
                                                    metadataConfig={this.props.metadataConfig}
                                                    optionsConfig={this.props.metadataConfig}
                                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                    folderDetails={tabValue}
                                                    actionsConfig={this.props.actionsConfig}
                                                    workflowConfig={this.props.workflowConfig.folderActions}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                    unmountComponent={this.unmountSubfolderDetails}
                                                    remountComponent={this.remountSubfolderDetails}
                                                    updateSubfolderMetadataAfterChanges={this.updateSubfolderMetadataAfterChanges}
                                                />
                                            </Grid>
                                        }


                                        <Grid item
                                              xs={(this.props.subFolderCommentsConfig.enabled || this.props.subFolderTasksConfig.enabled) ? 8 : 12}
                                              style={{
                                                  // paddingTop: "3px",
                                              }}
                                        >

                                            <Grid container
                                                  spacing={0}
                                                  style={{overflowY: 'auto'}}
                                            >


                                                <Grid item xs={12}
                                                      style={{
                                                          paddingTop: "0px",
                                                      }}
                                                >
                                                    {
                                                        this.state.subfolders.length > 0 && this.state.mountFolderDocuments &&
                                                        <FolderDocuments
                                                            parentClasses={this.props.classes}
                                                            userDetails={this.props.userDetails}
                                                            boxFolderID={tabValue.id}
                                                            selectedSearchConfig={this.props.selectedSearchConfig}
                                                            metadataConfig={this.props.metadataConfig}
                                                            optionsConfig={this.props.optionsConfig}
                                                            searchResults={this.props.searchResults}
                                                            searchTotalCount={this.props.searchTotalCount}
                                                            getNextResults={this.props.getNextResults}
                                                            getAllResults={this.props.getAllResults}
                                                            actionsConfig={this.props.actionsConfig}
                                                            workspaceConfig={this.props.workspaceConfig}
                                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                            folderDetails={tabValue}
                                                            isFetching={this.props.isFetching}
                                                            searchCriteria={this.props.searchCriteria}
                                                            searchCriteriaSimple={this.props.searchCriteriaSimple}
                                                            unmountComponent={this.unmountFolderDocuments}
                                                            remountComponent={this.remountFolderDocuments}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            displayInTab={true}
                                                            templatesConfig={this.props.templatesConfig}
                                                            workflowConfig={this.props.workflowConfig.documentActions}
                                                            updateFolderDetails={this.props.updateFolderDetails}
                                                            showingSubfolderDocs={true}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                        {
                                            (this.props.subFolderCommentsConfig.enabled || this.props.subFolderTasksConfig.enabled) &&

                                            <Grid item xs={4}>
                                                {/*Container for Comments and Tasks*/}
                                                <Grid container
                                                      spacing={0}
                                                      style={{overflowY: 'auto'}}
                                                >

                                                    {/*Comments*/}
                                                    {
                                                        (this.props.subFolderCommentsConfig.enabled) &&
                                                        <Grid item
                                                              xs={12}
                                                              style={{
                                                                  width: "100%",
                                                                  paddingLeft: "24px",
                                                                  paddingTop: "6px",
                                                                  paddingRight: "2px",
                                                                  paddingBottom: "12px"
                                                              }}
                                                        >
                                                            {
                                                                this.state.mountFolderComments &&
                                                                <FolderComments
                                                                    parentClasses={this.props.classes}
                                                                    userDetails={this.props.userDetails}
                                                                    boxFolderID={tabValue.id}
                                                                    folderCommentsConfig={this.props.subFolderCommentsConfig}
                                                                    remountComponent={this.remountFolderComments}
                                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                />
                                                            }
                                                        </Grid>
                                                    }

                                                    {/*Tasks*/}
                                                    {/***********************TODO TRATON ************************************************/}
                                                    {/*{*/}
                                                    {/*    (this.props.subFolderTasksConfig.enabled) &&*/}
                                                    {/*    <Grid item*/}
                                                    {/*        xs={12}*/}
                                                    {/*        style={{*/}
                                                    {/*        width: "100%",*/}
                                                    {/*        paddingLeft: "24px",*/}
                                                    {/*        paddingTop: "12px",*/}
                                                    {/*        paddingRight: "2px",*/}
                                                    {/*        paddingBottom: "10px"}}>*/}
                                                    {/*    {*/}
                                                    {/*        //this.state.mountFolderTasks &&*/}
                                                    {/*        <FolderTasks*/}
                                                    {/*            parentClasses={classes}*/}
                                                    {/*            userDetails={this.props.userDetails}*/}
                                                    {/*            boxFolderID={this.props.folderDetails.id}*/}
                                                    {/*            folderTasksConfig={this.props.actionsConfig.folderTasks}*/}
                                                    {/*            remountComponent={this.remountFolderTasks}*/}
                                                    {/*            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}*/}
                                                    {/*            displayInOwnTab={true}*/}
                                                    {/*            updateTasksBadge={this.updateTasksBadge}*/}
                                                    {/*            folderDetails={this.props.folderDetails}*/}
                                                    {/*        />*/}
                                                    {/*    }*/}

                                                    {/*</Grid>*/}
                                                    {/*}*/}
                                                </Grid>
                                            </Grid>

                                        }
                                    </Grid>

                                }
                            </TabContainer>
                        }



                    {
                        (this.state.showAddFolderDialog) &&
                        <AddFolderDialog
                            parentClasses={this.props.classes}
                            userDetails={this.props.userDetails}
                            addFolderConfig={this.props.selectedWorkspaceConfig.addSubfolderConfig}
                            metadataConfig={this.props.metadataConfig}
                            optionsConfig={this.props.optionsConfig}
                            closeDialog={this.closeAddFolderDialog}
                            parentFolderId={this.props.boxFolderID}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        />
                    }

                </Box>

                </div>

        );
    }
}

FolderSubfolders.propTypes = {
    classes: PropTypes.object.isRequired,
    boxFolderID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    subFolderCommentsConfig: PropTypes.object.isRequired,
    subFolderTasksConfig: PropTypes.object.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(FolderSubfolders));