import React from 'react';
import PropTypes from 'prop-types';
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import DashboardFolderList from "../../reporting/DashboardFolderList";
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import {Grid} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from "@material-ui/core/CardActionArea";
import IconButton from "@material-ui/core/IconButton/IconButton";

const styles = theme => ({
    root: {
        maxWidth: 345,
    },

    media: {
        //height: 140,
        height: 0,
        paddingTop: '56.25%', // 16:9,
        marginTop:'30'
    }
});

const tableTheme = theme => createTheme({

    // to merge with parent theme
    ...theme,

    media: {
        height: 100,
        width: 100,
        margin: 'auto',
    },

    //https://material-ui.com/customization/globals/#css
    //note, overrides overrides the overrides in the parent theme in app.css
    overrides: {

        MuiCard: {
            root: {
                textAlign: "center"
            }
        },

        MuiCardMedia: {
            media: {
                width: 'auto'
            }
        }

    }
});

function ColumnIcon(props){

    if (props.val && props.val !== "") {

        let valueMapping = props.iconConfig.valueMapping[props.val.toLowerCase()];

        if (valueMapping) {
            return(
                <Tooltip title = {valueMapping.tooltipTitle}>
                    {
                        props.iconConfig.icon === "avatar" ?

                            <Avatar key={"avatar"} style={{backgroundColor: valueMapping.colour, height: 20, width: 20, fontSize: '12px'}}>
                                {props.val}
                            </Avatar> :

                            <i className='material-icons' style={{color: valueMapping.colour}}>
                                <i className="material-icons">{props.iconConfig.icon}</i>
                            </i>
                    }
                </Tooltip>
            )
        } else {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('No value mapping found for ', props.val.toLowerCase());
            return(
                <span/>
            )
        }
    } else {
        return(
            <span/>
        )
    }
}

ColumnIcon.propTypes = {
    val: PropTypes.string,
    iconConfig: PropTypes.object.isRequired
};


class SearchResultsFoldersGrid extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedListItem: "",
            //show overall dashboard if configured
            dashboardUrl: this.props.selectedWorkspaceConfig.searchConfig.folderSearch.showDashboard ? window.REACT_APP_DASHBOARD_URL : ""
        }
    }


    componentDidUpdate(prevProps) {

        if (JSON.stringify(prevProps.searchResults)!== JSON.stringify(this.props.searchResults)){
            window.location.pathname.toLowerCase().includes("debug") && console.log ('searchResults changed...' +
                'prevProps.searchResults=', prevProps.searchResults,
                'props.searchResults=' , this.props.searchResults);
            let data = [];
            this.props.searchResults.forEach(sr => {
                data.push(sr)
            });
            window.location.pathname.toLowerCase().includes("debug") && console.log ('!!! updated data: ', data);
            this.props.unmountComponent();
        }
    }

    componentWillUnmount() {
        window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResultsFoldersGrid: componentWillUnmount');
        this.props.remountComponent();
    }

    render() {

        //const classes = this.props.classes;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('render SearchResultsFoldersGrid - this.props = ', this.props);

        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch;

        const gridIconConfig = searchConfig && searchConfig.resultsDisplay && searchConfig.resultsDisplay.grid && searchConfig.resultsDisplay.grid.icon;
        const useCustomGridIcon = gridIconConfig && gridIconConfig.custom &&
            gridIconConfig.custom.templateKey && gridIconConfig.custom.templateKey !== "" &&
            gridIconConfig.custom.metadataKey && gridIconConfig.custom.metadataKey !== ""
        const customGridIconConfig  = useCustomGridIcon && gridIconConfig.custom;
        const defaultGridIcon = gridIconConfig && gridIconConfig.default ? gridIconConfig.default : "folder"

        const gridImageConfig = searchConfig && searchConfig.resultsDisplay && searchConfig.resultsDisplay.grid && searchConfig.resultsDisplay.grid.image;

        const useCustomGridImage = gridImageConfig && gridImageConfig.custom &&
            gridImageConfig.custom.templateKey && gridImageConfig.custom.templateKey !== "" &&
            gridImageConfig.custom.metadataKey && gridImageConfig.custom.metadataKey !== ""

        const customGridImageConfig  = useCustomGridImage && gridImageConfig.custom;

        const defaultGridImage = gridImageConfig && gridImageConfig.default ? gridImageConfig.default : "logo.png"

        return (

            <ThemeProvider theme={tableTheme}>
                <Grid container style={{height:"100%", paddingTop: '24px'}}>

                    <Grid item
                          xs={this.state.dashboardUrl === "" ? 12 : 5}
                          style={{
                              height: "100%",
                              overflowY: "auto",
                              paddingLeft: "20px",
                              paddingRight: "20px"
                          }}
                    >

                        <Grid container spacing={2}>
                            {
                                this.props.searchResults.map(sr => {

                                    let folderIcon = useCustomGridIcon && customGridIconConfig &&
                                        sr[customGridIconConfig.templateKey + "~" + customGridIconConfig.metadataKey]
                                    folderIcon = folderIcon ? folderIcon : defaultGridIcon

                                    let image = useCustomGridImage && customGridImageConfig &&
                                        sr[customGridImageConfig.templateKey + "~" + customGridImageConfig.metadataKey]
                                    image = window.location.origin + '/images/' + (image ? image : defaultGridImage)
                                    const imageUrl = "url('" + image + "')";

                                    return(
                                        <Grid item xs={3}>
                                            {/*<Card className={this.props.classes.root}*/}
                                            {/*      // style={{display: 'block', width: '30vw', transitionDuration: '0.3s', height: '45vw'}}*/}
                                            {/*>*/}
                                            {/*    <CardActionArea>*/}
                                            {/*        <CardMedia*/}
                                            {/*            className={this.props.classes.media}*/}
                                            {/*            image={window.location.origin + '/images/folderlogo.png'}*/}
                                            {/*            title={sr.name}*/}
                                            {/*        />*/}
                                            {/*        <CardContent>*/}
                                            {/*            <span>{sr.name}</span>*/}
                                            {/*            /!*<ReactMarkdown children = {sr.hrTitle}/>*!/*/}
                                            {/*            /!*<Editor defaultValue={sr.hrSubtitle}/>*!/*/}
                                            {/*        </CardContent>*/}
                                            {/*    </CardActionArea>*/}
                                            {/*    <CardActions>*/}
                                            {/*        /!*<Button size="small" color="primary">*!/*/}
                                            {/*        /!*    Edit*!/*/}
                                            {/*        /!*</Button>*!/*/}
                                            {/*        <Button size="small" color="primary">*/}
                                            {/*            View Documents*/}
                                            {/*        </Button>*/}
                                            {/*    </CardActions>*/}
                                            {/*</Card>*/}

                                            <Card sx={{ maxWidth: 345 }}
                                                  // style={{marginTop: '20px'}}
                                                  style={{
                                                      marginTop: '20px',
                                                      height: '250px',
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:'center',
                                                      backgroundImage: imageUrl,
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundPosition: 'top right',
                                                      margin: '0px 0px 0px 0px',
                                                      //paddingRight: '50px'
                                                  }}
                                            >
                                                <CardActionArea onClick={() => this.props.openFolder(sr)} style={{height: '100%'}}>
                                                    <CardHeader title={sr.name}/>
                                                    <div style={{ display:'flex', justifyContent:'center' }}>
                                                        <IconButton color="inherit" aria-label="Open" onClick={() => this.props.openFolder(sr)}>
                                                            <i className='material-icons md-48' style={{color: '#646464', fontSize: '36px'}}>{folderIcon}</i>
                                                        </IconButton>
                                                    </div>
                                                    <CardContent
                                                         // style={{
                                                         //     height: '200px',
                                                         //     display: "flex",
                                                         //     alignItems: "center",
                                                         //     justifyContent:'center',
                                                         //     backgroundImage: imageUrl,
                                                         //     backgroundRepeat: "no-repeat",
                                                         //     backgroundPosition: 'top right',
                                                         //     margin: '0px 0px 0px 0px',
                                                         //     paddingRight: '50px'
                                                         // }}
                                                    >
                                                        <Typography variant="body2" color="text.secondary">
                                                            {sr.folderDetails ? sr.folderDetails : ""}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>

                                    )
                                })
                            }
                        </Grid>

                    </Grid>
                    {
                        this.state.dashboardUrl !== "" &&
                        <Grid item
                              xs={7}
                              style={{height: "100%", overflowY: "auto",}}
                        >
                            <DashboardFolderList
                                dashboardUrl={this.state.dashboardUrl}
                            />

                        </Grid>
                    }

                </Grid>

            </ThemeProvider>
        );
    };
}

SearchResultsFoldersGrid.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    openFolder: PropTypes.func.isRequired,
    parentFolderId: PropTypes.string.isRequired,
    showFolderResultsAsList: PropTypes.func.isRequired,
    showFolderResultsAsGrid: PropTypes.func.isRequired,
    showFolderResultsAsTable: PropTypes.func.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    workflowConfig: PropTypes.object.isRequired,
    remountSearch: PropTypes.func.isRequired,
    nextMarker: PropTypes.string
};

export default withSnackbar(withStyles(styles, { withTheme: true })(SearchResultsFoldersGrid));

