import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from "prop-types";
import {withSnackbar} from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({});

const INITIAL_STATE = {
    isFetching: false
};

class DownloadResults extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    download = async () => {

        this.setState({isFetching: true});

        const pathVar = "/download";
        const params =  "?userId=" + this.props.userDetails.boxId;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

        //Build array of ids from the search results
        let files = [];
        this.props.searchResults.map((sr) => {
            files.push(sr.id);
            return null;
        });

        const request = {
            method: 'POST',
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken},
            body: files
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('url = ', url, 'request = ', request);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('response.ok - download RESPONSE = ', response);
                    return (response.blob())
                } else {
                    return null
                }
            })
            .then(result => {

                //window.location.pathname.toLowerCase().includes("debug") && console.log ('result = ', result);
                const url = URL.createObjectURL(result);
                const fileName = files.length === 1 ? this.props.searchResults[0].name : this.props.fileName;

                // Create a new anchor element
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName || 'download';
                a.click();

                this.setState({isFetching: false});
                this.props.enqueueSnackbar("Download complete", {variant: 'success'});

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("download Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false});
                this.props.enqueueSnackbar("Error downloading documents: " + e, {variant: 'error'});
            })
    };


    render() {

        const {anchorEl} = this.state;
        let progressMessage = "";
        if (this.props.searchResults.length > 1) {
            progressMessage = "Downloading " + this.props.searchResults.length + " files as zip file";
        } else {
            progressMessage = "Downloading " + this.props.searchResults[0].name
        }

        const tooltipTitle = this.props.searchResults.length === 1 ?
            this.props.downloadResultsActionConfig.label + " 1 file" :
            this.props.downloadResultsActionConfig.label + " all "  + this.props.searchResults.length + " files";

        return (
            <React.Fragment>
                <div style={{display: "inline"}}>
                    <Tooltip title={this.state.isFetching ? progressMessage : tooltipTitle}>
                        <span>

                            <IconButton
                                aria-label = "Menu"
                                aria-owns = {anchorEl ? 'simple-menu' : undefined}
                                aria-haspopup = "true"
                                onClick={this.download}
                                disabled = { this.state.isFetching }
                            >
                                {
                                    this.state.isFetching ?

                                    <CircularProgress color="secondary" size={20}/> :

                                    <i className='material-icons md-36'>
                                        file_download
                                    </i>
                                }

                            </IconButton>

                        </span>
                    </Tooltip>
                </div>
            </React.Fragment>
        )
    }
}

DownloadResults.propTypes = {
    userDetails: PropTypes.object.isRequired,
    downloadResultsActionConfig: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles)(DownloadResults));