import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {Grid} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import RelatedCaseDetails from "./RelatedCaseDetails";
import {getDataForSearchResultsTableMetadataSearch, getUserValue} from "../../search/helper";
import {getErrorMessageFromResponse, getFieldConfig} from "../../common/helper";

const styles = theme => ({});

function RelatedCaseDialog (props)  {

    //const debug = window.location.pathname.toLowerCase().includes("debug");
    const [open, setOpen] = useState(true);
    const [relatedCaseDetails, setRelatedCaseDetails] = useState(null)
    const [isFetching, setIsFetching] = useState(false);

    console.log ('RelatedCaseDialog props=', props)

    useEffect(() => {
        getRelatedCase();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = () => {

        //clear values
        setOpen(false);
        setRelatedCaseDetails(null)

        if (props.handleCloseDialog) {
            props.handleCloseDialog()
        }
    };

    const getRelatedCase = async () => {

        setIsFetching(true)
        const debug = window.location.pathname.toLowerCase().includes("debug");

        try {

            let request = {};
            let url = "";

            let searchConfig = props.searchConfig.folderSearch;
            const searchQuery = searchConfig.box;

            let fields = [];
            //add standard fields
            fields.push("name", "created_by","created_at","modified_at","version","shared_link","sequence_id","size")
            //get metadata list to query from config
            if (searchQuery.metadataKeys) {
                for (let i = 0; i < searchQuery.metadataKeys.length; i++) {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey + "." + searchQuery.metadataKeys[i])
                }
            }

            //Add a field for each result column to allow us to get metadata from other metadata templates
            const resultsColumns =  JSON.parse(JSON.stringify(searchConfig.resultsColumns)); //deep clone
            resultsColumns.forEach(col => {
                if (col.templateKey !== "n/a") {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + col.templateKey + "." + col.metadataKey)
                }
            });

            let query = [];
            let query_params = {};
            let queryValid = true;
            let order_by= searchQuery.order_by ? searchQuery.order_by : [];
            let queryParams = searchQuery.queryParams;

            let n=0;
            for (let p = 0; p < queryParams.length; p++)  {

                //if values provided in config, use them, otherwise get values from search values entered on searchTemplate
                if (queryParams[p].values) {
                    let vals = [];
                    for (let v = 0; v < queryParams[p].values.length; v++) {
                        vals.push(":val" + n);
                        let val = queryParams[p].values[v];
                        if (val.startsWith("$USER")) {
                            val = getUserValue(val, props.userDetails)
                        }
                        query_params["val" + n] = val;
                        n++
                    }
                    if (vals.length > 1 ){
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " (" + vals.join() + ")")
                    } else {
                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + vals[0]);
                    }
                } else {
                    //get values from folder
                    let val = props.folderDetails[searchQuery.templateKey + "~" + queryParams[p].metadataKey];
                    let fieldConfig = getFieldConfig(props.metadataConfig,searchQuery.templateKey , queryParams[p].metadataKey )

                    if (!val) {
                        if (queryParams[p].mandatory) {
                            //mandatory value not provided so return an error
                            queryValid = false
                            debug && console.log (" Unable to find " + searchQuery.templateKey + "~" + queryParams[p].metadataKey + " value required for document search")
                            props.enqueueSnackbar(fieldConfig.label + " value required for document search", {variant: 'error'});
                        }
                    } else {

                        query.push(queryParams[p].paramName + " " + queryParams[p].operation + " :val" + n);

                        if (fieldConfig.type === "date") {
                            let dateVal = new Date(val);
                            dateVal.setUTCHours(0,0,0,0);
                            query_params["val" + n] = dateVal ;
                        } else {
                            if (queryParams[p].operation === "ILIKE" || queryParams[p].operation === "LIKE") {
                                query_params["val" + n] = '%' + val + '%';
                            } else {
                                query_params["val" + n] = val;
                            }
                        }
                        n++;
                    }
                }

            }

            debug && console.log ('doSearchMetadata query = ', query, 'query_params = ', query_params);

            const folderId = searchConfig.folderIds[0];

            if (!folderId  ){
                props.enqueueSnackbar("Folder ID required for search", {variant: 'error'});
            }
                //Proceed if all required values are available
                if (queryValid && folderId ) {
                    let body = {
                        "ancestor_folder_id": folderId,
                        "fields": fields,
                        "limit": window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT,
                        "from": "enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey,
                        "query": query.join(" AND "),
                        "query_params": query_params,
                        "order_by": order_by,
                        "marker": ""
                    }

                    await props.triggerRefreshAuthToken();

                    request = {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + props.userDetails.accessToken
                        },
                        body: JSON.stringify(body)
                    };
                    url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;
                    debug && console.log ('doSearchMetadata REQUEST.  url:', url, 'BODY: ', body, 'request:', request);

                    let rows = [];
                    let totalCount = 0;

                    fetch(url, request)
                        .then(response => {
                            if (response.ok) {
                                return (response.json())
                            } else {
                                Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                                    .then(message => {
                                        this.props.enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                                    })

                                debug && console.log (" doSearchMetadata error. url:", url, "request: ", request, "response:", response);
                                setIsFetching(false)
                                return null
                            }
                        })
                        .then(resultA => {
                            debug && console.log ('doSearchMetadata response.json: ', resultA);

                            //with paging
                            if (resultA) {
                                const resultsColumns =  JSON.parse(JSON.stringify(searchConfig.resultsColumns)); //deep clone
                                totalCount = resultA.total_count;
                                if (totalCount === 0 || !resultA.entries) {
                                    rows = [];
                                } else {
                                    resultA.entries.forEach((item, i) => {
                                        debug && console.log(i, item);
                                        rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, props.metadataConfig))
                                    })
                                }
                            }
                        })
                        .then(result => {
                            console.log ('rows=', rows);
                            //updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount, nextMarker)
                            setRelatedCaseDetails(rows[0])
                            setIsFetching(false)
                        })
                        .catch(e => {
                            setIsFetching(false)
                            debug && console.log (" doSearchMetadata Exception:", e, "url:", url, "request: ", request);
                        })
                } else {
                    debug && console.log ('query params incomplete')
                    setIsFetching(false)
                }

        } catch (e) {
            console.error(e);
            setIsFetching(false)
            props.handleCloseDialog();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogContent>
                <Grid container spacing={1} style={ {height: "600px"}}>
                    <Grid item xs={12} style={{height: "100%"}}>
                        <Grid container style={{height: "100%"}}>
                            <Grid item xs={12} style={{height: "100%", overflowY: "auto",}}>
                                <React.Fragment>

                                    {
                                        isFetching ?
                                            <LinearProgress variant={"indeterminate"} color={"primary"}/> :

                                            relatedCaseDetails &&

                                            <RelatedCaseDetails
                                                searchConfig={props.searchConfig}
                                                folderDetails={relatedCaseDetails}
                                                metadataConfig={props.metadataConfig}
                                                optionsConfig={props.optionsConfig}
                                            />
                                    }

                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );

}

RelatedCaseDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    searchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(RelatedCaseDialog));