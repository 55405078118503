import React, {useEffect, useReducer, useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Grid} from "@material-ui/core";
import Input from "@material-ui/core/Input/Input";
import {
    getErrorMessageFromResponse,
    getObjectByKey,
    getOktaGroups,
    getOktaUsers,
    validateEmail
} from "../../common/helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import classNames from "classnames";
import Radio from "@material-ui/core/Radio";

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&$disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    list: {
        width: '100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },

    listItem: {
        minWidth: 0,
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },
    listContainer: {
        overflow: 'auto',
    },
    listItemTextTransfer: {
        fontSize: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
        //fontSize: '30px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
        minWidth: 32
    },

    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});

function EditUserDialog(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log ('EditUserDialog props = ', props);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [status, setStatus] = useState("");
    const [groups, setGroups] = useState([])
    const [userName, setUserName] = useState({label: "", value: "", user: {login: ""}})
    const [deactivate, setDeactivate] = useState(false);
    const [retrieveSuccess, setRetrieveSuccess] = useState(false);
    const [isRetrieving, setIsRetrieving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const actionConfig = props.actionConfig;
    const [updatingGroups, setUpdatingGroups] = useState(false);
    const [updatingUsers, setUpdatingUsers] = useState(false);
    const userTypeOptions = actionConfig && actionConfig.userType && actionConfig.userType.options ? actionConfig.userType.options : [] ;
    const [userType, setUserType] = useState("" );
    const requiresBoxGroups  = (actionConfig.requiresBoxGroups === undefined || actionConfig.requiresBoxGroups === true) ? true : actionConfig.requiresBoxGroups //note, default is true

    async function getGroupOptions (userType, userGroups) {

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log('EditUserDialog getGroupOptions userType=', userType, 'userGroups=', userGroups);

        setUpdatingGroups(true);

        //get regex filter from config
        let selectedUserTypeOption = getObjectByKey("value", userType, userTypeOptions);
        debug && console.log ('selectedUserTypeOption = ', selectedUserTypeOption);
        const regexFilter =
            (selectedUserTypeOption && selectedUserTypeOption.regexFilter) ?
                selectedUserTypeOption.regexFilter :
                props.actionConfig.groupOptionsRegexFilter ?
                    props.actionConfig.groupOptionsRegexFilter :
                    "";
        debug && console.log('EditUserDialog regexFilter=', regexFilter);
        const showGroupDesc = props.actionConfig.showGroupDesc;

        let options = await getOktaGroups(props.userDetails, props.triggerRefreshAuthToken, regexFilter, showGroupDesc);
        if (options.length === 0) {
            props.enqueueSnackbar("No groups found" , {variant: 'error'});
            debug && console.log ('No options found');
        }

        //for each group that the user is in, add a value to groups array of objects
        for (const userGroup of userGroups) {
            const groupEntry = showGroupDesc && getObjectByKey("value", userGroup, options)
            const label = groupEntry && groupEntry.label ? groupEntry.label : userGroup
            setGroups(groups => [...groups, {label: label , value: userGroup}])
        }

        return (
            dispatch({type: "GROUP OPTIONS", value: options})
        )
    }

    let initialState =  {
        groupOptions: [],
        userOptions: [],
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {

        async function initUserOptions() {

            setUpdatingUsers(true);

            const filterDomains = props.actionConfig.domains ? props.actionConfig.domains : [];
            let options = await getOktaUsers(props.userDetails, props.triggerRefreshAuthToken, filterDomains)

            if (options.length === 0) {
                const domainStr = filterDomains.length > 0 ? " for " + filterDomains.join(", ") : " "
                props.enqueueSnackbar("No users found" + domainStr, {variant: 'error'});
            }

            return (
                dispatch({type: "USER OPTIONS", value: options})
            )
        }

        if (props.actionConfig.useAutoComplete) {
            initUserOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] )


    function reducer(state, action) {

        switch (action.type) {
            case "GROUP OPTIONS": {
                debug && console.log ('GROUP OPTIONS: ', action)
                setUpdatingGroups(false);
                return {
                    ...state,
                    groupOptions: action.value,
                    //initGroupOptionsDone: true
                }
            }

            case "USER OPTIONS": {
                setUpdatingUsers(false);
                return {
                    ...state,
                    userOptions: action.value,
                    //initUserOptionsDone: true
                }
            }

            default:
                return state
        }
    }

    function handleCloseDialog() {
        props.handleCloseDialog();
    }

    function validateGroups() {

        //check at least one group meets the regular expression specified in the config
        let valid = true;

        if (actionConfig.groupValidation && actionConfig.groupValidation.regex) {
            const re = new RegExp(actionConfig.groupValidation.regex);
            for (let i = 0; i < groups.length; i++) {
                //Check that at least one group meets the regular expression specified in config
                valid = re.test(groups[i].label)
                if (valid) {
                    break;
                }
            }
            if (!valid) {
                props.enqueueSnackbar(actionConfig.groupValidation.message, {variant: 'error'});
            }
        }
        return valid
    }

    function handleRetrieveClick() {
        if (validateEmail(userName.value)) {
            retrieve()
        } else {
            props.enqueueSnackbar("Please enter a valid email address" , {variant: 'error'});
        }
    }

    async function retrieve() {

        setIsRetrieving(true)

        const url = window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_SECURITY_API_USER_ENDPOINT + "/" + encodeURIComponent(userName.value);

        const request = {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
        }

        debug && console.log('retrieveUser url=', url, 'request=', request);

        await props.triggerRefreshAuthToken();
        await fetch(url, request)
            .then(response => {
                debug && console.log('retrieveUser response=', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving user'))
                        .then(message => {
                            props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("retrieve user error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(data => {
                debug && console.log('editUser response.json = ', data)

                if (data) {

                    setEmail(data.email)
                    setFirstName(data.firstName)
                    setLastName(data.lastName)
                    setStatus(data.status)
                    setUserName(data.username)
                    setRetrieveSuccess(true)

                    //determine userType based on groups returned
                    //get regex from config
                    let userType = "";
                    const calc = props.actionConfig.userType && props.actionConfig.userType.options;
                    let userGroups = data.groups;

                    if (calc && calc.length > 0) {
                        for (let i=0;i< calc.length; i++) {
                            const re = new RegExp(calc[i].regexFilter);
                            for(let g=0; g<userGroups.length;g++) {
                                //test regex, if criteria met then set userType
                                if (re.test(userGroups[g])) {
                                    debug && console.log ('criteria met, set userType=', userType)
                                    userType = calc[i].value
                                    setUserType(userType);
                                    break;
                                }
                            }
                            if (userType !== ""){
                                break;
                            }
                        }
                    }

                    // if (userType !== "") {
                    getGroupOptions(userType,userGroups).then(response => {
                        setUpdatingGroups(false);
                        //for each group that the user is in, add a value to groups array of objects
                        // for (const userGroup of userGroups) {
                        //     setGroups(groups => [...groups, {label: userGroup, value: userGroup}])
                        // }
                    })
                    // }
                }

                setIsRetrieving(false)

            })
            .catch(e => {
                props.enqueueSnackbar("Error retrieving user details (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("editUser  exception:" , e);
                setIsRetrieving(false)
            })
    }

    function validateChanges() {

        //check all fields complete and email format is valid
        let isValid = false;

       //const allFieldsComplete = email && firstName && lastName && groups.length > 0;
        const allFieldsComplete = email && firstName && lastName

        if (allFieldsComplete) {
            if (validateEmail(email)) {
                isValid = true
                isValid = validateGroups()
            } else {
                props.enqueueSnackbar("Please enter a valid email address" , {variant: 'error'});
            }
        } else {
            props.enqueueSnackbar("Please complete all fields before submitting" , {variant: 'error'});
        }
        return isValid
    }

    function handleSaveChangesClick() {
        if (validateChanges()) {
            saveChanges()
        }
    }

    async function saveChanges() {

        setIsSaving(true)

        const groupList = [];
        groups.forEach((group) => {groupList.push(group.value)})

        const url = window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_SECURITY_API_USER_ENDPOINT +  "/" + encodeURIComponent(userName);
        const body = {
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "groups": groupList,
            "deactivate": deactivate,
            "requiresBoxGroups":  requiresBoxGroups
        }

        const request = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('editUser url=', url, 'BODY', body, 'request=', request);

        await props.triggerRefreshAuthToken();

        await fetch(url, request)
            .then(response => {
                debug && console.log('saveChanges response=', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'saving changes to user details'))
                        .then(message => {
                            props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("save user error.  url:", url, "request: ", request);
                    return null
                }

            })
            .then(data => {
                debug && console.log('saveChanges response.json = ', data)

                if (data) {
                    props.enqueueSnackbar("User details updated successfully" , {variant: 'success'});
                    handleCloseDialog();
                }

                setIsSaving(false)

            })
            .catch(e => {
                props.enqueueSnackbar("Error saving user details (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("saveChanges  exception:" , e);
                setIsSaving(false)
            })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (!retrieveSuccess && !isRetrieving) {
                if ( userName.length > 0) {
                    handleRetrieveClick()
                }
            }
        }
    }

    const {classes} = props;
    const activeUser =  status !== "DEPROVISIONED"

    const defaultProps = {
        options: state.userOptions,
        getOptionLabel: (option) => (option && option.label + (option.description ? ' (' + option.description + ')' : '')) || ""
    };

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {retrieveSuccess ? "md" : "sm"}
                keepMounted={false}
            >
                <DialogTitle>{props.actionConfig.label ? props.actionConfig.label : "Edit User"}</DialogTitle>
                <DialogContent>
                    {
                        !retrieveSuccess ?

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {
                                        updatingUsers ?

                                            <LinearProgress variant={"indeterminate"} color={"secondary"}/> :

                                            <FormControl fullWidth>
                                                <InputLabel required shrink htmlFor="portalUser" className={classes.customLabel}>Username</InputLabel>

                                                {
                                                    props.actionConfig.useAutoComplete ?

                                                        <Autocomplete
                                                            {...defaultProps}
                                                            id={"portalUser"}
                                                            name={"portalUser"}
                                                            debug
                                                            value={userName}
                                                            autoFocus={true}
                                                            onChange={(event, newValue) => {
                                                                setUserName(newValue);
                                                            }}
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                           margin="none"
                                                                           autoComplete={"off"}
                                                                           disabled={isRetrieving || retrieveSuccess}
                                                                           onKeyDown={handleKeyDown}
                                                                           helperText={"Email address that this user registered with"}
                                                                />
                                                            }
                                                        /> :

                                                        <Input id="portalUser"
                                                               name="portalUser"
                                                               value={userName.value}
                                                               placeholder="Enter user logon (email address)"
                                                               type="text"
                                                               margin="none"
                                                               variant="outlined"
                                                               autoComplete=""
                                                               onChange={e => setUserName({value: e.target.value})}
                                                               autoFocus = {true}
                                                               disabled={isRetrieving || retrieveSuccess}
                                                        />
                                                }
                                            </FormControl>
                                    }
                                </Grid>
                            </Grid> :

                            <Grid container spacing={2}>

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel required shrink htmlFor="email" className={classes.customLabel}>Email</InputLabel>
                                        <Input
                                            id="email" name="email" value={email}
                                            type="text" margin="none" variant="outlined"
                                            placeholder="Enter email"
                                            onChange={e => setEmail(e.target.value)}
                                            readOnly={!activeUser} disabled={!activeUser || isSaving}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="userName" className={classes.customLabel}>Username</InputLabel>
                                        <Input
                                            id="userName" name="userName" value={userName}
                                            type="text" margin="none" variant="outlined"
                                            readOnly={true} disabled={true}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel required shrink htmlFor="firstName" className={classes.customLabel}>First Name</InputLabel>
                                        <Input
                                            id="firstName" name="firstName" value={firstName}
                                            type="text" margin="none" variant="outlined"
                                            placeholder="Enter first name" autoFocus={true}
                                            onChange={e => setFirstName(e.target.value)}
                                            readOnly={!activeUser} disabled={!activeUser || isSaving}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    {
                                        (userTypeOptions && userTypeOptions.length > 0) &&

                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="userName" className={classes.customLabel}>User
                                                Type</InputLabel>
                                            <Input
                                                id="userType" name="userType" value={userType}
                                                type="text" margin="none" variant="outlined"
                                                readOnly={true} disabled={true}
                                            />
                                        </FormControl>
                                    }
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel required shrink htmlFor="lastName" className={classes.customLabel}>Last Name</InputLabel>
                                        <Input
                                            id="lastName" name="lastName" value={lastName}
                                            type="text" margin="none" variant="outlined"
                                            placeholder="Enter last name"
                                            onChange={e => setLastName(e.target.value)}
                                            readOnly={!activeUser} disabled={!activeUser}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="status" className={classes.customLabel}>Status</InputLabel>
                                        <Input
                                            id="status" name="status" value={status}
                                            type="text" margin="none" variant="outlined"
                                            readOnly={true} disabled={true}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel required shrink htmlFor="groups-autocomplete" className={classes.customLabel}>Groups</InputLabel>
                                        {
                                            updatingGroups ?

                                                <LinearProgress variant={"indeterminate"} color={"secondary"}/> :

                                                <Autocomplete
                                                    // isOptionEqualToValue={(option, value) => option.value === value.value} //materialui v5 override isOptionEqualToValue to address console warning
                                                    getOptionSelected={(option, value) => option.value === value.value} //materialui v4 override getOptionSelected to address console warning
                                                    multiple
                                                    id="groups-autocomplete"
                                                    value={groups}
                                                    onChange={(event, newValue) => {
                                                        setGroups([...newValue]);
                                                    }}
                                                    options={updatingGroups ? [] : state.groupOptions}
                                                    getOptionLabel={(option) => option.label + (option.description ? ' (' + option.description + ')' : '')}
                                                    renderTags={(tagValue, getTagProps) =>
                                                        tagValue.map((option, index) => (
                                                            <Chip
                                                                label={option.label + (option.description ? ' (' + option.description + ')' : '')}
                                                                {...getTagProps({index})}
                                                            />
                                                        ))
                                                    }
                                                    style={{width: '100%'}}
                                                    renderInput={(params) => (
                                                        <TextField {...params} margin="none"  readOnly={!activeUser} disabled={!activeUser}/>
                                                    )}
                                                    readOnly={!activeUser || isSaving} disabled={!activeUser || isSaving}
                                                />
                                        }
                                    </FormControl>
                                </Grid>

                                {
                                    activeUser &&

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            value="deactivate"
                                            checked={deactivate}
                                            control={<Checkbox color="secondary" onChange={(event) => {setDeactivate(event.target.checked)}} />}
                                            label="Deactivate User"
                                            labelPlacement="end"
                                            readOnly={!activeUser} disabled={!activeUser || isSaving}
                                        />
                                    </Grid>
                                }

                            </Grid>
                    }

                </DialogContent>

                <DialogActions>
                    {
                        !isRetrieving && !isSaving &&

                        <Button onClick={() => handleCloseDialog()} variant="contained" >
                            { retrieveSuccess ? "Close"  : "Cancel"}
                        </Button>
                    }
                    {
                        !retrieveSuccess ?

                            <Button color="secondary" onClick={() => handleRetrieveClick()} variant={"contained"}
                                    disabled={!userName || userName.length === 0 || isRetrieving}>
                                {isRetrieving ? "Retrieving..." : "Retrieve User Details"}
                            </Button> :

                            retrieveSuccess && status !== "DEPROVISIONED" &&
                            <Button color="secondary" onClick={() => handleSaveChangesClick()} variant={"contained"} disabled={isSaving}>
                                {isSaving ? "Saving..." : "Save Changes"}
                            </Button>
                    }

                </DialogActions>
                {
                    (isRetrieving || isSaving) &&
                    <LinearProgress variant={"contained"} color={"primary"}/>
                }

            </Dialog>
        </div>
    );
}


EditUserDialog.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(EditUserDialog));