import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Grid} from "@material-ui/core";
import {
    getErrorMessageFromResponse, removeItemFromArray,
    validateEmail
} from "../../../common/helper";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import FormLabel from "@material-ui/core/FormLabel";

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&$disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    list: {
        width: '100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },

    listItem: {
        minWidth: 0,
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },
    listContainer: {
        overflow: 'auto',
    },
    listItemTextTransfer: {
        fontSize: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
        //fontSize: '30px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
        minWidth: 32
    },

    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    readOnlyText: {
        opacity: 0.3
    },
    fieldset: {
        marginBottom: 16,
        padding: 8,
        border: '1px solid #E0E0E0',
        borderRadius: 0,
        paddingTop: 12
    }

});

function RemoveSubfolderAccessDialog(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log ('RemoveSubfolderAccessDialog props = ', props);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [status, setStatus] = useState("");
    const [groups, setGroups] = useState([])
    const [userName, setUserName] = useState("")
    const [deactivate, setDeactivate] = useState(false);
    const [removeAccess, setRemoveAccess] = useState(true);
    const [retrieveSuccess, setRetrieveSuccess] = useState(false);
    const [isRetrieving, setIsRetrieving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {

        async function retrieve(user) {

            setIsRetrieving(true)

            const url = window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_SECURITY_API_USER_ENDPOINT + "/" + encodeURIComponent(user);

            const request = {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + props.userDetails.accessToken,
                    "Content-Type": "application/json",
                },
            }

            debug && console.log('retrieveUser url=', url, 'request=', request);

            await props.triggerRefreshAuthToken();
            await fetch(url, request)
                .then(response => {
                    debug && console.log('retrieveUser response=', response);
                    if (response.ok) {
                        return(response.json())
                    } else {
                        Promise.resolve(getErrorMessageFromResponse(response, 'retrieving user'))
                            .then(message => {
                                props.enqueueSnackbar(message, {variant: 'error'});
                            })
                        debug && console.log("retrieve user error.  url:", url, "request: ", request);
                        return null
                    }
                })
                .then(data => {
                    debug && console.log('editUser response.json = ', data)

                    if (data) {

                        setEmail(data.email)
                        setFirstName(data.firstName)
                        setLastName(data.lastName)
                        setStatus(data.status)
                        setUserName(data.username)
                        setGroups(data.groups)
                        setRetrieveSuccess(true)

                    }

                    setIsRetrieving(false)

                })
                .catch(e => {
                    props.enqueueSnackbar("Error retrieving user details (exception: " + e.message + ")" , {variant: 'error'});
                    debug && console.log("editUser  exception:" , e);
                    setIsRetrieving(false)
                })
        }

        debug && console.log ('RemoveSubfolderAccessDialog useEffect props=', props)

        retrieve(props.user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] )


    function handleCloseDialog(groupsUpdated) {
        props.handleCloseDialog(groupsUpdated);
    }

    function validateChanges() {

        //check all fields complete and email format is valid
        let isValid = false;

        const allFieldsComplete = email && firstName && lastName ;

        if (allFieldsComplete) {
            if (validateEmail(email)) {
                isValid = true
            } else {
                props.enqueueSnackbar("Please enter a valid email address" , {variant: 'error'});
            }
        } else {
            props.enqueueSnackbar("Please complete all fields before submitting" , {variant: 'error'});
        }
        return isValid
    }

    function handleSaveChangesClick() {
        if (validateChanges()) {
            saveChanges()
        }
    }

    async function saveChanges() {

        setIsSaving(true)

        //remove group
        const updatedGroups = removeItemFromArray(groups, props.folderGroupName);
        const url = window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_SECURITY_API_USER_ENDPOINT +  "/" + encodeURIComponent(userName);

        const body = {
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "groups": updatedGroups, //TODO REMOVE props.folderGroupName from list of groups
            "deactivate": deactivate
        }

        const request = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('editUser url=', url, 'BODY', body, 'request=', request);

        await props.triggerRefreshAuthToken();

        await fetch(url, request)
            .then(response => {
                debug && console.log('saveChanges response=', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'saving changes to user details'))
                        .then(message => {
                            props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("save user error.  url:", url, "request: ", request);
                    return null
                }

            })
            .then(data => {
                debug && console.log('saveChanges response.json = ', data)

                if (data) {
                    props.enqueueSnackbar("User details updated successfully" , {variant: 'success'});
                    handleCloseDialog(true);
                }

                setIsSaving(false)

            })
            .catch(e => {
                props.enqueueSnackbar("Error saving user details (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("saveChanges  exception:" , e);
                setIsSaving(false)
            })
    }


    const {classes} = props;
    const activeUser =  status !== "DEPROVISIONED"

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {"sm"}
                keepMounted={false}
            >
                <DialogTitle>Remove User Access</DialogTitle>
                <DialogContent>
                    <React.Fragment>
                        {
                            isRetrieving ?

                                <Typography variant={"body1"}>Retrieving user details for {props.user}</Typography>

                                :

                                <React.Fragment>

                                    <FormControl component="fieldset" className={classes.fieldset}>
                                        <FormLabel component="Legend">{userName}</FormLabel>


                                        <Grid container spacing={1}>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink htmlFor="firstName"
                                                                className={classes.customLabel}>Name</InputLabel>
                                                    <Typography variant={"body1"}
                                                                className={classes.readOnlyText}>{firstName + " " + lastName}</Typography>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink
                                                                className={classes.customLabel}>Email</InputLabel>
                                                    <Typography variant={"body1"}
                                                                className={classes.readOnlyText}>{userName}</Typography>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink htmlFor="status"
                                                                className={classes.customLabel}>Status</InputLabel>
                                                    <Typography variant={"body1"}
                                                                style={!activeUser ? {color: 'red'}: {}}
                                                                className={classes.readOnlyText}>{status}</Typography>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    <InputLabel shrink htmlFor="groups"
                                                                className={classes.customLabel}>Groups</InputLabel>
                                                    <Typography variant={"body1"}
                                                                className={classes.readOnlyText}>{groups.join('; ')}</Typography>
                                                </FormControl>
                                            </Grid>

                                        </Grid>

                                    </FormControl>

                                    {
                                        activeUser &&

                                        <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        value="removeAccess"
                                                        checked={removeAccess}
                                                        control={<Checkbox color="secondary" onChange={(event) => {
                                                            setRemoveAccess(event.target.checked)
                                                        }}/>}
                                                        label={"Remove from group " + props.folderGroupName + " (this will remove the user's access from all contracts that this group has access to)"}
                                                        labelPlacement="end"
                                                        readOnly={true} disabled={true}
                                                    />

                                                </Grid>

                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        value="deactivate"
                                                        checked={deactivate}
                                                        control={<Checkbox color="secondary" onChange={(event) => {
                                                            setDeactivate(event.target.checked)
                                                        }}/>}
                                                        label= {props.actionConfig.deactivateLabel ? props.actionConfig.deactivateLabel : "Deactivate User"}
                                                        labelPlacement="end"
                                                        readOnly={!activeUser} disabled={!activeUser || isSaving}
                                                    />
                                                </Grid>
                                        </Grid>
                                    }

                                </React.Fragment>
                        }

                    </React.Fragment>

                </DialogContent>

                <DialogActions>
                    <React.Fragment>
                        {
                            !isRetrieving && !isSaving &&

                            <Button onClick={() => handleCloseDialog(false)} variant="contained" >
                                { activeUser ? "Cancel"  : "Close"}
                            </Button>
                        }
                        {
                            retrieveSuccess && status !== "DEPROVISIONED"  &&
                            <Button color="secondary" onClick={() => handleSaveChangesClick()} variant={"contained"} disabled={(!deactivate && !removeAccess) || isSaving}>
                                {isSaving ? "Saving..." : "Submit"}
                            </Button>
                        }
                    </React.Fragment>

                </DialogActions>
                {
                    (isRetrieving || isSaving) &&
                    <LinearProgress variant={"contained"} color={"primary"}/>
                }

            </Dialog>
        </div>
    );
}

RemoveSubfolderAccessDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderLabel: PropTypes.string.isRequired,
    folderGroupName: PropTypes.string.isRequired,
    actionConfig: PropTypes.object.isRequired
};


export default withSnackbar(withStyles(styles, { withTheme: true })(RemoveSubfolderAccessDialog));