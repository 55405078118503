import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from "@material-ui/core/Divider/Divider";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {hasAccess} from "../common/helper"
import AddUserDialog from "../admin/user/AddUserDialog";
import EditUserDialog from "../admin/user/EditUserDialog";
import AddDocTypeDialog from "../admin/DocType/AddDocType.js";

const styles = (theme) => ({
    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },
    menuListItem: {
        color: theme.menuListItem.colour
    },
});

function AdminMenu(props) {

    const {classes} = props;
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [showEditUserDialog, setShowEditUserDialog] = useState(false);
    const addUserConfig = props.actionsConfig.addUser;
    const showApplicationSettings = props.actionsConfig.applicationSettings && props.actionsConfig.applicationSettings.enabled && hasAccess(props.actionsConfig.applicationSettings, props.userDetails.userRoles)
    const showAddUser = addUserConfig && addUserConfig.enabled && hasAccess(addUserConfig, props.userDetails.userRoles)
    const showEditUser = props.actionsConfig.editUser && props.actionsConfig.editUser.enabled && hasAccess(props.actionsConfig.editUser, props.userDetails.userRoles)
    const showAddDocType = props.actionsConfig.addDocType && props.actionsConfig.addDocType.enabled && hasAccess(props.actionsConfig.addDocType, props.userDetails.userRoles)
    const showAdminMenu = showApplicationSettings || showAddUser || showEditUser || showAddDocType
    const [showAddDocTypeDialog, setShowAddDocTypeDialog] = useState(false);


    function closeAddUserDialog() {
        setShowAddUserDialog(false)
    }
    function closeEditUserDialog() {
        setShowEditUserDialog(false)
    }
    function closeAddDocTypeDialog() {
        setShowAddDocTypeDialog(false)
    }

        return (
            <React.Fragment>
                {
                    showAdminMenu &&
                        <React.Fragment>

                            <ListSubheader disableSticky color={'primary'}>Administration</ListSubheader>

                            {
                                showApplicationSettings &&

                                <ListItem
                                    button={true}
                                    key={'adminGeneral'}
                                    id={'adminGeneral'}
                                    onClick={props.handleShowAdminGeneral}
                                >
                                    <ListItemIcon className={classes.listIcon}><i
                                        className='material-icons'>settings</i></ListItemIcon>
                                    <ListItemText
                                        className={classes.menuListItem}
                                        primary={'Application Settings'}
                                    />
                                </ListItem>

                            }
                            {
                                showAddUser &&

                                <ListItem
                                    button={true}
                                    key={'addUser'}
                                    id={'addUser'}
                                    onClick={() => setShowAddUserDialog(true)}
                                >
                                    <ListItemIcon className={classes.listIcon}><i
                                        className='material-icons'>person_add</i></ListItemIcon>
                                    <ListItemText
                                        className={classes.menuListItem}
                                        primary={addUserConfig.managedUser ? "Add Managed User" : "Add User"}
                                    />
                                </ListItem>

                            }
                            {
                                showAddUserDialog &&

                                <AddUserDialog
                                    actionConfig={addUserConfig}
                                    classes={props.classes}
                                    handleCloseDialog={closeAddUserDialog}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    userDetails={props.userDetails}
                                />
                            }
                            {
                                showEditUser &&

                                <ListItem
                                    button={true}
                                    key={'editUser'}
                                    id={'editUser'}
                                    onClick={() => setShowEditUserDialog(true)}
                                >
                                    <ListItemIcon className={classes.listIcon}><i
                                        className='material-icons'>create</i></ListItemIcon>
                                    <ListItemText
                                        className={classes.menuListItem}
                                        primary={props.actionsConfig.editUser && props.actionsConfig.editUser.label ? props.actionsConfig.editUser.label : 'Edit User'}
                                    />
                                </ListItem>

                            }
                            {
                                showEditUserDialog &&

                                <EditUserDialog
                                    actionConfig={props.actionsConfig.editUser}
                                    classes={props.classes}
                                    handleCloseDialog={closeEditUserDialog}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    userDetails={props.userDetails}
                                />
                            }
                            {
                                showAddDocType &&

                                <ListItem
                                    button={true}
                                    key={'addDocType'}
                                    id={'addDocType'}
                                    onClick={() => setShowAddDocTypeDialog(true)}
                                >
                                    <ListItemIcon className={classes.listIcon}><i
                                        className='material-icons'>description</i></ListItemIcon>
                                    <ListItemText
                                        className={classes.menuListItem}
                                        primary={'Add Doc Type'}
                                    />
                                </ListItem>

                            }
                            {
                                showAddDocTypeDialog &&

                                <AddDocTypeDialog
                                    actionConfig={props.actionsConfig.addDocType}
                                    classes={props.classes}
                                    handleCloseDialog={closeAddDocTypeDialog}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    userDetails={props.userDetails}
                                />
                            }
                            <Divider/>
                        </React.Fragment>

                }
            </React.Fragment>
        );
}


AdminMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    handleShowAdminGeneral: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(AdminMenu);