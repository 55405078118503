import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PropTypes from "prop-types";

function DisplayOptionButtons(props) {

    // const debug = window.location.pathname.toLowerCase().includes("debug");
    // debug && console.log ('DisplayOptionButtons props = ', props);

    const searchConfig = props.searchConfig;
    const displayOptions = searchConfig.resultsDisplay && searchConfig.resultsDisplay && searchConfig.resultsDisplay.options;
    const showGridOption = props.currentDisplay !== "grid" && displayOptions && displayOptions.grid;
    const showListOption = props.currentDisplay !== "list" && displayOptions && displayOptions.list;
    const showTableOption = props.currentDisplay !== "table" && displayOptions && displayOptions.table;
    // debug && console.log ("showGridOption:",showGridOption, "showTableOption:", showTableOption, "showListOption:",showListOption, "currentDisplay:", props.currentDisplay)

    return (
        <React.Fragment>
            {
                showTableOption &&
                <Tooltip title={"View results in table"}>
                    <IconButton color="inherit" aria-label={"View results in table"} onClick={props.showResultsAsTable}>
                        <i className='material-icons'>view_column</i>
                    </IconButton>
                </Tooltip>
            }
            {
                showListOption &&
                <Tooltip title={"View results as list"}>
                    <IconButton color="inherit" aria-label="View results as list" onClick={props.showResultsAsList}>
                        <i className='material-icons'>view_list</i>
                    </IconButton>
                </Tooltip>
            }
            {
                showGridOption &&
                <Tooltip title={"View results in grid"}>
                    <IconButton color="inherit" aria-label={"View results in grid"} onClick={props.showResultsAsGrid}>
                        <i className='material-icons'>grid_on</i>
                    </IconButton>
                </Tooltip>
            }

        </React.Fragment>
    );
}

DisplayOptionButtons.propTypes = {
    searchConfig: PropTypes.object.isRequired,
    showResultsAsList: PropTypes.func,
    showResultsAsGrid: PropTypes.func.isRequired,
    showResultsAsTable: PropTypes.func.isRequired,
    currentDisplay: PropTypes.string.isRequired //grid, list or table
};


export default DisplayOptionButtons;