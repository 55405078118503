import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {DialogTitle, Grid} from "@material-ui/core";
import MaterialTable from 'material-table'
import LinearProgress from "@material-ui/core/LinearProgress";
import {getErrorMessageFromResponse} from "../common/helper";

const styles = theme => ({});
const tableTheme = theme => createTheme({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides overrides the overrides in the parent theme in app.css
    overrides: {

        MuiToolbar: {
            gutters: {
                paddingLeft: theme.spacing(2)
            },
            root: {
                paddingLeft: "0px"
            },
            regular:{
                paddingLeft: "0px"
            }
        },
        MTableToolbar: {
            root: {
                paddingLeft: "0px"
            }
        },
        MuiTableCell: {
            root: {
                padding: '0px',
                //paddingLeft: "24px"
            }
        },

        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
            },
            colorInherit: {
                color: theme.palette.secondary.main
            }
        },

        MuiAvatar: {
            colorDefault: {
                backgroundColor: theme.palette.secondary.main
            }
        }
    }
});

function AuditDialog (props)  {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [open, setOpen] = useState(true);
    const [audits, setAudits] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        getAudit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = () => {

        //clear values
        setOpen(false);
        setAudits([])

        if (props.handleCloseDialog) {
            props.handleCloseDialog()
        }
    };

    const getAudit = async () => {

        setIsFetching(true)

        await props.triggerRefreshAuthToken();

        let pathVars = "";
        if (props.type === "case") {
            pathVars = '/case/' + props.caseId;
        } else if (props.type === "document") {
            pathVars = '/document/' + props.docId ;
        } else if (props.type === "result") {
            pathVars = '/result/' + props.resultId ;
        } else if (props.type === "snippet") {
            pathVars = '/result/' + props.resultId + '/entry/' + props.snippetId
        }

        let url = window.REACT_APP_BASE_URL_SCREENING + pathVars + "/audit"
        debug && console.log("getAudit:", url);

        let request = {
            method: 'GET',
            headers: {
                "case-token": props.userDetails.caseAccessToken,
                "Authorization": "Bearer " + props.userDetails.accessToken
            }
        };

        fetch(url , request )
            .then(response => {
                debug && console.log('getAudit RESPONSE: ', response);
                if (response.ok) {
                    return response.json();
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving audits'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'})
                        })
                    debug && console.log("error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(result => {
                debug && console.log ('result = ', result);
                setIsFetching(false)
                let rows = [];
                if (result) {
                    result.forEach(r => {
                        r.created = new Date(r.created_at);
                        rows.push(r)
                    })
                }
                debug && console.log ('rows=', rows);
                setAudits(rows)
            })
            .catch(e => {
                setIsFetching(false)
                debug && console.log("getAudit Exception:", e, " url:", url, "request: ", request);
                props.enqueueSnackbar("Error retrieving audit - " + e, {variant: 'error'});
            })
    };

    const cellStyle = {fontSize: "13px", fontFamily: "roboto"}
    let cols = [
        {title: 'Date', field: 'created', type: 'datetime', cellStyle: cellStyle},
        {title: 'Name', field: 'created_by',cellStyle: cellStyle},
        {title: 'Action', field: 'action', cellStyle: cellStyle}
    ]

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle>History</DialogTitle>
            <DialogContent>
                <Grid container spacing={1} style={ {height: "600px"}}>
                    <Grid item xs={12} style={{height: "100%"}}>
                        <Grid container style={{height: "100%"}}>
                            <Grid item xs={12} style={{height: "100%", overflowY: "auto",}}>
                                {
                                    isFetching ?
                                        <LinearProgress variant={"indeterminate"} color={"primary"}/>  :

                                        audits && audits.length > 0 ?

                                            <ThemeProvider theme={tableTheme}>
                                                <MaterialTable
                                                    title={""}
                                                    columns={cols}
                                                    data={audits}
                                                    options={{
                                                        pageSize: 200,
                                                        pageSizeOptions: [],
                                                        toolbar: false,
                                                        paging: false
                                                    }}
                                                    localization={{
                                                        body: {
                                                            emptyDataSourceMessage: '',
                                                            editTooltip: 'Edit'
                                                        },
                                                        toolbar: {
                                                            searchTooltip: 'Filter',
                                                            searchPlaceholder: 'Filter'
                                                        },
                                                        header: {
                                                            actions: ""
                                                        }
                                                    }}
                                                />
                                            </ThemeProvider> :
                                            <span/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );

}

AuditDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired, //case, document, result, snippet
    caseId: PropTypes.string,
    docId: PropTypes.string,
    resultId: PropTypes.string,
    snippetId: PropTypes.string,
};

export default withSnackbar(withStyles(styles, { withTheme: true })(AuditDialog));