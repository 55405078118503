import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
});

class FilterFolderDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = name => event => {
        this.props.toggleFilterFolderDocuments(event.target.checked);
    };

    render() {
        //const { classes } = this.props;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('FilterFolderDocuments props: ', this.props);
        return (
            <Tooltip
                title={this.props.isFiltering ?
                    this.props.filterFolderDocuments ?
                        this.props.filterFolderDocumentsActionConfig.tooltipInProgressOff :
                        this.props.filterFolderDocumentsActionConfig.tooltipInProgressOn :
                        this.props.filterFolderDocuments ?
                            this.props.filterFolderDocumentsActionConfig.tooltipToggleOff :
                            this.props.filterFolderDocumentsActionConfig.tooltipToggleOn}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.props.filterFolderDocuments}
                            onChange={this.handleChange('filter')}
                            value="filter"
                            disabled={this.props.isFiltering}
                        />
                    }
                    label={<Typography variant={"subtitle2"}>
                        {this.props.isFiltering?
                        "Updating results..." :
                            this.props.filterFolderDocuments ?
                                this.props.filterFolderDocumentsActionConfig.labelToggleOn :
                                this.props.filterFolderDocumentsActionConfig.labelToggleOff}
                    </Typography>}
                    style={{padding: "12px"}}
                />
            </Tooltip>
        )
    }

}

FilterFolderDocuments.propTypes = {
    classes: PropTypes.object.isRequired,
    filterFolderDocumentsActionConfig: PropTypes.object.isRequired,
    toggleFilterFolderDocuments: PropTypes.func.isRequired,
    filterFolderDocuments: PropTypes.bool.isRequired,
    isFiltering: PropTypes.bool.isRequired
};

export default withStyles(styles)(FilterFolderDocuments);