import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {withSnackbar} from 'notistack';
import {green, grey, red} from '@material-ui/core/colors';
import RenderMetadataField from "../../common/RenderMetadataField";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {getErrorMessageFromResponse} from "../../common/helper";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },
    greenAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: green[500],
    },
    greyAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: grey[500],
    },
    dialogText: {
        marginBottom: theme.spacing(4)
    }
});

const INITIAL_STATE = {
    //dialog
    open: false,
    metadata: {},
    //service
    response: {
        success: true,
        message: ""
    },
    inProgress: false,
    boxId: "",
    folderName: ""
};

class AddFolderStructureDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        this.handleOnChangeMetadata = this.handleOnChangeMetadata.bind(this);
        this.createFolderStructure = this.createFolderStructure.bind(this);

    }

    componentDidMount(){
        this.setState({
            open: true,
            metadata: {} //need to set here to prevent caching of previous values
        });
    }

    handleClose = () => {

        const END_STATE = {
            //dialog/stepper
            open: false,
            metadata: {},

        };
        this.setState(END_STATE);

        this.props.closeDialog();


    };

    handleOnChangeMetadata = (id, newValue) => {

        const metadata = this.state.metadata;
        metadata[id] = newValue;
        this.setState({metadata: metadata});

    };

    validateFolderName =(folderName) => {
        //Check that folder name doesn't have forbidden characters '/'
        //Folder name cannot contain: "/" or "\" or any Unicode character outside the basic multilingual plane.

        let isValid = true;

        if (folderName && (folderName.includes("/") || folderName.includes("\\"))) {
            isValid = false
        }

        return isValid
    }

    validate = () => {

        // this.validateFolderName()

        let fields = this.props.addFolderConfig.fields;
        let missingValues = [];

        if (fields && fields.length > 0) {
            fields.forEach(field => {
                if (field.required) {
                    if (!this.state.metadata[field.templateKey + "~" + field.metadataKey]  || this.state.metadata[field.templateKey + "~" + field.metadataKey].toString() === "" ) {
                        missingValues.push(field)
                    }
                }
            })
        }

        if (missingValues.length > 0) {
            return false;
        } else if (missingValues.length === 0 ){
            return true
        }

    }

    async createFolderStructure(){

        const debug = window.location.pathname.toLowerCase().includes("debug");
        const addFolderConfig = this.props.addFolderConfig;

        let metadata = this.state.metadata;

        console.log ('**** metadata = ', metadata)

        let folderName = "";

        //Loop through folderName fields and get Value
        const separator = addFolderConfig.rootFolderName.separator ? addFolderConfig.rootFolderName.separator : " ";
        addFolderConfig && addFolderConfig.rootFolderName && addFolderConfig.rootFolderName.fields && Array.isArray(addFolderConfig.rootFolderName.fields) &&
        addFolderConfig.rootFolderName.fields.forEach(rootFolderNameField => {
            Object.entries(metadata).forEach(entry => {
                if (entry[0] === rootFolderNameField.templateKey + "~" + rootFolderNameField.metadataKey) {
                    folderName = folderName + (folderName === "" ? "" : separator) + entry[1]
                }
            });
        })

        if (!this.validate()) {

            this.props.enqueueSnackbar('Please input required values', {variant: 'info'});

        } else if (!this.validateFolderName(folderName)) {

            this.props.enqueueSnackbar('Folder name cannot contain: "/" or "\\" or any Unicode character outside the basic multilingual plane.', {variant: 'info'});

        } else {

            this.setState({inProgress: true});

            debug && console.log('createFolderStructure start,  props=', this.props);

            let rootFolderMetadata = [];

            addFolderConfig.metadata.forEach(m => {
                if (m.value && typeof m.value === "object" && m.value.templateKey && m.value.metadataKey) {
                    if (metadata [m.value.templateKey + "~" + m.value.metadataKey]){
                        m.value = metadata [m.value.templateKey + "~" + m.value.metadataKey]
                    }
                }
                rootFolderMetadata.push(m)
            })

            debug && console.log('rootFolderMetadata = ', rootFolderMetadata);

            let subFolders = [];
            if (addFolderConfig.subFolders) {
                subFolders = JSON.parse(JSON.stringify(addFolderConfig.subFolders)); //deep clone
            }

            //recursive function to set fixed metadata on all sub-folders
            function loopThroughJSON(obj) {
                for (let key in obj) {
                    if (key === 'metadata' && Array.isArray(obj[key])) {
                        //loop through each metadata item, if value not provided get value from state.metadata
                        obj[key].forEach(m => {
                            if (m.value && typeof m.value === "object" && m.value.templateKey && m.value.metadataKey) {
                                if (metadata [m.value.templateKey + "~" + m.value.metadataKey]){
                                    m.value = metadata [m.value.templateKey + "~" + m.value.metadataKey]
                                }
                            }
                        })
                    // loop through nested subFolders array
                    } else if (key === 'subFolders' && Array.isArray(obj[key])) {
                        for (let i = 0; i < obj[key].length; i++) {
                            loopThroughJSON(obj[key][i]);
                        }
                    }
                }
            }

            if (subFolders.length > 0) {
                // loop through the subFolders to add fixed metadata
                subFolders.forEach(subfolder => {
                    loopThroughJSON(subfolder)
                })

            }

            //body is array but ui config currently set up to just create one folder
            let body = [
                {
                    name: folderName !== "" ? (folderName.trim()) : "new folder",
                    metadata: rootFolderMetadata,
                    // applyGroupsToFolder: [],  //not required currently for EWG as using existing security
                    subFolders: subFolders
                }
            ];

            debug && console.log('createFolderStructure body: ', body);

            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + "/" + addFolderConfig.folderId + "/subfolders";
            const request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };

            debug && console.log("createFolderStructure url:", url, 'request: ', request);

            await this.props.triggerRefreshAuthToken();
            fetch(url, request)
                .then(response => {
                    if (response.ok) {
                        debug && console.log('createFolderStructure RESPONSE ok: ', response);

                        return (response.json()).then(result => {
                            debug && console.log('createFolderStructure response.json = ', result);
                            this.props.enqueueSnackbar(addFolderConfig.successMessage + " - " + folderName, {variant: 'success'});
                            this.setState({
                                boxId: result[0].boxId,
                                folderName: folderName,
                                inProgress: false
                            })
                        })
                    } else {
                        debug && console.log('createFolderStructure RESPONSE not ok: ', response);

                        //response not ok
                        Promise.resolve(getErrorMessageFromResponse(response, 'creating folder'))
                            .then(message => {
                                //Check for 409 Conflict error and give custom message if available
                                if (response.status === 409 && addFolderConfig.conflictMessage) {
                                    this.props.enqueueSnackbar(message + ".  " + addFolderConfig.conflictMessage, {variant: 'error'});
                                } else {
                                    this.props.enqueueSnackbar(message, {variant: 'error'});
                                }
                            })
                        this.setState({inProgress: false})

                    }
                })
                .catch(e => {
                    debug && console.log("createFolderStructure Exception:", e, "url:", url, "request: ", request);
                    this.props.enqueueSnackbar('Create Folder Structure Exception' + e.message, {variant: 'error'});
                });
        }

    };

    render() {

        const { classes, addFolderConfig } = this.props;

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={"sm"}
                keepMounted={false}
                disableBackdropClick disableEscapeKeyDown>

                <DialogTitle id="form-dialog-title">{addFolderConfig.label}</DialogTitle>

                <DialogContent>
                    <React.Fragment>

                        {
                            <DialogContentText className={classes.dialogText}>{
                                this.state.boxId ?
                                    addFolderConfig.successMessage :
                                    addFolderConfig.dialogText
                            }
                            </DialogContentText>
                        }

                        {
                            !this.state.boxId ?

                                addFolderConfig.fields.map(field => {return(

                                    <FormControl fullWidth  style={{paddingBottom: '10px'}} key={"fc" + field.templateKey + "~" + field.metadataKey}>

                                        <RenderMetadataField
                                            fieldValue={(this.state.metadata[field.templateKey + "~" + field.metadataKey])? this.state.metadata[field.templateKey + "~" + field.metadataKey] : ""}
                                            handleOnChange={this.handleOnChangeMetadata}
                                            metadataConfig={this.props.metadataConfig}
                                            optionsConfig={this.props.optionsConfig}
                                            metadataKey={field.metadataKey}
                                            templateKey={field.templateKey}
                                            usage={"upload"}
                                            formValues={this.state.metadata}
                                            required={field.required}
                                            helperText={field.helperText}
                                            forceDisable={this.state.inProgress}
                                            userDetails={this.props.userDetails}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        />
                                    </FormControl>
                                )}):

                                <List>
                                    <React.Fragment key={"frag"}>
                                        <ListItem key={"li"}>
                                            <ListItemAvatar><Avatar style={{backgroundColor: green[500]}}><i className='material-icons'>done</i></Avatar></ListItemAvatar>
                                            <ListItemText key={"liText"} primary={this.state.folderName} secondary={"Folder ID: " + this.state.boxId + ""}/>
                                        </ListItem>
                                    </React.Fragment>
                                </List>


                        }
                    </React.Fragment>
                </DialogContent>

                <DialogActions>

                    {
                            <div>
                                {
                                    !this.state.inProgress &&
                                    <Button style={{marginRight: '8px'}}
                                            variant={this.state.boxId && "contained"}
                                            color={this.state.boxId && "secondary"}
                                            onClick={this.handleClose}>{this.state.boxId ? "Close" : "Cancel"}</Button>
                                }
                                {
                                    !this.state.boxId &&
                                    <Button variant="contained" color="secondary" disabled = {this.state.inProgress}
                                        onClick={this.createFolderStructure}
                                    > {this.state.inProgress ? "Creating..." : "Submit"}
                                    </Button>
                                }

                            </div>
                    }

                </DialogActions>

                {
                    (this.state.inProgress ) && <LinearProgress color = "primary"/>
                }

            </Dialog>
        );
    }
}

AddFolderStructureDialog.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    closeDialog: PropTypes.func.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};


export default withSnackbar(withStyles(styles, { withTheme: true })(AddFolderStructureDialog));
