import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({});

class FilterFolderDocumentsByMetadata extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange = name => event => {
        this.props.toggleFilterFolderDocumentsByMetadata(event.target.checked);
    };

    render() {

       // window.location.pathname.toLowerCase().includes("debug") && console.log ('FilterFolderDocumentsByMetadata  props: ', this.props);
        return (
            <Tooltip
                title={this.props.isFiltering ?
                    this.props.filterFolderDocuments ?
                        this.props.filterFolderDocumentsByMetadataActionConfig.tooltipInProgressOff :
                        this.props.filterFolderDocumentsByMetadataActionConfig.tooltipInProgressOn :
                    this.props.filterFolderDocumentsByMetadata ?
                        this.props.filterFolderDocumentsByMetadataActionConfig.tooltipToggleOff :
                        this.props.filterFolderDocumentsByMetadataActionConfig.tooltipToggleOn}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.props.filterFolderDocumentsByMetadata}
                            onChange={this.handleChange('filter')}
                            value="filter"
                            disabled={this.props.isFiltering}
                        />
                    }
                    label={<Typography variant={"subtitle2"}>
                        {this.props.isFiltering?
                            "Updating results..." :
                            this.props.filterFolderDocumentsByMetadata ?
                                this.props.filterFolderDocumentsByMetadataActionConfig.labelToggleOn :
                                this.props.filterFolderDocumentsByMetadataActionConfig.labelToggleOff}
                    </Typography>}
                    style={{padding: "12px"}}
                />
            </Tooltip>
        )
    }

}

FilterFolderDocumentsByMetadata.propTypes = {
    classes: PropTypes.object.isRequired,
    filterFolderDocumentsByMetadataActionConfig: PropTypes.object.isRequired,
    toggleFilterFolderDocumentsByMetadata: PropTypes.func.isRequired,
    filterFolderDocumentsByMetadata: PropTypes.bool.isRequired,
    isFiltering: PropTypes.bool.isRequired
};

export default withStyles(styles)(FilterFolderDocumentsByMetadata);