import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import { ReactComponent as CustomIcon } from '../../common/customIcon-1.svg';
import Button from '@material-ui/core/Button';
import IconButton from "@material-ui/core/IconButton";

function ColumnIcon(props){

    //const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log ('ColumnIcon props = ', props);

    if (props.val && props.val !== "") {

        let valueMapping = {};
        if (props.iconConfig) {
            if (props.iconConfig.valueMapping) {
                valueMapping = props.iconConfig.valueMapping[props.val.toLowerCase()];
            }
        }

        //debug && console.log('valueMapping=', valueMapping)

        if (valueMapping) {
            if (valueMapping.colour) {
                return(
                    <div style={{textAlign: "center"}}>

                        {
                            //button used for hyp-x demo env only
                            props.iconConfig.icon === "button" ?
                                <Button variant="contained" disableElevation disableRipple style={{backgroundColor: valueMapping.colour, width: '120px', height:'16px', borderRadius: '8px'}}>
                                    {props.val}
                                </Button> :

                                <Tooltip title={(valueMapping.tooltipTitle === "" && props.iconConfig.icon !== "avatar") ? props.val : valueMapping.tooltipTitle}>
                                    {
                                        props.iconConfig.icon === "avatar" ?

                                            <Avatar key={"avatar"} style={{
                                                backgroundColor: valueMapping.colour,
                                                height: 20,
                                                width: 20,
                                                fontSize: '12px'
                                            }}>
                                                {props.val}
                                            </Avatar> :

                                            props.iconConfig.icon === "customIcon-1" ?

                                                //Note, in svg file need fill="current" stroke="current"
                                                //div required for tooltip to work as a child of Tooltip must be able to hold a ref
                                                <div style={{display: "inline"}}>
                                                    <IconButton disabled>
                                                        <CustomIcon fill={valueMapping.colour} style={{width: "24px", height: "24px"
                                                        }}/>
                                                    </IconButton>
                                                </div> :

                                                //div required for tooltip to work as a child of Tooltip must be able to hold a ref
                                                <div style={{display: "inline"}}>
                                                    <IconButton disabled>
                                                        <i className='material-icons' style={{color: valueMapping.colour}}>
                                                            {props.iconConfig.icon}
                                                        </i>
                                                    </IconButton>
                                                </div>
                                    }
                                </Tooltip>
                        }
                    </div>
                )
            } else {
                return (<span/>)
            }

        } else {
            //window.location.pathname.toLowerCase().includes("debug") && console.log ('No value mapping found for ', props.val.toLowerCase());
            return(
                <span/>
            )
        }
    } else {
        return(
            <span/>
        )
    }
}

ColumnIcon.propTypes = {
    val: PropTypes.string,
    iconConfig: PropTypes.object.isRequired
};

export default ColumnIcon