import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';
import './App.css';
import PortalApp from "./PortalApp";
import {SnackbarProvider} from 'notistack';
import 'typeface-roboto';
import 'typeface-montserrat';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
//import Crypto from 'crypto-browserify';
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
//import * as locales from '@material-ui/core/locale';
import {asyncForEach} from "../common/helper";
import {getTheme, prepareTheme} from "./helper";
import CryptoES from 'crypto-es'

let configFiles = {};

const Home = props => {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //const [locale, setLocale] = useState('zhCN');

    const configFilePaths = {
        workspaces: 'config/configWorkspaces.json',
        actionsConfig: 'config/configActions.json',
        metadataConfig: 'config/configMetadata.json',
        optionsConfig: 'config/configMetadataOptions.json',
        publicLinksConfig: 'config/configPublicLinks.json',
        templatesConfig: 'config/configTemplates.json',
        workflowConfig: 'config/configWorkflow.json',
        dashboardsConfig: 'config/configDashboards.json',
        generalConfig: 'config/configGeneral.json',
    };

    async function getConfigFiles() {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        // application config - either retrieve from api or pull from local config
        if (window.REACT_APP_CONFIG_API_REQUIRED === "true" || window.REACT_APP_CONFIG_API_REQUIRED === true) {
            const url = window.REACT_APP_CONFIG_API_BASE_URL;
            const request = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authState.accessToken.accessToken
                }
            };
            debug && console.log('get config files from service..url: ', url, 'request:', request);

            fetch(url, request)
                .then(response => response.json())
                .then(data => {
                    debug && console.log('getConfigFiles - response.json', data);
                    configFiles = {
                        workspaces: JSON.parse(data.workspace),
                        actionsConfig: JSON.parse(data.action),
                        metadataConfig: JSON.parse(data.metadata),
                        optionsConfig: JSON.parse(data.metadataoption),
                        publicLinksConfig: JSON.parse(data.link),
                        dashboardsConfig: JSON.parse(data.link),
                    }
                })
                .catch(e => {
                    debug && console.log(e);
                    setErrorDetails("Config Retrieval Error" + e);
                })
        } else {

            //initialise values in case any not available
            configFiles = {
                workspaces: [],
                actionsConfig: {},
                metadataConfig: {},
                optionsConfig: {},
                publicLinksConfig: [],
                dashboardsConfig: [],
                templatesConfig: {},
                workflowConfig: {folderActions:[],documentActions:[]}
            }

            const configPrefix =
                window.location.href.indexOf("file") > -1 || window.location.href.indexOf("folder") > -1 || window.location.href.indexOf("upload") > -1 ? "../../" :
                    window.location.href.indexOf("dashboard") > -1 ? "../" :
                        "";

            await asyncForEach(Object.entries(configFilePaths), async (entry) => {
                let url = configPrefix + entry[1];
                await fetch(url)
                    .then(response => {
                        return(response.json())
                    })
                    .then(data => {
                        configFiles[entry[0]] = data;
                    })
                    .catch(e => {
                        debug && console.log("getConfigFiles exception:" , e, " entry: ", entry);
                    })
            })
            debug && console.log('getConfigFiles done: ', configFiles);
            return configFiles
        }
    }

    const theme = getTheme()
    const portalThemeOptions = prepareTheme(theme);
    const portalTheme = createTheme(portalThemeOptions);

    const history = useHistory();

    const drawerWidth = theme.drawerWidth;
    const {oktaAuth } = useOktaAuth();
    const authState = oktaAuth.authStateManager.getAuthState() //NEW

    const [workspaceInfo, setWorkspaceInfo] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);

    const elasticTokenRequired = window.REACT_APP_FOLDER_SOURCE === "elastic" ||
        window.REACT_APP_DOCUMENT_SOURCE === "elastic" ||
        window.REACT_APP_TASK_SOURCE === "elastic" ||
        window.REACT_APP_COMMENT_SOURCE === "elastic";

    const boxTokenRequired = window.REACT_APP_FOLDER_SOURCE === "box" ||
        window.REACT_APP_DOCUMENT_SOURCE === "box" ||
        window.REACT_APP_TASK_SOURCE === "box" ||
        window.REACT_APP_COMMENT_SOURCE === "box"

    debug && console.log('boxTokenRequired=', boxTokenRequired, 'elasticTokenRequired=', elasticTokenRequired)

    let workspaces = [];
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('code')) {
        console.log ('url has code param so set redirectUrlCode and redirect to ' + window.localStorage.getItem('windowUrl'))
        console.log ('authState=', authState);

        window.localStorage.setItem('redirectUrlCode', window.location);  //redirectUrlCode is used in request to get elastic token
        window.open(window.localStorage.getItem('windowUrl'), "_self");
    } else if(urlParams.has('error')) {
        window.localStorage.setItem('error', urlParams.get('error'));
    } else if((window.localStorage.getItem('forceRedirectUrl') !== null && window.localStorage.getItem('forceRedirectUrl') !== "null")) {
        window.localStorage.setItem('windowUrl', window.localStorage.getItem('forceRedirectUrl'));
        window.localStorage.setItem('forceRedirectUrl', null);
        window.open(window.localStorage.getItem('windowUrl'), "_self");
    } else {
        window.localStorage.setItem('windowUrl', window.location);
    }

    useEffect(() => {

        //runs when authState/oktaAuth change
        const debug = window.location.pathname.toLowerCase().includes("debug");
        let userRoles = null;

        if (!authState || !authState.isAuthenticated) {
           // alert ('not authenticated');
                // When user isn't authenticated, forget any user info
                setWorkspaceInfo(null);
                setUserDetails(null);
                setErrorDetails(null);
                window.localStorage.setItem('error', null);
        } else {
                // user is authenticated...
                if (window.localStorage.getItem('error') !== null && window.localStorage.getItem('error') !== "null") {
                    if(urlParams.get('error') === "login_required") {
                        if(window.localStorage.getItem('forceRedirectUrl') === null || window.localStorage.getItem('forceRedirectUrl') === "null"){
                            window.localStorage.setItem('forceRedirectUrl', window.localStorage.getItem('windowUrl'))
                        }
                        window.localStorage.setItem('redirectUrlCode', null);
                        window.localStorage.setItem('state', null);
                        window.localStorage.setItem('nonce', null);
                        window.localStorage.setItem('windowUrl', null);
                        window.localStorage.setItem('error', null);
                        oktaAuth.signOut({});
                    } else {
                        setErrorDetails(window.localStorage.getItem('error'));
                        window.localStorage.setItem('error', null);
                    }
                } else {

                    debug && console.log('isAuthenticated... process token...');

                    oktaAuth.getUser().then((info) => {
                        debug && console.log('oktaAuth.getUser response info = ', info);

                        // dynamic theme....
                        if(info.portalTheme){
                            window.localStorage.setItem('theme',info.portalTheme);
                        } else {
                            window.localStorage.setItem('theme',"");
                        }

                        // check if elastic auth is required... redirect url flow is required...
                        debug && console.log ('redirectUrlCode = ', window.localStorage.getItem('redirectUrlCode'), 'userDetails=', userDetails) ;

                        if (elasticTokenRequired && (window.localStorage.getItem('redirectUrlCode') === null || window.localStorage.getItem('redirectUrlCode') === "null")) {

                            //User is authenticated, elastic token is required and we haven't got recirectUrlCode available in local storage need to redirect to okta to get it

                            const randomBytes = require('randombytes')

                            const hash = CryptoES.SHA256(randomBytes(32).toString("hex"));
                            const authStateParam = hash.toString(CryptoES.enc.Hex);

                            const hash1 = CryptoES.SHA256(randomBytes(32).toString("hex"));
                            const authNonceParam = hash1.toString(CryptoES.enc.Hex);

                            window.localStorage.setItem('state', authStateParam);
                            window.localStorage.setItem('nonce', authNonceParam);

                            const url = window.REACT_APP_OKTA_BASE_URL + "/oauth2/default/v1/authorize?client_id=" + window.REACT_APP_OKTA_CASE_ID +
                                "&response_type=code&scope=" + window.REACT_APP_OKTA_CASE_SCOPES +
                                "&redirect_uri=" + window.location.origin +
                                "&state=" + authStateParam +
                                "&nonce=" + authNonceParam  + "&prompt=none";  //added + "&prompt=none" to remove okta login prompt if cache cleared


                            debug && console.log('Redirect to okta to get url with code.  Okta url =', url);
                            debug && console.log ('redirectUrlCode = ', window.localStorage.getItem('redirectUrlCode'), 'userDetails=', userDetails, 'authState=', authState);

                            //TODO showing Okta login screen after this redirect if cache has been cleared and page reloaded.
                            window.open(url, "_self");
                        } else {

                            getConfigFiles()
                                .then(configFiles => {
                                    const accessToken = authState.accessToken.accessToken;
                                    const boxId = info.boxId;
                                    userRoles = info[window.REACT_APP_OKTA_GROUP_ATTRIBUTE];
                                    if (!userRoles) {
                                        userRoles = info.groups;
                                    }
                                    userRoles = userRoles.sort()
                                    let boxAccessToken = null;

                                    const date = new Date();
                                    const currentTime = date.getTime();
                                    const expiresIn = currentTime + (1000 * 60 * parseInt(window.REACT_APP_OKTA_REFRESH));

                                    if (userRoles) {
                                        //Loop through the Workspaces in config file, if the user has any of the accessroles listed for that workspace then add the Workspace to the list
                                        configFiles.workspaces.forEach(ws => {
                                            for (let i = 0; i < ws.accessRoles.length; i++) {
                                                if (userRoles.includes(ws.accessRoles[i])) {
                                                    workspaces.push(ws);
                                                    break;
                                                } else if (ws.accessRoles[i].endsWith('*')) {
                                                    //if role ends with *, give access to any roles ending with *
                                                    const roleToTest = (ws.accessRoles[i]).substring(0,ws.accessRoles[i].lastIndexOf('*'))
                                                    const filteredUserRoles = userRoles.filter(r => r.startsWith(roleToTest));
                                                    if (filteredUserRoles.length > 0){
                                                        workspaces.push(ws);
                                                        break;
                                                    }
                                                }
                                            }
                                        })
                                    }

                                    if (workspaces.length === 0) {
                                        setErrorDetails("You are not configured to use this application");
                                        return null;
                                    }

                                    if (!boxTokenRequired) {
                                        // elastic auth only
                                        const body = {
                                            "redirect_uri": window.localStorage.getItem('redirectUrlCode'),
                                            "nonce": window.localStorage.getItem('nonce'),
                                            "state": window.localStorage.getItem('state')
                                        };
                                        const request = {
                                            method: 'POST',
                                            headers: {
                                                "Content-Type": "application/json",
                                                "Authorization": "Bearer " + authState.accessToken.accessToken
                                            },
                                            body: JSON.stringify(body)
                                        };
                                        const url = window.window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_CASE_AUTH_SECURITY

                                        debug && console.log('elastic only, get elastic access token, url: ', url, 'request: ', request);
                                        fetch(url, request)
                                            .then(response => {
                                                window.location.pathname.toLowerCase().includes("debug") && console.log ("get elastic access token response = ", response);
                                                if (response.ok) {
                                                    return(response.json())
                                                } else {
                                                    window.localStorage.setItem('error', 'Error getting token: ' + response.status + ' ' + response.statusText);
                                                    return null
                                                }
                                            })
                                            .then(data => {
                                                debug && console.log('get elastic access token, response.json: ', data);

                                                window.localStorage.setItem('redirectUrlCode', null);
                                                window.localStorage.setItem('state', null);
                                                window.localStorage.setItem('nonce', null);
                                                window.localStorage.setItem('windowUrl', null);

                                                setWorkspaceInfo(workspaces);

                                                setUserDetails({
                                                    isUserLoggedIn: true,
                                                    userLoggedOut: false,
                                                    userName: info.name,
                                                    userEmail: info.email,
                                                    expiresIn: expiresIn,
                                                    boxId: "",
                                                    userRoles: userRoles,
                                                    accessToken: accessToken,
                                                    boxAccessToken: "",
                                                    caseAccessToken: data && data.access_token,
                                                    caseExpiresIn: data && data.expires_in,
                                                    caseRefreshToken: data && data.refresh_token,
                                                    caseUsername: data && data.username,
                                                    otherInfo: "...."
                                                })
                                            })
                                    } else {
                                        // box auth required...
                                        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SECURITY_USER_TOKEN + "?userId=" + boxId;
                                        const request = {headers: {"Authorization": "Bearer " + accessToken}}
                                        debug && console.log('call box auth.  ', 'url: ', url, 'request:', request);
                                        // get box token
                                        fetch(url, request)
                                            // .then(response => response.json())
                                            .then(response => {
                                                window.location.pathname.toLowerCase().includes("debug") && console.log ("get box access token response = ", response);
                                                if (response.ok) {
                                                    return(response.json())
                                                } else {
                                                    console.log ('Error getting box token: ' + response.status + ' ' + response.statusText);
                                                    window.localStorage.setItem('error', 'Error getting box token: ' + response.status + ' ' + response.statusText);
                                                    return null
                                                }
                                            })
                                            .then(resultA => {
                                                debug && console.log('get box token response.json=', resultA);
                                                boxAccessToken = resultA && resultA.access_token;

                                                if (elasticTokenRequired) {
                                                    //alert ('box token done, now get elastic token')
                                                    // box and elastic auth required.
                                                    const url = window.window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_CASE_AUTH_SECURITY
                                                    const body = {
                                                        "redirect_uri": window.localStorage.getItem('redirectUrlCode'),
                                                        "nonce": window.localStorage.getItem('nonce'),
                                                        "state": window.localStorage.getItem('state')
                                                    };
                                                    const request = {
                                                        method: 'POST',
                                                        headers: {
                                                            "Content-Type": "application/json",
                                                            "Authorization": "Bearer " + authState.accessToken.accessToken
                                                        },
                                                        body: JSON.stringify(body)
                                                    };

                                                    debug && console.log('get elastic token url: ', url, 'request: ', request);
                                                    fetch(url, request)
                                                        .then(response => response.json())
                                                        .then(data => {
                                                            debug && console.log('get elastic token - response.json=', data);

                                                            window.localStorage.setItem('redirectUrlCode', null);
                                                            window.localStorage.setItem('state', null);
                                                            window.localStorage.setItem('nonce', null);
                                                            window.localStorage.setItem('windowUrl', null);

                                                            setWorkspaceInfo(workspaces);

                                                            setUserDetails({
                                                                isUserLoggedIn: true,
                                                                userLoggedOut: false,
                                                                userName: info.name,
                                                                userEmail: info.email,
                                                                expiresIn: expiresIn,
                                                                boxId: boxId,
                                                                userRoles: userRoles,
                                                                accessToken: accessToken,
                                                                boxAccessToken: boxAccessToken,
                                                                caseAccessToken: data.access_token,
                                                                caseExpiresIn: data.expires_in,
                                                                caseRefreshToken: data.refresh_token,
                                                                caseUsername: data.username,
                                                                otherInfo: "...."
                                                            })

                                                        })
                                                        .catch(e => {
                                                            debug && console.log(e);
                                                            setErrorDetails("Case Auth Error" + e);
                                                        })
                                                } else {
                                                    // elastic token not required, set user details
                                                    window.localStorage.setItem('redirectUrlCode', null);
                                                    window.localStorage.setItem('state', null);
                                                    window.localStorage.setItem('nonce', null);
                                                    window.localStorage.setItem('windowUrl', null);

                                                    setWorkspaceInfo(workspaces);

                                                    setUserDetails({
                                                        isUserLoggedIn: true,
                                                        userLoggedOut: false,
                                                        userName: info.name,
                                                        userEmail: info.email,
                                                        expiresIn: expiresIn,
                                                        boxId: boxId,
                                                        userRoles: userRoles,
                                                        accessToken: accessToken,
                                                        boxAccessToken: boxAccessToken,
                                                        caseAccessToken: null,
                                                        caseExpiresIn: null,
                                                        caseRefreshToken: null,
                                                        caseUsername: null,
                                                        otherInfo: "...."
                                                    })
                                                }
                                            })
                                            .catch(e => {
                                                debug && console.log(e)
                                                setErrorDetails("Box Auth Error " + e);
                                            })
                                    }
                                })
                        }
                    });
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState, oktaAuth]); // runs if authState/oktaAuth change


    const login = async () => {
        console.log ('oktaAuth=', oktaAuth);

        //oktaAuth.signInWithRedirect('/');
        history.push('/login');
    };

    const logout = async () => {

        // let windowUrl = "/";
        // // only redirect for static url's.
        // if(window.localStorage.getItem('windowUrl') != null &&
        //     window.localStorage.getItem('windowUrl').includes('dashboard')){
        //         windowUrl = window.localStorage.getItem('windowUrl');
        // }

        window.localStorage.setItem('redirectUrlCode', null);
        window.localStorage.setItem('state', null);
        window.localStorage.setItem('nonce', null);
        window.localStorage.setItem('windowUrl', null);

        // if(!authState.isAuthenticated) {
        //     alert ('not authenticated')
        //     oktaAuth.updateAuthState().catch(e => {
        //         console.log('logout exception', e);
        //         alert ('signout')
        //         setErrorDetails("Update Auth State : " + e);
        //         setUserDetails(null);
        //     });
        // }

        await oktaAuth.signOut()

    }

    const refreshAuthToken = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug")

        // if(!authState.isAuthenticated) {
        //   oktaAuth.updateAuthState().catch(e => {
        //       debug && console.log(e);
        //       setErrorDetails("Update Auth State : " + e);
        //       setUserDetails(null);
        //   });
        // }

        const date = new Date();
        const currentTime = date.getTime();
        if(userDetails.expiresIn < currentTime) {
              debug && console.log('refreshAuthToken - userDetails.expiresIn < currentTime, refresh access token')
              const accessToken = authState.accessToken.accessToken;
              const date = new Date();
              const currentTime = date.getTime();
              const expiresIn = currentTime + (1000 * 60 * parseInt(window.REACT_APP_OKTA_REFRESH));

              if (!boxTokenRequired) {
                  // elastic auth only...

                  // check the refresh token to see if it's expired...
                  debug && console.log('refreshAuthToken (elastic auth only) currentTime : ' + currentTime, 'caseExpiresIn : ' + userDetails.caseExpiresIn)
                  debug && console.log('refreshAuthToken (elastic auth only) ExpiresIn - CurrentTime : ' + (parseInt(userDetails.expiresIn) - parseInt(currentTime)))

                  const body = {"refresh_token": userDetails.caseRefreshToken};
                  const url = window.window.REACT_APP_SECURITY_API_BASE_URL + "/api/security/v1/elastic/refresh"
                  const request = {
                      method: 'POST',
                      headers: {
                          "Content-Type": "application/json",
                          "Authorization": "Bearer " + authState.accessToken.accessToken
                      },
                      body: JSON.stringify(body)
                  };

                  debug && console.log('refresh elastic token url: ', url, 'request:', request);

                  await fetch(url, request)
                      .then(response => {
                          if (response.status === 401) {
                              setErrorDetails("Session expired. Please return to the login screen and log in again.");
                              setUserDetails(null);
                          } else {
                              return (response.json())
                          }
                      })
                      .then(data => {
                          debug && console.log('refresh elastic token, response.json: ', data);
                          setUserDetails({
                              accessToken: authState.accessToken.accessToken,
                              expiresIn: expiresIn,
                              boxAccessToken: userDetails.boxAccessToken,
                              caseAccessToken: data.access_token,
                              caseExpiresIn: data.expires_in,
                              caseRefreshToken: data.refresh_token,
                              caseUsername: data.username,
                              isUserLoggedIn: userDetails.isUserLoggedIn,
                              userLoggedOut: userDetails.userLoggedOut,
                              userName: userDetails.userName,
                              userEmail: userDetails.userEmail,
                              boxId: userDetails.boxId,
                              userRoles: userDetails.userRoles,
                              otherInfo: userDetails.otherInfo
                          })
                      })
                      .catch(e => {
                          debug && console.log(e)
                          setErrorDetails("Case Auth Error " + e);
                          setUserDetails(null);
                      })
              } else {
                  // box token required...
                  let boxAccessToken = null;
                  const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SECURITY_USER_TOKEN + "?userId=" + userDetails.boxId;
                  const request = {
                      headers: {"Authorization": "Bearer " + accessToken}
                  }
                  debug && console.log ('refreshAuthToken (box token required) get box access token, url= ', url, 'request=', request);

                  await fetch(url, request)
                      .then(response => response.json())
                      .then(resultA => {
                          debug && console.log('get box access token, response=', resultA)
                          boxAccessToken = resultA.access_token;
                      })
                      .catch(e => {
                          debug && console.log(e);
                          setErrorDetails("Box Auth Error " + e);
                          setUserDetails(null);
                      })

                  if (elasticTokenRequired) {
                      // check the refresh token to see if it's expired
                      debug && console.log('refreshAuthToken (elastic token required) currentTime : ' + currentTime, 'caseExpiresIn : ' + userDetails.caseExpiresIn)
                      debug && console.log('refreshAuthToken (elastic token required) ExpiresIn - CurrentTime : ' + (parseInt(userDetails.expiresIn) - parseInt(currentTime)))

                      const body = {"refresh_token": userDetails.caseRefreshToken};
                      const url = window.window.REACT_APP_SECURITY_API_BASE_URL + "/api/security/v1/elastic/refresh"
                      const request = {
                          method: 'POST',
                          headers: {
                              "Content-Type": "application/json",
                              "Authorization": "Bearer " + authState.accessToken.accessToken
                          },
                          body: JSON.stringify(body)
                      };

                      debug && console.log('refresh elastic token url: ', url, 'request:', request);

                      await fetch(url, request)
                          .then(response => {
                              debug && console.log('get elastic token, response: ', response)
                              if (response.status === 401) {
                                  setErrorDetails("Session expired. Please return to the login screen and log in again.");
                                  setUserDetails(null);
                              } else {
                                  return (response.json())
                              }
                          })
                          .then(data => {

                              debug && console.log('refresh elastic token, response.json', data);
                              setUserDetails({
                                  accessToken: authState.accessToken.accessToken,
                                  expiresIn: expiresIn,
                                  boxAccessToken: boxAccessToken,
                                  caseAccessToken: data.access_token,
                                  caseExpiresIn: data.expires_in,
                                  caseRefreshToken: data.refresh_token,
                                  caseUsername: data.username,
                                  isUserLoggedIn: userDetails.isUserLoggedIn,
                                  userLoggedOut: userDetails.userLoggedOut,
                                  userName: userDetails.userName,
                                  userEmail: userDetails.userEmail,
                                  boxId: userDetails.boxId,
                                  userRoles: userDetails.userRoles,
                                  otherInfo: userDetails.otherInfo
                              })
                          })
                          .catch(e => {
                              debug && console.log(e)
                              setErrorDetails("Case Auth Error " + e);
                              setUserDetails(null);
                          })
                  } else {
                      setUserDetails({
                          accessToken: authState.accessToken.accessToken,
                          expiresIn: expiresIn,
                          boxAccessToken: boxAccessToken,
                          isUserLoggedIn: userDetails.isUserLoggedIn,
                          userLoggedOut: userDetails.userLoggedOut,
                          userName: userDetails.userName,
                          userEmail: userDetails.userEmail,
                          boxId: userDetails.boxId,
                          userRoles: userDetails.userRoles,
                          caseAccessToken: userDetails.caseAccessToken,
                          caseExpiresIn: userDetails.caseExpiresIn,
                          caseRefreshToken: userDetails.caseRefreshToken,
                          caseUsername: userDetails.caseUsername,
                          otherInfo: userDetails.otherInfo
                      })
                  }
              }
            }
    };

    // if (authState.isPending) {
    //     debug && console.log ('authState.isPending');
    //     return (
    //         <React.Fragment>
    //             <LinearProgress
    //                 // style={{marginTop: -8, marginLeft: -8}}
    //                 color="primary"
    //             />
    //             <div style={{color: '#808080', fontSize: '0.75rem', fontWeight: '500', lineHeight: '24px', paddingLeft: '6px'}}>
    //                 Authenticating...
    //             </div>
    //         </React.Fragment>
    //     );
    // }

    //alert ('check authState')

    if( !authState ) {
        return (
            <React.Fragment>
                <LinearProgress style={{backgroundColor: '#808080'}}/>
                <div style={{color: '#808080', fontSize: '0.75rem', fontWeight: '500', lineHeight: '24px', paddingLeft: '6px'}}>
                    Loading...
                </div>
            </React.Fragment>
        );
    }

    if(!authState.isAuthenticated  ){
        console.log('not authenticated so login authState=', authState)
        // gets here after logout
        login();
    }


    return (
        <ThemeProvider theme={portalTheme}>
            <div>
                <SnackbarProvider maxSnack={1}>
                    {
                        authState.isAuthenticated && userDetails ?
                            <PortalApp
                                {...props}
                                userDetails={userDetails}
                                logout={logout}
                                triggerRefreshAuthToken = {refreshAuthToken}
                                configFiles={configFiles}
                                workspaceConfig={workspaceInfo}
                                launchDashboard={props.launchDashboard}
                                drawerWidth={drawerWidth}
                                // setLocale={setLocale}
                                // locale={locale}
                            /> :
                            //TODO give option to return to login screen here if things not right...sometimes hanging on this 'Loading user info screen' (when authenticated but userdetails blank).....maybe add timer to prevent it hanging???
                            errorDetails === null ?
                                //getting token
                                <React.Fragment>
                                    <LinearProgress color="secondary"/>
                                    <div style={{color: '#808080', fontSize: '0.75rem', fontWeight: '500', lineHeight: '24px', paddingLeft: '6px'}}>
                                        Loading user information...
                                    </div>
                                </React.Fragment> :
                                <div style={{textAlign: "center", borderColor: '#ddd #ddd #d8d8d8', boxShadow: 'box-shadow: 0 2px 0 hsla(0,0%,68.6%,.12', width: "50%", height: "50%", overflow: 'visible', margin: 'auto', position: 'absolute', top: '0', left: '0', bottom: '0', right: '0'}}>
                                    <img src={window.location.origin + '/images/authlogo.png'} alt="logo"
                                         style={{display: "block", margin: "0 auto", maxWidth: "200px", maxHeight: "40px"}}/><br/><br/>
                                    <Typography variant="h6">{window.REACT_APP_APP_TITLE}</Typography><br/><br/>
                                    <Typography variant="subtitle1">An error has occurred, please retry.</Typography><br/><br/>
                                    <Typography variant="subtitle2">{errorDetails}</Typography><br/><br/>
                                    <Button variant="contained" color="secondary" onClick={logout} style={{width: "200px", marginTop: "20px"}}>Return to login</Button>
                                </div>


                    }
                </SnackbarProvider>
            </div>
        </ThemeProvider>
    );
};
export default Home;