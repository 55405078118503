import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ActionButtonDocument from "./ActionButtonDocument";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class ActionButtonsDocument extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

    };

    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = () => {

        this.setState({
            showDialog: false
        });
    }


    render() {

        return (
            <React.Fragment>
                {
                    this.props.actions.map(action => {return(
                        <div style={{display: "inline"}}>
                            <ActionButtonDocument
                                documentDetails={this.props.documentDetails}
                                folderId={this.props.folderId}
                                userDetails={this.props.userDetails}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                actionConfig={action}
                                unmountSearchResults={this.props.unmountFolderDocumentsGet}
                                metadataConfig={this.props.metadataConfig}
                                updateFolderDetails={this.props.updateFolderDetails}
                                reloadWorkspace={this.props.reloadWorkspace}
                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                            />
                        </div>
                    )})
                }
            </React.Fragment>
        )
    }
}

ActionButtonsDocument.propTypes = {
    documentDetails: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    folderId: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    unmountSearchResults: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    unmountFolderDocumentsGet: PropTypes.func.isRequired,
    reloadWorkspace: PropTypes.func,
    selectedWorkspaceConfig: PropTypes.object

};

export default withStyles(styles)(ActionButtonsDocument);