import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ActionButton from "./ActionButton";
import {hasAccess} from "../../common/helper"

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class ActionButtons extends Component {

    constructor(props) {
        super(props);

        INITIAL_STATE.folderDetails = {...props.folderDetails}
        this.state = INITIAL_STATE;

       // window.location.pathname.toLowerCase().includes("debug") && console.log ('ActionButtons constructor props.folderDetails = ' , props.folderDetails);
    };

    componentDidUpdate(prevProps) {

        // window.location.pathname.toLowerCase().includes("debug") && console.log ('ActionButtons componentDidUpdate - check for changes to folderDetails this.props.folderDetails=',
        //     this.props.folderDetails, 'prevProps.folderDetails=', prevProps.folderDetails, 'state.folderDetails = ', this.state.folderDetails)

        //if (JSON.stringify(prevProps.folderDetails)!== JSON.stringify(this.props.folderDetails)){
        // if (JSON.stringify(this.state.folderDetails) !== JSON.stringify(this.props.folderDetails)){
        //     window.location.pathname.toLowerCase().includes("debug") && console.log ('ActionButtons - folder details changed so unmount component to ensure correct actions displayed')
        //     this.props.unmountComponent();
        // } else {
        //     window.location.pathname.toLowerCase().includes("debug") && console.log ('ActionButtons - no change to folder details')
        // }

    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    displayWhen = (action) => {

        let folderDetails = this.props.folderDetails;

        let folderMetadata = {};
        if (folderDetails.metadata && folderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
            folderMetadata = folderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]
        }

        //return true if no displayWhen criteria specified, or if displayWhen criteria is met
        let display = true;

        //get metadata value on folder
        if (action.displayWhen && Array.isArray(action.displayWhen) && action.displayWhen.length > 0) {
            action.displayWhen.every(d => {
                let proceed = true;
                if (d.templateKey && d.metadataKey) {
                    let metadataValue;
                    if (folderDetails[d.templateKey + "~" + d.metadataKey]) {
                        metadataValue = folderDetails[d.templateKey + "~" + d.metadataKey]
                    } else {
                        //for subfolders need to dig deeper to get metadata
                        if (folderMetadata && folderMetadata[d.templateKey] && folderMetadata[d.templateKey][d.metadataKey]) {
                            metadataValue = folderMetadata[d.templateKey][d.metadataKey]
                        }
                    }
                    if (!d.values.includes(metadataValue)) {
                        display = false
                        proceed = false; //all criteria must be met for display so return false from 'every' loop to exit loop if any of criteria not met
                    } else {
                        proceed = true //to continue looping through displayWhen array
                    }
                }
                return proceed;
            })
        }

        return display

    }

    render() {

        //For all actions in the config, display a button

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('ActionButtons render this.props.folderDetails = ' , this.props.folderDetails);

        return (
            <div style={{display: "inline"}}>
                {
                    this.props.workflowConfig.map((action, index) => {
                        //hasAccess(configFiles.actionsConfig.dashboardMain, this.props.userDetails.userRoles)
                        //window.location.pathname.toLowerCase().includes("debug") && console.log (window.location.pathname.toLowerCase().includes("debug") && 'ActionButtons render action=', action)
                        if (action.enabled && hasAccess(action,this.props.userDetails.userRoles ) && this.displayWhen(action)) {

                            return (
                                <ActionButton folderDetails={this.props.folderDetails}
                                              userDetails={this.props.userDetails}
                                              triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                              actionConfig={action}
                                              updateFolderDetailsFromWorkflowAction={this.props.updateFolderDetailsFromWorkflowAction}
                                              updateFolderDetails={this.props.updateFolderDetails}
                                />
                            )
                       } else {
                           return(<React.Fragment key={'frag_' + index}/>)
                       }

                    })
                }
            </div>
        );
    }
}

ActionButtons.propTypes = {
    folderDetails: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    updateFolderDetailsFromWorkflowAction: PropTypes.func.isRequired,
    updateFolderDetails: PropTypes.func,
    workflowConfig: PropTypes.array.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired
};

export default withStyles(styles)(ActionButtons);