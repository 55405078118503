import React from 'react';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {getDataForSearchResultsTableMetadataSearch} from "../../search/helper";
import {getErrorMessageFromResponse, getFieldConfig} from "../../common/helper";

const INITIAL_STATE = {};

class RelatedDocumentsGet extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('RelatedDocumentsGet props = ', props)

    }

    UNSAFE_componentWillMount() {
       this.searchRelatedDocs()
    }

    componentDidMount(){
        //window.location.pathname.toLowerCase().includes("debug") && console.log('boxFolderId:', this.props.boxFolderID);
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    searchRelatedDocs = async () => {

        try {

            let request = {};
            let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;
            let nextMarker = this.props.newSearch ? null : this.props.nextMarker;

            const searchQuery = this.props.searchConfig.box;

            let fields = [];
            //add standard fields
            fields.push("name", "created_by","created_at","modified_at","version","shared_link","sequence_id","size")
            //get metadata list to query from config
            if (searchQuery.metadataKeys) {
                let metadataKeys = searchQuery.metadataKeys;
                //if no metadata properties listed, get all metadata for the template specified
                if (metadataKeys.length === 0) {
                    //get all metadata for the template specified - added this to avoid having to list all metadata properties in each search config
                    let templateConfig = this.props.metadataConfig[searchQuery.templateKey];
                    if (templateConfig && templateConfig.metadata) {
                        metadataKeys = Object.keys(templateConfig.metadata)
                    }
                }
                for (let i = 0; i < metadataKeys.length; i++) {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey + "." + metadataKeys[i])
                }

            }


            let query = [];
            let query_params = {};
            let queryValid = true;
            //append additional query params from Related Documents action config [need this to ensure the search works for different workspace search configs]
            const queryParams = (this.props.actionConfig.additionalQueryParams ? searchQuery.queryParams.concat(this.props.actionConfig.additionalQueryParams) : searchQuery.queryParams);

            //const order_by = (this.props.actionConfig.additional_order_by ? searchQuery.order_by.concat(this.props.actionConfig.additional_order_by) : searchQuery.order_by);
            const order_by = this.props.actionConfig.order_by;

            for (let i = 0; i < queryParams.length; i++)  {
                let val = this.props.documentDetails[queryParams[i].templateKey + "~" + queryParams[i].metadataKey];
                if (!val) {
                    //if val not available on document, check folderDetails
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('val not available on doc for ' + queryParams[i].templateKey + "~" + queryParams[i].metadataKey + ', get from folder instead');
                    val = this.props.folderDetails[queryParams[i].templateKey + "~" + queryParams[i].metadataKey];
                    window.location.pathname.toLowerCase().includes("debug") && console.log ("val = ", val);
                }
                if (val) {
                    let fieldConfig = getFieldConfig(this.props.metadataConfig,queryParams[i].templateKey , queryParams[i].metadataKey  )
                    query.push(queryParams[i].paramName + " " + queryParams[i].operation + " :val" + i)
                    query_params["val" + i] = fieldConfig.type === "date" ? new Date(val) : val;
                }
            }

            let group_by = JSON.parse(JSON.stringify(this.props.actionConfig.group_by));  //deep clone
            let only_docs_val;
            if (group_by) {
                if (group_by.only_docs) {
                    if (group_by.only_docs[0].value) {
                        if (group_by.only_docs[0].value.templateKey) {
                            only_docs_val = this.props.documentDetails[group_by.only_docs[0].value.templateKey + "~" + group_by.only_docs[0].value.metadataKey];
                            if (!only_docs_val) {
                                only_docs_val = this.props.folderDetails[group_by.only_docs[0].value.templateKey + "~" + group_by.only_docs[0].value.metadataKey];
                                window.location.pathname.toLowerCase().includes("debug") && console.log ("only_docs_val = ", only_docs_val);
                            }
                        }
                        let fieldConfig = getFieldConfig(this.props.metadataConfig,group_by.only_docs[0].value.templateKey , group_by.only_docs[0].value.metadataKey  )
                        if (only_docs_val) {
                            group_by.only_docs[0].value = fieldConfig.type === "date" ? new Date(only_docs_val) : only_docs_val;
                        }
                    }
                }
            }

            let folderId = this.props.folderDetails.box_id ? this.props.folderDetails.box_id : this.props.folderDetails.id;

            //Proceed if all required values are available
            if (queryValid && folderId ) {

                let body = {
                    "ancestor_folder_id": folderId,
                    "fields": fields,
                    "limit": window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT,
                    "from": "enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey,
                    "query": query.join(" AND "),
                    "query_params": query_params,
                    "order_by": order_by,
                    "marker": nextMarker,
                }

                //exclude group by if only_docs value not available - i.e. hireStartDate for Internal DawsonGroup
                if (only_docs_val) {
                    body.group_by = group_by;
                }

                request = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };

                window.location.pathname.toLowerCase().includes("debug") && console.log('searchRelatedDocs REQUEST.  url:', url, 'body: ', body, 'request:', request);

                let rows = [];
                let totalCount = 0;
                //let nextMarker;
                this.setState({isFetching: true});

                await this.props.triggerRefreshAuthToken();
                fetch(url, request)
                    .then(response => {
                        if (response.ok) {
                            return (response.json())
                        } else {
                            window.location.pathname.toLowerCase().includes("debug") && console.log('response not ok - searchRelatedDocs RESPONSE: ', response);
                            Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                                .then(message => {
                                    this.props.enqueueSnackbar(message , {variant: 'error'});
                                })
                            window.location.pathname.toLowerCase().includes("debug") && console.log("searchRelatedDocs error. url:", url, "request: ", request, "response:", response);
                            this.setState({isFetching: false});
                            return null
                        }
                    })
                    .then(resultA => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('searchRelatedDocs response.json: ', resultA);
                        const resultsColumns = this.props.searchConfig.resultsColumns;

                        //TODO PAGING CHANGED WITH METADATASEARCH, update accordingly

                        if (resultA !== null) {
                            totalCount = resultA.total_count;
                            nextMarker = resultA.next_marker;
                            //window.location.pathname.toLowerCase().includes("debug") && console.log('totalCount = ', totalCount);
                            resultA.entries.forEach((item, i) => {
                                window.location.pathname.toLowerCase().includes("debug") && console.log(i, item);
                                rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                            })
                        }
                        return (rows)

                    })
                    .then(result => {
                        // window.location.pathname.toLowerCase().includes("debug") && console.log ('updating state with search results')
                        // this.setState({
                        //     searchResults: result,
                        //     nextMarker: nextMarker,
                        //     isFetching: false,
                        //     mountSearchResults: true
                        // })

                        //updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount) {
                        window.location.pathname.toLowerCase().includes("debug") && console.log ('rows = ', rows);
                        //searchResults, totalCount, newSearch, nextMarker)
                        this.props.updateSearchResults(rows, totalCount, this.props.newSearch, nextMarker );


                    })
                    .catch(e => {
                        this.setState({
                            searchResults: [],
                            isFetching: false,
                            mountSearchResults: true
                        });
                        window.location.pathname.toLowerCase().includes("debug") && console.log("searchRelatedDocs Exception:", e, "url:", url, "request: ", request);
                    })
            } else {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('query params incomplete')
                this.setState({
                    searchResults: [],
                    isFetching: false,
                    mountSearchResults: true
                });
            }
        } catch (e) {
            console.error(e);
            this.setState({
                searchResults: [],
                isFetching: false,
                mountSearchResults: true
            });
        }
    };


    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log('*** RelatedDocumentsGet props = ', this.props)

        return (

            <React.Fragment/>

        );
    }
}

RelatedDocumentsGet.propTypes = {
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchLimit: PropTypes.number.isRequired,
    updateSearchResults: PropTypes.func.isRequired,
    newSearch: PropTypes.bool.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    nextMarker: PropTypes.string,
    searchConfig: PropTypes.object.isRequired,
    documentDetails: PropTypes.object.isRequired
};

export default withSnackbar((RelatedDocumentsGet));