import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'

///////////////////////////////
// var xhttp = new XMLHttpRequest();
// var theme = {};
// xhttp.onreadystatechange = function() {
//     if (this.readyState == 4 && this.status == 200) {
//         // Typical action to be performed when the document is ready:
//         theme = xhttp.responseText;
//         console.log ('theme = ', theme);
//         ReactDOM.render(<App theme={theme}/>, document.getElementById('root'));
//     }
// };
//
// xhttp.open("GET", window.location.origin + '/theme/theme/data.js', true);
// xhttp.send();
////////////////////////////////
ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
