import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import {ContentExplorer} from "box-ui-elements";
import messages from "box-ui-elements/i18n/en-US";
import {addLocaleData} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import 'box-ui-elements/dist/explorer.css';

addLocaleData(enLocaleData);

//const LANGUAGE = 'en_UK';
const LANGUAGE = "en-US";

class MyDocumentsDialog extends React.Component {
    state = {
        open: false,
    };

    componentDidMount(){
        this.setState({ open: true })
    }


    handleCloseDialog = () => {
        this.setState({ open: false });
        this.props.handleMyDocumentsClose()
    };


    render() {
        return (

            <Dialog
                open={this.state.open}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
            >
                <DialogContent>

                    <div style={{ border: 'border:1px solid #eee', height:'600px', width:'100%'}}>

                        <ContentExplorer
                            token={this.props.userDetails.boxAccessToken}
                            language={LANGUAGE}
                            messages={messages}
                            logoUrl = {window.location.origin + '/images/authlogo.png'}
                            currentFolderId={this.props.myDocumentsFolder}
                        />

                    </div>

                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={this.handleCloseDialog}
                        variant="contained"
                        color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

MyDocumentsDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleMyDocumentsClose: PropTypes.func.isRequired,
    myDocumentsFolder: PropTypes.string.isRequired
};

export default MyDocumentsDialog;