import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {Grid} from "@material-ui/core";
import RenderMetadataField from "../../common/RenderMetadataField";
import FormControl from "@material-ui/core/FormControl";
import Typography from '@material-ui/core/Typography';
import Collapse from "@material-ui/core/Collapse";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Box from '@material-ui/core/Box';
import MetadataValueIcon from "../../common/MetadataValueIcon";

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperNoBorder: {
        boxShadow: 'none',
    },
    folderMetadata: {
        // paddingTop : theme.spacing(2),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

function RelatedCaseDetails (props) {

    const [open, setOpen] = useState(true);

    const handleExpandCollapse = () => {
        setOpen(!open);
    };

    const { classes } = props;
    const folderHeaderConfig = props.searchConfig.folderSearch.folderDetails.header
    const folderDetails = props.folderDetails;
    let headerIcons = [];
    let header = "";

    for (let i = 0; i < folderHeaderConfig.length; i++) {
        if (folderHeaderConfig[i].displayAsIcon) {
            let iconConfig = {};
            if (props.metadataConfig[folderHeaderConfig[i].templateKey]) {
                if (props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey]) {
                    if (props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey]) {
                        if (props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey].iconConfig) {
                            iconConfig = props.metadataConfig[folderHeaderConfig[i].templateKey].metadata[folderHeaderConfig[i].metadataKey].iconConfig;
                        }
                    }
                }
            }
            headerIcons.push(
                <MetadataValueIcon
                    iconConfig={iconConfig}
                    key={'icon~'+ folderHeaderConfig[i].templateKey + '~' + folderHeaderConfig[i].metadataKey}
                    val={folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey]}/>);
        } else {
            let separator = (header === "") ? "" : " - ";
            header =
                folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey] &&
                folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey] !== "" ?
                    header + separator + folderDetails[folderHeaderConfig[i].templateKey + "~" + folderHeaderConfig[i].metadataKey] :
                    header
        }
    }

    if (header === "") {
        header= folderDetails.name
    }


    return (

        <div style={{width: "100%"}}>

            <Box
                className={classes.paper}
                elevation={1}
                style={{textAlign: 'left'}}
                border={'1px solid'} borderColor={'#E0E0E0'} boxShadow={0} borderRadius={0}>
                <Typography variant={"h6"} color={"textPrimary"}>

                    {header} {headerIcons}

                    <Tooltip title={(open ? "Hide " : "Show ") + props.searchConfig.folderSearch.resultsTitleSingular + " details"}>
                        <IconButton
                            onClick={handleExpandCollapse}
                            aria-expanded={open}
                            aria-label="show more"
                            color="secondary"
                        >
                            {open ? <ExpandMore/> : <ChevronRight/>}
                        </IconButton>
                    </Tooltip>

                </Typography>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Grid container spacing={2} xs={12} className={classes.folderMetadata}>
                        {
                            // for each on the search results columns for folder, read the value from folderDetails and get the label from metadataConfig
                            props.searchConfig.folderSearch.folderDetails.fields.map(entry => {
                                if (!entry.hide) {
                                    let fieldValue =
                                        entry.templateKey === "n/a" ?
                                            props.folderDetails[entry.metadataKey] ? props.folderDetails[entry.metadataKey] : "" :
                                            props.folderDetails[entry.templateKey + "~" + entry.metadataKey] ?
                                                props.folderDetails[entry.templateKey + "~" + entry.metadataKey] : "";

                                    return (
                                        <Grid item
                                              xs={fieldValue.length > 100 || entry.markdown ? 6 : 3}
                                              style={{
                                                  height: "100%",
                                                  overflowY: "auto"
                                              }}
                                              key={"g" + entry.templateKey + "~" + entry.metadataKey}
                                        >

                                            <FormControl fullWidth style={{paddingBottom: '10px'}}
                                                         key={"fc" + entry.templateKey + "~" + entry.metadataKey}>

                                                <RenderMetadataField
                                                    parentClasses={classes}
                                                    fieldValue={fieldValue}
                                                    metadataConfig={props.metadataConfig}
                                                    optionsConfig={props.optionsConfig}
                                                    metadataKey={entry.metadataKey}
                                                    templateKey={entry.templateKey}
                                                    forceDisable={true}
                                                    usage={"edit"}
                                                    formValues={props.folderDetails}
                                                />

                                            </FormControl>

                                        </Grid>
                                    )
                                } else {
                                    return (<React.Fragment/>)
                                }
                            })
                        }
                    </Grid>

                </Collapse>

            </Box>

        </div>

    );
}

RelatedCaseDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    searchConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(RelatedCaseDetails));

