import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import {getOktaGroupMembers, hasAccess} from "../../../common/helper";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress"
import EditFolderUserDialog from "../../security/EditFolderUserDialog";
import RemoveSubfolderAccessDialog from "./RemoveSubfolderAccessDialog";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperNoBorder: {
        boxShadow: 'none',
    },
    folderMetadata: {
        // paddingTop : theme.spacing(2),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});


function SubfolderGroupMembersDisplay(props) {

    const actionConfig = props.actionsConfig.addSubfolderUser;
   // const [folderGroupName, setFolderGroupName]= useState("")
    const [folderGroupMembers, setFolderGroupMembers] = useState([]);
    const [fetching, setFetching] = useState(false)
    const [showEditUserDialog, setShowEditUserDialog] = useState(false);
    const [showRemoveUserAccessDialog, setShowRemoveUserAccessDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState({})

    function closeEditUserDialog() {
        setShowEditUserDialog(false)
        setSelectedUser({})
    }

    function closeRemoveUserAccessDialog(groupsUpdated) {
        setShowRemoveUserAccessDialog(false)
        setSelectedUser({})
        //refresh list of folder users
        if (groupsUpdated) {
            props.remountComponent()
        }
    }

    useEffect(() => {

        //useEffect should trigger if props.triggerRemount is incremented

        const debug = window.location.pathname.toLowerCase().includes("debug");

        // Get list of existing group members
        // declare the async data fetching function
        const fetchUsers = async () => {
            let currentUsers = [];
            let currentUserEmails = [];
            setFetching(true);
            currentUsers = await getOktaGroupMembers(props.groupName, props.userDetails, props.triggerRefreshAuthToken)
            debug && console.log ('currentUsers=', currentUsers)
            currentUsers && currentUsers.length > 0 &&
                currentUsers.forEach(user => {
                    user.user && user.user.login && currentUserEmails.push (user.user.login)
                })
            setFetching(false);
            setFolderGroupMembers(currentUserEmails)

        }
        // call the function
       fetchUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.triggerRemount])

    const editUser = (user) => {
        setSelectedUser(user)
        setShowEditUserDialog(true)
    };

    const removeUser = (user) => {
        setSelectedUser(user)
        setShowRemoveUserAccessDialog(true)
    };

    const {classes} = props;
    const editSubfolderUserEnabled = props.actionsConfig.editSubfolderUser && props.actionsConfig.editSubfolderUser.enabled && hasAccess(props.actionsConfig.editSubfolderUser,props.userDetails.userRoles)


    return (
        <React.Fragment>
            <DialogContentText>Group: {props.groupName}</DialogContentText>
            <FormControl fullWidth >
                {
                    fetching && folderGroupMembers.length === 0 ?
                        <LinearProgress variant={"indeterminate"} color={"secondary"}/>
                        :
                        <span id="folderGroupMembersDisp">
                            {
                                folderGroupMembers.map(member =>
                                    <Chip
                                        icon={<i className='material-icons'
                                                 style={{fontSize: '18px', marginLeft: '0px'}}>person</i>}
                                        label={member} size="small" style={{border: 'none'}} variant="outlined"
                                        clickable
                                        onClick={() => editUser(member)}
                                        onDelete={() => removeUser(member)}
                                        disabled = {!editSubfolderUserEnabled}
                                        deleteIcon={ editSubfolderUserEnabled ? <IconButton><CancelIcon/></IconButton> : <span/>}
                                    />)
                            }
                            {
                                fetching && <CircularProgress color="secondary" size={15}/> //updating
                            }

                        </span>
                }

                {
                    showEditUserDialog && selectedUser &&

                    <EditFolderUserDialog
                        actionConfig={props.actionsConfig.editSubfolderUser}
                        classes={props.classes}
                        handleCloseDialog={closeEditUserDialog}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        userDetails={props.userDetails}
                        user={selectedUser}
                    />
                }

                {
                    showRemoveUserAccessDialog && selectedUser &&

                    <RemoveSubfolderAccessDialog
                        classes={props.classes}
                        handleCloseDialog={closeRemoveUserAccessDialog}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        userDetails={props.userDetails}
                        user={selectedUser}
                        folderLabel={props.folderLabel}
                        folderGroupName={props.groupName}
                        remountComponent={props.remountComponent}
                        actionConfig={props.actionsConfig.editFolderUser}
                    />
                }

            </FormControl>
        </React.Fragment>
    );
}

SubfolderGroupMembersDisplay.propTypes = {
    actionsConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRemount: PropTypes.number.isRequired, //used to trigger useEffect
    folderLabel: PropTypes.string.isRequired,
    groupName: PropTypes.string.isRequired,
    folderDetails: PropTypes.object.isRequired
};


export default withSnackbar(withStyles(styles, { withTheme: true })(SubfolderGroupMembersDisplay));