import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import WorkspaceMenuList from "../menus/WorkspaceMenuList"
import Popover from "@material-ui/core/Popover/Popover";

const styles = (theme) => ({});

const INITIAL_STATE = {
    anchorEl: null,
};

class WorkspaceMenu extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleClickMenuItem = this.handleClickMenuItem.bind(this);
    }

    openMenu = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    closeMenu = () => {
        this.setState({anchorEl: null});
    };

    handleClickMenuItem = (w) => {
        this.closeMenu();
        if (w.id) {
            this.props.handleSelectWorkspace(w);
        }
    };

    render() {

        const {t} = this.props;
        const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val;

        const { classes, ...other } = this.props;
        const { anchorEl } = this.state;
        let appBarTitle = this.props.selectedWorkspaceConfig.title;
        if (this.props.selectedWorkspaceConfig.title.indexOf("\\") > -1) {
            appBarTitle = this.props.selectedWorkspaceConfig.title.replace("\\", " > ");
        }

        return (
            <React.Fragment>
                <Tooltip title={"Switch workspace"}>
                    {/*The href attribute is required for an anchor to be keyboard accessible.*/}
                    <span onClick={this.openMenu}>
                        {translate(appBarTitle)}
                        <IconButton
                            color={"inherit"}
                            className={classNames(classes.menuButton)}
                            aria-label="Menu">
                            <i className='material-icons'>keyboard_arrow_down</i>
                        </IconButton>
                    </span>
                </Tooltip>

                <Popover
                    id="simple-menu"
                    // anchorOrigin={{
                    //     vertical: 'bottom',
                    //     horizontal: 'right',
                    // }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={this.closeMenu}
                >
                    <WorkspaceMenuList
                        {...other}
                        workspaceConfig={this.props.workspaceConfig}
                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                        handleClickMenuItem={this.handleClickMenuItem}
                        listTitle={this.props.listTitle}
                        mode={"search"}
                        enableAutofocus={true}
                        workspaceFilter={this.props.workspaceFilter}
                        updateWorkspaceFilter={this.props.updateWorkspaceFilter}
                        actionsConfig={this.props.actionsConfig}
                    />
                </Popover>
            </React.Fragment>

        );
    }
}

WorkspaceMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    handleSelectWorkspace: PropTypes.func.isRequired,
    listTitle: PropTypes.string.isRequired,
    workspaceFilter: PropTypes.string.isRequired,
    updateWorkspaceFilter: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default (withStyles(styles, { withTheme: true })(WorkspaceMenu));