import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import Preview from "../preview/Preview";
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {Grid} from "@material-ui/core";
import MLResults from "./results/MLResults";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getErrorMessageFromResponse, hasRole} from "../../common/helper";
import AuditButton from "../../audit/AuditButton";

//const resultsStub =  require('../../../resources/stubs/dxc_model_categories_new.json');

const styles = theme => ({
    root: {
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },

    left: {
        float: 'left',
        width: '50%'
    },

    right: {
        float: 'right',
        width: '50%'
    },

    smallAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }

});


const INITIAL_STATE = {
    open: false,
    mountPreview: true,
    showPreview: true,
    pageNumber: 1,
    result: {},
    allSnippetsActioned: false,
    isFetching: false,
    isCompleting: false
};

class MLDialog extends React.Component {


    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        this.changePageNumber = this.changePageNumber.bind(this);
        this.updateAllSnippetsActioned = this.updateAllSnippetsActioned.bind(this);

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log ('MLDialog props = ',props)

    }

    UNSAFE_componentWillMount() {
        //window.location.pathname.toLowerCase().includes("debug") && console.log('UNSAFE_componentWillMount');

        this.getMLResults()

    }

    componentDidMount() {

        window.location.pathname.toLowerCase().includes("debug") && console.log('componentDidMount boxDocId:', this.props.boxDocID);

        this.setState({open: true});

    }

    getMLResults = async () => {

        this.setState({isFetching: true});

        window.location.pathname.toLowerCase().includes("debug") && console.log("this.props.disableApproveRejectSnippet", this.props.disableApproveRejectSnippet);

        const docId = this.props.boxDocID;

        const url = window.REACT_APP_BASE_URL_SCREENING + "/document/" + docId + "/screeningresults"

        await this.props.triggerRefreshAuthToken();

        let request = {
            headers: {
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            }
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log("getMLResults url :", url, "request: ", request);

        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    return (response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving results'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getMLResults error.  url:", url, "request: ", request, "response.text:", response);
                    return null

                }
            })
            .then(result => {

                window.location.pathname.toLowerCase().includes("debug") && console.log('getMLResults, result=', result);

                if (result) {
                    const allSnippetsActioned = this.checkAllSnippetsActioned(result.categories)
                    window.location.pathname.toLowerCase().includes("debug") && console.log('getMLResults allSnippetsActioned = ', allSnippetsActioned);

                    let sortedResults = {
                        "id": result.id,
                        categories:[]
                    };

                    // ToDo AG move to config and clean up sort...
                    let customSort = "CSC,TSC,Non-location,Possibly,Worldwide";
                    var customSort_array = customSort.split(',');
                    // loop through the custom sort categories above and rebuild json object response.
                    for(let i = 0; i < customSort_array.length; i++) {
                        for (const property in result.categories) {
                            window.location.pathname.toLowerCase().includes("debug") && console.log(`${property}: ${result.categories[property]}`);
                            window.location.pathname.toLowerCase().includes("debug") && console.log(result.categories[property].category);
                            if(result.categories[property].category.startsWith(customSort_array[i])){
                                sortedResults.categories.push(result.categories[property]);
                            }
                        }
                    }
                    // loop through the json response and catch any items not added previously.
                    for (const property in result.categories) {
                        window.location.pathname.toLowerCase().includes("debug") && console.log(`${property}: ${result.categories[property]}`);
                        window.location.pathname.toLowerCase().includes("debug") && console.log(result.categories[property].category);
                        let missing = true;
                        for(let i = 0; i < customSort_array.length; i++) {
                            if(result.categories[property].category.startsWith(customSort_array[i])){
                                missing = false;
                            }
                        }
                        if(missing === true){
                            sortedResults.categories.push(result.categories[property]);
                        }
                    }


                    this.setState({
                        result: sortedResults,
                        //result: resultsStub,
                        isFetching: false,
                        allSnippetsActioned: allSnippetsActioned
                    });
                } else {
                    this.setState({isFetching: false})
                    this.props.enqueueSnackbar("Error retrieving results", {variant: 'error'});
                }


            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("getMLResults exception:", e, " url:", url, "request: ", request);

                this.setState({isFetching: false})

            })
    };

    completeReview = async() => {

        await this.props.triggerRefreshAuthToken();

        const url = window.REACT_APP_BASE_URL_SCREENING + "/result/completereview";
        const body = {
            result_id: this.state.result.id
        }
        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
            body: JSON.stringify(body)
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log('completeReview URL=', url, 'BODY=', body, 'request=', request);

        fetch(url, request)
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('submitSnippet response from service =', response);
                this.setState({isCompleting: false})

                if (response.ok) {
                    this.props.enqueueSnackbar("Completion successful", {variant: 'success'});
                    this.closeDialog(true)
                    //return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'completing'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })

                    window.location.pathname.toLowerCase().includes("debug") && console.log("submit error.  url:", url, "request: ", request, "response.text:", response);
                    //return null
                }
            })
            // .then(data => {
            //
            //     window.location.pathname.toLowerCase().includes("debug") && console.log('submitSnippet response.json = ', data)
            //     if (data) {
            //         //TODO check response
            //     } else {
            //         this.props.enqueueSnackbar("Error, no response json", {variant: 'error'});
            //     }
            //
            //     this.setState({isCompleting: false})

            // })
            .catch(e => {
                this.setState({isCompleting: false})
                window.location.pathname.toLowerCase().includes("debug") && console.log ('reviewComplete exception ', e);
            })

        this.closeDialog()
    }

    closeDialog = (reloadDocuments) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log('MLDialog closeDialog reloadDocuments = ', reloadDocuments)

        //clear state
        const END_STATE = {
            open: false,
        };

        this.setState(END_STATE);

        // if (reloadDocuments) {
        //     if (this.props.unmountFolderDocumentsGet) {
        //         window.location.pathname.toLowerCase().includes("debug") && console.log('MLDialog closeDialog call this.props.unmountFolderDocumentsGet')
        //         this.props.unmountFolderDocumentsGet()
        //     }
        // }


        this.props.handleCloseMLDialog(reloadDocuments)


    };

    submit = (updates) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log('updates = ', updates)

    }

    changePageNumber = (pageNumber, snippet) => {
        snippet ?
            this.setState({snippet: snippet, pageNumber: pageNumber}) :
            this.setState({snippet: null, pageNumber: pageNumber});
        // this.setState({pageNumber: pageNumber});
    };

    updateAllSnippetsActioned = (results) => {

        const allSnippetsActioned = this.checkAllSnippetsActioned(results);

        this.setState({allSnippetsActioned: allSnippetsActioned })
    }

    checkAllSnippetsActioned = (results) => {

        let allSnippetsActioned = true;

        //loop through all snippets, if all complete then return true
        window.location.pathname.toLowerCase().includes("debug") && console.log ('checkAllSnippetsActioned results=', results);

        for (const result of results) {
            for (const entity of result.results) {
                for (const page of entity.pages) {
                    for (const snippet of page.snippets) {

                        const approved = snippet.review_status === "VALID MATCH"
                        const rejected = snippet.review_status === "FALSE POSITIVE"
                        const actioned = approved || rejected

                        if (!actioned) {
                            allSnippetsActioned = false;
                            break;
                        }
                    }
                    if (!allSnippetsActioned) {
                        break
                    }
                }
                if (!allSnippetsActioned) {
                    break
                }
            }
        }

        return allSnippetsActioned

    }

    checkEnableCompleteReviewButton = () => {

        /* This button to be available when:

            All snippets have been approved/rejected => OK
            Document Status = 'OUTSTANDING'

            and
                Case Status = "IN PROGRESS OPS"
                User has the one of the following roles: [ "dxc.operations.team.member","dxc.operations.team.leader"]
            or
                Case Status = "IN PROGRESS SCREENING" : "SANCTIONS REVIEW"
                User has the one of the following roles: [ "dxc.screening.team.member","dxc.screening.team.leader"]
        */

        let enable = false;
        const debug = window.location.pathname.toLowerCase().includes("debug");
        const actionConfig = this.props.actionsConfig.watson;
        const folderDetails = this.props.folderDetails;
        const documentDetails = this.props.documentDetails;
        const userRoles = this.props.userDetails.userRoles

        debug && console.log('MLDialog enableCompleteReviewButton actionConfig=', actionConfig,
            'folderDetails=', folderDetails, 'documentDetails=', documentDetails)

        const config = actionConfig && actionConfig.enableCompleteReview;
        if (config && config.document && config.case) {
            if (this.state.allSnippetsActioned && documentDetails && folderDetails ) {
                if (documentDetails[config.document.templateKey + "~"+ config.document.metadataKey] === config.document.value) {
                    //now check for each of the case statuses
                    for (let i = 0; i < config.case.enableWhen.length; i++) {
                        if (folderDetails[config.case.templateKey + "~"+ config.case.metadataKey] === config.case.enableWhen[i].value) {
                            if (hasRole(config.case.enableWhen[i].roles, userRoles)) {
                                enable = true
                                break;
                            }
                        }
                    }
                }
            }
        }

        // if (this.state.allSnippetsActioned ) {
        //     if (documentDetails && folderDetails) {
        //         if (documentDetails["sanctiondocument~review_status"] === "OUTSTANDING") {
        //
        //             if (folderDetails["sanctioncase~status"] === "IN PROGRESS OPS") {
        //                 const roles = ["dxc.operations.team.member", "dxc.operations.team.leader","dxc.operations.global.team.member","dxc.operations.global.team.leader"]
        //                 if (hasRole(roles, this.props.userDetails.userRoles)) {
        //                     enable = true
        //                 }
        //             } else if (folderDetails["sanctioncase~status"] === "IN PROGRESS SCREENING" ){
        //                 const roles = ["dxc.screening.team.member", "dxc.screening.team.leader"]
        //                 if (hasRole(roles, this.props.userDetails.userRoles)) {
        //                     enable = true
        //                 }
        //             } else if (folderDetails["sanctioncase~status"] === "GR CREATED") {
        //                 const roles = ["dxc.screening.team.member","dxc.screening.team.leader","dxc.clearing.team.member","dxc.sanctions.team.member","dxc.compliance.team.member"]
        //                 if (hasRole(roles, this.props.userDetails.userRoles)) {
        //                     enable = true
        //                 }
        //             } else if (folderDetails["sanctioncase~status"] === "REJECTED") {
        //                 const roles = ["dxc.operations.team.leader","dxc.screening.team.leader","dxc.clearing.team.member","dxc.sanctions.team.member","dxc.compliance.team.member"]
        //                 if (hasRole(roles, this.props.userDetails.userRoles)) {
        //                     enable = true
        //                 }
        //             } else if (folderDetails["sanctioncase~status"] === "APPROVED") {
        //                 const roles = ["dxc.operations.team.leader","dxc.screening.team.leader","dxc.clearing.team.member","dxc.sanctions.team.member","dxc.compliance.team.member"]
        //                 if (hasRole(roles, this.props.userDetails.userRoles)) {
        //                     enable = true
        //                 }
        //             }
        //         }
        //     }
        // }
        return enable
    }

    actionButtonEnabled = () => {

        const action = this.props.actionsConfig.watson;
        let documentDetails = this.props.documentDetails;

        let enable = false;

        //Enabled if all criteria in config is met
        let enableApproveRejectSnippet = action.enableApproveRejectSnippet;
        if (enableApproveRejectSnippet) {
            for (let i = 0; i < enableApproveRejectSnippet.length; i++) {
                let metadataValue;
                if (documentDetails[enableApproveRejectSnippet[i].templateKey + "~" + action.enableApproveRejectSnippet[i].metadataKey] || documentDetails[enableApproveRejectSnippet[i].templateKey + "~" + action.enableApproveRejectSnippet[i].metadataKey] === false) {
                    metadataValue = documentDetails[action.enableApproveRejectSnippet[i].templateKey + "~" + action.enableApproveRejectSnippet[i].metadataKey].toString();
                }
                if (metadataValue && enableApproveRejectSnippet[i].values.includes(metadataValue)) {
                    enable = true;
                } else {
                    enable = false;
                    break;
                }
            }
        }

        return enable;
    }

    actionButtonDisabled = () => {

        const action = this.props.actionsConfig.watson;
        let documentDetails = this.props.documentDetails;

        let disabled = false;

        if(action.disableApproveRejectSnippet) {
            //disabled if all criteria in config is met
            let disableApproveRejectSnippet = action.disableApproveRejectSnippet;
            if (disableApproveRejectSnippet) {
                for (let i = 0; i < disableApproveRejectSnippet.length; i++) {
                    let metadataValue;
                    if (documentDetails[disableApproveRejectSnippet[i].templateKey + "~" + action.disableApproveRejectSnippet[i].metadataKey] || documentDetails[disableApproveRejectSnippet[i].templateKey + "~" + action.disableApproveRejectSnippet[i].metadataKey] === false) {
                        metadataValue = documentDetails[action.disableApproveRejectSnippet[i].templateKey + "~" + action.disableApproveRejectSnippet[i].metadataKey].toString();
                    }
                    if (metadataValue && disableApproveRejectSnippet[i].values.includes(metadataValue)) {
                        disabled = true;
                        break;
                    } else {
                        disabled = false;
                    }
                }
            }
        }

        return disabled;
    }

    render() {

        const result = this.state.result;
        //const result = resultsStub;

        // let enableApproveRejectSnippet = false;
        // //TODO get enableApproveRejectSnippet criteria from watson action config, hard coded for now
        // if (this.props.documentDetails && this.props.documentDetails["sanctiondocument~review_status"] === "OUTSTANDING") {
        //     enableApproveRejectSnippet = true
        // }

        const enableCompleteReviewButton = this.checkEnableCompleteReviewButton();

        let enableApproveRejectSnippet = this.actionButtonEnabled();
        let disableApproveRejectSnippet = this.actionButtonDisabled();

        const actionConfig = this.props.actionsConfig.comments;
        const documentDetails = this.props.documentDetails;
        let enableAddComment = true;
        const disableWhen = actionConfig.disableAddCommentWhen;
        if (disableWhen) {
            let key = disableWhen.templateKey + "~" + disableWhen.metadataKey;
            if (disableWhen.templateKey === "n/a") {
                key = disableWhen.metadataKey
            }
            if (disableWhen && Object.entries(disableWhen).length > 0 && documentDetails) {
                if (documentDetails[key] && (disableWhen.values.indexOf(documentDetails[key]) >= 0)) {
                    enableAddComment = false;
                }
            }
    }

        window.location.pathname.toLowerCase().includes("debug") && console.log('enableApproveRejectSnippet = ', enableApproveRejectSnippet, 'documentDetails=', this.props.documentDetails, 'actionConfig=', this.props.actionsConfig.watson);

        return (

            <Dialog
                open={this.state.open}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
            >
                <DialogContent>

                    <Grid container
                          spacing={1}
                          style={ {maxHeight: "100%",  height: "90vh", overflowY: "auto"}}
                    >
                        <Grid item
                              xs={6}
                              style={{height: "100%", overflowY: "auto"}}>
                                {
                                    (this.state.mountPreview) &&

                                        <Preview
                                            boxDocID={this.props.boxDocID}
                                            fileUrl={this.props.fileUrl}
                                            userDetails={this.props.userDetails}
                                            handleCloseMenu={this.props.handleCloseMenu}
                                            pageNumber={this.state.pageNumber}
                                            snippet={this.state.snippet}
                                            showHeader={true}
                                            actionsConfig={this.props.actionsConfig}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            unmountComponent={this.unmountPreview}
                                            remountComponent={this.remountPreview}
                                            viewer={"PDFViewer"}
                                        />
                                }
                        </Grid>
                        <Grid item
                              xs={6}
                              style={{height: "100%", overflowY: "auto"}}>

                            {this.state.isFetching ?

                                <React.Fragment>
                                    <LinearProgress variant={"indeterminate"}color={"primary"}/>
                                    <ListSubheader disableSticky>Retrieving AI results...</ListSubheader>
                                </React.Fragment> :

                                result && result.categories && result.categories.length > 0?
                                    <MLResults
                                        boxDocID={this.props.boxDocID}
                                        userDetails={this.props.userDetails}
                                        result={result}
                                        changePageNumber={this.changePageNumber}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        updateAllSnippetsActioned={this.updateAllSnippetsActioned}
                                        enableApproveRejectSnippet={enableApproveRejectSnippet}
                                        disableApproveRejectSnippet={disableApproveRejectSnippet}
                                        enableAddComment={enableAddComment}
                                    /> :
                                    <ListSubheader disableSticky>No AI results found</ListSubheader>
                            }

                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={this.closeDialog} variant="contained" color="secondary">
                        Close
                    </Button>
                    {
                        result && result.categories && result.categories.length > 0 &&
                            <Button onClick={this.completeReview}
                                    variant="contained" color="secondary"
                                    disabled = {!enableCompleteReviewButton || this.state.isCompleting || this.props.disableApproveRejectSnippet}>
                                {this.state.isCompleting ? "Completing..." : "Complete Review" }
                            </Button>
                    }

                    { this.state.allSnippetsActioned && this.props.documentDetails["sanctiondocument~review_status"] && this.props.documentDetails["sanctiondocument~review_status"] !== "OUTSTANDING"  &&
                        <AuditButton
                            parentClasses={this.props.classes}
                            userDetails={this.props.userDetails}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            resultId={result.id}
                            type={"result"}
                        />
                    }


                </DialogActions>
            </Dialog>
        );
    }
}

MLDialog.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    fileUrl: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    calledFromDocumentMenu: PropTypes.bool.isRequired,
    handleCloseMenu: PropTypes.func,
    handleCloseMLDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object,
    documentDetails: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func //only if called from folder documents table
};

export default withSnackbar(withStyles(styles, { withTheme: true })(MLDialog));