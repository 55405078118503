import React from 'react';
import PropTypes from 'prop-types';

export default class Dashboard extends React.Component{

    componentWillUnmount() {
        this.props.remountComponent();
    }

    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('Dashboard.js render Dashboard, props=', this.props);
        return(
            <div align="center" style={{marginTop: "-10px", height: '100%'}}>
                <iframe
                    title={"Dashboard"}
                    src={this.props.dashboardUrl}
                    frameBorder={0}
                    scrolling={'auto'}
                    style={{ display:'block', width:'100%', height: '90vh'}}
                />
            </div>
        )
    }
};

Dashboard.propTypes = {
    dashboardUrl: PropTypes.string.isRequired,
    remountComponent: PropTypes.func.isRequired
};