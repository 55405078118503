import Input from "@material-ui/core/Input";
import React, {useEffect, useReducer, useState} from "react";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import {withSnackbar} from "notistack";
import {getFieldConfig, getOptions, getOptionsExternal, getYearOptions} from './helper'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import DayPicker, {DateUtils} from 'react-day-picker';
import "react-day-picker/lib/style.css";
import Helmet from 'react-helmet';
import Popover from "@material-ui/core/Popover";
import {bindPopover, bindTrigger, usePopupState,} from 'material-ui-popup-state/hooks'
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton"
import Button from '@material-ui/core/Button'
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar'
import CardMedia from "@material-ui/core/CardMedia";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Typography} from "@material-ui/core";
import { useTranslation } from 'react-i18next'
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = (theme) => ({
    //https://material-ui.com/customization/components/
    disabled: {}, //required for the rule below to work
    root: {
        '&$disabled': {
            color: theme.disabledField.colour
        }
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    }
});


const fieldTheme = theme => createTheme({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides overrides the overrides in the parent theme in app.css

    //to fix left padding on date picker toolbar
    overrides: {
        MuiToolbar: {
            regular:{
                 paddingLeft: theme.spacing(3)
            },
            gutters: {
                paddingLeft: theme.spacing(2)
            },
            root: {
                paddingLeft: "0px"
            }
        },
    }
});


function RenderMetadataField(props){

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log('RenderMetadataField ', props.metadataKey , ' props = ', props);

    const { t } = useTranslation();
    const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

    let fieldConfig = getFieldConfig(props.metadataConfig, props.templateKey, props.metadataKey);

    const useExternalOptions = Object.entries(fieldConfig).length > 0 && fieldConfig.optionsExternal && Object.entries(fieldConfig.optionsExternal).length > 0

    let initialState =  {
        externalOptions: [],
        initExternalOptionsDone: false
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const [fieldValueDisp, setFieldValueDisp] = useState(null);

    useEffect(() => {

        //debug && console.log ('RenderMetadataField props = ', props);

        async function initExternalOptions () {

            //debug && console.log('RenderMetadataField initExternalOptions');

            let options = [];

            options = await getOptionsExternal(fieldConfig.optionsExternal, props.userDetails, props.triggerRefreshAuthToken, props.formValues )

            if (options.length === 0) {
                props.enqueueSnackbar("No options found for " + fieldConfig.label , {variant: 'error'});
                debug && console.log ('No options found for fieldConfig', fieldConfig);
            }

            return (
                dispatch({type: "EXTERNAL OPTIONS", value: options})
            )

        }

        let disabled = props.forceDisable ? true : fieldConfig.disabled;
        disabled = props.forceEnable ? false : disabled;

        if (!disabled && Object.entries(fieldConfig).length > 0 && fieldConfig.optionsExternal && Object.entries(fieldConfig.optionsExternal).length > 0) {
            debug && console.log ('useEffect, fieldConfig optionExternal config length > 0');
            initExternalOptions()
        } else {
            dispatch({type: "EXTERNAL OPTIONS", value: []})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
        //[ debug, fieldConfig, props])

    function reducer(state, action) {

        switch (action.type) {
            case "EXTERNAL OPTIONS": {
                return {
                    ...state,
                    externalOptions: action.value,
                    initExternalOptionsDone: true
                }
            }

            default:
                return state
        }
    }

    const { classes} = props;

    const handleChangeDate = (id) => (date) => {
        //need separate function to handle dates as the onChange event of the DatePicker doesn't pass the event
        props.handleOnChange( id, date);
    }

    const handleChange = (event) => {

        const val =
            event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) :
                event.target.type ==='number' ? parseFloat(event.target.value) :
                    event.target.value;

        props.handleOnChange( event.target.name, val );
    }

    const handleChangeAutocomplete = (newValue) => {
        let val = null;
        if (newValue) {
            val = isNaN(newValue.value) ? newValue.value : parseFloat(newValue.value)
        }
        setFieldValueDisp(newValue);
        props.handleOnChange( fieldId, val );
    }

    const handleKeypress = (event) => {
        event.preventDefault();
        return false
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(props.handleSearchClick != null) {
                props.handleSearchClick();
            }
        }
    }

    //=========================================DATE RANGE PICKER===================================

    const initialRange = {
        from: null,
        to: null,
        enteredTo: null
    }

    const [range, setRange] = useState(initialRange);
    const from = range.from;
    const to = range.to;
    const enteredTo = range.enteredTo;
    const disabledDays = !to ? { before: from } : {};
    const selectedDays = [from, { from, to: enteredTo }];
    const fromStr = from ? from.toLocaleDateString() : "";
    const toStr = to ? to.toLocaleDateString() : "";

    const selectedText =
        !to && !from ? "" :
            fromStr + " to " + toStr;

    const helperText = !from && !to ? '' :
        (to && !from) ? 'Please select the start date.':
            (from && !to) ? 'Please select the end date.': '';

    function isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    function handleDayClick(day) {

        debug && console.log ('handleDayClick day=', day, 'range=', range);

        if (from && to && day >= from && day <= to) {
            handleResetClick();
            return;
        }
        if (isSelectingFirstDay(from, to, day)) {
            updateRange({from: day, to: null, enteredTo: null,});
        } else {
            updateRange({from: from, to: day, enteredTo: day,});
            popupState.close()
        }
    }

    function handleDayMouseEnter(day) {
        if (!isSelectingFirstDay(from, to, day)) {
            updateRange({from: from, to: to, enteredTo: day});
        }
    }

    function handleResetClick() {
        setRange(initialRange);
        props.handleOnChange( fieldId, "");
    }

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'datePopover',
    })

    function updateRange(newRange) {
        setRange(newRange);
        props.handleOnChange(fieldId, newRange)
    }

    //==================================================END DATE RANGE PICKER==================================

    let fieldId = props.templateKey + "~" + props.metadataKey;

    //append with row id if field being rendered in a table
    if (props.rowId) {
        fieldId = fieldId + "_" + props.rowId
    }

    if (Object.entries(fieldConfig).length > 0) {

        let disabled = props.forceDisable ? true : fieldConfig.disabled;
        disabled = props.forceEnable ? false : disabled;

        let fieldType = fieldConfig.fieldType;

        if (fieldType === "Input" && (fieldConfig.optionsDependencies || fieldConfig.useYearOptions || props.customOptions) ){
            //Note, need fieldType in metadata config to be Input for the optionsDependencies to show a value in read mode on the Material-Table component in search results
            fieldType = "Select"
        }

        if (disabled && fieldType === "Select") {
            fieldType = "Input"
        }
        const fieldLabel = translate(fieldConfig.label);

        switch (fieldType) {
            case "Date":
                if (props.usage === "search" && fieldConfig.searchRange) {
                    return (
                        <React.Fragment>

                            <InputLabel shrink htmlFor={fieldId} className={classes.customLabel}
                                        required={props.required}>{fieldLabel} Range</InputLabel>
                            <React.Fragment>
                                    <Input
                                        classes={{root: classes.root, disabled: classes.disabled}}
                                        id={fieldId}
                                        name={fieldId}
                                        value={selectedText}
                                        disabled={false}
                                        readOnly={true}
                                        type="Input"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        // onChange={handleChangeDateRange}   - doesn't trigger as readOnly=true
                                        error={!helperText === ""}
                                        InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console

                                        endAdornment={
                                            <React.Fragment>
                                                {
                                                    (to || from) &&
                                                    <InputAdornment position={'start'}>
                                                        <Tooltip title={'Clear selection'}>
                                                            <IconButton aria-label={'clear date selection'}
                                                                        onClick={handleResetClick} size={"small"}>
                                                                <i className="material-icons">clear</i>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                }
                                                <InputAdornment position="end">
                                                    <Tooltip title={'Select date range'}>
                                                        <IconButton aria-label="date range picker"
                                                                    size={"small"}{...bindTrigger(popupState)}>
                                                            <i className="material-icons">date_range</i>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                                        transformOrigin={{vertical: 'top', horizontal: 'center'}}
                                                        style={{"width": "312px"}}
                                                    >
                                                        <React.Fragment>
                                                            <DayPicker
                                                                modifiers={range}
                                                                onDayClick={handleDayClick}
                                                                className="Range"
                                                                numberOfMonths={1}
                                                                fromMonth={from}
                                                                selectedDays={selectedDays}
                                                                disabledDays={disabledDays}
                                                                onDayMouseEnter={handleDayMouseEnter}
                                                            />

                                                            <Button contained className="link"
                                                                    disabled={!from && !to}
                                                                    onClick={handleResetClick}
                                                                    style={{"width": "100%"}}>
                                                                Clear selection
                                                            </Button>

                                                        </React.Fragment>
                                                    </Popover>
                                                </InputAdornment>
                                            </React.Fragment>
                                        }
                                    />
                                    <FormHelperText id="component-helper-text"
                                                    error={helperText !== ""}>{helperText}</FormHelperText>
                            </React.Fragment>

                            <Helmet>
                                <style>{`
                                      .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                        background-color: #f0f8ff !important;
                                        color: #4a90e2;
                                      }
                                      .Range .DayPicker-Day {
                                        border-radius: 0 !important;
                                      }
                                    `}</style>
                            </Helmet>

                        </React.Fragment>
                    )
                } else {
                    return(
                        <React.Fragment>
                            <InputLabel shrink htmlFor={fieldId} className={classes.customLabel} required={props.required}>{fieldLabel}</InputLabel>
                            <ThemeProvider theme={fieldTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} classes={{root: classes.root, disabled: classes.disabled}}>
                                    <KeyboardDatePicker
                                        InputProps={{ classes: {root: classes.root, disabled: classes.disabled}}}
                                        onChangeRaw={handleKeypress} //required to prevent typing in field
                                        onKeyPress={handleKeypress}
                                        margin="none"
                                        id={fieldId}
                                        format="dd/MM/yyyy"
                                        value = {props.fieldValue === "" ? null : (props.fieldValue)}
                                        onChange = {!disabled ? handleChangeDate(fieldId) : null}
                                        onKeyDown={handleKeyDown}
                                        KeyboardButtonProps={{'aria-label': 'change date',}}
                                        disabled = {disabled }
                                        autoFocus={props.autoFocus}
                                        helperText={props.helperText ? props.helperText : ""}
                                    />
                                </MuiPickersUtilsProvider>
                            </ThemeProvider>
                        </React.Fragment>
                    )
                }
            case "Input":
                return (
                    <React.Fragment>
                        <InputLabel shrink htmlFor={fieldId} className={classes.customLabel}  required={props.required}>{fieldLabel}</InputLabel>
                        <Input
                            classes={{root: classes.root, disabled: classes.disabled}}
                            id={fieldId}
                            name={fieldId}
                            value={props.fieldValue ? props.fieldValue : ""}
                            disabled={disabled}
                            type={fieldConfig.type}
                            margin="none"
                            autoComplete=""
                            onChange={!disabled ? handleChange : null}
                            onKeyDown={handleKeyDown}
                            multiline={fieldConfig.multiline}
                            autoFocus={props.autoFocus}
                            helperText={props.helperText ? props.helperText : ""}
                            //InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                            required={props.required}
                            startAdornment={
                                <React.Fragment>
                                    {
                                        (fieldConfig.currencySymbol && fieldConfig.currencySymbol !== "") &&
                                        <InputAdornment position={'start'}>
                                            {fieldConfig.currencySymbol}
                                        </InputAdornment>
                                    }
                                </React.Fragment>
                            }
                        />
                    </React.Fragment>);
            case "Select":

                let dependencies = fieldConfig.optionsDependencies;
                let options= useExternalOptions ? state.externalOptions :
                    fieldConfig.useYearOptions ? getYearOptions(fieldConfig.previousYears, fieldConfig.futureYears) :
                    fieldConfig.options ? fieldConfig.options : [];
                if (dependencies) {
                    if (dependencies.length > 0) {
                        options = getOptions(props.optionsConfig, props.templateKey, props.metadataKey, dependencies, props.formValues,props.usage)
                    }
                }

                // AG check if custom options are being passed in for folder seearch in the workspace config...
                if (props.selectedSearchConfig && props.selectedSearchConfig && props.selectedSearchConfig.folderSearch && props.selectedSearchConfig.folderSearch.searchFields){
                    let searchFieldsConfig = props.selectedSearchConfig.folderSearch.searchFields;
                    for (var i=0; i < searchFieldsConfig.length; i++) {
                        if (searchFieldsConfig[i].metadataKey === props.metadataKey && searchFieldsConfig[i].templateKey === props.templateKey) {
                            if(searchFieldsConfig[i].options){
                                options = searchFieldsConfig[i].options;
                            }
                        }
                    }
                }

                function validate(dependencies, metadataConfig, options){
                    let missingValues = [];
                    for (let i = 0; i < dependencies.length; i++) {
                        if (!dependencies[i].value && !dependencies[i].optional) {
                            let fieldConfig = getFieldConfig(metadataConfig, dependencies[i].templateKey, dependencies[i].metadataKey);
                            if (Object.entries(fieldConfig).length > 0) {
                                missingValues.push(fieldConfig.label);
                            }
                        }
                    }
                    if (missingValues.length > 0) {
                        if (missingValues.length === 1) {
                            props.enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                        } else {
                            props.enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                        }
                    } else if (missingValues.length === 0 && options.length ===0 ){
                        //no required field values missing but options still 0
                        //props.enqueueSnackbar('No options available for ' + fieldConfig.label, {variant: 'info'});
                    }
                }

                const defaultProps = {
                    options: options,
                    getOptionLabel: (option) => option.label,
                };

                // let hasAvatarOptions = false;
                // if (options.length > 0) {
                //     for (let i=0; i < options.length; i++) {
                //         if (options[i].avatar) {
                //             hasAvatarOptions = true;
                //             break;
                //         }
                //     }
                // }

                return (
                    <React.Fragment>
                        <InputLabel shrink htmlFor={fieldId} className={classes.customLabel} required={props.required}>{fieldLabel}</InputLabel>
                        {/*Changed Select with <option  to TextField with '<MenuItem select 'to improve styling, bringing it in line with inline editing in search results table*/}
                        {
                            (useExternalOptions && !state.initExternalOptionsDone)  ?

                                // <LinearProgress variant={"indeterminate"} color={"secondary"}/>
                                //Show disabled field if external lookup not complete

                                <React.Fragment>
                                    <Input
                                        classes={{root: classes.root, disabled: classes.disabled}}
                                        id={fieldId}
                                        name={fieldId}
                                        value={props.fieldValue}
                                        disabled={true}
                                        type="Input"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        autoFocus={props.autoFocus}
                                        onChange={null}
                                        onKeyDown={handleKeyDown}
                                        // style={{paddingTop: 16}}
                                        multiline
                                        InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                                        helperText={props.helperText ? props.helperText : ""}
                                    />
                                </React.Fragment>




                                :

                                // TODO implement Autocomplete for editing
                                // need to do further testing for editing as will need to display initial value as an object
                                // use helper.js - getObjectByKey(nameKey, val, myArray) to initialise fieldValueDisp

                                props.usage === "search" ?

                                    <Autocomplete
                                        {...defaultProps}
                                        id={fieldId}
                                        name={fieldId}
                                        debug
                                        value={fieldValueDisp}
                                        autoFocus={props.autoFocus}
                                        helperText={props.helperText ? props.helperText : ""}
                                        onChange={(event, newValue) => {
                                            handleChangeAutocomplete(newValue);
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params} margin="none"/>
                                        }
                                    /> :

                                    <TextField
                                        classes={{root: classes.root, disabled: classes.disabled}}
                                       // style={hasAvatarOptions ?  {paddingBottom: '0px', paddingTop: '3px', color: 'red'}: {}}
                                        select
                                        id={fieldId}
                                        name={fieldId}
                                        value={props.fieldValue}
                                        disabled={disabled}
                                        type={fieldConfig.type}
                                        native={true}
                                        margin="none"
                                        autoComplete=""
                                        autoFocus={props.autoFocus}
                                        onClick={ () => { if (options.length === 0) {validate(props.metadataConfig, dependencies,options)}}}
                                        onChange={!disabled ? handleChange : null}
                                        helperText={props.helperText ? props.helperText : ""}
                                    >
                                        {
                                            options.length > 0 &&
                                            <MenuItem value= {fieldConfig.type === "number" ? null : ""}>
                                                <Typography variant="inherit">{'- ' + fieldConfig.placeholder+ ' -'}</Typography>
                                            </MenuItem>
                                        }
                                        {options.map(opt => {
                                            return (
                                                <MenuItem id={opt.value} value={opt.value} key={opt.value}>
                                                    {opt.icon ?
                                                        <Tooltip title={opt.label ? opt.label : <i className={'material-icons'} style={{color: '#fff', fontSize: "36px"}}>{opt.icon}</i>}>
                                                            <span style={{width: '100%', display: 'block'}}>
                                                                <i className={'material-icons'} style={{color: '#646464', fontSize: "14px", verticalAlign: "middle", paddingRight: '4px'}}>{opt.icon}</i>
                                                                <Typography variant="inherit">{opt.label}</Typography>
                                                            </span>
                                                        </Tooltip>:
                                                        opt.avatar ?

                                                            <ListItem style={{padding: '0px'}}>
                                                                <Tooltip title={<CardMedia component="img" image={window.location.origin + '/images/' + opt.avatar}/>}>
                                                                    <ListItemIcon style={{minWidth: '32px'}}>
                                                                        <Avatar component={'span'} alt={opt.label} style={{ width: '18px', height: '18px' }} src={window.location.origin + '/images/' + opt.avatar} />
                                                                    </ListItemIcon>
                                                                </Tooltip>
                                                                <ListItemText primary={opt.label} style={{marginTop: '0px', marginBottom: '0px'}} />
                                                            </ListItem>
                                                            :
                                                            opt.label}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>

                        }
                    </React.Fragment>
                );
            case "Checkbox":
            return (
                <FormControlLabel
                    style={{paddingLeft: '16px'}}
                    value={props.fieldValue}
                    checked={props.fieldValue}
                    control={<Checkbox color="secondary" name= {fieldId} onChange={handleChange}/>}
                    label={fieldConfig.label}
                    labelPlacement="end"
                    readOnly={props.forceDisable}
                    disabled={props.forceDisable}
                />
            )
            default:
                return (
                    <span>Invalid field type</span>
                )
        }
    } else {
        debug && console.log ("Error retrieving field config for " + props.templateKey + "." + props.metadataKey);
        //props.enqueueSnackbar("No field configuration found for " + props.templateKey + "." + props.metadataKey, {variant: 'info'});
        //return read only Input field if field config not available
        return (
            <React.Fragment>
                <InputLabel shrink htmlFor={fieldId} className={classes.customLabel} required={props.required}>{fieldId}</InputLabel>
                <Input
                    classes={{root: classes.root, disabled: classes.disabled}}
                    id={fieldId}
                    name={fieldId}
                    value={props.fieldValue}
                    disabled={true}
                    type="Input"
                    margin="none"
                    variant="outlined"
                    autoComplete=""
                    autoFocus={props.autoFocus}
                    onChange={null}
                    onKeyDown={handleKeyDown}
                    // style={{paddingTop: 16}}
                    multiline
                    InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                    helperText={props.helperText ? props.helperText : ""}
                />
            </React.Fragment>
        )
    }
}

RenderMetadataField.propTypes = {
    classes: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    templateKey: PropTypes.string.isRequired,
    metadataKey: PropTypes.string.isRequired,
    //fieldValue: PropTypes.string.isRequired,
    fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
    autoFocus: PropTypes.bool, //optional, set in searchTemplate
    rowId: PropTypes.number, //optional
    handleOnChange: PropTypes.func,//only required if field not disabled
    forceDisable: PropTypes.bool, //optional
    forceEnable: PropTypes.bool, //used when component rendered in SearchTemplate
    usage: PropTypes.string, //search / edit / upload - to be required when fully implemented
    formValues: PropTypes.object,  // include existing values - required to be able to implement option dependencies - to be required when fully implemented
    handleSearchClick: PropTypes.func, //optional, only passed from SearchTemplate to facilitate searching on enter
    helperText: PropTypes.string,
    required: PropTypes.bool,
    userDetails: PropTypes.object, //required for external lookups
    triggerRefreshAuthToken: PropTypes.func //required for external lookups
};

export default withSnackbar(withStyles(styles, { withTheme: true })(RenderMetadataField))