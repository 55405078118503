import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';

class PublicLinkDialog extends React.Component {
    state = {
        open: false,
    };

    componentDidMount(){
        this.setState({ open: true })
    }


    handleCloseDialog = () => {
        this.setState({ open: false });
        this.props.handleClose()
    };


    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('PublicLinkDialog props=', this.props);

        return (

            <Dialog
                open={this.state.open}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
            >
                <DialogContent>

                    <div style={{ border: 'border:1px solid #eee', height:'90vh', width:'100%'}}>

                        <div style={{marginTop: "-10px", height: '100%'}}>
                            <iframe
                                title={this.props.label}
                                src={this.props.url}
                                frameBorder={0}
                                scrolling={'auto'}
                                style={{ display:'block', width:'100%', height: '90vh'}}
                            />
                        </div>

                    </div>

                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={this.handleCloseDialog}
                        variant="contained"
                        color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

PublicLinkDialog.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired
};

export default PublicLinkDialog;