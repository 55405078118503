import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ViewFolderLinkDialog from "./ViewUrlSearchLinkDialog";

function ViewUrlSearchLinkButton(props) {

    const [showDialog, setShowDialog] = useState(false)

    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = () => {
       setShowDialog(false)
    };

    return (
        <div style={{display: "inline"}}>
            <Tooltip title={props.actionConfig.button.label}>
                <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    color = "secondary"
                    onClick={handleClickIcon}
                >
                    <i className='material-icons'>
                        link
                    </i>
                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <ViewFolderLinkDialog
                    folderDetails={props.folderDetails}
                    handleCloseDialog={handleCloseDialog}
                    actionConfig={props.actionConfig}
                />
            }

        </div>
   )
}

ViewUrlSearchLinkButton.propTypes = {
    folderDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default (ViewUrlSearchLinkButton);