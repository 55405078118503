import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {Grid} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {getErrorMessageFromResponse, hasAccess} from "../../common/helper"
import {IntlProvider} from "react-intl";
import Preview from "./Preview";
import CommentsPanel from "../comments/CommentsPanel";
import MetadataPanel from "./MetadataPanel";
import Box from "@material-ui/core/Box";


const styles = theme => ({

    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" }, // KEY CHANGES


    previewDialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },

    root: {
        // height: '90vh',
        // overflow: 'hidden',
        // flexGrow: 1,
    },

    container: {
        display: 'flex',
        overflow: 'hidden',
    },

    left: {
        float: 'left',
        width: '50%'
    },

    right: {
        float: 'right',
        width: '50%'
    },

    paper: {
        height: 140,
        width: 100,
    },

    sideMenu: {
        width: theme.spacing(8),
        height: '100%',
        visibility: 'visible',
        display: 'block',
        float: 'right',
        backgroundColor: theme.palette.secondary.main
    },

    sideMenuButton: {
        color: 'white',
        marginTop: theme.spacing(1)
    },

    sideMenuButtonActive: {
        color: theme.selectedTab.underlineColour + '!important', //theme.selectedTab.textColour,
        marginTop: theme.spacing(1)
    },

    sidePanelHeader: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing (.5)
    },
    //https://stackoverflow.com/questions/47698037/how-can-i-set-a-height-to-a-dialog-in-material-ui
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },

});






class PreviewDialog extends React.Component {

    constructor(props) {

        super(props);

        const INITIAL_STATE = {
            open: false,
            doc: null,
            metadataFields: props.documentMetadataFields,
            metadataHasChanged: false,
            metadataUpdated: false,
            isFetchingMetadata: false,
            showSidePanel: false,
            showSidePanelOption: "" //todo pass in default?
        };

        this.state = INITIAL_STATE;
        this.handleOnChangeMetadata = this.handleOnChangeMetadata.bind(this);

        //this.handleSidepanelClose = this.handleSidepanelClose.bind(this);
    }

    componentDidMount(){
        const debug = window.location.pathname.toLowerCase().includes("debug");
        this.setState({ open: true});
        if(this.props.showMetadataOnOpen) {
            this.showSidePanel("metadata");
        }
        debug && console.log('boxDocId:', this.props.boxDocID);
    }

    handleCloseDialog = (metadataUpdated) => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        debug && console.log('PreviewDialog handleCloseDialog metadataUpdated = ', metadataUpdated);

        //clear state
        const END_STATE = {
            open: false,
            doc: null,
            metadataFields: {},
            isFetchingMetadata: false,
            fetchError: false,
            metadataHasChanged: false
        };

        this.setState(END_STATE);
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog(metadataUpdated)
        }
    };

    showSidePanel = (option) => {

        this.setState({
            showSidePanel: true,
            showSidePanelOption: option
        });
        switch (option) {
            case "metadata": {
                if (!this.state.doc ) {
                    //get metadata if not already retrieved
                    this.getMetadata();
                }
                break;
            }
            default: {
                break;
            }
        }
    };

    hideSidepanel = () => {
        this.setState({
            showSidePanel: false,
            showSidePanelOption: ""
        });
    }

    getMetadata = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug");
        
        debug && console.log ('====getMetadata=====');

        this.setState({isFetchingMetadata: true, fetchError: false});

        let getContent = true;

        //get list of templates from metadataConfig
        //&fields=metadata.scope.templateName
        let templateNames = [];
        Object.entries(this.props.metadataConfig).forEach(template => {
            if (template[0] !== "n/a") {
                if (templateNames.indexOf(template[0]) === -1) {
                    templateNames.push(template[0])
                }
            }
        })

        console.log ('**** templateNames=', templateNames)

        let fieldsStr = "name,created_at,modified_at,version,size";
        if (templateNames.length > 0) {
            const templateNamesStr = templateNames.map (t => "metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + t).toString();
            fieldsStr = fieldsStr + "," + templateNamesStr
        }

        const pathVar = "/" + this.props.boxDocID;
        //metadata=false still required but fields param used to get metadata
        const params =  "?userId=" + this.props.userDetails.boxId + "&content=" + getContent + "&metadata=false&fields=" + fieldsStr;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        debug && console.log ("getMetadata url :", url);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving metadata'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                            this.setState({fetchError: true});
                        })
                    debug && console.log("getMetadata error.  url:", url, "request: ", request, "response.text:", response);
                    return null

                }
            })
            .then(result => {

                debug && console.log('getMetadata, result=', result);

                this.setState({doc: result});
                this.setState({isFetchingMetadata: false});

                //extract metadata

                let metadataFields = this.state.metadataFields;

                //get values
                if (metadataFields) {
                    for (let i = 0; i < metadataFields.length; i++) {
                        let templateKey = metadataFields[i].templateKey;
                        if (templateKey !== 'n/a') {
                            let metadataKey = metadataFields[i].metadataKey;
                            // listOfFields.push(templateKey + "~" + metadataKey);
                            if (result.metadata && 
                                result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID] && 
                                result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey] && 
                                result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey]) {
                                
                                metadataFields[i].value = result.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey];
                            } else {
                                metadataFields[i].value = null;
                            }
                        }
                    }
                } 
                
                this.setState({metadataFields: metadataFields});

            })
            .catch(e => {
                debug && console.log("getMetadata Exception:", e, " url:", url, "request: ", request);
                this.props.enqueueSnackbar("Error retrieving metadata - " + e, {variant: 'error'});
                this.setState({isFetchingMetadata: false})
            })
    };


    setMetadata = async () => {
        
        const debug = window.location.pathname.toLowerCase().includes("debug");

        debug && console.log('PreviewDialog submitRequest');

        let body = {
            //content: content,
            fileName: this.state.doc.name,
            metadata: this.state.metadataFields
        };

        debug && console.log ("body: " , body);

        const pathVar = "/" + this.props.boxDocID;
        const params = '?audit=' + this.props.actionsConfig.edit.audit;
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;

        const request = {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        debug && console.log ('submitRequest url:' , url, 'BODY:', body, 'request:' , request);

        this.setState({isFetchingMetadata: true});

        await this.props.triggerRefreshAuthToken();
        fetch(url, request )
            .then(response => {
                if (response.ok) {
                    this.props.enqueueSnackbar("Metadata on " + body.fileName + " successfully updated.", {variant: 'success'});
                    this.setState({isFetchingMetadata: false});
                    this.setState({metadataHasChanged: false});
                    //this.handleCloseDialog(true);
                } else {

                    Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    debug && console.log("submitRequest error.  url:", url, "request: ", request, "response.text:", response.statusText, {variant: 'error'});
                    this.setState({isFetchingMetadata: false});
                }
            })
            .catch(e => {
                debug && console.log("submitRequest Exception:", e, "url:", url, "request: ", request);
                this.setState({isFetchingMetadata: false});
                this.props.enqueueSnackbar("Error " + e, {variant: 'error'});
            })
    };


    handleOnChangeMetadata = (id, newValue) => {

        let templateKey = id.split("~")[0];
        let metadataKey = id.split("~")[1];

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        let newMetadataFields = this.state.metadataFields;        //need this temporary object to ensure all values are maintained
        //TODO need to deep clone?
        for (let i = 0; i < newMetadataFields.length; i++) {
            if (newMetadataFields[i].metadataKey === metadataKey && newMetadataFields[i].templateKey === templateKey) {
                newMetadataFields[i].value = val;
                break;
            }
        }
        this.setState({"metadataFields" : newMetadataFields});
        if (!this.state.metadataHasChanged) {
            this.setState({metadataHasChanged: true});
        }
    };

    
    render() {

        const classes = this.props.classes;

        let fileOptions = {};
        fileOptions[this.props.boxDocID] = {
            startAt: {
                unit: 'pages',
                value: this.props.pageNumber
            }
        };

        const hasEditAccess = hasAccess(this.props.actionsConfig.edit, this.props.userDetails.userRoles);

        let enableCustomMetadata = false;
        if (this.props.actionsConfig.previewDocument.customMetadata) {
            if (this.props.actionsConfig.previewDocument.customMetadata.enabled) {
                if (hasAccess(this.props.actionsConfig.previewDocument.customMetadata, this.props.userDetails.userRoles)) {
                    enableCustomMetadata = true
                }
            }
        }

        let enableComments = false;
        const commentsAction = this.props.actionsConfig.comments;
        if (commentsAction && commentsAction.enabled && hasAccess(commentsAction, this.props.userDetails.userRoles)) {
            enableComments = true
        }


        const showMenuBar = enableCustomMetadata || enableComments;
        const showSidePanelOption = this.state.showSidePanelOption;
        const showSidePanel = this.state.showSidePanel;


        window.location.pathname.toLowerCase().includes("debug") && console.log('showHeader: ', this.props.actionsConfig.previewDocument.showHeader);

        const metadataFields = this.state.metadataFields;
        !metadataFields && console.log ('*** No metadata fields configured')

        return (
            <Dialog
                // classes={{ paper: classes.dialogPaper }}
                //classes={{ paper: classes.previewDialogPaper }} //works
                classes={{ paper: {
                        minHeight: '100vh',
                        maxHeight: '100vh',
                    } }}

                open={this.state.open}
                onClose={() => this.handleCloseDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                // style={{height: '90vh'}}
                //height="90vh"
            >

                <DialogContent
                    style={{
                        // backgroundColor: 'lightgreen',
                        overflow: 'hidden', //to prevent scroll bar appearing for container, want it to appear for each grid item instead
                        display: 'inline-flex' //*** NB *** ....adding this fixed problem with Grid expanding beyond contents of dialogContent
                }}>
                    <Box
                        noValidate
                        sx={{
                            flexBasis: 'auto',
                            flexGrow: 1,
                            flexShrink: 1,
                            overflowX: 'hidden',
                            overflowY: 'hidden'
                        }}
                    >

                        <React.Fragment>
                            {/*<div style={{width: '100%', height: "90vh"}}>*/}
                            {/*<div style={{width: '100%', height: "100%", display: 'flex'}}>*/}
                            {/*    <div style={{width: '100%' , height: "100%", display: 'flex', float: 'left'}}>*/}
                                <Grid container spacing={0}
                                      style={ {maxHeight: "100%",  height: "90vh", overflowY: "auto"}} //to get grid height to expand to hold contents
                                       // style={{height: "90vh", overflow: "none"}}
                                      //style={ {height: "100%"}}
                                >

                    {/*
                   https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
                   By default, a grid item cannot be smaller than the size of its content.
                   Grid items have an initial size of min-width: auto and min-height: auto.
                   You can override this behavior by setting grid items to
                       min-width: 0, min-height: 0 or overflow with any value other than visible.
                                    */}
                                    {/*Preview **************************/}
                                    <Grid item xs={showSidePanel ? 8 : (showMenuBar ? 11 : 12)}
                                          style={{height: "100%", overflowY: "auto"}}
                                    >
                                        <IntlProvider locale="en">
                                            <Preview boxDocID={this.props.boxDocID}
                                                     fileContent={this.state.doc ? this.state.doc.content : ""}
                                                     userDetails={this.props.userDetails}
                                                     showHeader={this.props.actionsConfig.previewDocument.showHeader}
                                                     actionsConfig={this.props.actionsConfig}
                                                     showSidebar={true}
                                                     triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            />
                                        </IntlProvider>
                                    </Grid>

                                    {/*Side Menu Bar **********************/}
                                    {
                                        showMenuBar &&
                                        <Grid item xs={1} style={{height: "100%", overflowY: "auto", textAlign: 'center'}}>
                                            <div className={classes.sideMenu}>
                                                <React.Fragment>
                                                    {
                                                        showSidePanel &&
                                                        <Tooltip title={"Close sidepanel"}>
                                                            <IconButton onClick={this.hideSidepanel}
                                                                        className={classes.sideMenuButton}>
                                                                <i className='material-icons'>chevron_right</i>
                                                            </IconButton>
                                                        </Tooltip>

                                                    }
                                                    {
                                                        enableCustomMetadata &&
                                                        <Tooltip title={"Show Metadata"}>
                                                            <IconButton onClick={() => this.showSidePanel("metadata")}
                                                                        className ={showSidePanelOption === "metadata" ? classes.sideMenuButtonActive : classes.sideMenuButton}
                                                                        disabled = {showSidePanelOption === "metadata"}
                                                            >
                                                                <i className="material-icons">info_outline</i>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {
                                                        enableComments &&
                                                        <Tooltip title={"Show Comments"}>
                                                            <IconButton onClick={() => this.showSidePanel("comments")}
                                                                        className={showSidePanelOption === "comments" ? classes.sideMenuButtonActive : classes.sideMenuButton}
                                                                         disabled = {showSidePanelOption === "comments"}
                                                            >
                                                                <i className="material-icons">comment</i>
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </React.Fragment>
                                            </div>

                                        </Grid>
                                    }

                                    {/*Side Panel ****************************/}
                                    {
                                        showSidePanel &&
                                        <Grid item xs={3} style={{height: "100%", overflowY: "auto"}}>
                                            {
                                                showSidePanelOption === "metadata" ?
                                                <MetadataPanel
                                                    classes={classes}
                                                    isFetchingMetadata={this.state.isFetchingMetadata}
                                                    fetchError={this.state.fetchError}
                                                    metadataFields={metadataFields}
                                                    metadataConfig={this.props.metadataConfig}
                                                    optionsConfig={this.props.optionsConfig}
                                                    handleOnChangeMetadata={this.handleOnChangeMetadata}
                                                    handleOnChangeMetadataDate={this.handleOnChangeMetadataDate}
                                                    hasEditAccess={hasEditAccess}
                                                /> :
                                                    showSidePanelOption === "comments" &&
                                                    <CommentsPanel
                                                        parentClasses={classes}
                                                        boxDocID={this.props.boxDocID}
                                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                        userDetails={this.props.userDetails}
                                                        // enableAddComment={}
                                                        actionsConfig={this.props.actionsConfig}
                                                        handleCloseDialog={this.handleCloseDialog}/>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            {/*</div>*/}
                        </React.Fragment>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => this.handleCloseDialog(false)} color="primary">Close</Button>
                    {
                        (hasEditAccess && this.state.metadataHasChanged) &&
                        <Button onClick={this.setMetadata}
                                variant = "contained"
                                color = "primary"
                                disabled={this.state.isFetchingMetadata || !this.state.metadataHasChanged}>
                            {this.state.isFetchingMetadata ? "Updating..." : "Save"}
                        </Button>
                    }
                </DialogActions>
            </Dialog>

        );
    }
}

PreviewDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired, // so that we can get the workspace details when a document is returned via Search All or when document has a different workspace property value to the selected workspace
    actionsConfig: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    //showMetadataOnOpen: PropTypes.bool,
    documentDetails: PropTypes.object.isRequired, //used by commentsPanel
    documentMetadataFields: PropTypes.array.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true} )(PreviewDialog));