import React from 'react';
import 'box-ui-elements/dist/preview.css';
import 'box-ui-elements/dist/explorer.css';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core";
import {withSnackbar} from "notistack";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from "@material-ui/core/Divider";
import LinearProgress from '@material-ui/core/LinearProgress';
import MLEntity from "./MLEntity";

const styles = theme => ({

    topLevel: {
        paddingLeft: "8px"
    }

})

function calculateTypeApprovalProgress(type) {

    //loop through all pages and entities go get progress
    let totalCount = 0
    let totalApproved = 0
    let totalRejected = 0
    type.results.forEach((entity) => {
        entity.pages.forEach((page) => {
            page.snippets.forEach ((snippet) =>{
                const approved = snippet.review_status === "VALID MATCH"
                const rejected = snippet.review_status === "FALSE POSITIVE"
                const actioned = approved || rejected

                totalCount++
                if (actioned) {
                    if (approved) {
                        totalApproved ++
                    } else if (rejected) {
                        totalRejected ++
                    }
                }
            })
        })
    })

    const totalProcessedCount = totalApproved + totalRejected;
    return totalProcessedCount / totalCount * 100;
}

function ApprovalSummary(props) {

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={props.progress}/>
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.progress)}%`}</Typography>
            </Box>
        </Box>
    );
}
ApprovalSummary.propTypes = {
    progress: PropTypes.number.isRequired
};

class MLTypes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        //window.location.pathname.toLowerCase().includes("debug") && console.log('MLTypes props =', props)
    }

    handleExpandCollapse = item => (event) => {

        this.setState(prevState => ({
            [item]: !prevState[item]
        }));

    };

    autoCollapseType = (typeIndex) => {

        let type = this.props.results[typeIndex];
        window.location.pathname.toLowerCase().includes("debug") && console.log ('autoCollapseType type ', type)
        const progress = calculateTypeApprovalProgress(type);
        if (progress === 100 && this.state[type.category] === true) {
            this.setState({
                [type.category] : false
            })
        }
    }

    render() {

        const {classes} = this.props;

        let results = this.props.results;

        return (

            <div style={{height: '100%', width: '100%'}}>
                <List className={classes.list}>
                    {
                        results && results.length > 0 ?
                            results.map((type, typeIndex) => {
                                if (!type.results) {
                                    return (
                                        <ListItem button>
                                            <ListItemIcon className={classes.listIcon}/>
                                            <ListItemText className={classes.topLevel}
                                                          primary={type.category + " (No results available)"}
                                                          primaryTypographyProps={{style: {fontWeight: "bold"}}}
                                            />
                                        </ListItem>
                                    )
                                } else {

                                    const progress = calculateTypeApprovalProgress(type);
                                    return (
                                        <React.Fragment key={type.category}>
                                            <Divider/>
                                                <ListItem button key={type.category}
                                                          disableGutters={true}
                                                          onClick={this.handleExpandCollapse(type.category)}>
                                                    <Box display="flex" alignItems="left" width="2%" height="60px" style={{backgroundColor:type.colour}}/>

                                                    <ListItemText className={classes.topLevel}
                                                              primary={type.category}
                                                              primaryTypographyProps={{style: {fontWeight: "bold"}}}
                                                              secondary={type.results.length  + " matched term" + (type.results.length !== 1 ? "s" : "")}
                                                    />
                                                    <ListItemText className={classes.topLevel}
                                                              primary={type.results.length > 0 && <ApprovalSummary type={type} progress={progress}/>}
                                                />
                                            </ListItem>
                                            <Collapse in={this.state[type.category]} timeout="auto" unmountOnExit>
                                                <List className={classes.list}>
                                                    {
                                                        type.results.length > 0 &&
                                                        type.results.map((entity, index) => {
                                                            return (
                                                                <MLEntity
                                                                    autoCollapseType={this.autoCollapseType}
                                                                    selectPage={this.props.selectPage}
                                                                    type={type}
                                                                    typeIndex={typeIndex}
                                                                    entity={entity}
                                                                    entityIndex={index}
                                                                    boxDocID={this.props.boxDocID}
                                                                    userDetails={this.props.userDetails}
                                                                    changePageNumber={this.props.changePageNumber}
                                                                    actionSnippet={this.props.actionSnippet}
                                                                    actionEntity={this.props.actionEntity}
                                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                    enableApproveRejectSnippet={this.props.enableApproveRejectSnippet}
                                                                    resultId={this.props.resultId}
                                                                    disableApproveRejectSnippet={this.props.disableApproveRejectSnippet}
                                                                    enableAddComment={this.props.enableAddComment}
                                                                />)
                                                        })
                                                    }
                                                </List>
                                            </Collapse>
                                        </React.Fragment>
                                    )
                                }
                            }) : <React.Fragment/>
                    }
                </List>
            </div>
        )
    };
}


MLTypes.propTypes = {
    actionSnippet: PropTypes.func.isRequired,
    actionEntity: PropTypes.func.isRequired,
    selectPage: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    changePageNumber: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    enableApproveRejectSnippet: PropTypes.bool.isRequired,
    resultId: PropTypes.string.isRequired,
    enableAddComment: PropTypes.bool.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(MLTypes));
