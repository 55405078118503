import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Divider from '@material-ui/core/Divider';
import Moment from 'react-moment';
import Avatar from '@material-ui/core/Avatar';
import Preview from "../preview/Preview";
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Input from "@material-ui/core/Input/Input";
import Box from "@material-ui/core/Box";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {getInitials, getColour, getOptionsExternal, getErrorMessageFromResponse} from "../../common/helper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {Tooltip} from "@material-ui/core";
// import moment from "moment";

const styles = (theme) => ({
    root: {
        height: '75vh',
        // overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },

    left: {
        float: 'left',
        width: '50%'
    },

    right: {
        float: 'right',
        width: '50%'
    },

    smallAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    },

    customLabel:{
        //color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    }

});

function InputComment(props) {
    const { classes} = props;
    return (
        <ListItem button={false}>
            <ListItemText>
                <FormControl style={{
                    paddingTop: '0px',
                    width: '100%'}}>
                    <InputLabel shrink htmlFor="inputComment" className={classes.customLabel}>Task Details</InputLabel>
                    <Input
                        id="inputComment"
                        name="inputComment"
                        value={props.inputCommentValue}
                        placeholder="Enter task description"
                        type="text"
                        margin="none"
                        variant="outlined"
                        autoComplete=""
                        onChange={props.handleOnChangeComment}
                        style={{paddingTop: 16}}
                        autoFocus = {true}/>
                </FormControl>

                {
                    props.initAssigneeOptionsDone &&
                        <FormControl style={{
                            paddingTop: '24px',
                            width: '100%'
                        }}>
                            <InputLabel shrink htmlFor="inputAssignee" className={classes.customLabel}>Assignee</InputLabel>

                            {/*<Input*/}
                            {/*    id="inputAssignee"*/}
                            {/*    name="inputAssignee"*/}
                            {/*    value={props.inputAssigneeValue}*/}
                            {/*    placeholder="Assignee email"*/}
                            {/*    type="text"*/}
                            {/*    margin="none"*/}
                            {/*    variant="outlined"*/}
                            {/*    autoComplete=""*/}
                            {/*    onChange={props.handleOnChangeAssignee}*/}
                            {/*    style={{paddingTop: 16}}*/}
                            {/*    autoFocus={false}/>*/}

                            <TextField
                                classes={{root: classes.rootInput, disabled: classes.disabled}}
                                id="inputAssignee"
                                name="inputAssignee"
                                value={props.inputAssigneeValue}
                                placeholder={props.assigneeConfig.label + " email"}
                                type="Select"
                                margin="none"
                                variant="outlined"
                                autoComplete=""
                                onChange={props.handleOnChangeAssignee}
                                style={{paddingTop: 16}}
                                autoFocus = {false}
                                disabled = {props.isProcessing}
                                select
                                native={true}
                                helperText=""
                            >
                                {props.assigneeOptions.length > 0 && <MenuItem value="">{'- ' + props.assigneeConfig.label + ' -'}</MenuItem>}
                                {props.assigneeOptions.map(opt => {
                                    return (
                                        <MenuItem id={opt.value} value={opt} key={opt.value}>{opt.label}</MenuItem>
                                    )
                                })}
                            </TextField>
                        </FormControl>
                }

            </ListItemText>
        </ListItem>
    )
}
InputComment.propTypes = {
    classes: PropTypes.object.isRequired,
    userName: PropTypes.string.isRequired,
    inputCommentValue: PropTypes.string.isRequired,
    handleOnChangeComment: PropTypes.func.isRequired,
    inputAssigneeValue: PropTypes.string.isRequired,
    handleOnChangeAssignee: PropTypes.func.isRequired,
    initAssigneeOptionsDone: PropTypes.bool.isRequired,
    isProcessing: PropTypes.bool.isRequired,
    assigneeConfig: PropTypes.object.isRequired,
    assigneeOptions: PropTypes.array.isRequired
};

// function DisplayTasks(props) {
//
//     const dateFormat = "DD/MM/yyyy"
//     window.location.pathname.toLowerCase().includes("debug") && console.log ('-- DisplayTasks -- props=', props);
//     if (props.tasks)  {
//         return(
//
//             props.tasks.map((task, index) => {
//                 return(
//                     <div key={"div" + task.id}>
//                         {index > 0 && <Divider/>}
//                         <ListItem key = {"li" + task.id}>
//
//                             {/*<ListItemIcon key = {"liIcon" + task.id}>*/}
//                                 {/*<Avatar key = {"avatar" + task.id}*/}
//                                         {/*style={{backgroundColor: getColour(task.created_by.name)}}>*/}
//                                     {/*{getInitials(task.created_by.name)}*/}
//                                 {/*</Avatar>*/}
//                             {/*</ListItemIcon>*/}
//                             <ListItemText
//                                 key = {"liText" + task.id}
//                                 primary={
//                                     <React.Fragment key = {"fragment" + task.id}>
//                                         <Typography
//                                             key = {"typog" + task.id}
//                                             component="span"
//                                             variant="body2"
//                                             color="textPrimary">
//                                             <b>{task.message}</b>
//                                         </Typography>
//                                     </React.Fragment>}
//                                 secondary={
//                                     <React.Fragment key = {"fragment1" + task.id}>
//                                         {/*{task.created_by.name} assigned a task */}
//                                         Created: <Moment key = {"moment" + task.id} format={dateFormat}>{ task.created_at}</Moment>
//                                         {/*Created: {task.created_at}*/}
//                                         <br/>Due: <Moment key = {"momentDue" + task.id} format={dateFormat}>{task.due_at}</Moment>
//                                         {/*<br/>Status: {task.completed ? "Completed" : "In Progress"}*/}
//                                         {/*<br/>ID: {task.id}*/}
//                                     </React.Fragment>
//                                 }>
//                             </ListItemText>
//                         </ListItem>
//
//                         <List style={{paddingLeft: "30px"}}>
//                             {
//                                 task.task_assignment_collection.entries.map(
//                                     assignment => {
//
//                                         console.log ("*** assignment = ", assignment);
//                                         console.log ("*** assignment.assigned_to.id = ", assignment.assigned_to.id);
//                                         console.log ("*** props.userDetails.boxId = ", props.userDetails.boxId);
//
//                                         if (assignment.assigned_to.id === props.userDetails.boxId ) {
//
//                                             console.log ("*** here, the task is assigned to me!!!....expand")
//
//                                             return (
//                                                 <ListItem key={task.id}>
//                                                     <ListItemIcon>
//                                                         <Badge badgeContent={
//                                                             task.completed ?
//                                                                 <i className='material-icons' style={{fontSize: '10px'}}>check</i> :
//                                                                 null
//                                                         }
//                                                                color="primary"
//                                                                overlap="circle"
//                                                                anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
//                                                         >
//                                                             <Avatar key={"avatar" + task.id}
//                                                                     style={{backgroundColor: getColour(assignment.assigned_to.name), height: 30, width: 30, fontSize: '10px'}}>
//                                                                 {getInitials(assignment.assigned_to.name)}
//                                                             </Avatar>
//                                                         </Badge>
//                                                     </ListItemIcon>
//                                                     <ListItemText
//                                                         primary={assignment.assigned_to.name}
//                                                         secondary={
//                                                             assignment.resolution_state === "completed" &&
//                                                             <React.Fragment>
//                                                                 Completed <Moment key={"moment" + assignment.id}>{assignment.completed_at}</Moment>
//                                                             </React.Fragment>
//                                                         }
//                                                     />
//                                                     <ListItemSecondaryAction>
//                                                         {
//                                                             (!task.completed && assignment.resolution_state === "incomplete" && assignment.assigned_to.id === props.userDetails.boxId) &&
//                                                             <Button
//                                                                 variant="contained"
//                                                                 color="secondary"
//                                                                 id={assignment.id}
//                                                                 onClick={() => props.completeTask(assignment)}>
//                                                                 Mark as Complete
//                                                             </Button>
//                                                         }
//                                                     </ListItemSecondaryAction>
//                                                 </ListItem>
//                                             )
//
//                                         }
//                                         return(null)
//                                     }
//
//                                 )
//                             }
//                         </List>
//                     </div>
//
//                 )
//             })
//         )
//     } else {
//         //in case of error retrieving tasks
//         return(<ListSubheader disableSticky>No Tasks</ListSubheader>)
//     }
// }
//
// DisplayTasks.propTypes = {
//     tasks: PropTypes.array.isRequired,
//     classes: PropTypes.object.isRequired,
//     completeTask: PropTypes.func.isRequired,
//     userDetails: PropTypes.object.isRequired
// };

const INITIAL_STATE = {
    open: false,
    inputComment: "",
    inputAssignee: "",
    tasks: [],
    isProcessing: false,
    assigneeOptions: [],
    initAssigneeOptionsDone: false
};

class TasksDialog extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        this.handleOnChangeComment = this.handleOnChangeComment.bind(this);
        this.handleOnChangeAssignee = this.handleOnChangeAssignee.bind(this);
        this.completeTask = this.completeTask.bind(this);

    }

    UNSAFE_componentWillMount() {

        const actionConfig = this.props.actionsConfig.tasks;
        const assigneeConfig = actionConfig.assignee;
        //const assigneeUseExternalOptions = assigneeConfig && assigneeConfig.optionsExternal && Object.entries(assigneeConfig.optionsExternal).length > 0

        getOptionsExternal(assigneeConfig.optionsExternal, this.props.userDetails, this.props.triggerRefreshAuthToken)
            .then(response => {
                this.setState({
                    assigneeOptions: response,
                    initAssigneeOptionsDone: true
                })
            })

        this.getTasks();
    }

    componentDidMount(){

        this.setState({ open: true });
        window.location.pathname.toLowerCase().includes("debug") && console.log('boxDocId:', this.props.boxDocID);

    }


    closeDialog = () => {


        //clear state
        const END_STATE = {
            open: false,
            tasks: []
        };

        this.setState(END_STATE);

        //Added this to address issue of dialog not re-opening after initial open
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog()
        }

    };


    getTasks = async () => {

        window.location.pathname.toLowerCase().includes("debug") && console.log (" -- GET TASKS --");

        this.setState({isFetching: true});

        const pathVar = "/" + this.props.boxDocID + "/tasks";
        const queryStr =  "?userId=" + this.props.userDetails.boxId;
        const url = window.REACT_APP_TASK_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + queryStr;
        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("getTasks url:", url);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('getTasks RESPONSE: ', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving tasks'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getTasks error.  url:", url, "request: ", request);
                    return (null)
                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('result = ' , result);
                this.setState({tasks: result})
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("getTasks Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })
    };

    completeTask = async assignment => {

        window.location.pathname.toLowerCase().includes("debug") && console.log (' - Complete Task - ');

        this.setState({isFetching: true});

        //let assignment = this.state.tasks[0].task_assignment_collection.entries[0];
        assignment.resolution_state = "completed";
        window.location.pathname.toLowerCase().includes("debug") && console.log ("assignment = ", assignment);

        const queryStr =  "?userId=" + this.props.userDetails.boxId;
        const url = window.REACT_APP_TASK_API_BASE_URL + window.REACT_APP_TASK_API_DOCUMENT_ASSIGNMENT + queryStr;
        const request = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(assignment)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("completeTask url:", url, "request=", request);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('completeTaskAssignment RESPONSE: ', response);
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ("completeTask response status = OK ");
                    this.props.enqueueSnackbar("Task completed successfully", {variant: 'success'});
                    return(response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ("completeTask response status not OK ");
                    Promise.resolve(getErrorMessageFromResponse(response, 'completing task assignment'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("error.  url:", url, "request: ", request);
                    return (null)
                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('result json = ' , result);
                //refresh tasks
                this.getTasks();
            })
            .catch(e => {
                this.props.enqueueSnackbar("Error retrieving tasks: " + e.toString(), {variant: 'error'});
                window.location.pathname.toLowerCase().includes("debug") && console.log("completeTask Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })
    };

    handleOnChangeComment = event => {
        this.setState({inputComment : event.target.value});
    };

    handleOnChangeAssignee = event => {
        this.setState({inputAssignee : event.target.value});
    };


    postTask = async () => {

        const url = window.REACT_APP_TASK_API_BASE_URL + "/api/box/task";

        let body = {
            item : {
                "id" : this.props.boxDocID,
                "type" : "file",
            },
            type: "task",
            action: "complete",  //review or complete
            message: this.state.inputComment
        };

        const selectedAssignee = this.state.inputAssignee;
        if (selectedAssignee) {
            body.assign_to = {
                id: selectedAssignee.user.boxId,
                type: "user",
                login: selectedAssignee.user.login
            }
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log ('body: ', body);

        let request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("postTask url:", url, 'request: ', request);

        await this.props.triggerRefreshAuthToken();
        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postTask RESPONSE ok: ', response);
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postTask RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'posting task'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("postTask error. url:", url, "request: ", request, "response:", response);
                    return (null)
                }
            })
            .then(result => {
                this.postAssignment(result.id); //refresh comments
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("postTask Exception:", e, "url:", url, "request: ", request);
            });

    };

    postAssignment = async (taskid) => {

        const url = window.REACT_APP_TASK_API_BASE_URL + "/api/box/taskassignment";

        let body = {
            task : {
                "id" : taskid,
                "type" : "task",
            },
        };

        const selectedAssignee = this.state.inputAssignee;
        if (selectedAssignee) {
            body.assign_to = {
                id: selectedAssignee.user.boxUserId,
                type: "user",
                //login: selectedAssignee.user.login
            }
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log ('body: ', body);

        let request = {
            method: 'POST',
            headers: {
                "case-token": this.props.userDetails.caseAccessToken,
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("postAssignment url:", url, 'request: ', request);

        await this.props.triggerRefreshAuthToken();
        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postAssignment RESPONSE ok: ', response);
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postAssignment RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'assigning task'))
                        .then(message => {
                            this.props.enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("postAssignment error. url:", url, "request: ", request, "response:", response);
                    return (null)
                }
            })
            .then(result => {
                this.setState({
                    inputComment: "",
                    inputAssignee: "",
                    isProcessing: false
                });
                this.getTasks(); //refresh tasks
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("postAssignment Exception:", e, "url:", url, "request: ", request);
            });

    };

    handleExpandCollapse = item => (event) => {

        this.setState(prevState => ({
            [item]: !prevState[item]
        }));

    };

    render() {

        //const classes = styles();
        const dateFormat = "DD/MM/yyyy"

        return (

            <Dialog
                open={this.state.open}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
            >

                <DialogContent>

                    {/*<AppBar position="static" color="default">*/}
                        {/*<Toolbar>*/}
                            {/*<Typography variant="title" color="inherit">*/}
                                {/*Scrolling Columns*/}
                            {/*</Typography>*/}
                        {/*</Toolbar>*/}
                    {/*</AppBar>*/}

                    <Grid container
                          spacing={1}
                          style={ {height: "600px"}}
                    >
                        <Grid item
                              xs={8}
                              style={{height: "100%", overflowY: "auto"}}>
                            <Preview
                                boxDocID={this.props.boxDocID}
                                userDetails={this.props.userDetails}
                                showHeader={true}
                                actionsConfig={this.props.actionsConfig}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            />
                        </Grid>
                        <Grid item
                              xs={4}
                              style={{height: "100%", overflowY: "auto"
                            }}
                        >
                            <React.Fragment>
                                <Typography variant={"h6"} color={"textPrimary"} style={{paddingLeft: "12px", paddingTop: "0px"}}>
                                    Tasks
                                </Typography>
                                <List style={{paddingTop: '0px', paddingBottom: '0px'}}>
                                    {
                                        this.state.tasks &&
                                        this.state.tasks.length > 0 &&
                                        this.state.tasks.map((task,index) => {

                                            let completeCount = 0;
                                            for (let i = 0; i < task.task_assignment_collection.entries.length; i++) {
                                                let assignment = task.task_assignment_collection.entries[i]
                                                if (assignment.resolution_state === "completed") {
                                                    completeCount++
                                                }
                                            }
                                            const isComplete = completeCount === task.task_assignment_collection.entries.length;

                                            return(
                                                <div key={"div" + task.id}>
                                                    {index > 0 && <Divider/>}
                                                    <ListItem button key = {"li" + task.id} style={{paddingTop: '0px', paddingBottom: '0px'}}
                                                              onClick={this.handleExpandCollapse(task.id)}
                                                    >

                                                        <ListItemText key = {"liText" + task.id}
                                                                      primary={
                                                                          <Box minWidth={50}>
                                                                              <Typography key = {"typog" + task.id} component="span" variant="body2" color="textPrimary">
                                                                                  <b>{task.message}</b>
                                                                              </Typography>
                                                                          </Box>
                                                                      }
                                                                      secondary={
                                                                          <React.Fragment key = {"fragment1" + task.id}>
                                                                              {/*{task.created_by.name} assigned a task */}
                                                                              Created: <Moment key = {"moment" + task.id} format={dateFormat}>{ task.created_at}</Moment>
                                                                              {!isComplete && <span> Due: <Moment key = {"momentDue" + task.id} format={dateFormat}>{task.due_at}</Moment></span>}

                                                                          </React.Fragment>
                                                                      }
                                                        />
                                                        <ListItemIcon>{this.state[task.id] ? <ExpandLess/> : <ExpandMore/>}</ListItemIcon>
                                                    </ListItem>

                                                    {/*Assigment nested list*/}
                                                    <Collapse in={this.state[task.id]}>
                                                        <List style={{paddingLeft: "30px", paddingTop: "0px", paddingBottom: "0px"}}>
                                                            {
                                                                task.task_assignment_collection.entries.map(
                                                                    assignment => {
                                                                        return (
                                                                            <ListItem key={task.id}>
                                                                                <ListItemIcon>
                                                                                    <Badge
                                                                                        badgeContent={task.completed && <i className='material-icons' style={{fontSize: '10px'}}>check</i>}
                                                                                        color="primary"
                                                                                        overlap="circle"
                                                                                        anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
                                                                                        <Avatar key={"avatar" + task.id}
                                                                                                style={{backgroundColor: getColour(assignment.assigned_to.name), height: 30, width: 30, fontSize: '10px'}}>
                                                                                            {getInitials(assignment.assigned_to.name)}
                                                                                        </Avatar>
                                                                                    </Badge>
                                                                                </ListItemIcon>
                                                                                <ListItemText
                                                                                    primary={assignment.assigned_to.name}
                                                                                    secondary={
                                                                                        assignment.resolution_state === "completed" &&
                                                                                        <React.Fragment>
                                                                                            Completed <Moment key={"moment" + assignment.id}>{assignment.completed_at}</Moment>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                />

                                                                                <ListItemSecondaryAction>
                                                                                    {
                                                                                        (assignment.resolution_state === "incomplete" && assignment.assigned_to.id === this.props.userDetails.boxId) &&
                                                                                        <Tooltip title={"Mark as complete"}>
                                                                                            <IconButton
                                                                                                variant="contained"
                                                                                                //color="secondary"
                                                                                                style={{color:"#4caf50"}}
                                                                                                id={assignment.id}
                                                                                                onClick={() => this.completeTask(assignment)}>
                                                                                                <i className="material-icons">check_circle</i>
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </ListItemSecondaryAction>
                                                                            </ListItem>
                                                                        )

                                                                    }

                                                                )
                                                            }
                                                        </List>
                                                    </Collapse>

                                                </div>

                                            )
                                        })
                                    }
                                </List>

                                <List>
                                    <InputComment
                                        classes={this.props.classes}
                                        inputCommentValue={this.state.inputComment}
                                        userName={this.props.userDetails.userName}
                                        handleOnChangeComment={this.handleOnChangeComment}
                                        inputAssigneeValue={this.state.inputAssignee}
                                        handleOnChangeAssignee={this.handleOnChangeAssignee}
                                        initAssigneeOptionsDone={this.state.initAssigneeOptionsDone}
                                        isProcessing={this.state.isProcessing}
                                        assigneeConfig={this.props.actionsConfig.tasks.assignee}
                                        assigneeOptions={this.state.assigneeOptions}
                                    />
                                </List>
                            </React.Fragment>

                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={this.closeDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={this.postTask}
                            variant="contained"
                            color="secondary"
                            disabled={(this.state.inputComment === "") ? (true) : (false)}>
                        Add Task
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TasksDialog.propTypes = {
    classes: PropTypes.object,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    calledFromDocumentMenu: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(TasksDialog));