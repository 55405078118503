import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List/List";
import {withSnackbar} from "notistack";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "@material-ui/core/Button/Button";
import AddFolderTaskButton from "./AddFolderTaskButton";
import {createTheme, ThemeProvider, withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {Grid} from "@material-ui/core";
import MaterialTable from 'material-table'
import {getErrorMessageFromResponse, getOptionsExternal} from "../../common/helper";

const styles = theme => ({
    paper: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        paddingTop: '0px',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    tasksInTab: {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '0px',
        paddingRight: '0px'
    },
});

const tableTheme = theme => createTheme({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides overrides the overrides in the parent theme in app.css
    overrides: {

        MuiToolbar: {
            gutters: {
                paddingLeft: "0px"
            },
            root: {
                paddingLeft: "0px"
            },
            regular:{
                // paddingLeft: "0px"
            }
        },
        MTableToolbar: {
            root: {
                // paddingLeft: "0px"
            }
        },
        MuiTableCell: {
            root: {
                padding: '0px',
                //paddingLeft: "24px"
            }
        },

        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
            },
            colorInherit: {
                color: theme.palette.secondary.main
            }
        },

        MuiAvatar: {
            colorDefault: {
                backgroundColor: theme.palette.secondary.main
            }
        }
    }
});


function TaskDescription(props) {
    return(
        <div style={{width: "300px"}}>
            {props.description}
        </div>
    )
}
TaskDescription.propTypes = {
    description: PropTypes.string.isRequired
};

function CompleteTaskButton(props) {

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('CompleteTaskButton props=', props)

    if (props.task && props.task.assign_to && props.task.assign_to.login && props.task.assign_to.login === props.userDetails.userEmail && !props.task.completed)  {
        return(
            <Tooltip title={"Mark as Complete"}>
                <IconButton
                    aria-label={"Mark as Complete"}
                    aria-haspopup="true"
                    color ="secondary"
                    onClick={() => props.completeFolderTask(props.task.task_assignment_collection.entries[0])}
                >
                    <i className='material-icons' style ={{color: "secondary"}}>
                        <i className="material-icons">done</i>
                    </i>
                </IconButton>
            </Tooltip>
        )
    } else {
        //in case of error retrieving tasks
        return(<span/>)
    }
}

CompleteTaskButton.propTypes = {
    task: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    completeFolderTask: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};

function TaskStatusButton(props) {

    const statusConfig = {
        "complete": {
            "colour": "#4caf50"
        },
        "in progress": {
            "colour": "#33B2FF"
        },
        "not started": {
            "colour": "#FFDD33"
        },
        "overdue": {
            "colour": "#FF3339"
        }
    }

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('TaskStatusButton props=', props)

    if (props.status)  {
        const config = statusConfig[props.status.toLowerCase()]

        return(
            <Button variant="contained" disableElevation disableRipple
                    style={{backgroundColor: props.overdue ? statusConfig.overdue.colour : (config ? config.colour : "black") , width: '120px', height:"16px", borderRadius: '8px'}}>
                {props.status}
            </Button>
        )
    } else {
        return(<span/>)
    }
}

TaskStatusButton.propTypes = {
    status: PropTypes.string.isRequired,
    overdue: PropTypes.bool.isRequired
};


const INITIAL_STATE = {
    tasks: [],
};

class FolderTasks extends React.Component {

    constructor(props) {

        super(props);

        this.completeFolderTask = this.completeFolderTask.bind(this);

        INITIAL_STATE.columns = [
            // {
            //     title: '',
            //     field: 'id',
            //     filtering: false,
            //     sorting: false,
            //     width: 48,
            //     cellStyle: {
            //         width: 48,
            //         maxWidth: 48,
            //         padding: 0,
            //     },
            //     headerStyle: {
            //         width: 48,
            //         maxWidth: 48,
            //         padding: 0,},
            //     editComponent: rowData => {return (<span/>)},
            //     render: rowData => {return (<div><IconButton color="inherit"><i className='material-icons'>assignment</i></IconButton></div>)}
            // },
            {
                title: 'Task',
                field: 'message',
                width:300,
                editable: "never",
                cellStyle: {
                    width: 300,
                    maxWidth: 300,
                    padding: 0,
                    //paddingLeft: "8px"
                    // marginRight: 40
                },
                headerStyle: {
                    width: 300,
                    maxWidth: 300,
                    padding: 0,
                    // marginRight: 40
                },
            },
            {
                title: '',
                field: 'id',
                filtering: false,
                sorting: false,
                cellStyle: {padding: 0,},
                headerStyle: {padding: 0},
                disableClick: true,
                editable: "never",
                editComponent: rowData => {return (<span/>)},
                render: rowData => {return (
                    rowData.overdue ?
                        <Tooltip title={"Overdue"}><i className="material-icons" style={{color: "#FF3339"}}>alarm</i></Tooltip> :
                        <span/>
                )}
            },
            { title: 'Due date', field: 'due_at', type: 'date', editable: "never" },
            {
                title: 'Assignee', //TODO Get from task config
                field: 'assignee',
                lookup: {
                    // "3923718504": "dee.marsh@datumsolutions.net" ,
                    // "3923727458": "andrew.gaskin@datumsolutions.net"
                }
                // editComponent: props => {
                //     const value = props.value;
                //     return (
                //         <TextField
                //             classes={{root: classes.rootInput, disabled: classes.disabled}}
                //             id="assignee"
                //             name="assignee"
                //             value={value}
                //             placeholder={assigneeConfig.label + " email"}
                //             type="Select"
                //             margin="none"
                //             variant="outlined"
                //             autoComplete=""
                //             onChange={e => props.onChange(e.target.value)}
                //             style={{paddingTop: 16}}
                //             autoFocus = {false}
                //             //disabled = {this.state.isProcessing}
                //             select
                //             native={true}
                //             helperText=""
                //         >
                //             {this.state.assigneeOptions.length > 0 && <MenuItem value="">{'- ' + assigneeConfig.label + ' -'}</MenuItem>}
                //             {this.state.assigneeOptions.map(opt => {
                //                 return (
                //                     <MenuItem id={opt.value} value={opt} key={opt.value}>{opt.label}</MenuItem>
                //                 )
                //             })}
                //         </TextField>
                //     )
                //
                // }
            },
            {
                title: 'Status',
                field: 'id',
                filtering: false,
                editable: "never",
                sorting: false,
                cellStyle: {padding: 0,},
                headerStyle: {padding: 0},
                disableClick: true,
                editComponent: rowData => {return (<span/>)},
                render: rowData => {return (<TaskStatusButton status={rowData.statusDisp} overdue={rowData.overdue}/>)}
            },
            {
                title: 'Actions',
                field: 'id',
                filtering: false,
                // editable: "never",
                sorting: false,
                cellStyle: {padding: 0,},
                headerStyle: {padding: 0},
                // disableClick: true,
                // readOnly: true,
                editComponent: rowData => {return (<span/>)},
                render: rowData => {return (<CompleteTaskButton userDetails={props.userDetails} classes={props.classes} completeFolderTask={this.completeFolderTask} task={rowData}/>)}
            }]

        this.state = INITIAL_STATE;

    }

    UNSAFE_componentWillMount() {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('FolderTasks props=', this.props);
        if (this.props.boxFolderID !== 0) {
            this.getFolderTasks();
        }

        const actionConfig = this.props.folderTasksConfig;
        const assigneeConfig = actionConfig.assignee;

        //const assigneeUseExternalOptions = assigneeConfig && assigneeConfig.optionsExternal && Object.entries(assigneeConfig.optionsExternal).length > 0

        getOptionsExternal(assigneeConfig && assigneeConfig.optionsExternal, this.props.userDetails, this.props.triggerRefreshAuthToken)
            .then(response => {

                //console.log ('*** getOptionsExternal response = ', response)

                let assigneeOptions = {};
                let assigneeOptionsByEmail = {};
                response.forEach(opt => {
                    assigneeOptions[opt.user && opt.user.boxUserId] = opt.label
                    assigneeOptionsByEmail[opt.label] = opt.user && opt.user.boxUserId
                })

                // console.log ('*** assigneeOptions = ', assigneeOptions)
                // console.log ('*** columns = ', this.state.columns)
                // console.log ('*** ========= ', )

                this.setState({
                    assigneeOptions: assigneeOptions,
                    assigneeOptionsByEmail: assigneeOptionsByEmail,
                    initAssigneeOptionsDone: true
                })

                //Set lookup value on assignee column
                //console.log ('*** set lookup value on assignee column')
                let columns = this.state.columns;
                //console.log ('*** cols = ', columns)
                for (let i = 0; i < columns.length; i++) {
                    let col = columns[i];
                    //console.log ('*** *** col = ', col)
                    if (col.field === "assignee" ) {
                        //console.log ('*** assignee col = ', col , 'set lookup....')
                        col.lookup = assigneeOptions
                        //col.lookup = assigneeOptionsByEmail
                        //console.log ('*** assignee col updated = ', col , 'lookup should be set now')
                    }
                }
                //console.log ('*** columns = ', columns)
                this.setState({columns: columns});
                //console.log ('*** state.columns = ', this.state.columns)

            })
    }

    componentDidMount(){

       // window.location.pathname.toLowerCase().includes("debug") && console.log('FolderTasks componentDidMount boxFolderID:', this.props.boxFolderID);

        //auto-refresh comments at interval set in config
        this.interval = setInterval(() => this.getFolderTasks(), this.props.folderTasksConfig.pollingIntervalMilliseconds);

    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    getFolderTasks = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        this.setState({isFetching: true});

        await this.props.triggerRefreshAuthToken();

        const pathVar = "/" + this.props.boxFolderID ;
        const url = window.REACT_APP_TASK_API_BASE_URL + window.REACT_APP_TASK_API_FOLDER + pathVar;
        const request = {
            headers: {
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
        };

        debug && console.log ("getFolderTasks url = ", url, "request = ", request);

        fetch(url , request )
            .then(response => {
                debug && console.log('getFolderTasks RESPONSE: ', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving folder tasks'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("getFolderTasks error.  url:", url, "request: ", request);
                    return ([])
                }
            })
            .then(result => {
                debug && console.log ('getFolderTasks response.json = ' , result);

                const todayDate = new Date();
                result.forEach(task => {


                    task.statusDisp = task.completed ? "Complete" : "Not started"

                    //need to transform to use box id as value
                    //task.assignee = task.assign_to && task.assign_to.login
                    task.assignee = task.assign_to && task.assign_to.id;

                    if (!task.completed && task.due_at) {
                        const dueDate = new Date(task.due_at)
                        task.overdue = dueDate <= todayDate;
                    }
                })

                this.setState({
                    tasks: result,
                    isFetching: false})

                if (this.props.updateTasksBadge) {
                    debug && console.log ('call updateTasksBadge, result = ' , result);
                    this.props.updateTasksBadge(result)
                }

            })
            .catch(e => {
                this.props.enqueueSnackbar("Error retrieving folder tasks: " + e.toString(), {variant: 'error'});
                debug && console.log("getFolderTasks Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })
    };

    completeFolderTask = async(assignment) => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        debug && console.log (' - Complete Folder Task - ');

        this.setState({isFetching: true});

        //let assignment = this.state.tasks[0].task_assignment_collection.entries[0];
        debug && console.log ("id = ", this.props.userDetails.boxId);

        const queryStr =  "?id=" + this.props.userDetails.boxId;
        const url = window.REACT_APP_TASK_API_BASE_URL + window.REACT_APP_TASK_API_FOLDER_ASSIGNMENT + queryStr;
        await this.props.triggerRefreshAuthToken();
        const request = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
            body: JSON.stringify(assignment)
        };

        debug && console.log ("completeFolderTask url:", url, "BODY=",assignment, "request=", request);

        await fetch(url , request )
            .then(response => {
                debug && console.log('completeFolderTask RESPONSE: ', response);
                if (response.ok) {
                    debug && console.log ("completeFolderTask response status = OK ");
                    this.props.enqueueSnackbar("Task completed successfully", {variant: 'success'});
                    return(response.json())
                } else {
                    debug && console.log ("completeFolderTask response status not OK ");
                    Promise.resolve(getErrorMessageFromResponse(response, 'completing task assignment'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(result => {
                debug && console.log ('result json = ' , result);
                //refresh tasks
                this.getFolderTasks();
            })
            .catch(e => {
                this.props.enqueueSnackbar("Error retrieving tasks: " + e.toString(), {variant: 'error'});
                debug && console.log("completeFolderTask Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })
    };

    render() {
        
        const classes = this.props.classes;

        return (

            <div style={{width: "100%"}}>
                <Grid container spacing={0} style={{overflowY:'auto'}}>
                    <Grid item xs={12}
                        className={this.props.displayInTab && classes.tasksInTab}
                    >
                        <Paper className={this.props.displayInOwnTab ? classes.tasksInTab : classes.paper}>

                            <Typography variant={"h6"} color={"textPrimary"} style={{paddingLeft: "12px", paddingTop: "0px"}}>
                                {this.state.tasks.length > 0 ? "Tasks (" + this.state.tasks.length + ")" : "Tasks"}
                                <Tooltip title={"Refresh tasks"}>
                                    <IconButton
                                        aria-label="Menu"
                                        aria-haspopup="true"
                                        onClick = {() => this.getFolderTasks()}
                                        color = "secondary"
                                    >
                                        <i className='material-icons md-36'>refresh</i>
                                    </IconButton>
                                </Tooltip>
                                <AddFolderTaskButton
                                    parentClasses={classes}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    parentFolderId={this.props.boxFolderID}
                                    triggerRefreshAuthToken = {this.props.triggerRefreshAuthToken}
                                    getFolderTasks={this.getFolderTasks}
                                    folderTasksConfig={this.props.folderTasksConfig}
                                    folderDetails={this.props.folderDetails}
                                />
                            </Typography>

                            <div style={{width: "100%"}}>

                                <List>
                                    {
                                        this.state.isFetching ?
                                            <React.Fragment>
                                                <div>
                                                    <Typography variant="subtitle1">
                                                        <ListSubheader disableSticky>
                                                            Retrieving tasks...
                                                        </ListSubheader>
                                                    </Typography>
                                                </div>
                                            </React.Fragment> :

                                            this.state.tasks && this.state.tasks.length > 0 ?
                                                <ThemeProvider theme={tableTheme}>

                                                    <MaterialTable
                                                        title={""}
                                                        columns={this.state.columns}
                                                        data={this.state.tasks}
                                                        options={{
                                                            pageSize: 200,
                                                            pageSizeOptions: [],
                                                            toolbar: false,
                                                            paging: false,
                                                            actionsColumnIndex: 4
                                                        }}
                                                        // cellEditable={{
                                                        //     onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
                                                        //         new Promise(async (resolve, reject) => {
                                                        //
                                                        //             const debug = window.location.pathname.toLowerCase().includes("debug");
                                                        //
                                                        //             debug && console.log('edit task newData=', newValue);
                                                        //
                                                        //             let body = {
                                                        //                 action: "complete",
                                                        //                 message: rowData.message,
                                                        //                 assign_to: {
                                                        //                     id: newValue,
                                                        //                     type: "user",
                                                        //                     login: this.state.assigneeOptions[newValue]
                                                        //                 }
                                                        //             };
                                                        //             debug && console.log((new Date()).toLocaleTimeString() + ' body= ', body);
                                                        //
                                                        //             await this.props.triggerRefreshAuthToken();
                                                        //
                                                        //             const pathVar = "/" + rowData.id;
                                                        //             const url = window.REACT_APP_TASK_API_BASE_URL + "/api/elastic/tasks" + pathVar  ;
                                                        //             const request = {
                                                        //                 method: 'PUT',
                                                        //                 headers: {
                                                        //                     "Content-Type": "application/json",
                                                        //                     "case-token": this.props.userDetails.caseAccessToken,
                                                        //                     "Authorization": "Bearer " + this.props.userDetails.accessToken
                                                        //                 },
                                                        //                 body: JSON.stringify(body)
                                                        //             };
                                                        //
                                                        //             debug && console.log((new Date()).toLocaleTimeString() + ' edit task ', 'url=', url, 'BODY=', body, 'request=', request);
                                                        //             this.setState({isFetching: true});
                                                        //
                                                        //             fetch(url, request)
                                                        //                 .then(response => {
                                                        //                     if (response.ok) {
                                                        //                         debug && console.log((new Date()).toLocaleTimeString() + ' response=', response);
                                                        //                         this.props.enqueueSnackbar("Task successfully updated.", {variant: 'success'});
                                                        //                         this.setState({isFetching: false});
                                                        //                         //TODO
                                                        //
                                                        //                         const data = [...this.state.tasks];
                                                        //                         console.log ('*** data=', data);
                                                        //                         console.log ('*** data.indexOf(rowData)=', data.indexOf(rowData))
                                                        //                         console.log ('*** rowData=', rowData);
                                                        //
                                                        //                         let newData = JSON.parse(JSON.stringify(rowData));
                                                        //                         newData.assignee = newValue;
                                                        //                         newData.assign_to = {
                                                        //                             id: newValue,
                                                        //                             type: "user",
                                                        //                             login: this.state.assigneeOptions[newValue]
                                                        //                         }
                                                        //                         console.log ('*** newData=', newData);
                                                        //                         data[data.indexOf(rowData)] = newData;
                                                        //                         console.log ('*** data=', newData);
                                                        //
                                                        //                         //TODO Not resolving correctly - use row editing for now...
                                                        //                         this.setState({...this.state, tasks: data});
                                                        //                         resolve();
                                                        //
                                                        //                     } else {
                                                        //                         debug && console.log((new Date()).toLocaleTimeString() + ' response=', response);
                                                        //                         Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                                                        //                             .then(message => {
                                                        //                                 this.props.enqueueSnackbar(message, {variant: 'error'});
                                                        //                                 console.log('updateTask error.  url:', url, 'request: ', request);
                                                        //                                 this.setState({isFetching: false});
                                                        //                                 reject();
                                                        //                             })
                                                        //                     }
                                                        //                 })
                                                        //         }),
                                                        //         // return new Promise((resolve, reject) => {
                                                        //         //     console.log('newValue: ' + newValue);
                                                        //         //     setTimeout(resolve, 1000);
                                                        //         // });
                                                        //
                                                        //
                                                        // }}
                                                        editable={{
                                                            isEditable: rowData => !rowData.completed,
                                                            onRowUpdate: (newData, oldData) =>
                                                                new Promise(async (resolve, reject) => {

                                                                    const debug = window.location.pathname.toLowerCase().includes("debug");

                                                                    debug && console.log('edit task newData=', newData);

                                                                    let body = {
                                                                        action: "complete",
                                                                        message: newData.message,
                                                                        assign_to: {
                                                                            id: newData.assignee,
                                                                            type: "user",
                                                                            login: this.state.assigneeOptions[newData.assignee]
                                                                        }
                                                                    };
                                                                    debug && console.log((new Date()).toLocaleTimeString() + ' body= ', body);

                                                                    await this.props.triggerRefreshAuthToken();

                                                                    const pathVar = "/" + newData.id;
                                                                    const url = window.REACT_APP_TASK_API_BASE_URL + "/api/elastic/tasks" + pathVar  ;
                                                                    const request = {
                                                                        method: 'PUT',
                                                                        headers: {
                                                                            "Content-Type": "application/json",
                                                                            "case-token": this.props.userDetails.caseAccessToken,
                                                                            "Authorization": "Bearer " + this.props.userDetails.accessToken
                                                                        },
                                                                        body: JSON.stringify(body)
                                                                    };

                                                                    debug && console.log((new Date()).toLocaleTimeString() + ' edit task ', 'url=', url, 'BODY=', body, 'request=', request);
                                                                    this.setState({isFetching: true});

                                                                    fetch(url, request)
                                                                        .then(response => {
                                                                            if (response.ok) {
                                                                                debug && console.log((new Date()).toLocaleTimeString() + ' response=', response);
                                                                                this.props.enqueueSnackbar("Task successfully updated.", {variant: 'success'});
                                                                                this.setState({isFetching: false});
                                                                                const data = [...this.state.tasks];
                                                                                data[data.indexOf(oldData)] = newData;
                                                                                this.setState({...this.state, tasks: data});
                                                                                resolve();

                                                                            } else {
                                                                                debug && console.log((new Date()).toLocaleTimeString() + ' response=', response);
                                                                                Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                                                                                    .then(message => {
                                                                                        this.props.enqueueSnackbar(message, {variant: 'error'});
                                                                                        console.log('updateTask error.  url:', url, 'request: ', request);
                                                                                        this.setState({isFetching: false});
                                                                                        reject();
                                                                                    })
                                                                            }
                                                                        })
                                                                }),
                                                        }}
                                                        localization={{
                                                            body: {
                                                                emptyDataSourceMessage: '',
                                                                editTooltip: 'Edit'
                                                            },
                                                            toolbar: {
                                                                searchTooltip: 'Filter',
                                                                searchPlaceholder: 'Filter'
                                                            },
                                                            header:{
                                                                actions: ""
                                                            }
                                                        }}
                                                        detailPanel={
                                                            [
                                                                {
                                                                    tooltip: 'Description',
                                                                    disabled: false,
                                                                    width: 48,
                                                                    cellStyle: {
                                                                        width: '48px',
                                                                        maxWidth: '50px',
                                                                        padding: 0,
                                                                        // marginRight: 40
                                                                    },
                                                                    headerStyle: {
                                                                        width: '48px',
                                                                        maxWidth: '50px',
                                                                        padding: 0,
                                                                        // marginRight: 40
                                                                    },
                                                                    render: rowData => {
                                                                        return (
                                                                            <div style={{height: "80px", paddingLeft: "42px", border: "solid", backgroundColor: "lightgrey", borderColor: "lightgrey"}}>
                                                                                <TaskDescription description={rowData.description}/>
                                                                            </div>
                                                                        )
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    />
                                                </ThemeProvider>
                                                    :
                                                <ListSubheader disableSticky>No Tasks</ListSubheader>
                                    }
                                </List>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

FolderTasks.propTypes = {
    classes: PropTypes.object.isRequired,
    boxFolderID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderTasksConfig: PropTypes.object.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    displayInOwnTab: PropTypes.bool,
    updateTasksBadge: PropTypes.func,
    folderDetails: PropTypes.object
};

export default withSnackbar(withStyles(styles, { withTheme: true })(FolderTasks));