export function getFieldConfig(metadataConfig, templateKey, metadataKey)  {

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('getFieldConfig: metadataConfig = ', metadataConfig, 'templateKey = ', templateKey, 'metadataKey = ', metadataKey);

    //standard values
    //"name", "created_by","created_at", "modified_at","version","shared_link","sequence_id"
    const systemVals = {
        name: {label: "Name", placeholder: "Name", type: "text", disabled:true, fieldType: "Input", elasticField: metadataKey},
        original_name: {label: "Name", placeholder: "Name", type: "text", disabled:true, fieldType: "Input", elasticField: metadataKey},
        created_by: {label: "Created by", placeholder: "Created by", type: "text", disabled:true, fieldType: "Input", elasticField: metadataKey},
        created_at: { label: "Created", placeholder: "Created", type: "date", disabled:true, fieldType: "Date", elasticField: metadataKey},
        modified_at: {label: "Modified", placeholder: "Modified", type: "date", disabled:true, fieldType: "Date", elasticField: metadataKey},
        version: {label: "Version", placeholder: "Version", type: "text", disabled:true, fieldType: "Input", elasticField: metadataKey},
        size: {label: "Size", placeholder: "Size", type: "text", disabled:true, fieldType: "Input", elasticField: metadataKey},
    }

    if (templateKey === "n/a" && systemVals[metadataKey]) {
            return (systemVals[metadataKey])
    } else if (metadataConfig[templateKey]) {
        if (metadataConfig[templateKey].metadata) {
            if (metadataConfig[templateKey].metadata[metadataKey]) {
                return (metadataConfig[templateKey].metadata[metadataKey])
            } else {
                return {}
            }
        } else {
            return{}
        }
    } else {
        return {}
    }
}

export function getCaseField(metadataConfig, templateKey, metadataKey)  {

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('getFieldConfig: metadataConfig = ', metadataConfig, 'templateKey = ', templateKey, 'metadataKey = ', metadataKey);

    const fieldConfig = getFieldConfig(metadataConfig, templateKey, metadataKey);
    if (fieldConfig && fieldConfig.elasticField) {
        return (fieldConfig.elasticField)
    } else {
        return ""
    }

}

export function getOptions(optionsConfig, templateKey, metadataKey, dependencies, formValues, usage) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    //debug && console.log('getOptions templateKey = ', templateKey, 'metadataKey = ', metadataKey, 'dependencies = ', dependencies, 'formValues = ', formValues, 'usage = ', usage);

    let options = [];

    //window.location.pathname.toLowerCase().includes("debug") && console.log('optionsConfig = ', optionsConfig);
    //let config = optionsConfig[templateKey][metadataKey];
    let config = {};
    if (optionsConfig) {
        if (optionsConfig[templateKey]) {
            config = optionsConfig[templateKey][metadataKey]
        } else {
            debug && console.log('Unable to find config for templateKey ' + templateKey);
        }
    } else {
        debug && console.log('optionsConfig not available ');
    }
    //debug && console.log('options config for ' + templateKey + ' - ' + metadataKey + ' = ', config);

    if (config) {
        //Loop through dependencies
       // window.location.pathname.toLowerCase().includes("debug") && console.log('loop through dependencies.  Dependencies = ', dependencies);
        for (let i = 0; i < dependencies.length; i++) {
            //debug && console.log ('i = ', i, 'dependency = ' , dependencies[i], ' config = ', config);
            //get field values for each dependency field
            dependencies[i].value = formValues[dependencies[i].templateKey + "~" + dependencies[i].metadataKey];
            if(dependencies[i].optional && (!dependencies[i].value || dependencies[i].value === "")) {
                dependencies[i].value = ""
            }
            //check if a value has been entered
            if (dependencies[i].value || (dependencies[i].optional && dependencies[i].value === "")) {
                if (config[dependencies[i].templateKey]) {
                    config = config[dependencies[i].templateKey];
                    if (config[dependencies[i].metadataKey]) {
                        config = config[dependencies[i].metadataKey];
                        if (config[dependencies[i].value]) {
                            config = config[(dependencies[i].value)];
                        } else {
                            //check if generic options available
                            if (config[""]) {
                                config = config[""];
                            } else {
                                debug && console.log('Config not found for "" (generic options):', (dependencies[i].value), 'config = ', config)
                                break;
                            }
                        }
                    } else {
                        debug && console.log('Config not found for metadataKey:', dependencies[i].metadataKey, 'config = ', config)
                        break;
                    }
                } else {
                    debug && console.log('Config not found for templateKey: ', dependencies[i].templateKey, 'config = ', config)
                    break;
                }
            } else {
                debug && console.log('Missing value for this dependency - EXIT ', dependencies[i])
                break;
            }
        }
    }

    //config should now be returning an array
   // window.location.pathname.toLowerCase().includes("debug") && console.log ('config = ', config);
    if (Array.isArray(config)) {
        options = config
    } else {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('No options available')
        options = [];
    }

   // window.location.pathname.toLowerCase().includes("debug") && console.log ('options = ', options)

    //Refine options based on usage - search / edit / upload

    let show = "";
    switch (usage) {
        case "edit":
            show = "showForEdit"
            break;
        case "search":
            show = "showForSearch"
            break;
        case "upload" :
            show = "showForUpload";
            break;
        default:
            show="showForSearch"
    }

    let finalOptions = [];
    for (let i = 0; i < options.length; i++) {
        if (options[i][show]) {
            finalOptions.push(options[i])
        }
    }
   // window.location.pathname.toLowerCase().includes("debug") && console.log (show, 'finalOptions = ', finalOptions)

    return finalOptions;
}

export async function getOptionsExternalWrapper (optionsExternalConfig, userDetails, triggerRefreshAuthToken) {
    //wrapper to allow us to use use getOptions external from getOptions
    return await getOptionsExternal(optionsExternalConfig, userDetails, triggerRefreshAuthToken)

}

export async function getOptionsExternal(optionsExternalConfig, userDetails, triggerRefreshAuthToken) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    debug && console.log ("getOptionsExternal optionsExternalConfig = ", optionsExternalConfig)

    //e.g. get okta group members
    //window.location.pathname.toLowerCase().includes("debug") && console.log('optionsExternalConfig =', optionsExternalConfig);
    let options = [];

    if (optionsExternalConfig && optionsExternalConfig.source && optionsExternalConfig.source === "group") {
        console.log('*** ...now call getOktaGroupMembers....')
        options = await getOktaGroupMembers(optionsExternalConfig.group, userDetails, triggerRefreshAuthToken)
        return options
    } else {
        console.log ('*** returning empty options')
        return options;
    }
}

export async function getOktaGroupMembers(group, userDetails, triggerRefreshAuthToken) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    //get okta group members

    const params =  "?name=" + group;
    const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/group/members" + params;
    const request = {
        method: 'GET',
        headers: {"Authorization": "Bearer " + userDetails.accessToken},
    }

    debug && console.log('getOktaGroupMembers url=', url, 'request=', request);

    await triggerRefreshAuthToken();
    let error = false;
    let members = [];

    await fetch(url, request)
        .then(response => {
            debug && console.log('getOktaGroupMembers response=', response);
            error = !response.ok
            return response.json()
        })
        .then(data => {

            debug && console.log('getOktaGroupMembers response.json = ', data)

            if (error) {
                console.log ('*** ERROR ***', error, 'response.json = ' , data);
                members = [];
            } else {
                data.forEach(item => {
                    members.push({
                        label: item.login,
                        value: item.login,
                        user: item //add user object so we can get other properties if required, e.g. boxUserId
                    })
                });
            }

        })
        .catch(e => {
            debug && console.log("getOktaGroupMembers  exception:" , e);
        })

    debug && console.log('getOktaGroupMembers return members ', members);
    members.sort( dynamicSort("label") );
    debug && console.log('sorted members = ', members);
    return members;
}

export async function getOktaUsers( userDetails, triggerRefreshAuthToken, filterDomains) {

    //get all Okta users

    const debug = window.location.pathname.toLowerCase().includes("debug");

    const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/users";
    const request = {
        method: 'GET',
        headers: {"Authorization": "Bearer " + userDetails.accessToken},
    }

    debug && console.log('getOktaUsers url=', url, 'request=', request);

    await triggerRefreshAuthToken();
    let error = false;
    let users = [];

    await fetch(url, request)
        .then(response => {
            debug && console.log('getOktaUsers response=', response);
            error = !response.ok
            return response.json()
        })
        .then(data => {

            debug && console.log('getOktaUsers response.json = ', data)

            if (error) {
                console.log ('ERROR', error, 'response.json = ' , data);
                users = [];
            } else {
                let filteredData = [];
                if (filterDomains && Array.isArray(filterDomains) && filterDomains.length > 0){
                    filterDomains.forEach(domain => {
                        data.forEach(item => {
                            if (item.login.endsWith(domain)) {
                                filteredData.push(item)
                            }
                        })
                    })
                } else {
                    filteredData = data
                }
                filteredData.forEach(item => {
                    users.push({
                        label: item.login,
                        value: item.login,
                        user: item //add user object so we can get other properties if required, e.g. boxUserId
                    })
                });
            }

        })
        .catch(e => {
            debug && console.log("getOktaUsers  exception:" , e);
        })

    debug && console.log('getOktaUsers return users ', users);
    users.sort( dynamicSort("label") );
    debug && console.log('sorted users = ', users);
    return users;
}

export async function getOktaGroups(userDetails, triggerRefreshAuthToken, regexFilter, getDesc) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    //get okta groups
    const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/group?regexFilter=" + encodeURIComponent(regexFilter) + (getDesc ? '&desc=true' : '');
    const request = {
        method: 'GET',
        headers: {"Authorization": "Bearer " + userDetails.accessToken},
    }

    debug && console.log('getOktaGroup url=', url, 'request=', request);

    await triggerRefreshAuthToken();
    let error = false;
    let groups = [];

    await fetch(url, request)
        .then(response => {
            debug && console.log('getOktaGroups response=', response);
            error = !response.ok
            return response.json()
        })
        .then(data => {

            debug && console.log('getOktaGroups response.json = ', data)

            if (error) {
                console.log ('*** ERROR ***', error, 'response.json = ' , data);
                groups = [];
            } else {
                data.forEach(item => {
                    //groups.push(item.name)
                    let group = {
                        label: item.name,
                        value: item.name,
                        //id: item.id //for reference
                    }
                    if (getDesc) {
                        group.description = item.description
                    }
                    groups.push(group)
                });
            }

        })
        .catch(e => {
            debug && console.log("getOktaGroups  exception:" , e);
        })

    debug && console.log('getOktaGroups return groups ', groups);
    //groups.sort( dynamicSort("label") );
    //debug && console.log('sorted members = ', members);
    return groups;
}

export async function checkGroupExists(userDetails, triggerRefreshAuthToken, groupName, enqueueSnackbar) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    //get okta groups
    const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/group";
    const request = {
        method: 'GET',
        headers: {"Authorization": "Bearer " + userDetails.accessToken},
    }

    debug && console.log('getOktaGroups url=', url, 'request=', request);

    await triggerRefreshAuthToken();

    let groupExists = false;

    await fetch(url, request)
        .then(response => {
            debug && console.log('getOktaGroups response=', response);
            if (response.ok) {
                return(response.json())
            } else {
                Promise.resolve(getErrorMessageFromResponse(response, 'getting groups'))
                    .then(message => {
                        //console.log (message)
                        enqueueSnackbar(message, {variant: 'error'});
                    })
                return null
            }
        })
        .then(data => {
            debug && console.log('checkGroupExists response.json = ', data)
            if (data) {
                data.forEach(item => {
                    if (item.name === groupName) {
                        groupExists = true
                    }
                });
            }
        })
        .catch(e => {
            debug && console.log("checkGroupExists  exception:" , e);
        })

    debug && console.log('checkGroupExists return groups ', groupExists);
    return groupExists;
}

export function getObjectByKey(nameKey, val, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i][nameKey] === val) {
            return myArray[i];
        }
    }
}

export function hasAccess(actionConfig, userRoles) {
    //check if any of user's roles are listed in config
    let hasAccess = false;
    if (userRoles && actionConfig && actionConfig.roles) {
        actionConfig.roles.forEach(role => {
                if (userRoles.includes(role)) {
                    hasAccess = true;
                } else if (role.endsWith('*')) {
                    //if role ends with *, give access to any roles that match
                    const roleToTest = (role).substring(0,role.lastIndexOf('*'))
                    const filteredUserRoles = userRoles.filter(r => r.startsWith(roleToTest));
                    if (filteredUserRoles.length > 0){
                        hasAccess = true
                    }
                }
            }
        )
    }
    return hasAccess;
}

export function hasRole(roles, userRoles) {

    //check if any of userRoles[] listed in roles[]

    let hasRole = false;
    roles.forEach(role => {
        if (userRoles.includes(role)) {
            hasRole = true;
        }
    })

    return hasRole;
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//async/await with forEach() - see https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
//generic asyncForEach function - pass array and function to execute as parameters
export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export function formatDate(date, format) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    if (format) {
        switch (format) {
            case "dd-mm-yyyy": {
                return [day, month, year].join('-')
            }
            default:
                console.log ('formatDate return default');
                return [year, month, day].join('-');
        }
    } else {
        return [year, month, day].join('-');
    }
}

export function dynamicSort(property) {

    //Sorts an array of objectes by the property name specified in the property parameter

    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers*/
        var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
        return result * sortOrder;
    }
}

export function validateEmail(email) {
    //check email is in the format 'anystring@anystring.anystring'
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function getInitials(name) {

    let initials = "";

    if (name) {
        initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    }

    return initials
}

export function getColour(name) {

    const saturation = 30;
    const lightness = 60;
    let hash = 0;

    if (name) {
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
    }

    let h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';

}

export function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export async function fetch_retry (url, options, n) {
    const debug = window.location.pathname.toLowerCase().includes("debug");
    for (let i = 0; i < n; i++) {
        const isLastAttempt = i + 1 === n;
        const attemptNum = i+1
        try {
            debug && i > 0 && console.log ('retry' , i);
            let response =  await fetch(url, options);
            debug && console.log ('response on attempt ' + attemptNum + ' = ', response)
            if (response.ok) {
                return response
            } else {
                debug && console.log("Error on attempt ", attemptNum, " of " , n);
                if (isLastAttempt) {
                    return response
                } else {
                    //throw ("error");
                    throw new Error("error")
                }
            }
        } catch (err) {
            if (isLastAttempt) throw err;
        }
    }
}

export function getErrorMessageFromResponse (response, requestDescription) {

    //const debug = window.location.pathname.toLowerCase().includes("debug");
    console.log ('getErrorMessageFromResponse response=', response, 'requestDescription=' , requestDescription)

    //requestDescription e.g. 'uploading file'
    let desc = requestDescription && requestDescription !== "" ?
        ("Error " + requestDescription + ": Error " + (response.status ? response.status : "")) :
        ((response.status ? response.status : ""));


    // if (response.status === 401) {
    //     desc = desc + ".  Please log in again."
    // }

    //return error details from response
    let errorMessage = "";
    const contentType = response.headers && response.headers.get("content-type");
    const hasJSON = contentType && contentType.indexOf("application/json") !== -1;
    const hasText = contentType && contentType.indexOf("text/plain") !== -1;

    if (hasJSON) {
        return (response.json()).then(responseJSON => {
            console.log('responseJSON = ', responseJSON);
            errorMessage = desc + (
                responseJSON.message ? (" " + responseJSON.message) :
                    responseJSON.error ? (" " + responseJSON.error) :
                        responseJSON.statusText ? (" " + responseJSON.statusText) : "");
            if (response.status === 401) {
                errorMessage += ".  Please log in again."
            }
            console.log('getErrorMessageFromResponse, response = ', response, 'return errorMessage: ', errorMessage);
            return (errorMessage)
        })
    } else if (hasText) {
        return(response.text()).then(responseText => {
            console.log ('responseText = ', responseText);
            errorMessage = desc + (
                responseText ? (" " + responseText) : "");
            if (response.status === 401) {
                errorMessage += ".  Please log in again."
            }
            console.log('getErrorMessageFromResponse, response = ', response, 'return errorMessage: ', errorMessage);
            return (errorMessage)
        })
    } else {
        //no json in response
        errorMessage = desc + (
            response.message ? " " + response.message :
                response.error ? " " + response.error :
                    response.statusText ? " " + response.statusText :
                        "");

        if (response.status === 401) {
            errorMessage += ".  Please log in again."
        }
        return (errorMessage)
    }
}

export async function getErrorMessageFromResponseAsync (response, requestDescription) {

    //const debug = window.location.pathname.toLowerCase().includes("debug");
    console.log ('getErrorMessageFromResponse response=', response, 'requestDescription=' , requestDescription)

    //requestDescription e.g. 'uploading file'
    let desc = requestDescription && requestDescription !== "" ?
        ("Error " + requestDescription + ": Error " + (response.status ? response.status : "")) :
        ((response.status ? response.status : ""));


    if (response.status === 401) {
        desc = desc + ".  Please log in again."
    }

    //return error details from response
    let errorMessage = "";
    const contentType = response.headers && response.headers.get("content-type");
    const hasJSON = contentType && contentType.indexOf("application/json") !== -1;
    const hasText = contentType && contentType.indexOf("text/plain") !== -1;

    if (hasJSON) {
        return (response.json()).then(responseJSON => {
            console.log('responseJSON = ', responseJSON);
            errorMessage = desc + (
                responseJSON.message ? (" " + responseJSON.message) :
                    responseJSON.error ? (" " + responseJSON.error) :
                        responseJSON.statusText ? (" " + responseJSON.statusText) : "");
            if (response.status === 401) {
                errorMessage += ".  Please log in again."
            }
            console.log('getErrorMessageFromResponse, response = ', response, 'return errorMessage: ', errorMessage);
            return (errorMessage)
        })
    } else if (hasText) {
        return(response.text()).then(responseText => {
            console.log ('responseText = ', responseText);
            errorMessage = desc + (
                responseText ? (" " + responseText) : "");
            if (response.status === 401) {
                errorMessage += ".  Please log in again."
            }
            console.log('getErrorMessageFromResponse, response = ', response, 'return errorMessage: ', errorMessage);
            return (errorMessage)
        })
    } else {
        //no json in response
        errorMessage = desc + (
            response.message ? " " + response.message :
                response.error ? " " + response.error :
                    response.statusText ? " " + response.statusText :
                        response.text() ? " " + response.text() :
                            "");
        if (response.status === 401) {
            errorMessage += ".  Please log in again."
        }
        return (errorMessage)
    }
}

export function getYearOptions(previousYears, futureYears) {

    const maxYear = new Date().getFullYear() + (futureYears ? futureYears : 0)
    const minYear = new Date().getFullYear() - (previousYears ? previousYears : 25)
    let years = []

    for (var i = maxYear; i >= minYear; i--) {
        years.push({
            label: i.toString(),
            value: i
        })
    }
    return years
}

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
export function formatBytes(bytes) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const decimals = 0;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`
}

export function removeItemFromArray(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray(myArray, chunk_size) {
    let results = [];

    while (myArray.length) {
        results.push(myArray.splice(0, chunk_size))
    }

    return results;
}

export async function mockFetch(stubFilename) {

    const resultsStub =  require('../../resources/stubs/' + stubFilename)
    const fileType = stubFilename.substring(stubFilename.lastIndexOf(".")+1); //e.g. json / blob

    console.log ('resultsStub = ', resultsStub)
    console.log ('fileType = ', fileType)

    let response = {}
    if (fileType === 'json') {
        response = {
            ok: true,
            status: 200,
            json: async () => (resultsStub)
        }
    } else if (fileType === 'pdf') {
        response = new Request(stubFilename);
    }

    console.log ('mockFetch return ', response)
    return response
}

