import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import MoveDocumentToCaseDialog from "./MoveDocumentToCaseDialog";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false
};

class MoveDocumentToCaseButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = () => {
        this.setState({
            showDialog: false
        });

    };

    render() {

        const { anchorEl } = this.state;

        return (
            <div>
                <Tooltip title={this.props.actionConfig.label}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClickIcon}
                    >
                        <i className='material-icons'>
                            swap_horiz
                        </i>
                    </IconButton>
                </Tooltip>

                {
                    (this.state.showDialog) &&
                    <MoveDocumentToCaseDialog
                        boxDocID={this.props.boxDocID}
                        rowData={this.props.rowData}
                        folderDetails = {this.props.folderDetails}
                        actionConfig={this.props.actionConfig}
                        handleCloseDialog={this.handleCloseDialog}
                        userDetails={this.props.userDetails}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                    />
                }

            </div>
        );
    }
}

MoveDocumentToCaseButton.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default withStyles(styles)(MoveDocumentToCaseButton);