export function getTheme() {

    //theme imported via script tag in index.html
    let theme;
    if(window.theme.theme.default) {
        if(window.localStorage.getItem('theme') && window.localStorage.getItem('theme') !== "") {
            if(window.theme.theme[window.localStorage.getItem('theme')]){
                theme = window.theme.theme[window.localStorage.getItem('theme')];
            } else {
                theme = window.theme.theme.default;
            }
        } else {
            theme = window.theme.theme.default;
        }
    } else {
        theme = window.theme.theme;
    }
    return  theme
}

export function prepareTheme(theme) {
    return (
        {
            drawerWidth: theme.drawerWidth,

            drawerBackgroundColour: theme.drawerBackgroundColour ? theme.drawerBackgroundColour : "#FFFFFF",

            appBarLogo: theme.appBarLogo ? theme.appBarLogo : {},

            menuListItem: theme.menuListItem ? theme.menuListItem : {},

            documentMenu: {
                color: theme.documentMenu.colour,
            },

            selectedTab: theme.selectedTab ? theme.selectedTab : {},

            disabledField: theme.disabledField ? theme.disabledField : {},

            fieldLabel: theme.fieldLabel ? theme.fieldLabel : {},

            typography: {
                suppressWarning: true,
                fontSize: 12,
            //fontFamily: theme.fontFamily
            },

            palette: theme.palette,

            //https://material-ui.com/customization/globals/#css
            overrides: {

                // MuiAppBar: {
                //     colorSecondary: {
                //         backgroundColor: theme.appBarColour ? theme.appBarColour : theme.palette.secondary.main,
                //     },
                // },


                MuiCardMedia: {
                    media: {
                        width: theme.logoWidth
                    }
                },

                MuiBadge: {
                    colorPrimary: {
                        backgroundColor: "#43a047"
                    }
                },

                MuiListSubheader: {
                    colorPrimary: {
                        color: theme.palette.secondary.main
                    }
                },

                MuiAvatar: {
                    colorDefault: {
                        backgroundColor: theme.palette.secondary.main
                    }
                },

                MuiButton: {
                    containedSecondary: {
                        backgroundColor: theme.palette.secondary.main
                    }
                },

                MuiTab: {
                    root: {
                        minWidth: "30px",
                            "&:hover": {
                            backgroundColor: "#eee",
                                color: theme.palette.primary.main
                        },
                        "&:active": {
                            backgroundColor: "#eee",
                                color: theme.palette.primary.main
                        },
                        "&:selected": {
                            backgroundColor: "#eee",
                                color: theme.palette.primary.main
                        }
                    },
                    textColorInherit: {
                        "&.Mui-selected": {
                            color: theme.selectedTab.textColour,
                        }
                    },

                },

                MuiTabs: {
                    indicator: {
                        backgroundColor: theme.selectedTab.underlineColour,
                            height: '3px'
                    },
                },

                DropzoneArea: {
                    dropzoneParagraph: {
                        fontSize: "14px",
                            fontFamily: "roboto:"
                    }
                },

                MuiStepIcon: {
                    root: {
                        color: theme.palette.primary.main,
                            '&$active': {
                            color: theme.palette.secondary.main, //theme.selectedTab.underlineColour
                        },
                        '&$completed': {
                            color: theme.palette.primary.main,
                        },
                    },
                },



                //not taking effect in Upload dialog
                MuiInput: {
                    formControl: {
                        "label + &": {
                            marginTop: "0px"
                        }
                    }
                }
            }
        }
    )
}

export function printConfig(configFiles, workspaceConfig) {
    console.log("configFiles.actionsConfig:", configFiles.actionsConfig);
    console.log("configFiles.metadataConfig:", configFiles.metadataConfig);
    console.log("configFiles.optionsConfig:", configFiles.optionsConfig);
    console.log("configFiles.publicLinksConfig:", configFiles.publicLinksConfig);
    console.log("configFiles.dashboardsConfig:", configFiles.dashboardsConfig);
    console.log("configFiles.templatesConfig:", configFiles.templatesConfig);
    console.log("configFiles.workflowConfig:", configFiles.workflowConfig);
    console.log("workspaces:", workspaceConfig);
    console.log ("REACT_APP_ADMIN_APP_URL=", window.REACT_APP_ADMIN_APP_URL);
    console.log ("REACT_APP_APP_BAR_LOGO_LINK_URL=", window.REACT_APP_APP_BAR_LOGO_LINK_URL);
    console.log ("REACT_APP_APP_BAR_LOGO_SHOW=", window.REACT_APP_APP_BAR_LOGO_SHOW);
    console.log ("REACT_APP_APP_TITLE=", window.REACT_APP_APP_TITLE);
    console.log ("REACT_APP_CASE_API_BASE_URL=", window.REACT_APP_CASE_API_BASE_URL);
    console.log ("REACT_APP_CASE_API_SEARCH=", window.REACT_APP_CASE_API_SEARCH);
    console.log ("REACT_APP_SECURITY_API_BASE_URL=", window.REACT_APP_SECURITY_API_BASE_URL);
    console.log ("REACT_APP_CASE_AUTH_REQUIRED=", window.REACT_APP_CASE_AUTH_REQUIRED);
    console.log ("REACT_APP_CASE_AUTH_SECURITY=", window.REACT_APP_CASE_AUTH_SECURITY);
    console.log ("REACT_APP_COMMENT_API_BASE_URL=", window.REACT_APP_COMMENT_API_BASE_URL);
    console.log ("REACT_APP_COMMENT_API_DOCUMENT=", window.REACT_APP_COMMENT_API_DOCUMENT);
    console.log ("REACT_APP_COMMENT_API_FOLDER=", window.REACT_APP_COMMENT_API_FOLDER);
    console.log ("REACT_APP_CONTENT_API_BASE_URL=", window.REACT_APP_CONTENT_API_BASE_URL);
    console.log ("REACT_APP_CONTENT_API_BASE_URL_AI=", window.REACT_APP_CONTENT_API_BASE_URL_AI);
    console.log ("REACT_APP_CONTENT_API_CAPTURE=", window.REACT_APP_CONTENT_API_CAPTURE);
    console.log ("REACT_APP_CONTENT_API_COLLABORATION=", window.REACT_APP_CONTENT_API_COLLABORATION);
    console.log ("REACT_APP_CONTENT_API_DOCUMENT=", window.REACT_APP_CONTENT_API_DOCUMENT);
    console.log ("REACT_APP_CONTENT_API_FOLDER_FOLDER=", window.REACT_APP_CONTENT_API_FOLDER_FOLDER);
    console.log ("REACT_APP_CONTENT_API_FOLDER_SEARCH_ADVANCED=", window.REACT_APP_CONTENT_API_FOLDER_SEARCH_ADVANCED);
    console.log ("REACT_APP_CONTENT_API_FOLDER_SEARCH_SIMPLE=", window.REACT_APP_CONTENT_API_FOLDER_SEARCH_SIMPLE);
    console.log ("REACT_APP_CONTENT_API_SEARCH_ADVANCED=", window.REACT_APP_CONTENT_API_SEARCH_ADVANCED);
    console.log ("REACT_APP_CONTENT_API_SEARCH_LIMIT=", window.REACT_APP_CONTENT_API_SEARCH_LIMIT);
    console.log ("REACT_APP_CONTENT_API_SEARCH_METADATA=", window.REACT_APP_CONTENT_API_SEARCH_METADATA);
    console.log ("REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT=", window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT);
    console.log ("REACT_APP_CONTENT_API_BOX_FOLDER_ITEMS_LIMIT=", window.REACT_APP_CONTENT_API_BOX_FOLDER_ITEMS_LIMIT);
    console.log ("REACT_APP_CONTENT_API_SEARCH_SIMPLE=", window.REACT_APP_CONTENT_API_SEARCH_SIMPLE);
    console.log ("REACT_APP_CONTENT_API_SECURITY_USER_TOKEN=", window.REACT_APP_CONTENT_API_SECURITY_USER_TOKEN);
    console.log ("REACT_APP_DASHBOARD_URL=", window.REACT_APP_DASHBOARD_URL);
    console.log ("REACT_APP_FOLDER_SOURCE=", window.REACT_APP_FOLDER_SOURCE);
    console.log ("REACT_APP_DOCUMENT_SOURCE=", window.REACT_APP_DOCUMENT_SOURCE);
    console.log ("REACT_APP_TASK_SOURCE=", window.REACT_APP_TASK_SOURCE);
    console.log ("REACT_APP_COMMENT_SOURCE=", window.REACT_APP_COMMENT_SOURCE);
    console.log ("REACT_APP_ENTERPRISE_ID=", window.REACT_APP_ENTERPRISE_ID);
    console.log ("REACT_APP_ENV=", window.REACT_APP_ENV);
    console.log ("REACT_APP_FORMS_URL=", window.REACT_APP_FORMS_URL);
    console.log ("REACT_APP_MY_DOCUMENTS_FOLDER", window.REACT_APP_MY_DOCUMENTS_FOLDER);
    console.log ("REACT_APP_OKTA_BASE_URL=", window.REACT_APP_OKTA_BASE_URL);
    console.log ("REACT_APP_OKTA_CASE_ID=", window.REACT_APP_OKTA_CASE_ID);
    console.log ("REACT_APP_OKTA_CLIENT_ID=", window.REACT_APP_OKTA_CLIENT_ID);
    console.log ("REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH=", window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH);
    console.log ("REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH_WITH_DASHBOARD=", window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH_WITH_DASHBOARD);
    console.log ("REACT_APP_TASK_API_BASE_URL=", window.REACT_APP_TASK_API_BASE_URL);
    console.log ("REACT_APP_TASK_API_DOCUMENT_ASSIGNMENT=", window.REACT_APP_TASK_API_DOCUMENT_ASSIGNMENT);
    console.log ("REACT_APP_TASK_API_FOLDER_ASSIGNMENT=", window.REACT_APP_TASK_API_FOLDER_ASSIGNMENT);
    console.log ("REACT_APP_TASK_API_FOLDER=", window.REACT_APP_TASK_API_FOLDER);
    console.log ("REACT_APP_WATSON_APIKEY=", window.REACT_APP_WATSON_APIKEY);
    console.log ("REACT_APP_WATSON_COLLECTIONID=", window.REACT_APP_WATSON_COLLECTIONID);
    console.log ("REACT_APP_WATSON_ENVIRONMENTID=", window.REACT_APP_WATSON_ENVIRONMENTID);
    console.log ("REACT_APP_WATSON_INSTANCEID=", window.REACT_APP_WATSON_INSTANCEID);
    console.log ("REACT_APP_USE_PDF_VIEWER=", window.REACT_APP_USE_PDF_VIEWER);
    console.log ("REACT_APP_SYSTEM_METADATA_TEMPLATE=", window.REACT_APP_SYSTEM_METADATA_TEMPLATE);
    console.log ("REACT_APP_BASE_URL_WORKFLOW=", window.REACT_APP_BASE_URL_WORKFLOW);
    console.log ("REACT_APP_BASE_URL_SCREENING=", window.REACT_APP_BASE_URL_SCREENING);
    console.log ("REACT_APP_ENABLE_TRANSLATION=", window.REACT_APP_ENABLE_TRANSLATION);
    console.log ("REACT_APP_AUTHENTICATE_WITH=", window.REACT_APP_AUTHENTICATE_WITH);
    console.log ("REACT_APP_CASE_TOKEN_REQUIRED=", window.REACT_APP_CASE_TOKEN_REQUIRED);
    console.log ('REACT_APP_COGNITO_IDENTITY_POOL_ID=', window.REACT_APP_COGNITO_IDENTITY_POOL_ID);
    console.log ('REACT_APP_COGNITO_REGION=', window.REACT_APP_COGNITO_REGION);
    console.log ('REACT_APP_COGNITO_USER_POOL_ID=', window.REACT_APP_COGNITO_USER_POOL_ID);
    console.log ('REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID=', window.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID);
    console.log ('REACT_APP_COGNITO_ROLES_ENDPOINT=', window.REACT_APP_COGNITO_ROLES_ENDPOINT)
}
