import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import SubfolderGroupMembersDialog from "./SubfolderGroupMembersDialog";

function SubfolderGroupMembersButton(props) {

    const [showDialog, setShowDialog] = useState(false)

    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = () => {
       setShowDialog(false)
    };

   const titleConfig = props.subfolderGroupMembersConfig && props.subfolderGroupMembersConfig.title;
   let title = ""
   if (titleConfig.templateKey && titleConfig.metadataKey) {
       if (props.folderDetails[titleConfig.templateKey + "~"+ titleConfig.metadataKey]){
           title = props.folderDetails[titleConfig.templateKey + "~"+ titleConfig.metadataKey]
       }
   }

    return (
        <>
            <Tooltip title={ "Show " + (title ? title : "External") + " users"}>
                <IconButton aria-label="Menu" aria-haspopup="true" onClick={handleClickIcon}>
                    <i className='material-icons'>people_alt</i>
                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <SubfolderGroupMembersDialog
                    handleCloseDialog={handleCloseDialog}
                    subfolderGroupMembersConfig={props.subfolderGroupMembersConfig}
                    actionsConfig={props.actionsConfig}
                    folderLabel={props.folderLabel}
                    classes={props.classes}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    userDetails={props.userDetails}
                    folderDetails={props.folderDetails}
                    title={title}
                />
            }

        </>
    );
}

SubfolderGroupMembersButton.propTypes = {
    classes: PropTypes.object,
    subfolderGroupMembersConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderLabel: PropTypes.string.isRequired,
    folderDetails: PropTypes.object.isRequired
};

export default (SubfolderGroupMembersButton);