// noinspection JSCheckFunctionSignatures

import React from 'react';
import 'box-ui-elements/dist/preview.css';
import 'box-ui-elements/dist/explorer.css';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {withStyles} from "@material-ui/core";
import {withSnackbar} from "notistack";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import AuditButton from "../../../audit/AuditButton";
import AddFolderTaskButton from "../../../folder/tasks/AddFolderTaskButton";

const styles = theme => ({
    nestedLevel3: {
        border: "1px solid #bdbdbd",
        borderRadius: "4px",
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
    }
})

function DisplayHighlightText(props) {
    //window.location.pathname.toLowerCase().includes("debug") && console.log ('DisplayHighlightText props = ', props);
    //split on <em> then loop through array, adding typography elements as we go to format text within <em> tags as bold
    //generate array of components to be returned
    let typographyComponents = [];
    if (props.highlightText.indexOf("<em>") > -1 ) {
        let arr = props.highlightText.split ("<em>");
        arr.map(
            (s, index) => {
                if (s.indexOf("</em>") > -1) {
                    typographyComponents.push(
                        <Typography key={"typog" + props.keyVal + "." + index} component="span">
                            <Box key={"box" + props.keyVal + "." + index} display="inline" fontWeight="fontWeightBold" component="span">
                                {s.slice(0, s.indexOf("</em>"))}
                            </Box>
                            {s.substring(s.indexOf("</em>")+5)}
                        </Typography>
                    )
                } else {
                    typographyComponents.push(
                        <Typography key={"typog" + props.keyVal + "." + index} component="span">
                            {s}
                        </Typography>
                    )
                }
                return null;
            })
    } else {
        typographyComponents.push (
            <Typography key = {"typog" + props.keyVal } component="span">
                {props.highlightText }
            </Typography>
        )
    }
    return (
        <div key = {"rf" + props.keyVal}>
            {typographyComponents}
        </div>
    );
}
DisplayHighlightText.propTypes = {
    highlightText: PropTypes.string.isRequired,
    keyVal: PropTypes.string.isRequired
};

class MLSnippet extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            isApproving: false,
            isRejecting: false
        };
        //window.location.pathname.toLowerCase().includes("debug") && console.log('MLSnippet props =', props)
    }

    approve = async (typeIndex, entityIndex, pageIndex, snippetIndex, snippet) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('MLSnippet approve ', "typeIndex:" , typeIndex, "entityIndex:", entityIndex, "pageIndex:", pageIndex, "snippetIndex:", snippetIndex)

        this.setState({isApproving: true})

        let response =  await this.props.actionSnippet(typeIndex, entityIndex, pageIndex, snippetIndex, snippet, 'approve');

        this.setState({isApproving: false})

         if (response) {
            //this.props.autoCollapsePage();
         }
    };

    reject = async (typeIndex, entityIndex, pageIndex, snippetIndex, snippet) => {

        this.setState({isRejecting: true})

        let response =  await this.props.actionSnippet(typeIndex, entityIndex, pageIndex, snippetIndex, snippet, 'reject');

        this.setState({isRejecting: false})

        if (response) {
            //this.props.autoCollapsePage(typeIndex);
        }

    };

    render() {

        const {classes} = this.props;

        const entityIndex = this.props.entityIndex;
        const pageIndex = this.props.pageIndex;
        const page = this.props.page;
        const typeIndex = this.props.typeIndex;
        const snippetIndex = this.props.snippetIndex;
        const snippet = this.props.snippet;
        const approved = snippet.review_status === "VALID MATCH"
        const rejected = snippet.review_status === "FALSE POSITIVE"
        const actioned = approved || rejected
        const enableApproveRejectSnippet = this.props.enableApproveRejectSnippet;
        const disableApprove = actioned || this.state.isApproving || this.state.isRejecting  || (this.props.isApprovingEntity && !snippet.approveError) || (this.props.isRejectingEntity && !snippet.rejectError)
        const disableReject = actioned || this.state.isApproving || this.state.isRejecting || (this.props.isApprovingEntity && !snippet.approveError) || (this.props.isRejectingEntity && !snippet.rejectError)

        const folderTasksConfig = {
            "enabled": true,
            "pollingIntervalMilliseconds" : 600000,
            "assigneeLabel": "Owner",
            "assignee": {
                "label": "Owner",
                "optionsExternal": {
                    "source": "group",
                    "group": "internal"
                }
            }
        };

        const addFolderConfig =  {
            "enabled": false,
                "label": "",
                "folderId": "",
                "subFolderConfig": [],
                "autoGenerateSubFolders": [],
                "folderNameField": {},
                "fixedMetadata": [],
                "fields": [],
                "ocr": false,
                "watson": false
        };

       let snippetText = snippet.entity && snippet.entity.snippet ? snippet.entity.snippet : "";
        return (
            <ul key={"ul_" + this.props.entityIndex + "_" + page.page_number}>
                <ListItem
                    className={classes.nestedLevel3}
                    alignItems= {snippetText.length > 90 ? "flex-start" : "center"}
                    key={"li_" + entityIndex + "_" + page.page_number}
                    button={true}
                    onClick={() => this.props.selectPage(page.page_number, snippet.entity.text)}>
                    <ListItemText
                        key={"lit_" + entityIndex + "_" + page.page_number}
                        primary={<DisplayHighlightText highlightText={snippetText } keyVal={"lit_" + entityIndex + "_" + page.page_number}/>}
                    />

                        <React.Fragment>
                            {
                                approved ?

                                    <Tooltip title={"Approved by " + snippet.modified_by}>
                                        <span>
                                            <IconButton style={{color: approved  ? "#cff0c9" : "#eba2a2", backgroundColor: "white"}}
                                                        aria-label="Result" >
                                                <i className="material-icons">check_circle_outline</i>
                                            </IconButton>
                                        </span>
                                    </Tooltip> :

                                    enableApproveRejectSnippet &&

                                        <Tooltip title={disableApprove ? "" : "Valid Match"}>
                                            {/*span needed to fix issue with tooltip not closing for disabled buttons*/}
                                            <span>
                                                <IconButton
                                                    disabled={actioned || this.props.disableApproveRejectSnippet}
                                                    color="inherit"
                                                    aria-label="Valid Match"
                                                    onClick={() => this.approve(typeIndex, entityIndex, pageIndex, snippetIndex, snippet)}>
                                                    {this.state.isApproving || (!actioned && this.props.isApprovingEntity) ?
                                                        <CircularProgress style={{paddingTop:"5px"}} color="primary" size={20}/> :
                                                        <i className="material-icons">check_circle</i>}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                            }
                            {
                                rejected ?
                                    <Tooltip title={"Rejected by " + snippet.modified_by}>
                                        <span>
                                            <IconButton style={{color:"#eba2a2", backgroundColor: "white"}} aria-label="Result" >
                                                <i className="material-icons">highlight_off</i>
                                            </IconButton>
                                        </span>
                                    </Tooltip> :

                                    enableApproveRejectSnippet &&

                                        <Tooltip title={disableReject ? "" : "False Positive"}>
                                            {/*span needed to fix issue with tooltip not closing for disabled buttons*/}
                                            <span>
                                                <IconButton
                                                    disabled={actioned || this.props.disableApproveRejectSnippet}
                                                    color="inherit"
                                                    aria-label="False Positive"
                                                    onClick={() => this.reject(typeIndex, entityIndex, pageIndex, snippetIndex, snippet)}>
                                                    {this.state.isRejecting || (!actioned && this.props.isRejectingEntity) ?
                                                        <CircularProgress color="primary" size={20}/> :
                                                        <i className="material-icons">cancel</i>}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                            }

                            {
                                snippet.approveError ?
                                    <Tooltip title={"Valid Match update unsuccessful"}>
                                        <IconButton
                                            style={{color:"#FF0000"}} aria-label="Error">
                                            <i className="material-icons">error</i>
                                        </IconButton>
                                    </Tooltip> :
                                    snippet.rejectError ?
                                        <Tooltip title={"False Positive update unsuccessful"}>
                                            <IconButton
                                                style={{color:"#FF0000"}} aria-label="Error">
                                                <i className="material-icons">error</i>
                                            </IconButton>
                                        </Tooltip> :
                                        <React.Fragment/>
                            }

                            <AddFolderTaskButton
                                folderTasksConfig= {folderTasksConfig}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                classes={classes}
                                userDetails={this.props.userDetails}
                                addFolderConfig={addFolderConfig}
                                metadataConfig={{}}
                                optionsConfig={[]}
                                parentFolderId={""}
                                defaultTaskDesc={snippetText}
                                snippet={this.props.snippet}
                                folderDetails={this.props.folderDetails}
                            />

                            <AuditButton
                                parentClasses={classes}
                                userDetails={this.props.userDetails}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                snippetId={snippet.id}
                                resultId={this.props.resultId}
                                type={"snippet"}
                            />

                    </React.Fragment>
                </ListItem>
            </ul>
        )
    }
}

MLSnippet.propTypes = {
    actionSnippet: PropTypes.func.isRequired,
    typeIndex: PropTypes.number.isRequired,
    entityIndex: PropTypes.string.isRequired,
    pageIndex: PropTypes.number.isRequired,
    snippet: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    autoCollapsePage: PropTypes.func.isRequired,
    selectPage: PropTypes.func.isRequired,
    selectedMention: PropTypes.string.isRequired, //needed??
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    isApprovingEntity: PropTypes.bool.isRequired,
    isRejectingEntity: PropTypes.bool.isRequired,
    enableApproveRejectSnippet: PropTypes.bool.isRequired,
    resultId: PropTypes.string.isRequired,
    folderDetails: PropTypes.object
};

export default withSnackbar(withStyles(styles, { withTheme: true })(MLSnippet));