import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    }
}));

function CustomSnackbar(props) {
    const classes = useStyles();
    const handleClose = (event, reason) => {
        // if (reason === 'clickaway') {
        //     return;
        // }
        props.handleClose()
    };

    return (
            <div className={classes.root}>
                <Snackbar open={props.open}  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} autoHideDuration={6000} onClose={handleClose}>
                    <Alert severity={props.severity}>{props.message}</Alert>
                </Snackbar>
            </div>
    );
}
CustomSnackbar.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    severity: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired
}
export default CustomSnackbar
