import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import AddFolderDialog from "./AddFolderDialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});

const INITIAL_STATE = {
    //dialog
    open: false,
};

class AddFolderButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

    }

    showDialog = () => {
        this.setState({
            //open: this.props.isOpen
            showDialog: true
        });
    };

    closeDialog = (folderDetails) => {

        //close dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            showDialog: false,
        });

        //Open newly created folder
        if (folderDetails) {
            if (folderDetails.id) {
                this.props.openFolder(folderDetails)
            }
        }

    };


    render() {

        //window.location.pathname.toLowerCase().includes("debug") && console.log('render Add Folder Button, props = ', this.props);

        return (
            <React.Fragment>
                <Tooltip title={'Add ' + this.props.addFolderConfig.label}>
                    <IconButton color = "secondary"
                                aria-label={'Add ' + this.props.addFolderConfig.label}
                                onClick={this.showDialog}>
                        <i className='material-icons'>
                            create_new_folder
                        </i>
                    </IconButton>
                </Tooltip>

                {this.state.showDialog &&
                    <AddFolderDialog
                        parentClasses={this.props.classes}
                        userDetails={this.props.userDetails}
                        addFolderConfig={this.props.addFolderConfig}
                        metadataConfig={this.props.metadataConfig}
                        optionsConfig={this.props.optionsConfig}
                        closeDialog={this.closeDialog}
                        parentFolderId={this.props.parentFolderId}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        //openFolder={this.props.openFolder}
                    />
                }
            </React.Fragment>
        );
    }
}

AddFolderButton.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    //uploadConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    parentFolderId: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    openFolder: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(AddFolderButton));
