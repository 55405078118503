import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class EmailButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    };

    render() {

        const { anchorEl } = this.state;
        const metadataKey = this.props.actionConfig.metadataKey;
        const templateKey = this.props.actionConfig.templateKey;
        const emailAddress = ( metadataKey && templateKey) && this.props.folderDetails[templateKey + "~" + metadataKey];

        return (
            <div style={{display: "inline"}}>
                <Tooltip title={this.props.actionConfig.label}>
                    <Link href={"mailto:"+ (emailAddress ? emailAddress : "")} target="_blank">
                        <IconButton
                            aria-label="Menu"
                            aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            color = "secondary"
                        >
                            <i className='material-icons'>
                                mail
                            </i>
                        </IconButton>
                    </Link>
                </Tooltip>

            </div>
        );
    }
}

EmailButton.propTypes = {
    folderDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default withStyles(styles)(EmailButton);