import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import FolderDocuments from "./documents/FolderDocuments";
import FolderTabs from "./FolderTabs";
import Box from "@material-ui/core/Box";
import {hasAccess} from "../common/helper";

function FolderContent(props) {

    const { classes, theme, ...other } = props;
    const [mountFolderDocuments, setMountFolderDocuments] = useState(true);

    function unmountFolderDocuments() {
        setMountFolderDocuments(false)
    }

    function remountFolderDocuments() {
        setMountFolderDocuments(true)
    }

    let showTasks = false;
    const tasksAction = props.actionsConfig.folderTasks
    if (tasksAction && tasksAction.enabled && hasAccess(tasksAction, props.userDetails.userRoles) ) {
        //if displayWhen criteria provided in config then only show tasks if criteria met
        if (tasksAction.displayWhen && tasksAction.displayWhen.templateKey && tasksAction.displayWhen.metadataKey) {
            let metadataValue;
            if (props.folderDetails[tasksAction.displayWhen.templateKey + "~" + tasksAction.displayWhen.metadataKey]) {
                metadataValue = props.folderDetails[tasksAction.displayWhen.templateKey + "~" + tasksAction.displayWhen.metadataKey]
                showTasks = tasksAction.displayWhen.value === metadataValue
            }
        } else {
            showTasks = true
        }
    }

    let showSign = false;
    const signAction = props.actionsConfig.signRequest;
    if (signAction && signAction.enabled && hasAccess(signAction, props.userDetails.userRoles) ) {
        showSign = true
    }

    return (
        <div style={{width: "100%"}}>
            {
                //show tabs if folder tasks enabled, otherwise just show folder documents
                showTasks || showSign ?

                    <Box border={'1px solid'} borderColor={'#E0E0E0'} boxShadow={0} borderRadius={0}>
                        <FolderTabs
                            classes={classes}
                            boxFolderID={props.folderDetails.box_id ? props.folderDetails.box_id : props.folderDetails.id}
                            userDetails={props.userDetails}
                            selectedSearchConfig={props.selectedWorkspaceConfig.searchConfig}
                            metadataConfig={props.metadataConfig}
                            optionsConfig={props.optionsConfig}
                            actionsConfig={props.actionsConfig}
                            workspaceConfig={props.workspaceConfig}
                            selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                            folderDetails={props.folderDetails}
                            searchCriteria={props.searchCriteria}
                            searchCriteriaSimple={props.searchCriteriaSimple}
                            triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                            templatesConfig={props.templatesConfig}
                            workflowConfig={props.workflowConfig.documentActions}
                            updateFolderDetails={props.updateFolderDetails}
                        />
                    </Box>

                    :

                    mountFolderDocuments &&

                    <Box border={'1px solid'} borderColor={'#E0E0E0'} boxShadow={0} borderRadius={0}>
                        <FolderDocuments
                            {...other}
                            classes={classes}
                            boxFolderID={props.folderDetails.box_id}
                            userDetails={props.userDetails}
                            selectedSearchConfig={props.selectedWorkspaceConfig.searchConfig}
                            metadataConfig={props.metadataConfig}
                            optionsConfig={props.optionsConfig}
                            actionsConfig={props.actionsConfig}
                            workspaceConfig={props.workspaceConfig}
                            selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                            folderDetails={props.folderDetails}
                            searchCriteria={props.searchCriteria}
                            searchCriteriaSimple={props.searchCriteriaSimple}
                            unmountComponent={unmountFolderDocuments}
                            remountComponent={remountFolderDocuments}
                            triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                            templatesConfig={props.templatesConfig}
                            workflowConfig={props.workflowConfig.documentActions}
                            displayInTab={false}
                            updateFolderDetails={props.updateFolderDetails}
                            disableAddDocument={props.disableAddDocument}
                        />
                    </Box>
            }
        </div>

    );
}

FolderContent.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    isSubfolder: PropTypes.bool,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    disableAddDocument: PropTypes.bool //set in PortalApp to prevent upload when not in a subfolder
};

export default withSnackbar(FolderContent);
