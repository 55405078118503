import React from "react";
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

const styles = theme => ({

    buttons: {
        textAlign: "right"
    },

    formControl : {
        width: "100%"
    }

});

const INITIAL_STATE = {
    settings: [],
    settingsChanged: false
};

class PublicLinks extends React.Component {


    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        this.handleEdit = this.handleEdit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

    }

    UNSAFE_componentWillMount() {
        this.getSettings();

    }

    handleEdit() {
        this.setState(
            {editMode: true}
        )
    }

    getSettings() {
        window.location.pathname.toLowerCase().includes("debug") && console.log ('getSettings');
        //let response = require("../../resources/stubs/configPublicLinks.json");
        //fetch from public folder as we do in home.jsx - not tested
        fetch('config/configPublicLinks.json')
            .then(response => response.json())
            .then(data => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('settings: ', data)
                this.setState({settings: data});
            })
    }

    handleOnChange = event => {

        //need this temporary object to ensure all values are maintained
        let newSettings = this.state.settings;

        switch(event.target.type) {
            case "number":
                newSettings[event.target.name] = event.target.valueAsNumber;
                break;
            case "date":
                newSettings[event.target.name] = event.target.valueAsDate;
                break;
            default:
                newSettings[event.target.name] = event.target.value;
        }

        this.setState({"settings" : newSettings});
        this.setState({"settingsChanged": true});

    };

    render() {

        const { classes} = this.props;

        return(

            <Paper className={classes.root}>

                <List>
                    {this.state.settings.map(
                        s => {
                            return(
                                <ListItem>
                                    <ListItemText
                                        primary= {s.label}
                                        secondary={s.url[window.REACT_APP_ENV]}/>
                                </ListItem>
                            )
                        })
                    }

                </List>


                <div className={classes.buttons}>
                    {
                        this.state.editMode ?
                            <Button variant="contained"
                                    color="secondary"
                                    disabled={!this.state.settingsChanged}>Submit</Button> :
                            <Button variant="contained"
                                    color="secondary"
                                    onClick={this.handleEdit}>Edit</Button>
                    }
                </div>


            </Paper>

        )
    }
}

export default withStyles(styles, { withTheme: true })(PublicLinks);

PublicLinks.propTypes = {
    classes: PropTypes.object.isRequired,
};
