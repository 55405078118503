import React, {useEffect, useReducer} from "react";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {withSnackbar} from "notistack";
import FolderDocuments from "./documents/FolderDocuments";
import FolderTasks from "./tasks/FolderTasks";
import {Badge} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {getErrorMessageFromResponse, hasAccess} from "../common/helper";

const styles = theme => ({
    tabBadge: {
        transform: 'translate(90%)',
    }
})

function TabPanel(props) {
    const { children, tabValue, tabIndex, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={tabValue !== tabIndex}
            id={`tabpanel-${tabIndex}`}
            aria-labelledby={`simple-tab-${tabIndex}`}
            {...other}
        >
            {tabValue === tabIndex && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    tabIndex: PropTypes.any.isRequired,
    tabValue: PropTypes.any.isRequired,
};

function a11yProps(tabIndex) {
    return {
        id: `simple-tab-${tabIndex}`,
        'aria-controls': `tabpanel-${tabIndex}`,
    };
}

function FolderTabs(props) {
    const { classes, theme, ...other } = props;
    const [tabValue, setTabValue] = React.useState(0);
    const [, setIsFetching] = React.useState(false);
    const [, setMountFolderDocuments] = React.useState(true)
    const [, setMountFolderTasks] = React.useState(true)

    const handleChangeTab = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    function unmountFolderDocuments() {
        setMountFolderDocuments(false)
    }

    function remountFolderDocuments() {
        setMountFolderDocuments(true)
    }

    function remountFolderTasks() {
        setMountFolderTasks(true)
    }

    async function getFolderTasks() {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        setIsFetching(true)

        await props.triggerRefreshAuthToken();

        const pathVar = "/" + props.folderDetails.id ;
        const url = window.REACT_APP_TASK_API_BASE_URL + window.REACT_APP_TASK_API_FOLDER + pathVar;
        const request = {
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "case-token": props.userDetails.caseAccessToken
            },
        };

        debug && console.log ("*** *** getFolderTasks url = ", url, "request = ", request);

        let tasks=[]

        await fetch(url , request )
            .then(response => {
                debug && console.log('*** *** getFolderTasks RESPONSE: ', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving folder tasks'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("getFolderTasks error.  url:", url, "request: ", request);
                    return ([])
                }
            })
            .then(result => {
                debug && console.log ('*** *** getFolderTasks response.json = ' , result);

                const todayDate = new Date();
                result.forEach(task => {
                    task.statusDisp = task.completed ? "Complete" : "Not started"
                    task.assignee = task.assign_to && task.assign_to.login
                    if (!task.completed && task.due_at) {
                        const dueDate = new Date(task.due_at)
                        task.overdue = dueDate <= todayDate;
                    }
                })

                //check if task overdue
                tasks = result

            })
            .catch(e => {
                props.enqueueSnackbar("Error retrieving folder tasks: " + e.toString(), {variant: 'error'});
                debug && console.log("getFolderTasks Exception:", e, " url:", url, "request: ", request);
            })

        return tasks
    }

    let initialState =  {
        isFetching: false,
        tasks: [],
        tasksOverdue: []
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {

        async function initTasks() {

            setIsFetching(true);

            if (props.actionsConfig.folderTasks && props.actionsConfig.folderTasks.enabled) {
                let tasks = await getFolderTasks();

                return (
                    dispatch({type: "TASKS", value: tasks})
                )
            }
        }
        initTasks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] )

    function reducer(state, action) {

        switch (action.type) {
            case "TASKS": {

                let filteredOverdue = action.value.filter(task => task.overdue);
                console.log('filteredOverdue = ', filteredOverdue)

                setIsFetching(false);
                return {
                    ...state,
                    tasks: action.value,
                    tasksOverdue: filteredOverdue
                }
            }

            default:
                return state
        }
    }

    function updateTasksBadge(tasks) {
        dispatch({type: "TASKS", value: tasks})
    }

    const showTasksTab = props.actionsConfig.folderTasks && props.actionsConfig.folderTasks.enabled && hasAccess(props.actionsConfig.folderTasks, props.userDetails.userRoles) ;
    const showSignTab = props.actionsConfig.signRequest && props.actionsConfig.signRequest.enabled && hasAccess(props.actionsConfig.signRequest, props.userDetails.userRoles);

    const documentsTabLabel = props.selectedWorkspaceConfig.searchConfig.folderDocuments.resultsTitlePlural ?
        props.selectedWorkspaceConfig.searchConfig.folderDocuments.resultsTitlePlural :
        "Documents"

    return (
        <>
            <AppBar position={"sticky"} color="secondary">
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="folder content">
                    <Tab label={documentsTabLabel} {...a11yProps(0)} />
                    {
                        showTasksTab &&
                        <Tab label={
                            <Badge badgeContent={state.tasksOverdue.length} color="error" >
                                <Box component="span" sx={{paddingRight: '5px'}}>Tasks</Box>
                            </Badge>
                        } {...a11yProps(1)} />
                    }
                    {
                        showSignTab &&
                        <Tab label="Sign Requests" {...a11yProps( showTasksTab ? 2 : 1)} />
                    }
                </Tabs>
            </AppBar>

            <TabPanel tabValue={tabValue} tabIndex={0}>
                <FolderDocuments
                    {...other}
                    classes={classes}
                    boxFolderID={props.folderDetails.box_id}
                    userDetails={props.userDetails}
                    selectedSearchConfig={props.selectedWorkspaceConfig.searchConfig}
                    metadataConfig={props.metadataConfig}
                    optionsConfig={props.optionsConfig}
                    actionsConfig={props.actionsConfig}
                    workspaceConfig={props.workspaceConfig}
                    selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                    folderDetails={props.folderDetails}
                    searchCriteria={props.searchCriteria}
                    searchCriteriaSimple={props.searchCriteriaSimple}
                    unmountComponent={unmountFolderDocuments}
                    remountComponent={remountFolderDocuments}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    templatesConfig={props.templatesConfig}
                    workflowConfig={props.workflowConfig}
                    displayInTab={true}
                />
            </TabPanel>

            {
                showTasksTab &&
                <TabPanel tabValue={tabValue} tabIndex={1}>
                    <FolderTasks
                        parentClasses={classes}
                        userDetails={props.userDetails}
                        boxFolderID={props.folderDetails.id}
                        folderTasksConfig={props.actionsConfig.folderTasks}
                        remountComponent={remountFolderTasks}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        displayInOwnTab={true}
                        updateTasksBadge={updateTasksBadge}
                        folderDetails={props.folderDetails}
                    />
                </TabPanel>
            }

            {
                showSignTab &&
                <TabPanel tabValue={tabValue} tabIndex={ showTasksTab ? 2 : 1}>
                    <FolderDocuments
                        {...other}
                        classes={classes}
                        boxFolderID={props.folderDetails.box_id}
                        userDetails={props.userDetails}
                        selectedSearchConfig={props.selectedWorkspaceConfig.searchConfig}
                        metadataConfig={props.metadataConfig}
                        optionsConfig={props.optionsConfig}
                        actionsConfig={props.actionsConfig}
                        workspaceConfig={props.workspaceConfig}
                        selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                        folderDetails={props.folderDetails}
                        searchCriteria={props.searchCriteria}
                        searchCriteriaSimple={props.searchCriteriaSimple}
                        unmountComponent={unmountFolderDocuments}
                        remountComponent={remountFolderDocuments}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        templatesConfig={props.templatesConfig}
                        workflowConfig={props.workflowConfig}
                        displayInTab={true}
                        sign={true}
                    />
                </TabPanel>
            }
        </>
    );
}

FolderTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    boxFolderID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderTasksConfig: PropTypes.object.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    isSubfolder: PropTypes.bool,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    updateFolderDetails: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(FolderTabs));

