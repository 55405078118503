import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {Grid} from "@material-ui/core";
import RenderMetadataField from "../../common/RenderMetadataField";
import FormControl from "@material-ui/core/FormControl";
import Paper from '@material-ui/core/Paper';
import EditSubfolderButton from "./EditSubfolderButton";
import {hasAccess} from "../../common/helper";
import ViewSharedLinkFolderButton from "../link/ViewSharedLinkFolderButton";

const styles = theme => ({
    paper: {
        //padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
});

class SubfolderDetails extends React.Component {

    constructor(props) {

        super(props);

        let editAccess = false;
        if (props.actionsConfig.editSubfolder && props.actionsConfig.editSubfolder.enabled) {
            if (hasAccess(props.actionsConfig.editSubfolder, props.userDetails.userRoles)) {
                editAccess = true;
            }
        }

        this.state = {
            folderDetails: {...props.folderDetails},
            metadataHasChanged: false,
            showFolderMetadata: true,
            mountActionButtons: true,
            editAccess: editAccess,
            isSaving: false
        }

        this.handleOnChangeMetadata = this.handleOnChangeMetadata.bind(this);
        this.resetMetadataHasChanged = this.resetMetadataHasChanged.bind(this);

        window.location.pathname.toLowerCase().includes("debug") && console.log ('SubfolderDetails props = ' , props, 'this.state=', this.state);
    }

    componentDidUpdate(prevProps) {

        //const debug = window.location.pathname.toLowerCase().includes("debug")
        // debug && console.log('FolderDetails componentDidUpdate - check if folder details have changed')

        //don't unmount if changes have been made via ui
        if (this.state.editAccess) {
            if (!this.state.metadataHasChanged) {
                if (JSON.stringify(this.state.folderDetails) !== JSON.stringify(this.props.folderDetails)) {
                    //debug && console.log('FolderDetails - componentDidUpdate - folder details changed so unmount component to ensure latest values displayed.')
                    this.props.unmountComponent();
                    // } else {
                    //     debug && console.log('FolderDetails - no change to folder details')
                }
            }
        } else {
            if (JSON.stringify(this.state.folderDetails) !== JSON.stringify(this.props.folderDetails)) {
                //debug && console.log('FolderDetails - componentDidUpdate - folder details changed so unmount component to ensure latest values displayed.')
                this.props.unmountComponent();
                // } else {
                //debug && console.log('FolderDetails - no change to folder details')
            }
        }

    }

    UNSAFE_componentWillMount() {
        //const debug = window.location.pathname.toLowerCase().includes("debug");
        //debug && console.log ('*** SubfolderDetails - .....componentWillMount... editAccess = ' + this.state.editAccess);
        // if (!this.props.folderDetails.newFolder) {
           // if (this.state.editAccess) {
                //refresh metadata to ensure latest metadata is displaying
                //Note, to prevent this code running twice, ensure folder search has all metadata fields listed in workspaceConfig.searchConfig.box.metadataKeys

               // debug && console.log ('FolderDetails - .....in edit mode...get latest metadata...')
                //this.props.getFolderMetadata(this.props.folderDetails.id)
          //  }
        // }
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    handleExpandCollapse = item => (event) => {

        this.setState(prevState => ({
            [item]: !prevState[item]
        }));

    };

    updateIsSaving = (isSaving) => {
        this.setState({isSaving: isSaving})
    }

    updateFolderDetailsFromWorkflowAction = (templateKey, metadataKey, newValue) => {
        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        let newFolderDetails = this.state.folderDetails;        //need this temporary object to ensure all values are maintained

        newFolderDetails[templateKey + "~" + metadataKey] = val;

        if (newFolderDetails.metadata) {
            if (newFolderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
                if (newFolderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey]) {
                    if (newFolderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey]) {
                        if (newFolderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey]) {
                            newFolderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][templateKey][metadataKey] = newValue
                        }
                    }

                }
            }
        }

        this.setState({folderDetails : newFolderDetails});

        //remount component
        this.props.unmountComponent();

    }

    resetMetadataHasChanged = () => {
        this.setState({metadataHasChanged: false});
        this.props.updateSubfolderMetadataAfterChanges(this.state.folderDetails);
    }

    handleOnChangeMetadata = (id, newValue) => {

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0, 0, 0, 0);
            val = dateVal;
        }

        //Update value on metadataFields object used to display the metadata fields
        let newFolderDetails = this.state.folderDetails;        //need this temporary object to ensure all values are maintained

        newFolderDetails[id] = val;
        if (id.indexOf("~")>-1){
            newFolderDetails[id.substring(id.indexOf("~") + 1)] = val;
        }

        this.setState({folderDetails: newFolderDetails});

        if (!this.state.metadataHasChanged) {
            this.setState({metadataHasChanged: true});
        }

    };

    unmountActionButtons = () => {
        this.setState({mountActionButtons: false })
    }

    remountActionButtons = () => {
        this.setState({mountActionButtons: true })
    }

    render() {

        //window.location.pathname.toLowerCase().includes("debug") && console.log('SubfolderDetails props: ', this.props);

        const {classes} = this.props;
        const editAccess = this.state.editAccess;
        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch;

        let folderDetails = this.state.folderDetails;

        //window.location.pathname.toLowerCase().includes("debug") && console.log('SubFolder folderDetails = ', folderDetails);

        let folderMetadata;
        if (folderDetails.metadata) {
            //when using get all subfolders
            if (folderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
                folderMetadata = folderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]
            }
        }

        //window.location.pathname.toLowerCase().includes("debug") && console.log('SubFolder folderMetadata = ', folderMetadata);

        //loop through each item of metadata to display and get value
        let metadata = [];
        let metadataToDisplay = [];
        if (searchConfig.subfolderDetails) {
            metadata = searchConfig.subfolderDetails.fields;

            if (metadata) {
                for (let i = 0; i < metadata.length; i++) {
                    //get values from folder metadata
                    if (folderMetadata) {
                        //subfolders retrieved via get all folders
                        if (folderMetadata.hasOwnProperty(metadata[i].templateKey)) {
                            let value = folderMetadata[metadata[i].templateKey][metadata[i].metadataKey]
                            if (value || (!value && (editAccess || metadata[i].displayIfNoValue))) {
                                metadata[i].value = folderMetadata[metadata[i].templateKey][metadata[i].metadataKey]
                                if (!metadata[i].hide) {
                                    metadataToDisplay.push(metadata[i]);
                                }

                            }
                        }
                    } else {
                        //subfolders retrieved via metadata search
                        const key = metadata[i].templateKey + "~" + metadata[i].metadataKey;
                        if (folderDetails.hasOwnProperty(key)) {
                            let value = folderDetails[key]
                            if (value || (!value && (editAccess || metadata[i].displayIfNoValue))) {
                                metadata[i].value = value
                                if (!metadata[i].hide ) {
                                    metadataToDisplay.push(metadata[i]);
                                }
                            }
                        } else {
                            //if metadata value not already set still give option to edit it in ui
                            if (!metadata[i].hide && (editAccess || metadata[i].displayIfNoValue)) {
                                // metadata[i].value = value
                                metadataToDisplay.push(metadata[i]);
                            }
                        }
                    }
                }
            }
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log('metadataToDisplay = ', metadataToDisplay);

        return (

            <React.Fragment>
                {/*Hide for Traton demo*/}
                {/*<AppBar position="static" color={"secondary"}>*/}
                {/*    <Toolbar>*/}
                {/*{*/}
                {/*    this.state.mountActionButtons &&*/}
                {/*    <ActionButtons*/}
                {/*        folderDetails={this.props.folderDetails}*/}
                {/*        userDetails={this.props.userDetails}*/}
                {/*        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}*/}
                {/*        updateFolderDetailsFromWorkflowAction={this.updateFolderDetailsFromWorkflowAction}*/}
                {/*        workflowConfig={this.props.workflowConfig}*/}
                {/*        unmountComponent={this.unmountActionButtons}*/}
                {/*        remountComponent={this.remountActionButtons}*/}
                {/*    />*/}
                {/*}*/}
                {/*    </Toolbar>*/}
                {/*</AppBar>*/}

                {
                    folderDetails.shared_link && this.props.actionsConfig.viewSharedLinkFolder &&
                    this.props.actionsConfig.viewSharedLinkFolder.enabled &&
                    hasAccess(this.props.actionsConfig.viewSharedLinkFolder, this.props.userDetails.userRoles) &&
                    <div style={{display: "inline"}}>
                        <ViewSharedLinkFolderButton
                            sharedLink={folderDetails.shared_link}
                            actionConfig={this.props.actionsConfig.viewSharedLinkFolder}/>
                    </div>
                }
                {
                    this.state.metadataHasChanged &&
                        <div style={{paddingBottom: '16px'}}>
                            <EditSubfolderButton
                                folderDetails={this.state.folderDetails}
                                actionConfig={this.props.actionsConfig.editSubfolder}
                                userDetails={this.props.userDetails}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                metadataHasChanged={this.state.metadataHasChanged}
                                resetMetadataHasChanged={this.resetMetadataHasChanged}
                                addFolderConfig={this.props.addFolderConfig}
                                updateIsSaving={this.updateIsSaving}
                                searchConfig={this.props.searchConfig}
                                metadataConfig ={this.props.metadataConfig}
                                updateSubfolderMetadataAfterChanges={this.props.updateSubfolderMetadataAfterChanges}
                            />
                        </div>
                }

                {
                    metadata.length > 0 &&
                        <React.Fragment>
                            {
                                this.props.folderDetails.name &&
                                <Paper className={classes.paper} style={{textAlign: "left"}} >
                                    <Grid container spacing={2} xs={12}>
                                        {
                                            metadataToDisplay
                                                .filter(entry => entry.value || (!entry.value && (editAccess || entry.displayIfNoValue)))
                                                .map(entry => {
                                                    return (
                                                        <Grid item
                                                              xs={entry.extraWide ? 6 : 3}
                                                              style={{height: "100%", overflowY: "auto"}}
                                                              key={"g" + entry.templateKey + "~" + entry.metadataKey}>
                                                            <FormControl fullWidth style={{paddingBottom: '10px'}}
                                                                         key={"fc" + entry.templateKey + "~" + entry.metadataKey}>
                                                                <RenderMetadataField
                                                                    fieldValue={entry.value}
                                                                    metadataConfig={this.props.metadataConfig}
                                                                    optionsConfig={this.props.optionsConfig}
                                                                    metadataKey={entry.metadataKey}
                                                                    templateKey={entry.templateKey}
                                                                    forceDisable={!editAccess || entry.readOnly || this.state.isSaving}
                                                                    usage={"edit"}
                                                                    handleOnChange={this.handleOnChangeMetadata}
                                                                    formValues={this.state.folderDetails}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                })
                                        }

                                    </Grid>

                                </Paper>
                            }
                        </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

SubfolderDetails.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    updateSubfolderMetadataAfterChanges: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(SubfolderDetails));

