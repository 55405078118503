import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ViewSharedLinkFolderDialog from "./ViewSharedLinkFolderDialog";

function ViewSharedLinkFolderButton(props) {

    const [showDialog, setShowDialog] = useState(false)

    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = () => {
        setShowDialog(false)
    };


    const sharedLink = props.sharedLink;
    const actionConfig = props.actionConfig;

    const url = sharedLink && sharedLink.substring(0,sharedLink.indexOf("/s/")) + "/embed_widget" + sharedLink.substring(sharedLink.indexOf("/s/"));

    return (
        <React.Fragment>
            {
                sharedLink &&
                <div>
                    <Tooltip title={actionConfig.label}>
                        <IconButton
                            aria-label="Menu"
                            aria-haspopup="true"
                            onClick={handleClickIcon}
                        >
                            <i className='material-icons'>
                                link
                            </i>
                        </IconButton>
                    </Tooltip>

                    {
                        showDialog &&
                        <ViewSharedLinkFolderDialog
                            sharedLink={url}
                            handleCloseDialog={handleCloseDialog}
                            actionConfig={props.actionConfig}
                        />
                    }

                </div>
             }
        </React.Fragment>
    );
}

ViewSharedLinkFolderButton.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    sharedLink: PropTypes.string.isRequired
};

export default ViewSharedLinkFolderButton;