import PropTypes from "prop-types";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

function MetadataValueImage(props){

    const debug = window.location.pathname.toLowerCase().includes("debug")
    debug && console.log ('MetadataValueImage props = ', props);
    const imageFile = props.imageConfig[props.val]?.image

    return <div style={{display: "inline"}}>
        <Tooltip title={props.val}>
            <img src={window.location.origin + '/images/' + imageFile} height={'5%'} width={'5%'}/>
        </Tooltip>
    </div>
}

MetadataValueImage.propTypes = {
    val: PropTypes.string,
    imageConfig: PropTypes.object
};

export default (MetadataValueImage)