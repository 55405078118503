import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from "@material-ui/core/Divider/Divider";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import {hasAccess} from "../common/helper"
import SanctionsCreateCaseDialog from "./SanctionsCreateCaseDialog";

const styles = (theme) => ({
    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },
    menuListItem: {
        color: theme.menuListItem.colour
    },
});


function CustomMenu(props) {

    const {classes} = props;
    const [showSanctionsCreateCaseDialog, setShowSanctionsCreateCaseDialog] = useState(false);
    const actionConfig = props.actionsConfig && props.actionsConfig.createSanctionsCase
    const showSanctionsCreateCase = actionConfig && actionConfig.enabled && hasAccess(actionConfig, props.userDetails.userRoles);
    // const showSanctionsCreateCase = true;

    function closeSanctionsCreateCaseDialogDialog() {
        setShowSanctionsCreateCaseDialog(false)
    }

        return (
            <React.Fragment>
                {
                    showSanctionsCreateCase &&
                        <React.Fragment>

                            <ListSubheader disableSticky color={'primary'}></ListSubheader>

                            {
                                showSanctionsCreateCase &&

                                <ListItem
                                    button={true}
                                    key={'sanctionsCreateCase'}
                                    id={'sanctionsCreateCase'}
                                    onClick={() => setShowSanctionsCreateCaseDialog(true)}
                                >
                                    <ListItemIcon className={classes.listIcon}><i
                                        className='material-icons'>create</i></ListItemIcon>
                                    <ListItemText
                                        className={classes.menuListItem}
                                        primary={'Create Sanctions Case'}
                                    />
                                </ListItem>

                            }
                            {
                                showSanctionsCreateCaseDialog &&

                                <SanctionsCreateCaseDialog
                                    actionConfig={props.actionsConfig.addUser}
                                    classes={props.classes}
                                    handleCloseDialog={closeSanctionsCreateCaseDialogDialog}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    userDetails={props.userDetails}
                                />
                            }
                        </React.Fragment>
                }
            </React.Fragment>
        );
}


CustomMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    handleShowAdminGeneral: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(CustomMenu);