import React from 'react';
import PropTypes from 'prop-types';
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import DownloadFile from "../../document/download/DownloadFile";
import {Grid} from "@material-ui/core";
import {hasAccess, numberWithCommas} from "../../common/helper"
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import DisplayOptionButtons from "../../search/results/DisplayOptionButtons";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import SearchCriteriaTooltip from "../../search/SearchCriteriaTooltip";
import FolderDocumentsRefresh from "./FolderDocumentsRefresh";
import AddDocButton from "../../document/addDocument/AddDocButton";
import FilterFolderDocuments from "./FilterFolderDocuments";
import FilterFolderDocumentsByMetadata from "./FilterFolderDocumentsByMetadata";
import DownloadResults from "../../document/download/DownloadResults";
import CardActionArea from "@material-ui/core/CardActionArea"
import PreviewDialog from "../../document/preview/PreviewDialog";
import DeleteFile from "../../document/DeleteFile";
import ViewFileLinkButton from "../../document/link/ViewFileLinkButton";
import PreviewButton from "../../document/preview/PreviewButton";
import InfiniteScrollLoader from "../../search/results/InfiniteScrollLoader";
import InfiniteScrollEndMessage from "../../search/results/InfiniteScrollEndMessage";
import InfiniteScroll from 'react-infinite-scroll-component';
import DocumentCardContent from "./DocumentCardContent";

const styles = theme => ({
    folderDocsGrid: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
});
const tableTheme = theme => createTheme({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides overrides the overrides in the parent theme in app.css
    overrides: {

        MuiToolbar: {
            gutters: {
                paddingLeft: theme.spacing(2)
            },
            root: {
                paddingLeft: "0px"
            },
            regular:{
                paddingLeft: "0px"
            }
        },
        MTableToolbar: {
            root: {
                paddingLeft: "0px"
            }
        },
        MuiTableCell: {
            root: {
                padding: '0px',
                //paddingLeft: "24px"
            }
        },

        MuiIconButton: {
            root: {
                color: theme.palette.secondary.main,
            },
            colorInherit: {
                color: theme.palette.secondary.main
            }
        },

        MuiAvatar: {
            colorDefault: {
                backgroundColor: theme.palette.secondary.main
            }
        }
    }
});

class SearchResultsDocumentsTableFolderDocs extends React.Component {

    constructor(props) {

        super(props);

        //window.location.pathname.toLowerCase().includes("debug") && console.log('SearchResultsDocumentsTableFolderDocs props = ' , props);

        let hasEditableColumns = false;
        const searchConfig = props.selectedWorkspaceConfig.searchConfig.folderDocuments;
        searchConfig.resultsColumns.forEach(column => {
            if (column.editable) {
                hasEditableColumns = true;
            }
        })

        this.state = {
            searchResults: props.searchResults,
            showPreviewDialog: false,
            hasEditableColumns: hasEditableColumns
        }

    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.state.searchResults)!== JSON.stringify(this.props.searchResults)){
            this.props.unmountComponent();
         } else {
            //window.location.pathname.toLowerCase().includes("debug") && console.log ('no change to search Results')
        }
    }

    componentWillUnmount() {

        this.props.remountComponent();

    }

    showPreview = (sr) => {
        this.setState({
            showPreviewDialog: true,
            showPreviewDocId: sr.id,
            showPreviewDocumentDetails: sr
        });
    };

    closePreviewDialog = () => {
        this.setState({
            showPreviewDialog: false,
            showPreviewDocId: "",
            showPreviewDocumentDetails: {}
        })
    };


    render() {

        const hasEditAccess = hasAccess(this.props.actionsConfig.edit, this.props.userDetails.userRoles);


        //To cater for Metadata api which doesn't return searchTotalCount
        let totalCountDisp ;
        if (!this.props.searchTotalCount || this.props.searchTotalCount === 0) {
            totalCountDisp = this.props.searchResults.length
        } else {
            totalCountDisp = this.props.searchTotalCount
        }

        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderDocuments;
        const tableTitle = numberWithCommas(totalCountDisp) + " " +
            (totalCountDisp === 1 ? searchConfig.resultsTitleSingular : searchConfig.resultsTitlePlural);

        const gridConfig = searchConfig && searchConfig.resultsDisplay && searchConfig.resultsDisplay.grid;
        const gridImageConfig = gridConfig && gridConfig.image;
        const showThumbnail = gridConfig && gridConfig.showThumbnail;

        const useCustomGridImage = gridImageConfig && gridImageConfig.custom &&
            gridImageConfig.custom.templateKey && gridImageConfig.custom.templateKey !== "" &&
            gridImageConfig.custom.metadataKey && gridImageConfig.custom.metadataKey !== ""

        const customGridImageConfig  = useCustomGridImage && gridImageConfig.custom;
        const defaultGridImage = gridImageConfig && gridImageConfig.default ? gridImageConfig.default : "logo.png"

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        //Access to Add Doc button now further restricted by roles listed in workspaceConfig.uploadConfig (if available)
        const workspaceUploadRoles =
            this.props.selectedWorkspaceConfig.uploadConfig.roles &&
            Array.isArray(this.props.selectedWorkspaceConfig.uploadConfig.roles) ?
                this.props.selectedWorkspaceConfig.uploadConfig.roles :
                [];

        const showAddDocButton = this.props.actionsConfig.addDocumentWithinFolder.enabled &&
        workspaceUploadRoles.length > 0 ?
            hasAccess(this.props.selectedWorkspaceConfig.uploadConfig, this.props.userDetails.userRoles) :
            hasAccess(this.props.actionsConfig.addDocumentWithinFolder, this.props.userDetails.userRoles)

        return (

                <ThemeProvider theme={tableTheme}>
                    <Grid container style={{height:"100%"}}>
                        <Grid item xs={12}>
                            <AppBar
                                position="inherit"
                                style={{backgroundColor: "white", color: "black", boxShadow: "none",}}>
                                <Toolbar disableGutters>
                                    <Typography variant={"h6"} color={"textPrimary"}>
                                        {tableTitle}
                                        <DisplayOptionButtons searchConfig = {searchConfig}
                                                              showResultsAsGrid={this.props.showResultsAsGrid}
                                                              showResultsAsTable={this.props.showResultsAsTable}
                                                              currentDisplay={"grid"}
                                        />
                                        {
                                            hasMore &&
                                            <Tooltip title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                        <span>
                                                            <Button onClick={this.props.getNextResults}
                                                                    key={"ibNext"}
                                                                    disabled={this.props.isFetching}
                                                                    style={{fontWeight: 'bold', minWidth: '0px',paddingLeft: '5px',paddingRight: '5px',paddingTop: '5px', fontSize: '1rem'}}>
                                                                {this.props.isFetching ?
                                                                    <CircularProgress color="secondary" size={15}/> :
                                                                    "..."}
                                                            </Button>
                                                        </span>
                                            </Tooltip>
                                        }
                                        {
                                            this.props.actionsConfig.searchCriteriaTooltip.enabled &&
                                            <SearchCriteriaTooltip
                                                parentClasses={this.props.classes}
                                                metadataConfig={this.props.metadataConfig}
                                                searchCriteriaSimple={this.props.searchCriteriaSimple}
                                                searchCriteria={this.props.searchCriteria}
                                                iconClass={""}
                                            />
                                        }
                                        {
                                            this.props.actionsConfig.refreshFolderDocuments.enabled &&
                                            <FolderDocumentsRefresh
                                                unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                            />
                                        }

                                        {
                                            showAddDocButton &&
                                            <div style={{display: "inline"}}>
                                                <AddDocButton
                                                    parentClasses={this.props.classes}
                                                    userDetails={this.props.userDetails}
                                                    uploadConfig={this.props.selectedWorkspaceConfig.uploadConfig}
                                                    metadataConfig={this.props.metadataConfig}
                                                    optionsConfig={this.props.optionsConfig}
                                                    folderDetails={this.props.folderDetails}
                                                    iconClass={""}
                                                    unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                    actionConfig={this.props.actionsConfig.addDocumentWithinFolder}
                                                    workspaceConfig={this.props.workspaceConfig}
                                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                    templatesConfig={this.props.templatesConfig}
                                                    actionsConfig={this.props.actionsConfig}
                                                />
                                            </div>
                                        }
                                        {
                                            this.props.actionsConfig.filterFolderDocuments.enabled &&
                                            hasAccess(this.props.actionsConfig.filterFolderDocuments, this.props.userDetails.userRoles) &&
                                            <FilterFolderDocuments
                                                parentClasses={this.props.classes}
                                                filterFolderDocumentsActionConfig={this.props.actionsConfig.filterFolderDocuments}
                                                toggleFilterFolderDocuments={this.props.toggleFilterFolderDocuments}
                                                filterFolderDocuments={this.props.filterFolderDocuments}
                                                isFiltering={this.props.isFilteringFolderDocuments}
                                            />
                                        }
                                        {
                                            this.props.actionsConfig.filterFolderDocumentsByMetadata.enabled &&
                                            !this.props.disableFilterFolderDocumentsByMetadata &&
                                            hasAccess(this.props.actionsConfig.filterFolderDocumentsByMetadata, this.props.userDetails.userRoles) &&
                                            <FilterFolderDocumentsByMetadata
                                                parentClasses={this.props.classes}
                                                filterFolderDocumentsByMetadataActionConfig={this.props.actionsConfig.filterFolderDocumentsByMetadata}
                                                toggleFilterFolderDocumentsByMetadata={this.props.toggleFilterFolderDocumentsByMetadata}
                                                filterFolderDocumentsByMetadata={this.props.filterFolderDocumentsByMetadata}
                                                isFiltering={this.props.isFilteringFolderDocumentsByMetadata}
                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            />
                                        }
                                        {
                                            this.props.searchResults.length > 0 &&
                                            this.props.actionsConfig.downloadResults.enabled &&
                                            hasAccess(this.props.actionsConfig.downloadResults, this.props.userDetails.userRoles) &&
                                            <div style={{display: "inline"}}>
                                                <DownloadResults
                                                    userDetails={this.props.userDetails}
                                                    downloadResultsActionConfig={this.props.actionsConfig.downloadResults}
                                                    searchResults={this.props.searchResults}
                                                    fileName = {this.props.folderDetails.name + ".zip"}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                />
                                            </div>
                                        }
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Grid>

                        <Grid item xs={12} style={{height: "100%", overflowY: "auto", overflowX: "hidden"}} className={this.props.classes.folderDocsGrid}>
                            <InfiniteScroll
                                style={{overflowX: 'hidden'}}
                                dataLength={this.props.searchResults.length}
                                next={this.props.getNextResults}
                                hasMore={hasMore}
                                loader={
                                    <InfiniteScrollLoader
                                        searchReturnedCount={this.props.searchResults.length}
                                        searchTotalCount={this.props.searchTotalCount}
                                        searchLimit={this.props.searchLimit}
                                        isFetching={this.props.isFetching}
                                        nextMarker={this.props.nextMarker}
                                        getNextResults={this.props.getNextResults}
                                    />
                                }
                                endMessage={
                                    <InfiniteScrollEndMessage
                                        searchReturnedCount={this.props.searchResults.length}
                                        searchTotalCount={this.props.searchTotalCount}
                                        nextMarker={this.props.nextMarker}
                                    />
                                }
                                // scrollableTarget="scrollableDiv1"
                            >
                            <Grid container spacing={3} style={{overflowX: 'hidden'}}>
                                {
                                    this.props.searchResults.map(sr => {

                                        let image = useCustomGridImage && customGridImageConfig &&
                                            sr[customGridImageConfig.templateKey + "~" + customGridImageConfig.metadataKey]
                                        image = window.location.origin + '/images/' + (image ? image : defaultGridImage)

                                        return(
                                            <React.Fragment>
                                                {
                                                    this.state.showPreviewDialog &&
                                                        <PreviewDialog
                                                            parentClasses={this.props.classes}
                                                            boxDocID={this.state.showPreviewDocId}
                                                            userDetails={this.props.userDetails}
                                                            handleCloseDialog={this.closePreviewDialog}
                                                            calledFromDocumentMenu={false}
                                                            metadataConfig={this.props.metadataConfig}
                                                            optionsConfig={this.props.optionsConfig}
                                                            workspaceConfig={this.props.workspaceConfig}
                                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                            actionsConfig={this.props.actionsConfig}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            documentDetails={this.state.showPreviewDocumentDetails}
                                                        />
                                                }

                                                <Grid item xs={showThumbnail ? 2 : 3}>

                                                    <Card sx={{ maxWidth: showThumbnail ? 100 : 345 }}
                                                          style={{
                                                              height: showThumbnail ? 'auto' : '292px',
                                                              backgroundColor: '#f6f6f8',
                                                          }}>
                                                        <CardActionArea onClick={() => this.showPreview(sr)}>

                                                            <DocumentCardContent
                                                                file={sr}
                                                                image={image}
                                                                showThumbnail = {showThumbnail}
                                                                userDetails={this.props.userDetails}
                                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            />


                                                        </CardActionArea>

                                                        <div style={{ display:'flex', justifyContent:'center' }}>
                                                            <CardActions disableSpacing>
                                                                {
                                                                    this.props.actionsConfig.downloadFile.enabled &&
                                                                    hasAccess(this.props.actionsConfig.downloadFile, this.props.userDetails.userRoles) &&

                                                                    <DownloadFile
                                                                        boxDocID={sr.id}
                                                                        fileName={sr.name}
                                                                        userDetails={this.props.userDetails}
                                                                        downloadFileActionConfig={this.props.actionsConfig.downloadFile}
                                                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                    />
                                                                }
                                                                {
                                                                    this.props.actionsConfig.delete.enabled &&
                                                                    hasAccess(this.props.actionsConfig.delete, this.props.userDetails.userRoles) &&

                                                                    <DeleteFile
                                                                        boxDocID={sr.id}
                                                                        userDetails={this.props.userDetails}
                                                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                        actionConfig={this.props.actionsConfig.delete}
                                                                    />

                                                                }
                                                                {
                                                                    this.props.actionsConfig.viewLinkFile.enabled &&
                                                                    hasAccess(this.props.actionsConfig.viewLinkFile, this.props.userDetails.userRoles) &&

                                                                    <ViewFileLinkButton
                                                                        boxDocID={sr.id}
                                                                        actionConfig={this.props.actionsConfig.viewLinkFile}
                                                                    />
                                                                }
                                                                {
                                                                    hasEditAccess && this.state.hasEditableColumns
                                                                    && this.props.actionsConfig.edit.enabled &&
                                                                    hasAccess(this.props.actionsConfig.edit, this.props.userDetails.userRoles) &&

                                                                    <PreviewButton
                                                                        parentClasses={this.props.classes}
                                                                        boxDocID={sr.id}
                                                                        userDetails={this.props.userDetails}
                                                                        metadataConfig={this.props.metadataConfig}
                                                                        optionsConfig={this.props.optionsConfig}
                                                                        workspaceConfig={this.props.workspaceConfig}
                                                                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                                        actionsConfig={this.props.actionsConfig}
                                                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                                        unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                                                        showMetadataOnOpen={true}
                                                                        useEditIcon={true}
                                                                        refreshAfterEditMetadata={true}
                                                                        documentDetails={sr}
                                                                    />
                                                                }
                                                            </CardActions>
                                                        </div>
                                                    </Card>

                                                </Grid>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Grid>
                            </InfiniteScroll>
                        </Grid>
                    </Grid>
                </ThemeProvider>

            );
        }
    // };
}

SearchResultsDocumentsTableFolderDocs.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    toggleFilterFolderDocuments: PropTypes.func,
    filterFolderDocuments: PropTypes.bool,
    isFilteringFolderDocuments: PropTypes.bool,
    toggleFilterFolderDocumentsByMetadata: PropTypes.func,
    filterFolderDocumentsByMetadata: PropTypes.bool,
    disableFilterFolderDocumentsByMetadata: PropTypes.bool,
    isFilteringFolderDocumentsByMetadata: PropTypes.bool,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    folderDocuments: PropTypes.bool,
    nextMarker: PropTypes.string,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    showResultsAsGrid: PropTypes.func.isRequired,
    showResultsAsTable: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles, { withTheme: true })(SearchResultsDocumentsTableFolderDocs));

