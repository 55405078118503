import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FileIcon from "./FileIcon";

function FileIconThumbnail(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [thumbnail,setThumbnail] = useState();

    useEffect(() => {

        const getThumbnail = async (file)  => {

            debug && console.log('getThumbnail for file id: ', file.id);

            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/' + file.id + '/thumbnail?width=32&height=32';
            const request = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + props.userDetails.accessToken
                }
            };
            await props.triggerRefreshAuthToken();
            fetch(url, request)
                .then(response => {
                    debug && console.log('thumbnail response = ', response)
                    if (response.ok) {
                        return (response.blob())
                    } else {
                        // Promise.resolve(getErrorMessageFromResponse(response, 'retrieving thumbnail'))
                        //     .then(message => {
                        //         props.enqueueSnackbar(message, {variant: 'error'});
                        //     })
                        debug && console.log("thumbnail error. url:", url, "request: ", request, "response:", response);
                        return null
                    }
                }).then(resultA => {
                    if (resultA) {
                        const thumbnailUrl = URL.createObjectURL(resultA);
                        setThumbnail(thumbnailUrl)
                    }
            })
        }

        getThumbnail(props.file)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.file]);

    debug && console.log ('thumbnail = ', thumbnail)

    return (
        <React.Fragment>
            {
                thumbnail ?
                    <img src={thumbnail}
                         // style={{paddingRight: '8px'}}
                         height="24"
                         width="24"
                         alt={props.file.name}
                    />
                :
                <FileIcon height={'32px'} width={'32px'} fileName={props.file.name}/>
            }
        </React.Fragment>
    )
}

FileIconThumbnail.propTypes = {
    file: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.bool.isRequired
}

export default FileIconThumbnail;