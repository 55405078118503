import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Collapse from "@material-ui/core/Collapse";
import {ReactComponent as CustomIcon} from "../common/customIcon-1.svg";
import WorkspaceFilter from "./WorkspaceFilter";
import {hasAccess} from "../common/helper";
import { withTranslation} from 'react-i18next'

const styles = (theme) => ({

    topLevelText: {
        color: theme.menuListItem.colour
    },

    list: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },

    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    listIconNested: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    nestedLevel1: {
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(0),
        color: theme.menuListItem.colour
    },

    listItemText: {
        color: theme.menuListItem.colour
    },

});

class WorkspaceMenuList extends Component {

    constructor(props) {
        super(props);

        let initialState = {};

        //loop through all workspace names and expand any set to defaultExpand
        let groupingList = [];
        let filterList = [];
        props.workspaceConfig.forEach(
            w => {
                if (w.defaultExpand) {
                    let grouping = "";
                    let newGrouping = false;

                    if (w.title.indexOf("\\") > -1) {
                        grouping = w.title.split("\\")[0];
                        //check if grouping has already been processed
                        if (groupingList.indexOf(grouping) === -1) {
                            groupingList.push(grouping);
                            newGrouping = true
                        }
                    }

                    if (grouping !== "" && newGrouping) {
                        initialState[grouping] = true;
                    }
                }
                if (w.filter && w.filter !== "") {
                    if (filterList.indexOf(w.filter) === -1) {
                        filterList.push(w.filter);
                    }
                }

            })

        initialState.filterList = filterList.sort();
        this.state = initialState
    }

    handleExpandCollapse = item => (event) => {

        this.setState(prevState => ({
            [item]: !prevState[item]
        }));

    };


    render() {

        const { classes } = this.props;

        const {t} = this.props;
        const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

        let groupingList = [];

        return (
            <React.Fragment>
                {
                    this.props.listTitle !== "" &&
                        <ListSubheader
                            disableSticky
                            className={classes.menuListItemHeader}
                            color="primary"
                            key={"shWorkspace"}>
                            {this.props.listTitle}
                            {
                                this.state.filterList.length > 0 &&
                                this.props.actionsConfig.workspaceFilter && this.props.actionsConfig.workspaceFilter.enabled &&
                                hasAccess(this.props.actionsConfig.workspaceFilter, this.props.userDetails.userRoles) &&
                                <WorkspaceFilter updateWorkspaceFilter={this.props.updateWorkspaceFilter}
                                                 workspaceFilter={this.props.workspaceFilter}
                                                 filterList={this.state.filterList}
                                />
                        }
                        </ListSubheader>
                }
                {
                    this.props.workspaceConfig
                            .filter(w => {
                                const wFilter = w.filter ? w.filter : "";
                                if (this.props.workspaceFilter !== "" ){
                                    return(wFilter === this.props.workspaceFilter)
                                } else {
                                    return(w)
                                }
                            })
                            .map(w => {

                            let grouping = "";
                            let newGrouping = false;
                            let title = w.title;

                            if (w.title.indexOf("\\") > -1)  {
                                grouping = w.title.split("\\")[0];
                                //check if grouping has already been rendered
                                if (groupingList.indexOf(grouping) === -1) {
                                    groupingList.push(grouping);
                                    newGrouping = true
                                }
                                title = w.title.split("\\")[1]
                            }

                            //need an array of returnElements as Menu doesn't permit React.Fragment
                            let returnElements = [];

                            if (grouping !== "" && newGrouping ){
                                //grouping header
                                returnElements.push(
                                    <ListItem button onClick={this.handleExpandCollapse(grouping)}
                                              key={'li'+ grouping}  id={'li'+ grouping}
                                    >
                                        <ListItemIcon className={classes.listIcon} key={'lii'+ grouping}>{this.state[grouping] ? <ExpandMore/> : <ChevronRight/>}</ListItemIcon>
                                        <ListItemText
                                            className = {classes.topLevelText}
                                            primary={grouping}
                                            key={'lit'+ grouping}
                                            // primaryTypographyProps={{ style: {fontWeight: "bold"} }}
                                        />
                                    </ListItem>)
                            }
                            if (grouping !== "") {
                                //nested list item
                                returnElements.push(
                                    <Collapse in={this.state[grouping]} timeout="auto" unmountOnExit>
                                        <ListItem
                                            className={classes.nestedLevel1}
                                            button={true}
                                            key={w.id}
                                            id={w.id}
                                            onClick={() => this.props.handleClickMenuItem(w)}
                                            // disabled={w.id === this.props.selectedWorkspaceConfig.id && this.props.mode === "search"}
                                            selected={w.id === this.props.selectedWorkspaceConfig.id && this.props.mode === "search"}
                                            autoFocus={w.id === this.props.selectedWorkspaceConfig.id && this.props.mode === "search" && this.props.enableAutofocus}
                                        >
                                            <ListItemIcon
                                                className = {classes.listIconNested}
                                                key={"lii" + w.id}>
                                                {
                                                    w.icon === "customIcon-1" ?

                                                        <div style={{display: "inline"}}>
                                                            <CustomIcon fill={w.iconColour} style={{ width: "22px",height: "22px"}} key={"i" + w.id}/>
                                                        </div> :

                                                    <i className='material-icons'
                                                       style={{color: w.iconColour, fontSize: "22px"}} key={"i" + w.id}>
                                                        {w.icon}
                                                    </i>
                                                }
                                            </ListItemIcon>
                                            <ListItemText className={classes.menuListItem} primary={translate(title)} key={"lit" + w.id}/>
                                        </ListItem>
                                    </Collapse>
                                );
                            } else {
                                //ungrouped
                                returnElements.push(
                                    <ListItem
                                        className={classes.listItemText}
                                        button={true}
                                        key={w.id}
                                        id={w.id}
                                        onClick={() => this.props.handleClickMenuItem(w)}
                                        // disabled={w.id === this.props.selectedWorkspaceConfig.id && this.props.mode === "search"}
                                        selected={w.id === this.props.selectedWorkspaceConfig.id && this.props.mode === "search"}
                                        autoFocus={w.id === this.props.selectedWorkspaceConfig.id && this.props.mode === "search" && this.props.enableAutofocus}
                                    >
                                        <ListItemIcon
                                            className = {classes.listIcon}
                                            key={"lii" + w.id}>
                                            <i className='material-icons'
                                               style={{color: w.iconColour}} key={"i" + w.id}>
                                                {w.icon}
                                            </i>
                                        </ListItemIcon>
                                        <ListItemText className={classes.menuListItem} primary={translate(title)} key={"lit" + w.id}/>
                                    </ListItem>
                                );
                            }

                            return(returnElements)
                        }
                    )
                }
            </React.Fragment>
        );
    }
}

WorkspaceMenuList.propTypes = {
    classes: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    handleClickMenuItem: PropTypes.func.isRequired,
    listTitle: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    enableAutofocus: PropTypes.bool,
    workspaceFilter: PropTypes.string.isRequired,
    updateWorkspaceFilter: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withTranslation()((withStyles(styles, { withTheme: true })(WorkspaceMenuList)));