import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({});

const INITIAL_STATE = {};

class SearchCriteriaTooltip extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

    }

    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchCriteriaTooltip props = ', this.props);
        let searchCriteriaStr = "";

        this.props.searchCriteria.map(criteria => {
           //window.location.pathname.toLowerCase().includes("debug") && console.log ('criteria=', criteria);

            let templateConfig = this.props.metadataConfig[criteria.templateKey];
            if (templateConfig) {
                let metadataConfig = templateConfig.metadata[criteria.metadataKey];
                if (metadataConfig) {
                    searchCriteriaStr = searchCriteriaStr === "" ?  searchCriteriaStr : searchCriteriaStr + ", ";
                    searchCriteriaStr = searchCriteriaStr + templateConfig.templateLabel + " " + metadataConfig.label + " = " + criteria.value ;
                }
            }
            return null;
        });

        let tooltipTitle = this.props.searchCriteriaSimple !== "" ?
            'Showing results for "'+ this.props.searchCriteriaSimple +'"' :
            this.props.searchCriteria.length === 0 ? "Showing all files in folder" : "Showing results for: " + searchCriteriaStr;


        return (
                <Tooltip title={tooltipTitle}>
                    <IconButton color="inherit"
                                aria-label="Search criteria"
                                onClick={this.showAddDocDialog}>
                        <i className={'material-icons md-36 ' + this.props.iconClass}>
                            info
                        </i>
                    </IconButton>
                </Tooltip>

        );
    }
}

SearchCriteriaTooltip.propTypes = {
    classes: PropTypes.object,
    metadataConfig: PropTypes.object.isRequired,
    iconClass: PropTypes.string.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    searchCriteria: PropTypes.array.isRequired
};


export default withSnackbar(withStyles(styles, { withTheme: true })(SearchCriteriaTooltip));
