import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from 'notistack';
import Button from "@material-ui/core/Button";
import {getCaseField, getErrorMessageFromResponse} from "../../common/helper";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});

const INITIAL_STATE = {
    //dialog
    open: false,
    metadata: {},
};

class EditFolderButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = INITIAL_STATE;

        window.location.pathname.toLowerCase().includes("debug") && console.log('*** Edit Folder Button, props = ', props);

    }

    getMetadata = async () => {


        window.location.pathname.toLowerCase().includes("debug") && console.log('EditFolderButton getFolderMetadata');

        const pathVar = "/" + this.props.folderDetails.id + "/metadata";
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar;

        const request = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('getMetadata url:' , url, 'request:' , request);

        await this.props.triggerRefreshAuthToken();
        let response = await (fetch(url,request));

        window.location.pathname.toLowerCase().includes("debug") && console.log ('getMetadata response = ', response);

        let responseCheck = await response.ok;

        if (responseCheck) {
            let data = await response.json();
            window.location.pathname.toLowerCase().includes("debug") &&  console.log('response.json =', data);
            return data;
        } else {
            Promise.resolve(getErrorMessageFromResponse(response, 'getting metadata'))
                .then(message => {
                    this.props.enqueueSnackbar(message, {variant: 'error'});
                })
            window.location.pathname.toLowerCase().includes("debug") && console.log("getMetadata error. response.statusText:", response.statusText, {variant: 'error'});
            return null
        }
    };

    setMetadataAsync = () => {

        //async/await with forEach() - see https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
        //generic asyncForEach function - pass array and function to execute as parameters
        async function asyncForEach(
            array,
            callback
        ) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        }

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            //Get latest Metadata from Box
            await this.props.triggerRefreshAuthToken();
            let getMetadataResponse = await this.getMetadata();
            let response = await getMetadataResponse;

            window.location.pathname.toLowerCase().includes("debug") && console.log ('getMetadata allMetadata = ' + JSON.stringify(response));

            let allMetadata = [...response]; //clone, don't want to update original object

            let progressCounter = 0;

            if (allMetadata) {

                window.location.pathname.toLowerCase().includes("debug") && console.log ('allMetadata = '  , allMetadata)

                //TODO Also check for any new metadata templates that don't already exist on the folder but that we have data for
                //go through folderDetails to check for any new metadata values we want to set for this template

                let templateNames = [];
                for (let i = 0; i < allMetadata.length; i++) {
                    templateNames.push(allMetadata[i]["$template"])
                }

                Object.entries(this.props.folderDetails).forEach(entry => {

                    if (entry[0].includes("~")) {
                        let templateName = entry[0].split("~")[0]
                        if (!templateNames.includes(templateName)) {
                            allMetadata.push({"$template": templateName})
                            templateNames.push(templateName)
                    }}
                });
                this.setState ({isFetching: true});
                this.props.updateIsSaving(true);

                let unsuccessful = [];
                let successful = [];

                await asyncForEach(
                    allMetadata,
                    async (metadata) => {

                        const templateName = metadata["$template"];

                        progressCounter = progressCounter + 1;
                        window.location.pathname.toLowerCase().includes("debug") && console.log (progressCounter, templateName, " metadata = "  , metadata);

                        this.setState({uploadProgress: progressCounter});

                        let setMetadataResponse = await this.setMetadata(metadata);
                        let result = await setMetadataResponse;

                        window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata result = ', result);
                        if (!result){
                            window.location.pathname.toLowerCase().includes("debug") && console.log ('fail')
                            unsuccessful.push(templateName)
                        } else {
                            successful.push(templateName)
                        }
                    });

                this.setState({isFetching: false});
                this.props.updateIsSaving(false)

                window.location.pathname.toLowerCase().includes("debug") && console.log('Successful: ', successful, 'Unsuccessful:', unsuccessful);

                if (unsuccessful.length === 0) {
                    this.props.enqueueSnackbar("Updates successful.", {variant: 'success'});
                    this.props.resetMetadataHasChanged();
                    //this.props.resetMetadataHasChanged();

                    if (this.props.actionConfig.remountFolderContentOnSave && this.props.unmountFolderContent) {
                        console.log ("*** DO remountFolderContentOnSave props=", this.props)
                        this.props.unmountFolderContent();
                    } else {
                        console.log ("*** don't remountFolderContentOnSave props=", this.props)
                    }


                } else {
                   if (unsuccessful.length === allMetadata.length) {
                       this.props.enqueueSnackbar("Updates unsuccessful, please retry.", {variant: 'error'});
                   } else {
                       this.props.enqueueSnackbar("Updates partially successful.  Failed to update the following templates: " + unsuccessful.toString() + ". Please retry.", {variant: 'error'});
                   }
                }
            }

        };

        //start upload
        start();

    };

    async setMetadata(metadata) {

        try {

            //Set Metadata
            let templateName = metadata["$template"]

            window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata templateName = ', templateName, ' metadata = ' , metadata);

            //construct body from metadata
            let body = metadata;

            //go through folderDetails to check for any new metadata values we want to set for this template
            Object.entries(this.props.folderDetails).forEach(entry => {
                if (entry[0].includes("~")) {
                    if (entry[0].split("~")[0] === templateName) {
                        let metadataKey = entry[0].split("~")[1];
                        body[metadataKey] = entry[1];
                    }
                }
            });

            for (const key of Object.keys(body)) {
                if (key.startsWith("$")) {
                    //exclude $ entries from request
                    delete(body[key]);
                } else if(key.endsWith("_markdown")){
                    //Check for any markdown entries, remove them and update the corresponding metadatakey
                    let metadataKey = key.substring(0, key.indexOf("_markdown"))
                    body[metadataKey] = body[key]
                    delete(body[key]);
                }
            }

            window.location.pathname.toLowerCase().includes("debug") && console.log ("body: " , body);

            const pathVar = "/" + this.props.folderDetails.id + "/metadata/enterprise_" + window.REACT_APP_ENTERPRISE_ID + "/" + templateName;
            let params = "";

            //indicate if all metadata to be cascaded (will use box cascade policy)
            let cascade = "false";
            if (this.props.addFolderConfig.createCascadeFor) {
                if (this.props.addFolderConfig.createCascadeFor.indexOf(templateName) !== -1) {
                    //force cascading of this metadata template
                    cascade = "true"
                }
            }

            //if only specific fields to be cascaded then specify them (using custom cascade policy)
            //POST /api/box/folder/{id}/metadata/{scope}/{templateName}?cascade=true&cascadeFields=fieldA,fieldC
            let cascadeFields = "";
            if (this.props.actionConfig.cascade && this.props.actionConfig.cascade[templateName]){
                if (this.props.actionConfig.cascade[templateName].length > 0){
                    cascade="true"
                    cascadeFields = this.props.actionConfig.cascade[templateName].toString();
                }
            }

            params = "?cascade="+ cascade + (cascadeFields !== "" ? "&cascadeFields=" + cascadeFields : "");

            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + params;

            const request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata url:' , url, 'BODY:', body, 'request:' , request);

            //fetch
            await this.props.triggerRefreshAuthToken();
            let response = await (fetch(url,request));

            window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata response = ', response);

            let result = await response.ok;

            if (result) {
                return result;
            } else {
                Promise.resolve(getErrorMessageFromResponse(response, 'setting metadata'))
                    .then(message => {
                        this.props.enqueueSnackbar(message, {variant: 'error'});
                    })
                window.location.pathname.toLowerCase().includes("debug") && console.log("setMetadata error. response.statusText:", response.statusText, {variant: 'error'});
                return result
            }

        } catch(err) {
            window.location.pathname.toLowerCase().includes("debug") && console.log("setMetadata error: " + err);
            return false;
        }

    };

    setCaseMetadata = async () => {

        window.location.pathname.toLowerCase().includes("debug") && console.log('edit case metadata');

        let metadata = {...this.props.folderDetails.metadata};
        //Update metadata object with values from folderDetails

        Object.entries(this.props.folderDetails).forEach(entry => {
            if (entry[0].indexOf("~") > -1) {
                let val = entry[1];
                if (val && typeof val === 'object') {
                    let dateVal = new Date(val)
                    dateVal.setUTCHours(0, 0, 0, 0);
                    val = dateVal.valueOf();
                }

                const metadataKey = entry[0].split("~")[1];
                const templateKey = entry[0].split("~")[0];
                const elasticField = getCaseField(this.props.metadataConfig, templateKey, metadataKey);

                if (metadata.hasOwnProperty(elasticField)) {
                    metadata[elasticField] = val
                }
            }
        });

        let body = {
            metadata: metadata,
            id: this.props.folderDetails.id,
            box_id: this.props.folderDetails.box_id,
            case_type: this.props.folderDetails.case_type
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' body= ', body);

        await this.props.triggerRefreshAuthToken();

        const indexes = this.props.searchConfig.elastic.indexes[0]; //TODO temporarily get first index
        const pathVar =  indexes.length > 0 ?  "/" + indexes.toString()  : "";
        const url = window.REACT_APP_CASE_API_BASE_URL + pathVar + "/" ;
        const request = {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' edit case metadata url = ', url, ' BODY=', body, 'request=', request);
        this.setState({isFetching: true});
        this.props.updateIsSaving(true);

        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log( 'response=', response);
                    this.props.enqueueSnackbar("Metadata successfully updated.", {variant: 'success'});
                    this.setState({isFetching: false});
                    this.props.updateIsSaving(false);
                    this.props.resetMetadataHasChanged();

                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' response=', response);

                    Promise.resolve(getErrorMessageFromResponse(response, 'saving metadata'))
                        .then(message => {
                            this.props.enqueueSnackbar(message, {variant: 'error'})
                            window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' updateMetadata error.  url:', url, 'request: ', request);
                            this.setState({isFetching: false});
                            this.props.updateIsSaving(false);
                        })
                }
            })
    }

    render() {

        return (
            <React.Fragment>
                <Button onClick={ window.REACT_APP_FOLDER_SOURCE === "elastic" ? this.setCaseMetadata : this.setMetadataAsync}
                        variant = "contained"
                        color = "primary"
                        disabled={this.state.isFetching}>
                    {this.state.isFetching ? "Updating..." : this.props.actionConfig.label}
                </Button>
            </React.Fragment>
        );
    }
}

EditFolderButton.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    resetMetadataHasChanged: PropTypes.func.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    updateIsSaving: PropTypes.func,
    searchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    unmountFolderContent: PropTypes.func.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(EditFolderButton));
