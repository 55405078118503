import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import ActionDialogDocument from "./ActionDialogDocument";
import {getUserValue} from "../../search/helper";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class ActionButtonDocument extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('ActionButtonDocument props = ', props);
    };

    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = () => {

        this.setState({
            showDialog: false
        });
    }

    actionButtonEnabled = () => {

        const action = this.props.actionConfig;
        let documentDetails = this.props.documentDetails;

        //check if file type restrictions - hide if fileType listed in hideForFileType
        let hideForThisFileType = false;
        const hideForFileType = action.hideForFileType;
        if (hideForFileType && hideForFileType.length > 0) {
            const fileType = documentDetails.name.substring(documentDetails.name.lastIndexOf("."));
            if (hideForFileType.indexOf(fileType) > -1) {
                hideForThisFileType = true
            }
        }

        let enable = false;
        if (!hideForThisFileType) {
            //Enabled if all criteria in config is met
            let enableWhen = action.enableWhen;
            if (enableWhen) {
                for (let i = 0; i < enableWhen.length; i++) {
                    let metadataValue;
                    if (enableWhen[i].templateKey ==="n/a") {
                        if (documentDetails[action.enableWhen[i].metadataKey] || documentDetails[action.enableWhen[i].metadataKey] === false) {
                            metadataValue = documentDetails[action.enableWhen[i].metadataKey].toString();
                        }
                    }
                    else if (documentDetails[enableWhen[i].templateKey + "~" + action.enableWhen[i].metadataKey] || documentDetails[enableWhen[i].templateKey + "~" + action.enableWhen[i].metadataKey] === false) {
                        metadataValue = documentDetails[action.enableWhen[i].templateKey + "~" + action.enableWhen[i].metadataKey].toString();
                    }

                    //check if any of the values are "$USER_EMAIL", if they are then replace with user email
                    let values = enableWhen[i].values;
                    if (values.includes ('$USER_EMAIL')){
                        const userEmail=getUserValue("$USER_EMAIL", this.props.userDetails)
                        //replace instances of $USER_EMAIL with the user's email address
                        for (i=0;i<values.length;i++) {
                            if (values[i] === "$USER_EMAIL") {
                                values[i] = userEmail;
                            }
                        }
                    }

                   //if (metadataValue && enableWhen[i].values.includes(metadataValue)) {
                    if (metadataValue && values.includes(metadataValue)) {
                        enable = true;
                    } else {
                        enable = false;
                        break;
                    }
                }
            }
        }

        return enable;
    }

    actionButtonDisabled = () => {

        const action = this.props.actionConfig;
        let documentDetails = this.props.documentDetails;

        let disabled = false;

        //disabled if all criteria in config is met
        let disableWhen = action.disableWhen;
        if (disableWhen) {
            for (let i = 0; i < disableWhen.length; i++) {
                let metadataValue;
                if (documentDetails[disableWhen[i].templateKey + "~" + action.disableWhen[i].metadataKey] || documentDetails[disableWhen[i].templateKey + "~" + action.disableWhen[i].metadataKey] === false) {
                    metadataValue = documentDetails[action.disableWhen[i].templateKey + "~" + action.disableWhen[i].metadataKey].toString();
                }
                if (metadataValue && disableWhen[i].values.includes(metadataValue)) {
                    disabled = true;
                    break;
                } else {
                    disabled = false;
                }
            }
        }

        return disabled;
    }

    render() {

        const { anchorEl } = this.state;

        let isEnabled = this.actionButtonEnabled();
        let isDisabled = this.actionButtonDisabled();

        return (
            <div style={{display: "inline"}}>
                {
                    isEnabled &&
                        <React.Fragment>
                            <Tooltip title={this.props.actionConfig.label}>
                                <IconButton
                                    aria-label={this.props.actionConfig.label}
                                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                                    aria-haspopup="true"
                                    color={this.props.actionConfig.iconColour ? this.props.actionConfig.iconColour : "secondary"}
                                    onClick={this.handleClickIcon}
                                    disabled={isDisabled}
                                >
                                    <i className='material-icons'
                                       style={{color: isDisabled ? "grey" : this.props.actionConfig.iconColour}}>
                                        <i className="material-icons">{this.props.actionConfig.icon}</i>
                                    </i>


                                </IconButton>

                            </Tooltip>
                            {
                                (this.state.showDialog) &&
                                <ActionDialogDocument
                                    documentDetails={this.props.documentDetails}
                                    folderId={this.props.folderId}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    handleCloseDialog={this.handleCloseDialog}
                                    actionConfig={this.props.actionConfig}
                                    unmountSearchResults={this.props.unmountSearchResults}
                                    metadataConfig={this.props.metadataConfig}
                                    updateFolderDetails={this.props.updateFolderDetails}
                                    reloadWorkspace={this.props.reloadWorkspace}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                />
                            }
                    </React.Fragment>
                }

            </div>
        );
    }
}

ActionButtonDocument.propTypes = {
    documentDetails: PropTypes.object.isRequired,
    folderId: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    unmountSearchResults: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    reloadWorkspace: PropTypes.func,
    selectedWorkspaceConfig: PropTypes.object
};

export default withStyles(styles)(ActionButtonDocument);